import { toThousand } from 'tool'
import dayjs from 'dayjs'
export default {
	name: 'tableExpand',
	functional: true,
	props: {
		field: String,
		row: Object,
		index: Number,
		render: Function,
		config: Object
	},
	render: (h, ctx) => {
		const params = {
			row: ctx.props.row,
			field: ctx.props.field,
			config: ctx.props.config
		}
		if (ctx.props.render) {
			return ctx.props.render(h, params)
		} else if (params.config.isMoney) {
			return h('span', toThousand(params.row[params.field]))
		} else if (params.config.isDate && params.config.dateFormat) {
			return h(
				'span',
				dayjs(params.row[params.field]).format(params.config.dateFormat)
			)
		} else {
			return h('span', params.row[params.field])
		}
	}
}
