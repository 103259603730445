var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-editor", style: { height: _vm.height + "px" } },
    [
      _c("quill-editor", {
        ref: "QuillEditor",
        staticClass: "wy-ql-editor",
        style: { height: _vm.height - 66 + "px" },
        attrs: { options: _vm.editorOption },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _c(
        "el-upload",
        {
          staticClass: "wy-el-upload-group",
          attrs: {
            action: "/api/file/uploadFiles",
            "on-success": _vm.handleUploadSuccess,
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }