var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-layout-page-set" }, [
    _c("header", { staticClass: "wy-layout-page-set_top" }, [
      _c("div", { staticClass: "wy-layout-page-set_header" }, [
        _c(
          "h1",
          {
            staticClass: "wy-layout-page-set_title",
            attrs: { "data-guide": "layout-page-set-title" },
          },
          [_vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")]
        ),
        _c(
          "div",
          { staticClass: "wy-layout-page-set_btn-panel" },
          [_vm._t("btn-group")],
          2
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "wy-layout-page-set_main" },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }