var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-layout-page-tab" }, [
    _c(
      "div",
      { staticClass: "wy-layout-page-tab_container" },
      [
        _c(
          "div",
          { staticClass: "wy-layout-page-tab_btn-panel" },
          [_vm._t("btn-group")],
          2
        ),
        _vm._t("tab"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }