<template>
	<div
		class="wy-popper"
		@click="handleClick"
		@mouseenter="handleMouseenter"
		@mouseleave="handleMouseleave"
	>
		<slot></slot>
		<transition name="fade">
			<div
				v-show="visible"
				ref="popper"
				class="wy-popper-content"
				:style="setStyle"
				@mouseenter="handleMouseenter"
				@mouseleave="handleMouseleave"
			>
				<slot name="content"></slot>
			</div>
		</transition>
	</div>
</template>

<script>
import Popper from 'popper.js'
import { contains, nextIndex, off, on } from 'tool'

export default {
	name: 'WyPopper',
	props: {
		width: String,
		padding: {
			type: String,
			default: '10px'
		},
		trigger: {
			type: String,
			default: 'click',
			validator: value => ['click', 'hover'].includes(value)
		},
		placement: {
			type: String,
			default: 'bottom-start'
		},
		delayOnMouseOver: {
			type: Number,
			default: 10
		},
		delayOnMouseOut: {
			type: Number,
			default: 10
		},
		disabled: {
			type: Boolean,
			default: false
		},
		content: String,
		forceShow: {
			type: Boolean,
			default: false
		},
		visibleArrow: {
			type: Boolean,
			default: true
		},
		options: {
			type: Object,
			default() {
				return {
					gpuAcceleration: false
				}
			}
		},
		delay: {
			// hover时消失的时间
			type: Number,
			default: 300
		},
		boundariesSelector: {
			type: String,
			default: 'body'
		}
	},
	data() {
		return {
			reference: null,
			referenceWidth: 'auto',
			popperJS: null,
			visible: false,
			currentPlacement: '',
			popperOptions: {
				computeStyle: {
					gpuAcceleration: false
				},
				modifiers: {}
			},
			timeout: null,
			zIndex: 0
		}
	},
	computed: {
		setStyle() {
			const style = {}
			if (this.width) {
				style.width = this.width
			} else {
				style.width = this.referenceWidth + 'px'
			}
			if (this.padding) {
				style.padding = this.padding
			}
			style.zIndex = this.zIndex
			return style
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.$emit('show', this)
				this.updatePopper()
				this.referenceWidth = this.reference.offsetWidth
				this.zIndex = nextIndex(5000, 10000)
			} else {
				this.$emit('hide', this)
			}
		},
		disabled(value) {
			if (value) {
				this.visible = false
			}
		}
	},
	created() {
		this.appendedArrow = false
		this.appendedToBody = false
		if (this.trigger == 'click') {
			on(document, 'click', this.handleDocumentClick)
		}
	},
	mounted() {
		this.reference = this.$el
		this.popper = this.$slots.content[0].elm.parentElement
	},
	beforeDestroy() {
		off(document, 'click', this.handleDocumentClick)
		this.visible = false

		if (this.popperJS) {
			this.popperJS.destroy()
			this.popperJS = null
		}
		if (this.appendedToBody) {
			this.appendedToBody = false
			document.body.removeChild(this.popper)
		}
	},
	methods: {
		show() {
			this.visible = true
		},
		hide() {
			this.visible = false
		},
		handleClick() {
			if (this.trigger != 'click') {
				return
			}
			if (this.disabled) {
				return
			}
			this.visible = !this.visible
		},
		handleDocumentClick(e) {
			if (
				contains(this.reference, e.target) ||
				contains(this.popper, e.target)
			) {
				return
			}
			this.hide()
		},
		handleMouseenter() {
			clearTimeout(this.timeout)
			if (this.trigger != 'hover') {
				return
			}
			if (this.disabled) {
				return
			}
			this.show()
		},
		handleMouseleave() {
			if (this.trigger != 'hover') {
				return
			}
			if (this.disabled) {
				return
			}
			this.timeout = setTimeout(() => {
				this.hide()
			}, this.delay)
		},
		createPopper() {
			if (this.visibleArrow) {
				this.appendArrow(this.popper)
			}
			if (!this.appendedToBody) {
				this.appendedToBody = true
				document.body.appendChild(this.popper)
			}
			if (this.popperJS && this.popperJS.destroy) {
				this.popperJS.destroy()
			}
			this.popperOptions = Object.assign(
				{},
				this.options,
				this.popperOptions
			)
			this.popperOptions.placement = this.placement
			if (this.boundariesSelector) {
				const boundariesElement = document.querySelector(
					this.boundariesSelector
				)
				if (boundariesElement) {
					this.popperOptions.modifiers = Object.assign(
						{},
						this.popperOptions.modifiers
					)
					this.popperOptions.modifiers.preventOverflow =
						Object.assign(
							{},
							this.popperOptions.modifiers.preventOverflow
						)
					this.popperOptions.modifiers.preventOverflow.boundariesElement =
						boundariesElement
				}
			}

			this.popperOptions.onCreate = () => {
				this.$emit('created', this)
				this.updatePopper()
			}

			this.popperJS = new Popper(
				this.reference,
				this.popper,
				this.popperOptions
			)
		},
		appendArrow(element) {
			if (this.appendedArrow) {
				return
			}
			this.appendedArrow = true
			const arrow = document.createElement('div')
			arrow.setAttribute('x-arrow', '')
			arrow.className = 'wy-popper__arrow'
			element.appendChild(arrow)
		},
		updatePopper() {
			if (this.popperJS) {
				this.popperJS.scheduleUpdate()
			} else {
				this.createPopper()
			}
		}
	}
}
</script>
