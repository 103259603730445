<template>
	<transition name="wy-loader">
		<div v-if="visible" class="wy-loader" :style="{ 'z-index': zIndex }">
			<!--<svg viewBox="25 25 50 50" class="svg" :class="{active: visible}">-->
			<!--<circle cx="50" cy="50" r="20" fill="none" class="path"></circle>-->
			<!--</svg>-->
			<div class="wy-loader-box"></div>
			<div class="wy-loader-gif">
				<img :src="require('../../src/img/loading.gif')" alt="我家云" />
			</div>
			<div
				v-if="content"
				class="wy-loader_content"
				v-html="content"
			></div>
		</div>
	</transition>
</template>
<script>
export default {
	name: 'WyLoader',
	props: {
		options: {}
	},
	data() {
		return {
			visible: false,
			num: 0,
			content: null,
			zIndex: 10000
		}
	},
	created() {},
	methods: {}
}
</script>
