/*
 * @Description:
 * @Author: qxia
 * @Date: 2022-01-10 10:53:47
 * @LastEditTime: 2022-06-06 16:21:17
 * @LastEditors: qxia
 */
import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from './upload-file.vue'

const defaultOpt = {
	width: '800px',
	height: '560px',
	downLoadURL: '',
	tableItem: [],
	title: '',
	parseAndSaveURL: '',
	isSave: true,
	onCancel: Function,
	onOk: Function,
	formatTableData: null,
	isAsyn: false, // 是否异步执行上传
	showProgress: true, // 查询进度 显示进度条or loading 根据实际业务做判断 数据量大时显示进度条
	sendTime: 2000, // 查询频率  默认每隔2s查询一次
	isCheckUnPass: true,
	btnList: [],
	typeList: [],
	uploadType: '1', // 上传类型（1添加新数据 2覆盖已有数据 3仅覆盖重复数据）
	barTip: '',
	downloadBtnName: '下载模板',
	uploadBtnName: '上传文件',
	downloadReportURL: '', // 下载检验报告
	isShowTemp: true, // 是否显示下载模板按钮
	customButtons: [],
	loading: false
}

let instance

/**
 * @param opt
 */
export default function uploadFile(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.width = options.width
	instance.height = options.height
	instance.downLoadURL = options.downLoadURL
	instance.tableItem = options.tableItem
	instance.title = options.title
	instance.isSave = options.isSave
	instance.parseAndSaveURL = options.parseAndSaveURL
	instance.onCancel = options.onCancel
	instance.onOk = options.onOk
	instance.formatTableData = options.formatTableData
	instance.isAsyn = options.isAsyn
	instance.showProgress = options.showProgress
	instance.sendTime = options.sendTime
	instance.isCheckUnPass = options.isCheckUnPass
	instance.btnList = options.btnList
	instance.typeList = options.typeList
	instance.barTip = options.barTip
	instance.downloadBtnName = options.downloadBtnName
	instance.uploadBtnName = options.uploadBtnName
	instance.uploadType = options.uploadType
	instance.downloadReportURL = options.downloadReportURL
	instance.isShowTemp = options.isShowTemp
	instance.filterParams = options.filterParams
	instance.isPreUploadType = options.isPreUploadType
	instance.customButtons = options.customButtons
	instance.loading = options.loading

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wxUploadFileBox',
		id: instance._uid,
		instance
	})

	return instance
}
