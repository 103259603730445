const Cropper = {}
import CropperPlugin from './cropper.js'

/* istanbul ignore next */
Cropper.install = function (Vue) {
	Vue.prototype.$cropper = Vue.$cropper = function cropper() {
		return new CropperPlugin(this)
	}
}

export default Cropper
