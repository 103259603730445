import ProjectTreeBox from './project-tree-box.vue'
import ProjectTreeBoxPlugin from './project-tree-box.js'

/* istanbul ignore next */
ProjectTreeBox.install = function (Vue) {
	Vue.component(ProjectTreeBox.name, ProjectTreeBox)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$projectTreeBox = Vue.$projectTreeBox = ProjectTreeBoxPlugin
}

export default ProjectTreeBox
