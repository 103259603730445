<template>
	<wy-box
		class="wy-uploadFile_box"
		:title="title"
		:visible="visible"
		:loading="loading"
		width="800px"
		height="400px"
		@onOk="submit"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div class="wy-uploadFile">
			<div class="wy-uploadFile_bar">
				<div class="wy-uploadFile_type">
					<el-select
						v-if="typeList.length > 1"
						v-model="type"
						class="wy-border-bottom"
					>
						<el-option
							v-if="typeList.includes(1)"
							key="1"
							value="1"
							label="仅添加新数据"
							>仅添加新数据</el-option
						>
						<el-option
							v-if="typeList.includes(2)"
							key="2"
							value="2"
							label="添加并覆盖数据"
							>添加并覆盖数据</el-option
						>
						<el-option
							v-if="typeList.includes(3)"
							key="3"
							value="3"
							label="仅覆盖已有数据"
							>仅覆盖已有数据</el-option
						>
					</el-select>
					<p
						v-if="typeList.length <= 1 && barTip"
						class="wy-uploadFile_bar_tip"
					>
						{{ barTip }}
					</p>
				</div>
				<!-- 自定义按钮 start -->
				<template v-for="item in customButtons">
					<el-button
						v-if="!item.children || item.children.length === 0"
						:key="item.name"
						class="wy-downloadBtn"
						:type="item.type || 'primary'"
						:plain="item.isPlainBtn"
						size="small"
						@click="customButtonClick(item.event)"
						>{{ item.name }}</el-button
					>
					<template v-else>
						<el-dropdown
							v-if="item.event"
							:key="item.name + '_withEvent'"
							split-button
							:type="item.type || 'primary'"
							@click="customButtonClick(item.event)"
						>
							{{ item.name }}
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-for="btn in item.children"
									:key="btn.field"
									:disabled="!btn.event"
									@click.native="customButtonClick(btn.event)"
									>{{ btn.name }}</el-dropdown-item
								>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown v-else :key="item.name">
							<el-button :type="item.type || 'primary'">
								{{ item.name }}
								<i
									class="el-icon-arrow-down el-icon--right"
								></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-for="btn in item.children"
									:key="btn.field"
									:disabled="!btn.event"
									@click.native="customButtonClick(btn.event)"
								>
									{{ btn.name }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</template>
				<!-- 自定义按钮 end -->
				<el-button
					v-if="isShowTemp"
					class="wy-downloadBtn"
					type="primary"
					size="small"
					@click="downloadTmp"
					>{{ downloadBtnName }}</el-button
				>
				<el-upload
					class="wy-el-upload"
					action="/jar/api/file/tempFiles"
					name="files"
					:multiple="false"
					:show-file-list="false"
					:on-success="handleUploadSuccess"
					:before-upload="beforeUpload"
				>
					<el-button size="small" type="primary">{{
						uploadBtnName
					}}</el-button>
				</el-upload>
			</div>
			<div class="wy-uploadFile_table-flex">
				<el-table
					ref="table"
					:data="tableData"
					border
					height="320px"
					style="width: 100%"
					:row-class-name="tableRowClassName"
				>
					<el-table-column
						type="index"
						label="序号"
						width="50"
					></el-table-column>
					<el-table-column
						v-for="item in tableItem"
						:key="item.prop"
						:prop="item.prop"
						:label="item.label"
						:width="item.width"
					></el-table-column>
					<el-table-column prop="result" label="校验结果" width="140">
						<template slot-scope="scope">
							<span v-if="scope.row.result" style="color: red">{{
								scope.row.result
							}}</span>
							<span
								v-if="!scope.row.result"
								style="color: #00d1a0"
								>通过</span
							>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="wy-uploadFile_result">
				<span>
					已校验通过：
					<span style="color: #00d1a0">{{ passNum }}</span
					>条，
				</span>
				<span>
					校验未通过：
					<span style="color: red">{{ unPassNum }}</span
					>条。
				</span>
				<el-link
					v-if="
						typeList.length > 0 &&
						unPassNum > 0 &&
						downloadReportURL
					"
					type="primary"
					class="wy-uploadFile_downBtn"
					:underline="false"
					@click="downLoadReport"
				>
					下载错误报告
				</el-link>
			</div>
		</div>
		<div
			v-if="btnList.length > 0"
			slot="footer"
			class="wy-uploadFile_ctrl-btn"
		>
			<el-button
				v-for="(item, index) in btnList"
				:key="index"
				class="wy-uploadFile_btn"
				type="primary"
				@click="uploadEvent(item)"
				>{{ item.name }}</el-button
			>
			<el-button class="wy-uploadFile_btn" @click="cancel">
				取消
			</el-button>
		</div>
	</wy-box>
</template>

<script>
import { getType } from 'tool'

export default {
	name: 'UploadFile',
	props: {
		// 文档模板下载链接，支持string和function
		downLoadURL: {
			type: [String, Function],
			default: ''
		},
		// 解析和保存地址
		parseAndSaveURL: {
			type: String,
			default: ''
		},
		tableItem: {
			type: Array,
			default: () => []
		},
		// 标题
		title: {
			type: String,
			default: ''
		},
		isSave: {
			type: Boolean,
			default: true
		},
		// 是否异步执行上传
		isAsyn: {
			type: Boolean,
			default: false
		},
		// 查询进度 显示进度条or loading 根据实际业务做判断 数据量大时显示进度条
		showProgress: {
			type: Boolean,
			default: true
		},
		// 查询频率  默认每隔2s查询一次
		sendTime: {
			type: Number,
			default: 2000
		},
		// 是否必须全部校验成功
		isCheckUnPass: {
			type: Boolean,
			default: true
		},
		// 自定义操作按钮
		btnList: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 下载模板按钮名称
		downloadBtnName: {
			type: String,
			default: '下载模板'
		},
		// 上传按钮名称
		uploadBtnName: {
			type: String,
			default: '上传文件'
		},
		// 导入类型
		uploadType: {
			type: String,
			default: '1'
		},
		// 导入类型开启哪几种方式，不开启时默认为1
		typeList: {
			type: Array,
			default() {
				return []
			}
		},
		// 提示语
		barTip: {
			type: String,
			default: ''
		},
		// 下载检验报告地址
		downloadReportURL: {
			type: String,
			default: ''
		},
		// 是否显示下载模板按钮
		isShowTemp: {
			type: Boolean,
			default: true
		},
		// 筛选参数
		filterParams: {
			type: Object,
			default: () => ({})
		},
		// 上传时 uploadType是否需要传值
		isPreUploadType: {
			type: Boolean,
			default: false
		},
		// 用户自定义按钮
		customButtons: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			visible: false,
			zIndex: -100,
			fileName: '',
			fileID: '',
			tableData: [],
			passNum: 0,
			unPassNum: 0,
			unPassIndex: '',
			loading: false,
			type: '1'
		}
	},
	created() {
		this.$nextTick(() => {
			this.type = this.uploadType
		})
	},
	methods: {
		tableRowClassName(data) {
			if (data.row && data.row.result) {
				return 'unPass-row'
			}
		},
		downloadTmp() {
			if (typeof this.downLoadURL === 'function') {
				const url = this.downLoadURL({
					uploadType: this.type,
					passNum: this.passNum,
					unPassNum: this.unPassNum,
					unPassIndex: this.unPassIndex
				})
				url && this.$openFile('/jar' + url)
			} else {
				this.$openFile('/jar' + this.downLoadURL)
			}
		},
		// 用户自定义按钮点击事件
		customButtonClick(event) {
			if (!event) return
			const type = getType(event)
			type === 'function' && event()
		},
		beforeUpload(file) {
			// 前端校验文件后缀
			const reg = ['xls', 'xlsx']
			const _index = file.name.lastIndexOf('.') + 1
			const cext = file.name.substring(_index)
			if (!reg.includes(cext)) {
				this.$message({
					type: 'warning',
					message: '请上传后缀为xls或者xlsx的excel文件！'
				})
				return false
			}
		},
		handleUploadSuccess(response, files) {
			this.verification(files.response.data[0].id)
			this.fileName = files.name
			this.fileID = files.response.data[0].id
		},
		verification(id) {
			const obj = {
				fileID: id,
				isSave: false
			}
			let params = Object.assign(obj, this.filterParams)
			if (this.isPreUploadType) {
				params = Object.assign(params, { type: this.type })
			}
			this.$axios({
				type: 'post',
				url: this.parseAndSaveURL,
				data: params,
				loading: true,
				cancelPending: true,
				noLimitTime: true
			}).then(res => {
				if (res.data) {
					if (
						this.formatTableData &&
						typeof this.formatTableData == 'function'
					) {
						this.tableData = this.formatTableData(res.data)
					} else {
						this.tableData = [].concat(res.data)
					}
				}
				this.passNum = 0
				this.unPassNum = 0
				const unPassList = []
				this.tableData.forEach((item, index) => {
					if (item.result) {
						this.unPassNum++
						unPassList.push(index + 1)
					} else {
						this.passNum++
					}
				})
				this.unPassIndex = unPassList.join('、')
				this.$nextTick(() => {
					this.$refs.table.doLayout()
				})
			})
		},
		// 下载检验报告
		downLoadReport() {
			location.href = this.downloadReportURL
		},
		submit() {
			if (this.isCheckUnPass) {
				if (this.unPassNum > 0) {
					this.$message({
						type: 'warning',
						message: '有未通过校验的数据！'
					})
					return
				}
			}
			if (!this.isCheckUnPass) {
				if (this.passNum === 0) {
					this.$message({
						type: 'warning',
						message: '没有校验成功的数据'
					})
					return
				}
			}
			if (this.tableData.length === 0) {
				this.$message({
					type: 'warning',
					message: '请上传有效文件！'
				})
				return
			}
			if (this.isSave) {
				const obj = {
					fileID: this.fileID,
					isSave: true,
					type: this.type
				}
				this.loading = true
				const params = Object.assign(obj, this.filterParams)
				this.$axios({
					type: 'post',
					url: this.parseAndSaveURL,
					data: params,
					loading: true,
					cancelPending: true
				}).then(() => {
					// 异步的情况下 去做进度查询
					if (this.isAsyn) {
						this.$progress({
							keyVal: this.fileID,
							sendTime: this.sendTime,
							showProgress: this.showProgress,
							onSuccess: () => {
								this.tableData = []
								this.fileName = ''
								this.loading = false
								this.visible = false
								this.fileID = ''
								this.passNum = 0
								this.unPassNum = 0
								this.unPassIndex = ''
								this.onOk()
							}
						})
					} else {
						this.tableData = []
						this.fileName = ''
						this.loading = false
						this.visible = false
						this.fileID = ''
						this.passNum = 0
						this.unPassNum = 0
						this.unPassIndex = ''
						this.onOk()
					}
				})
			} else {
				this.loading = false
				this.visible = false
				this.fileName = ''
				this.fileID = ''
				this.passNum = 0
				this.unPassNum = 0
				this.unPassIndex = ''
				this.onOk(this.tableData)
				this.tableData = []
			}
		},
		cancel() {
			this.visible = false
			this.tableData = []
			this.fileName = ''
			this.fileID = ''
			this.passNum = 0
			this.unPassNum = 0
			this.unPassIndex = ''
			if (this.onCancel && typeof this.onCancel == 'function') {
				this.onCancel()
			}
		},
		uploadEvent(opt) {
			if (opt.save) {
				this.submit()
			} else {
				opt.event({
					list: this.tableData,
					fileID: this.fileID,
					uploadType: this.type
				}).then(() => {
					if (!opt.isNotClose) {
						this.visible = false
						this.tableData = []
						this.fileName = ''
						this.fileID = ''
					}
				})
			}
		}
	}
}
</script>
