<template>
	<div class="wy-layout-add">
		<div class="wy-layout-add_top">
			<h1 class="wy-layout-add_title" data-guide="layout-add-title">
				<el-tooltip content="点击查看功能指引">
					<i
						v-if="intro"
						class="iconfont icon-guide wy-pointer"
						@click="showIntro(true)"
					></i>
				</el-tooltip>
				{{ title }}
			</h1>
			<div class="wy-layout-add_btn-group">
				<slot name="btn"></slot>
			</div>
		</div>
		<div v-if="$slots.panel" class="wy-layout-add_panel">
			<slot name="panel"></slot>
		</div>
		<div class="wy-layout-add_main">
			<slot name="content"></slot>
		</div>
		<div v-if="$slots.footer" class="wy-layout-add_footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WyLayoutAdd',
	props: {
		title: String,
		intro: Object,
		name: String
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.showIntro()
	},
	methods: {
		showIntro(replay) {
			if (this.intro) {
				this.$nextTick(() => {
					this.$intro({
						el: this.$el,
						options: this.intro,
						replay
					})
				})
			}
		}
	}
}
</script>
