<template>
	<div class="wy-row" :style="style">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'WyRow',
	props: {
		flexDirection: {
			type: String,
			default: 'row',
			validator: value =>
				['row', 'row-reverse', 'column', 'column-reverse'].includes(
					value
				)
		},
		flexWrap: {
			type: String,
			default: 'nowrap',
			validator: value =>
				['nowrap', 'wrap', 'wrap-reverse'].includes(value)
		},
		justifyContent: {
			type: String,
			default: 'flex-start',
			validator: value =>
				[
					'flex-start',
					'flex-end',
					'center',
					'space-between',
					'space-around'
				].includes(value)
		},
		alignItems: {
			type: String,
			default: 'stretch',
			validator: value =>
				[
					'flex-start',
					'flex-end',
					'center',
					'baseline',
					'stretch'
				].includes(value)
		},
		alignContent: {
			type: String,
			default: 'stretch',
			validator: value =>
				[
					'flex-start',
					'flex-end',
					'center',
					'space-between',
					'space-around',
					'stretch'
				].includes(value)
		}
	},
	data() {
		return {
			project: {}
		}
	},
	computed: {
		style() {
			return {
				flexDirection: this.flexDirection,
				flexWrap: this.flexWrap,
				justifyContent: this.justifyContent,
				alignItems: this.alignItems,
				alignContent: this.alignContent
			}
		}
	},
	watch: {},
	mounted() {},
	methods: {}
}
</script>
