<template>
	<div
		ref="picContent"
		class="wy-show-pic-content"
		:class="{ 'wy-show-pic_header': title }"
	>
		<el-carousel
			:height="height"
			:autoplay="auto"
			:initial-index="index"
			:arrow="list && list.length > 1 ? 'hover' : 'never'"
		>
			<el-carousel-item v-for="(item, index) in list" :key="item">
				<div
					class="wy-show-pic_box"
					@wheel.prevent="mousewheel($event, index)"
				>
					<i class="el-icon-refresh" @click="refresh(index)"></i>
					<img
						:src="item"
						:style="imgStyle[index]"
						class="wy-show-pic_img"
						@mousedown="mousedown($event, index)"
					/>
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
export default {
	name: 'WyShowPic',
	props: {
		title: String,
		width: String,
		height: String,
		index: {
			type: Number,
			default: 0
		},
		list: {
			type: Array,
			default: () => []
		},
		auto: Boolean,
		enableDragAndScale: Boolean
	},
	data() {
		return {
			transformScale: [],
			imgStyle: []
		}
	},
	created() {
		this.$nextTick(() => {
			this.init()
		})
	},
	methods: {
		init() {
			if (this.list && this.list.length) {
				this.list.forEach(() => {
					this.transformScale.push(1)
					this.imgStyle.push({
						left: 0,
						top: 0,
						transform: 'scale(1)'
					})
				})
			}
		},
		/**
		 * 一键复原
		 *
		 * @param index
		 */
		refresh(index) {
			this.$set(this.transformScale, index, 1)
			this.$set(this.imgStyle, index, {
				left: 0,
				top: 0,
				transform: 'scale(1)'
			})
		},
		/**
		 * 鼠标滚轮 放大缩小
		 *
		 * @param el
		 * @param index
		 */
		mousewheel(el, index) {
			if (!this.enableDragAndScale) {
				return
			}
			const val = el.wheelDelta || -el.deltaY // 滚轮滑动方向 - 兼容火狐浏览器
			if (val > 0) {
				// 放大
				this.$set(
					this.transformScale,
					index,
					this.transformScale[index] * 1.1
				)
			} else {
				// 缩小
				this.$set(
					this.transformScale,
					index,
					this.transformScale[index] * 0.9
				)
			}
			this.$set(
				this.imgStyle[index],
				'transform',
				'scale(' + this.transformScale[index] + ')'
			)
		},
		/**
		 * 鼠标点击拖拽
		 *
		 * @param el
		 * @param index
		 */
		mousedown(el, index) {
			if (!this.enableDragAndScale) {
				return
			}
			// 鼠标按下
			const $img = el.target // 图片节点
			const clientWidth = parseInt(this.$refs.picContent.clientWidth)
			const clientHeight = parseInt(this.$refs.picContent.clientHeight)
			/**
			 * 鼠标相对图片的位置
			 *
			 * 因为包含了滚轮放大缩小功能 -> 图片的宽高会变化
			 * 所以需要额外处理相对位置的宽高
			 * clientWidth - 图片可视区域宽度
			 * clientHeight - 图片可视区域高度
			 */
			const positionX =
				el.clientX - $img.offsetLeft + (clientWidth - $img.width) / 2
			const positionY =
				el.clientY - $img.offsetTop + (clientHeight - $img.height) / 2
			el.preventDefault() // 阻止默认事件
			document.onmousemove = e => {
				// 鼠标移动
				e.preventDefault()
				// 用鼠标的位置减去鼠标相对图片的位置，得到图片的位置
				const left = e.clientX - positionX
				const top = e.clientY - positionY
				this.$set(this.imgStyle, index, {
					left: left + 'px',
					top: top + 'px',
					transform: this.imgStyle[index].transform
				})
			}
			document.onmouseup = () => {
				// 鼠标放开
				document.onmousemove = null
				document.onmouseup = null
			}
		}
	}
}
</script>
