var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "wy-loader" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "wy-loader", style: { "z-index": _vm.zIndex } },
          [
            _c("div", { staticClass: "wy-loader-box" }),
            _c("div", { staticClass: "wy-loader-gif" }, [
              _c("img", {
                attrs: {
                  src: require("../../src/img/loading.gif"),
                  alt: "我家云",
                },
              }),
            ]),
            _vm.content
              ? _c("div", {
                  staticClass: "wy-loader_content",
                  domProps: { innerHTML: _vm._s(_vm.content) },
                })
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }