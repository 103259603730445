<template>
	<wy-box
		:visible="visible"
		width="600px"
		height="400px"
		@onOk="save"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div slot="title" class="wy-project-tree-box_bar">
			<h3 class="wy-project-tree-box_title">{{ title }}</h3>
			<span class="wy-project-tree-box_total">
				已选择
				<em class="link">{{ selection.length }}</em
				>个
			</span>
			<el-input
				v-model.trim="keyword"
				class="wy-project-tree-box_search wy-border-bottom"
				placeholder="请输入名称"
				prefix-icon="el-icon-search"
			></el-input>
		</div>
		<el-tree
			ref="tree"
			:data="treeProjectData"
			:props="treeProps"
			:check-on-click-node="true"
			:default-checked-keys="checked"
			:filter-node-method="filterNode"
			:empty-text="treeEmptyText"
			:default-expanded-keys="defaultExpandedKeys"
			node-key="id"
			:show-checkbox="multi"
			@check-change="checkChange"
			@node-click="nodeClick"
		>
			<div slot-scope="{ data }" :class="{ active: isChecked(data) }">
				<i v-if="data.isCompany == 3" class="iconfont icon-project"></i>
				<span class="wy-project-tree-box_name">{{ data.name }}</span>
			</div>
		</el-tree>
	</wy-box>
</template>
<script>
export default {
	name: 'WyTreeBox',
	props: {
		placeholder: {
			type: String,
			default: '请输入关键词...'
		},
		title: {
			type: String,
			default: '选择项目'
		},
		onCancel: Function,
		onOk: Function,
		currentSelection: Array,
		treeProps: {
			type: Object,
			default() {
				return {
					label: 'name',
					children: 'child',
					isLeaf: 'isLeaf'
				}
			}
		},
		multi: Boolean,
		cleanTreeData: Function
	},
	data() {
		return {
			visible: false,
			treeLoaded: false,
			checked: [],
			treeProjectData: [],
			treeOrgData: [],
			keyword: null,
			selection: [],
			isMulti: false,
			defaultExpandedKeys: [],
			dataLength: null
		}
	},
	computed: {
		treeEmptyText() {
			if (this.treeLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		}
	},
	watch: {
		keyword(val) {
			// 清空搜索时也能查询
			// if(val){
			this.$refs.tree.filter(val)
			// }
		},
		currentSelection() {
			this.setChecked()
		}
	},
	created() {},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			const innerArr = []
			const flatObj = (arr, obj = {}) => {
				arr.forEach(item => {
					if (
						Object.prototype.toString.call(item) ===
						'[object Object]'
					) {
						let flag = true
						for (const prop in item) {
							if (Array.isArray(item[prop])) {
								flag = false
								flatObj(item[prop], obj)
							} else {
								obj[prop] = item[prop]
							}
						}
						flag && innerArr.push(JSON.parse(JSON.stringify(obj)))
					}
				})
				this.dataLength = innerArr.length
			}
			this.treeLoaded = false
			this.$axios({
				url: '/orgUnits/getAllOrgUnitV2'
			}).then(res => {
				this.treeLoaded = true
				if (
					this.cleanTreeData &&
					typeof this.cleanTreeData == 'function'
				) {
					this.treeProjectData = [].concat(
						this.cleanTreeData(res.data)
					)
				} else {
					this.treeProjectData = [].concat(res.data)
				}
				this.defaultExpandedKeys = [].concat(res.data.id)
				this.setChecked()
				res.data.child ? flatObj(res.data.child) : ''
			})
		},
		setChecked() {
			if (!this.currentSelection) {
				return
			}
			const checked = []
			this.currentSelection.forEach(item => {
				if (item.id) {
					checked.push(item.id)
					if (this.$refs.tree) {
						this.$refs.tree.setCurrentKey(item.id)
					}
				}
			})
			this.checked = checked
			this.selection = [].concat(this.currentSelection)
			this.defaultExpandedKeys = checked
		},
		filterNode(value, data) {
			if (!value) {
				return true
			}
			value = value.toLocaleUpperCase()
			return data[this.treeProps.label]
				.toLocaleUpperCase()
				.includes(value)
		},
		checkChange() {
			if (this.multi) {
				this.selection = this.$refs.tree.getCheckedNodes(true)
			}
		},
		nodeClick(data) {
			if (!this.multi && data.isCompany == 3) {
				this.selection = [].concat(data)
			}
		},
		isChecked(data) {
			// 单选选中判断
			if (
				!this.multi &&
				this.selection.length > 0 &&
				data.id == this.selection[0].id &&
				data.isCompany == 3
			) {
				return true
			}
			return false
		},
		save() {
			let result = {},
				selection
			if (this.multi) {
				// 多选
				let company = [],
					companyID = [],
					companyName = [],
					project = [],
					projectID = [],
					checkAll = false,
					projectName = []
				selection = this.$refs.tree.getCheckedNodes()
				selection.forEach(item => {
					if (item.isCompany == 3) {
						project.push(item)
						projectID.push(item.id)
						projectName.push(item.name)
					} else {
						company.push(item)
						companyID.push(item.id)
						companyName.push(item.name)
					}
				})
				if (project.length == this.dataLength) {
					checkAll = true
				}
				try {
					if (project.length == 1) {
						throw '只有一个项目'
					}
					project.forEach(item => {
						if (!companyID.includes(item.parentID)) {
							// 所选项目的父节点没选中，属于项目级别
							throw '有组织未全选项目'
						}
					})
					const arr = [].concat(company)
					for (let i = company.length - 1; i >= 0; i--) {
						if (companyID.includes(company[i].parentID)) {
							arr.splice(i, 1)
						}
					}
					if (arr.length != 1) {
						// 所选项目的父节点没选中，属于项目级别
						throw '有组织未全选'
					}
					result = {
						id: projectID.join(','),
						name: arr[0].name + '下' + project.length + '个项目',
						selection: project,
						checkAll
					}
				} catch (e) {
					result = {
						id: projectID.join(','),
						name:
							projectName.length > 1
								? projectName[0] +
								  '等' +
								  projectName.length +
								  '个项目'
								: projectName.join(','),
						selection: project,
						checkAll
					}
				}
			} else {
				selection = this.selection[0]
				result = {
					id: selection.id,
					name: selection.name,
					selection: [].concat(selection)
				}
			}

			if (!this.onOk || typeof this.onOk != 'function') {
				this.keyword = ''
				this.visible = false
				return
			}
			this.onOk(result, () => {
				this.keyword = ''
				this.visible = false
			})
		},
		cancel() {
			this.keyword = ''
			this.visible = false
			typeof this.onCancel == 'function' && this.onCancel()
		}
	}
}
</script>
