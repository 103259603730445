import MoneyDefineBox from './money-define-box.vue'
import MoneyDefineBoxPlugin from './money-define-box.js'

/* istanbul ignore next */
MoneyDefineBox.install = function (Vue) {
	Vue.component(MoneyDefineBox.name, MoneyDefineBox)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$moneyDefineBox = Vue.$moneyDefineBox = MoneyDefineBoxPlugin
}

export default MoneyDefineBox
