<template>
	<transition name="fade">
		<div v-if="visible" class="wy-alert" :style="{ 'z-index': zIndex }">
			<div class="wy-alert_box" :style="{ width: width }">
				<div class="wy-alert_header">
					<template v-if="!$slots.title">{{ title }}</template>
					<slot v-if="$slots.title" name="title"></slot>
				</div>
				<div
					v-if="$slots.default"
					class="wy-alert_content"
					:style="{ height: height, maxHeight: maxHeight }"
				>
					<slot></slot>
				</div>
				<div class="wy-alert_footer">
					<template v-if="!$slots.footer">
						<wy-button theme @click="handleOk">{{
							okBtnName
						}}</wy-button>
						<wy-button @click="handleCancel">{{
							cancelBtnName
						}}</wy-button>
					</template>
					<slot v-if="$slots.footer" name="footer"></slot>
				</div>
				<i
					v-if="showClose"
					class="wy-alert_close iconfont icon-del"
					title="关闭"
					@click="handleClose"
				></i>
			</div>
		</div>
	</transition>
</template>
<script>
import { nextIndex } from 'tool'
export default {
	name: 'WyAlert',
	props: {
		// 标题
		title: {
			type: String,
			default: '提示'
		},
		// 可见
		visible: {
			type: Boolean,
			default: false
		},
		// 长度
		width: {
			type: String,
			default: '600px'
		},
		// 高度
		height: {
			type: String
		},
		// 最大高度
		maxHeight: {
			type: String,
			default: '480px'
		},
		// 确认键名称
		okBtnName: {
			type: String,
			default: '确定'
		},
		// 取消键名称
		cancelBtnName: {
			type: String,
			default: '取消'
		},
		// 是否显示右上角的关闭
		showClose: {
			type: Boolean,
			default: true
		},
		// 提示内容
		message: {
			type: String
		},
		/**
		 * @function onOk
		 * @description 点击确认后发生的事件
		 */
		onOk: {
			type: Function
		},
		/**
		 * @function onCancel
		 * @description 点击取消后发生的事件
		 */
		onCancel: {
			type: Function
		},
		/**
		 * @function onClose
		 * @description 点击关闭后发生的事件
		 */
		onClose: {
			type: Function
		}
	},
	data() {
		return {
			zIndex: 0,
			instance: null
		}
	},
	watch: {
		visible() {
			this.zIndex = nextIndex(5000, 10000)
		}
	},
	created() {
		this.zIndex = nextIndex(5000, 10000)
	},
	methods: {
		handleOk() {
			this.$emit('onOk')
		},
		handleCancel() {
			this.$emit('onCancel')
		},
		handleClose() {
			this.$emit('onClose')
		}
	}
}
</script>
