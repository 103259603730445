<template>
	<transition name="fade">
		<div
			v-if="visible"
			class="wy-box_wrap"
			:style="{ 'z-index': zIndex }"
			tabindex="-1"
		>
			<div
				ref="wyBox"
				class="wy-box"
				:class="{
					'wy-box_one-btn': btns === 1,
					'wy-box_two-btn': btns === 2,
					'wy-box_no-btn': btns === 0
				}"
				:style="{
					width: mWidth || options.width,
					maxWidth: !max ? maxW : ''
				}"
			>
				<div
					v-if="options.showHeader"
					class="wy-box_header"
					v-html="options.title"
				></div>
				<div
					v-if="options.message && !options.template"
					class="wy-box_content"
					:style="{
						height: mHeight || options.height,
						maxHeight: !max ? maxH : '',
						minHeight: mHeight
					}"
					v-html="options.message"
				></div>
				<div
					v-if="options.template && !options.message"
					class="wy-box_content"
					:class="{ 'wy-box_no-header': !options.showHeader }"
					:style="{
						height: mHeight || options.height,
						maxHeight: !max ? maxH : '',
						minHeight: mHeight
					}"
				>
					<div></div>
				</div>
				<div v-if="options.showBtn" class="wy-box_footer">
					<button
						v-if="options.showOkBtn"
						class="wy-box_btn wy-box_btn-theme"
						@click="handleOk"
					>
						{{ options.okBtnName }}
					</button>
					<button
						v-if="options.showCancelBtn"
						class="wy-box_btn"
						@click="handleCancel"
					>
						{{ options.cancelBtnName }}
					</button>
				</div>
				<div class="wy-box_ico">
					<div class="wy-box_filter"></div>
					<i
						v-if="options.showMax && max"
						class="wy-box_max iconfont icon-tuichuzhuanhuan"
						title="恢复默认"
						@click="handleMax"
					></i>
					<i
						v-if="options.showMax && !max"
						class="wy-box_max iconfont icon-quanping"
						title="最大化"
						@click="handleMax"
					></i>
					<i
						v-if="options.showClose"
						class="wy-box_close iconfont icon-del"
						title="关闭"
						@click="handleClose"
					></i>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import { nextIndex, off, on } from 'tool'
import Vue from 'vue'
export default {
	name: 'WyPluginBox',
	props: {
		options: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			visible: false,
			zIndex: 0,
			instance: null,
			isAppendContent: false,
			maxW: '600px',
			maxH: '480px',
			mWidth: null,
			mHeight: null,
			max: false
		}
	},
	computed: {
		btns() {
			if (this.options.showMax) {
				if (this.options.showClose) return 2
				return 1
			} else {
				if (this.options.showClose) return 1
				return 0
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.zIndex = nextIndex(5000, 10000)
				if (!this.isAppendContent && this.options.template) {
					this.createdInstance()
					this.isAppendContent = true
				}
			}
		}
	},
	mounted() {},
	methods: {
		createdInstance() {
			this.max = this.options.defaultMax
			this.maxH = this.options.maxHeight
			this.maxW = this.options.maxWidth || this.options.width
			this.calculate()
			const VueConstructor = Vue.extend(
				Object.assign({ router: this.$router }, this.options.template)
			)
			let initOpt = {}
			if (this.options.templateInitOpt) {
				initOpt = {
					propsData: this.options.templateInitOpt // 这里暂时没有更好的方案，先以propsData传值
				}
			}
			this.instance = new VueConstructor(initOpt)
			for (const i in this.options.templateOpt) {
				this.instance[i] = this.options.templateOpt[i]
			}
			this.instance.$store = this.$store
			this.instance.$parent = this
			// 监听模板组件事件
			this.instance.$on('onOk', () => {
				this.handleOk()
			})
			this.instance.$on('onCancel', () => {
				this.handleCancel()
			})
			this.instance.$on('onClose', () => {
				this.handleClose()
			})
			this.$nextTick(() => {
				const titleNode = this.$el.querySelector('.wy-box_header')
				const filterNode = this.$el.querySelector('.wy-box_filter')
				const footerNode = this.$el.querySelector('.wy-box_footer')
				on(window, 'resize', this.reSize)
				if (this.options.showBtn && this.options.showOkBtn) {
					this.$el.querySelector('button.wy-box_btn-theme').focus()
				} else {
					this.$el.focus()
				}
				this.instance.$mount(
					this.$el.querySelector('.wy-box_content div')
				)
				// 挂载插槽
				const title = this.instance.$el.querySelector('[slot="title"]')
				const filter =
					this.instance.$el.querySelector('[slot="filter"]')
				const footer =
					this.instance.$el.querySelector('[slot="footer"]')
				title && this.mountToSlot(titleNode, title)
				filter && filterNode.appendChild(filter)
				footer && this.mountToSlot(footerNode, footer)
			})
		},
		mountToSlot(parent, newNode) {
			let len = parent.childNodes.length
			while (len--) {
				parent.removeChild(parent.childNodes[len])
			}
			parent.appendChild(newNode)
		},
		fixNumber(n) {
			n = parseInt(n)
			if (n % 2 !== 0) n = n + 1
			return n
		},
		reSize() {
			this.$delay.register('windowReSize', this.calculate, 500)
		},
		calculate() {
			const c =
				110 -
				(this.options.showBtn ? 0 : 60) -
				(this.options.showHeader ? 0 : 50) // herder=50和footer=60高度
			const p = this.options.showHeader ? 0 : 40
			const maxW = this.fixNumber(
				window.innerWidth > 320 ? window.innerWidth - 20 : 300
			)
			const maxH = this.fixNumber(
				window.innerHeight > 300 + c
					? window.innerHeight - c - 60 + p
					: 240 + p
			)
			const maxHeight = parseInt(
				this.options.maxHeight || this.options.height || maxH || 480
			)
			const maxWidth = parseInt(
				this.options.maxWidth || this.options.width || maxW || 600
			)
			this.maxW = Math.min(maxWidth, maxW) + 'px'
			this.maxH = Math.min(maxHeight, maxH) + 'px'
			if (this.max) {
				this.mWidth = maxW + 'px'
				this.mHeight = maxH + 'px'
			}
		},
		handleOk() {
			if (!this.options.onOk || typeof this.options.onOk != 'function') {
				this.hide()
				return
			}
			// onOk 事件必须返回一个promise
			this.options.onOk(this.instance).then(() => {
				this.hide()
			})
		},
		handleCancel() {
			this.hide()
			if (
				this.options.onCancel &&
				typeof this.options.onCancel === 'function'
			) {
				this.options.onCancel()
			}
		},
		handleClose() {
			this.hide()
			if (
				this.options.onClose &&
				typeof this.options.onClose === 'function'
			) {
				this.options.onClose()
			}
		},
		/**
		 * @function handleMax
		 * @description 窗口对大化
		 */
		handleMax() {
			const c =
				110 -
				(this.options.showBtn ? 0 : 60) -
				(this.options.showHeader ? 0 : 50) // herder=50和footer=60高度
			const p = this.options.showHeader ? 0 : 40
			const w = this.fixNumber(
				window.innerWidth > 320 ? window.innerWidth - 20 : 300
			)
			const h = this.fixNumber(
				window.innerHeight > 300 + c
					? window.innerHeight - c - 60 + p
					: 240 + p
			)
			if (!this.max) {
				this.max = true
				this.mWidth = w + 'px'
				this.mHeight = h + 'px'
			} else {
				this.max = false
				this.mWidth = null
				this.mHeight = null
			}
		},
		hide() {
			off(window, 'resize', this.reSize)
			this.visible = false
			typeof this.instance.$destroy === 'function' &&
				this.instance.$destroy()
		}
	}
}
</script>
