<!--
 * @Description:
 * @Author:
 * @LastEditors: qxia
 * @Date: 2019-08-29 15:15:38
 * @LastEditTime: 2022-03-09 19:45:03
 -->
<template>
	<wy-box
		:visible="visible"
		:title="title"
		width="800px"
		height="400px"
		@onOk="save"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div slot="title" class="wy-table-list-bar">
			<h3 class="wy-table-list-box_title">{{ title }}</h3>
			<el-input
				v-if="showSearch"
				v-model="table.searchPhrase"
				class="wy-table-list-box_search wy-border-bottom"
				:placeholder="placeholder"
				prefix-icon="el-icon-search"
				clearable
			></el-input>
		</div>
		<div class="wy-table-list" :class="{ multi: multi }">
			<div class="wy-table-list-table">
				<el-table
					ref="table"
					height="100%"
					class="normal"
					:row-key="tableKey"
					:empty-text="tableEmptyText"
					:data="table.list"
					@selection-change="changeSelection"
					@row-click="handleRowClick"
				>
					<el-table-column
						v-if="multi"
						:reserve-selection="reserveSelection"
						type="selection"
						:selectable="setSelectable"
						width="55"
					></el-table-column>
					<el-table-column v-if="!multi" width="55">
						<template slot-scope="scope">
							<i
								v-if="!isChecked(scope.row)"
								class="iconfont icon-radio-normal"
							></i>
							<i
								v-if="isChecked(scope.row)"
								class="iconfont icon-radio-checked"
							></i>
						</template>
					</el-table-column>
					<el-table-column
						type="index"
						label="序号"
						width="50"
					></el-table-column>
					<el-table-column
						v-for="item in tableList"
						:key="item.id"
						:width="item.width"
						:prop="item.id"
						:label="item.name"
						:show-overflow-tooltip="item.overflowTooltip"
					>
						<template slot-scope="scope">
							<div
								v-if="item.clean"
								v-html="item.clean(scope.row)"
							></div>
							<div v-if="!item.clean">
								{{ scope.row[item.id] }}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div v-if="table.rowCount" class="wy-table-list-pages">
				<div class="wy-table-list-box_message"></div>
				<wy-pages
					:current="table.current"
					:page-size="table.rowCount"
					:page-sizes="pageSize"
					:total="table.total"
					@changePageSize="changePageSize"
					@changeCurrentPage="changeCurrentPage"
				></wy-pages>
			</div>
		</div>
		<div v-if="multi" class="wy-table-list-box_result">
			<div class="wy-table-list-box_bar">
				<span class="wy-table-list-box_selection"
					>已选择{{ selection.length }}个</span
				>
				<el-button type="text" @click="clearAll">全部清除</el-button>
			</div>
			<div class="wy-table-list-box_list">
				<ul v-if="listType === 1" class="wy-table-list-box_list1">
					<li v-for="(item, index) in selection" :key="index">
						<span>{{ item.name }}</span>
						<i
							class="el-icon-close"
							@click="clear(item, index)"
						></i>
					</li>
				</ul>
				<ul v-if="listType === 2" class="wy-table-list-box_list2">
					<li v-for="(item, index) in selection" :key="index">
						<div class="wy-table-list-box_left">
							<img :src="item.icon" alt />
							<span>{{ item.name }}</span>
						</div>
						<div class="wy-table-list-box_right">
							<div class="link" @click="clear(item, index)">
								删除
							</div>
							<div class="link" @click="upper(index)">上移</div>
							<div class="link" @click="changeIcon(index)">
								修改图标
							</div>
						</div>
					</li>
				</ul>
			</div>
			<el-upload
				class="el-upload-group"
				action="/api/file/uploadFiles"
				:on-success="handleUploadSuccess"
			>
				<el-button size="small" type="primary">点击上传</el-button>
			</el-upload>
		</div>
	</wy-box>
</template>
<script>
import Vue from 'vue'
export default {
	name: 'WyTableListBox',
	components: {},
	props: {
		title: String,
		reserveSelection: {
			type: Boolean,
			default: true
		},
		tableUrl: String,
		tableList: {
			type: Array,
			default: () => []
		},
		filter: Object,
		cleanTableData: Function,
		currentSelection: {
			type: Array,
			default: () => []
		},
		placeholder: {
			type: String,
			default: '请输入名称'
		},
		multi: {
			type: Boolean,
			default: true
		},
		tableKey: {
			type: String,
			default: 'id'
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		messageRender: Function,
		httpWay: {
			type: String,
			default: 'get'
		},
		listType: {
			type: Number,
			default: 1
		},
		pageSize: {
			type: Array,
			default() {
				return [10, 20, 100]
			}
		},
		selectable: {
			type: Function,
			default: () => true
		},
		// 模糊查询关键词名称
		keywordKey: {
			type: String,
			default: 'searchPhrase'
		}
	},
	data() {
		return {
			visible: false,
			table: {
				list: [],
				current: 1,
				rowCount: 20,
				total: 0,
				searchPhrase: ''
			},
			tableLoaded: false,
			searchTimeout: null,
			selection: [],
			curSelection: [],
			hasMessageAppend: false,
			currentIndex: null
		}
	},
	computed: {
		tableEmptyText() {
			if (this.tableLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.init()
			} else {
				this.tableLoaded = false
				this.table = {
					list: [],
					current: 1,
					rowCount: 20,
					total: 0,
					searchPhrase: ''
				}
				this.hasMessageAppend = false
				this.selection = []
			}
		},
		'table.searchPhrase'(newVal, oldVal) {
			if (newVal === oldVal) {
				return
			}
			this.$delay.register('tableList', this.changeKeyword, 500)
		},
		pageSize(newVal) {
			if (newVal.length > 0) {
				this.table.rowCount = newVal[0]
			}
		}
	},
	created() {},
	mounted() {},
	methods: {
		init() {
			if (this.currentSelection && this.currentSelection.length > 0) {
				if (this.multi) {
					this.$nextTick(() => {
						this.currentSelection.forEach(item => {
							this.$refs.table.toggleRowSelection(item, true)
						})
					})
				} else {
					this.selection = [].concat(this.currentSelection)
				}
			}
			this.getTableData()
		},
		createMessage() {
			if (this.messageRender && !this.hasMessageAppend) {
				this.messageInstance = new Vue({
					render: this.messageRender
				}).$mount()
				this.$nextTick(() => {
					this.$el
						.querySelector('.wy-table-list-box_message')
						.appendChild(this.messageInstance.$el)
				})
				this.hasMessageAppend = true
			}
		},
		getTableData() {
			const filterData = Object.assign(
				{},
				{
					current: this.table.current,
					rowCount: this.table.rowCount,
					[this.keywordKey]: this.table.searchPhrase
						? encodeURIComponent(this.table.searchPhrase)
						: null
				},
				this.filter
			)
			this.tableLoaded = false
			this.$axios({
				url: this.tableUrl,
				type: this.httpWay,
				data: filterData
			}).then(res => {
				this.tableLoaded = true
				if (
					this.cleanTableData &&
					typeof this.cleanTableData == 'function'
				) {
					this.table.list = this.cleanTableData(res.data)
				} else {
					this.table.list = res.data.rows
				}
				this.table.current = res.data.current
				this.table.rowCount = res.data.rowCount
				this.table.total = res.data.total
				this.$nextTick(() => {
					this.createMessage()
				})
			})
		},
		reload() {
			this.table.current = 1
			this.table.searchPhrase = null
			this.getTableData()
		},
		handleRowClick(row) {
			// 如果有可勾选设置 则点击行需要单独处理
			if (this.selectable && typeof this.selectable == 'function') {
				if (!this.selectable(row)) {
					return
				}
			}
			if (this.multi) {
				this.$refs.table.toggleRowSelection(row)
			} else {
				this.selection = [].concat(row)
				this.$refs.table.toggleRowSelection(row, true)
			}
		},
		changeKeyword() {
			this.table.current = 1
			this.getTableData()
		},
		changePageSize(rowCount) {
			this.table.current = 1
			this.table.rowCount = rowCount
			this.getTableData()
		},
		changeCurrentPage(current) {
			this.table.current = current
			this.getTableData()
		},
		changeSelection(selection) {
			if (this.multi) {
				if (this.listType === 2) {
					selection.forEach((e, i) => {
						this.selection.forEach(e2 => {
							if (e.id === e2.id) {
								selection[i] = e2
							}
						})
					})
					this.selection = selection
				} else {
					this.selection = selection
				}
			}
		},
		changeIcon(index) {
			this.currentIndex = index
			document.querySelector('.el-upload-group .el-button').click()
		},
		handleUploadSuccess(res) {
			this.selection[this.currentIndex].hasIcon = true
			this.selection[this.currentIndex].icon = res.data[0].url
		},
		isChecked(data) {
			if (
				this.selection.length > 0 &&
				data[this.tableKey] === this.selection[0][this.tableKey]
			) {
				return true
			}
			return false
		},
		getSelection() {
			return this.selection
		},
		save() {
			const selection = this.getSelection()
			if (!this.onOk || typeof this.onOk !== 'function') {
				this.table.searchPhrase = null
				this.visible = false
				return
			}
			this.onOk(selection, () => {
				this.table.searchPhrase = null
				this.visible = false
			})
		},
		hide() {
			this.visible = false
		},
		cancel() {
			this.table.searchPhrase = null
			this.visible = false
			typeof this.onCancel === 'function' && this.onCancel()
		},
		clear(row, index) {
			this.selection.splice(index, 1)
			this.$refs.table.toggleRowSelection(row, false)
		},
		upper(index) {
			if (index !== 0) {
				const val = this.selection
				const obj = val.splice(index - 1, 1, val[index])[0]
				this.$set(val, index, obj)
			}
		},
		clearAll() {
			this.selection = []
			this.curSelection = []
			this.$refs.table.clearSelection()
		},
		setSelectable(row, index) {
			if (this.selectable && typeof this.selectable === 'function') {
				return this.selectable(row, index)
			} else {
				return true
			}
		}
	}
}
</script>
