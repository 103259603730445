<template>
	<wy-popper
		ref="popper"
		class="wy-select"
		trigger="click"
		placement="bottom"
	>
		<div class="wy-select-dt">
			<wy-input
				v-if="multi"
				suffix-icon="arrow-down"
				cursor="pointer"
				:value="names.join()"
				:noborder="noborder"
				readonly
			></wy-input>
			<wy-input
				v-if="!multi"
				v-model="name"
				suffix-icon="arrow-down"
				cursor="pointer"
				:noborder="noborder"
				readonly
			></wy-input>
		</div>
		<template slot="content">
			<div class="wy-select-content" :style="{ maxHeight: height }">
				<div v-if="showSearch" class="wy-select_keyword">
					<wy-input
						v-model="keyword"
						suffix-icon="search"
						size="normal"
						placeholder="请输入关键词..."
					></wy-input>
				</div>
				<slot></slot>
			</div>
		</template>
	</wy-popper>
</template>
<script>
export default {
	name: 'WySelect',
	components: {},
	props: {
		multi: Boolean,
		value: null,
		showSearch: Boolean,
		noborder: Boolean,
		height: {
			type: String,
			default: '150px'
		}
	},
	data() {
		return {
			result: [],
			name: null,
			names: [],
			keyword: null,
			map: {}
		}
	},
	computed: {},
	watch: {
		multi() {
			this.changeMulti()
		},
		result() {
			this.changeSelect()
		},
		keyword() {
			this.changeKeyword()
		},
		value(val) {
			this.result = [].concat(val)
			this.changeResult()
		}
	},
	created() {
		if (typeof this.value != 'undefined') {
			this.result = [].concat(this.value)
		}
		this.$on('wy.select.option.click', this.handleOptionClick)
		this.$on('wy.select.option.updated', this.handleOptionUpdated)
	},
	methods: {
		handleOptionUpdated(instance) {
			this.map[instance.value] = instance
			this.changeResult()
		},
		changeSelect() {
			this.$broadcast('wySelectOption', 'wy.select.change', [this.result])
		},
		changeMulti() {
			this.$broadcast('wySelectOption', 'wy.select.multi', [this.multi])
		},
		changeKeyword() {
			this.$broadcast('wySelectOption', 'wy.select.filter', [
				this.keyword
			])
		},
		changeResult() {
			if (this.result.length == 0) {
				this.names = []
				this.name = ''
				return
			}
			if (this.multi) {
				const names = []
				this.result.forEach(item => {
					if (this.map[item]) {
						names.push(this.map[item].label)
					}
				})
				this.names = names
			} else {
				if (this.map[this.result[0]]) {
					this.name = this.map[this.result[0]].label
				}
			}
			this.changeSelect()
		},
		handleOptionClick(instance) {
			const index = this.result.indexOf(instance.value)
			if (this.multi) {
				// 多选
				if (index == -1) {
					this.result.push(instance.value)
				} else {
					this.result.splice(index, 1)
				}
				this.$emit('input', this.result)
			} else {
				// 单选
				this.result = [].concat(instance.value)
				this.$refs.popper.hide()
				this.$emit('input', this.result[0])
			}
			this.changeResult()
			this.$dispatch('wyFormItem', 'wy.form.change', [this.result])
		}
	}
}
</script>
