import ShowPic from './show-pic.vue'
import ShowPicPlugin from './show-pic.js'

/* istanbul ignore next */
ShowPic.install = function (Vue) {
	Vue.component(ShowPic.name, ShowPic)
	Vue.prototype.$showPic = Vue.$showPic = ShowPicPlugin
}

export default ShowPic
