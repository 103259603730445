<template>
	<div class="wy-layout-set">
		<header class="wy-layout-set_header">
			<h1 class="wy-layout-set_title" data-guide="layout-set-title">
				{{ title }}
			</h1>
		</header>
		<section class="wy-layout-set_main">
			<table class="wy-layout-set_list">
				<template v-for="(item, index) in list">
					<tr
						v-if="
							(!item.permission || permission[item.permission]) &&
							(!item.schema ||
								item.schema.includes(permission.schema))
						"
						:key="index"
					>
						<th class="wy-layout-set_name" :width="nameWidth">
							{{ item.name }}
						</th>
						<td class="wy-layout-set_description">
							{{ item.description }}
						</td>
						<td class="wy-layout-set_ctrl">
							<el-button
								v-if="item.url"
								type="text"
								:data-guide="`btn-1-${item.permission || index}`"
								@click="$openUrl(item.name, item.url)"
								>{{ buttonName }}</el-button
							>
							<router-link
								v-if="item.http"
								class="link"
								:to="item.http"
								target="_blank"
								:data-guide="`link-1-${item.permission || index}`"
								>{{ buttonName }}</router-link
							>
						</td>
					</tr>
				</template>
			</table>
		</section>
	</div>
</template>
<script>
/**
 * @author su 2019-10-23
 * @description 该组件主要用于设置的导航页面
 * @example list =>
 * [{
		permission: 'ProviderTypeView',	// 权限
		schema: [],  	// 判断版本
		name: '供应商类型设置',	// 名称
		description: '配置系统中供应商类型的候选项，便于集团对供应商的分类及管理',	// 描述
		url: '/supplier/set/type',	// 访问链接
		http: 'https://wy.wojiacloud.com/supplier/set/type',	// 新窗口打开外部访问链接
	},]
 */
export default {
	name: 'WyLayoutSet',
	props: {
		nameWidth: {
			type: String,
			default: '140px'
		},
		buttonName: {
			type: String,
			default: '编辑'
		},
		title: {
			type: String,
			default() {
				return this.$route.meta.title
			}
		},
		list: Array
	},
	data() {
		return {
			permission: {}
		}
	},
	computed: {},
	watch: {
		list() {
			this.init()
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			const arr = []
			if (this.list.length === 0) {
				return
			}
			this.list.forEach(item => {
				arr.push(item.permission)
			})
			this.$getPermission('', arr, data => {
				this.permission = data
			})
		}
	}
}
</script>
