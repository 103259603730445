var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-box",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        width: "800px",
        height: "400px",
      },
      on: { onOk: _vm.save, onCancel: _vm.cancel, onClose: _vm.cancel },
    },
    [
      _c("div", { staticClass: "wy-money-define-box" }, [
        _c(
          "li",
          {
            staticClass: "wy-money-define-box_all",
            on: { click: _vm.selectAll },
          },
          [
            _c("i", {
              staticClass: "iconfont",
              class: {
                "icon-checkbox-normal": !_vm.isSelectAll,
                "icon-checkbox-checked": _vm.isSelectAll,
              },
            }),
            _c("span", { staticClass: "wy-money-define-box_name" }, [
              _vm._v("全选"),
            ]),
          ]
        ),
        _c(
          "ul",
          _vm._l(_vm.list, function (item) {
            return _c(
              "li",
              {
                key: item.id,
                on: {
                  click: function ($event) {
                    return _vm.checkItem(item)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont",
                  class: {
                    "icon-checkbox-normal": !item.selected,
                    "icon-checkbox-checked": item.selected,
                  },
                }),
                _c("span", { staticClass: "wy-money-define-box_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }