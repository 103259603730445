import dayjs from 'dayjs'
export default {
	name: 'moment',
	fn: (value, format) => {
		if (value) {
			return dayjs(value).format(format)
		} else {
			return value
		}
	}
}
