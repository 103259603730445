<template>
	<div class="wy-tree-item">
		<div
			class="wy-tree-item-root"
			:title="root[nodeName]"
			:class="{ active: isActive }"
			:style="setStyle(root)"
			@click.stop="clickRoot(root)"
		>
			<i
				v-hidden="!root[nodeChild] || root[nodeChild].length == 0"
				class="iconfont icon-triangle-right"
				:class="{ down: root.show }"
				@click.stop="showChild(root)"
			></i>
			<span class="wy-tree-item_name" v-text="root[nodeName]"></span>
		</div>
		<template v-for="item in root[nodeChild]">
			<wy-tree-item
				v-if="filter(item)"
				v-show="root.show"
				:key="item[nodeKey]"
				:parent-node="root"
				:node="item"
				:cur-node="curNode"
				@handleClick="handleClick($event)"
			></wy-tree-item>
		</template>
	</div>
</template>
<script>
import { extend } from 'tool'
export default {
	name: 'WyTreeItem',
	inject: ['nodeKey', 'nodeName', 'nodeChild', 'filter'],
	props: {
		parentNode: Object,
		node: Object,
		curNode: Object
	},
	data() {
		return {
			root: {}
		}
	},
	computed: {
		isActive() {
			if (
				this.curNode &&
				this.curNode[this.nodeKey] == this.root[this.nodeKey]
			) {
				this.$set(this.parentNode, 'show', true)
				this.$emit('showRoot', this.parentNode)
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		node(value) {
			this.setLevel(value)
		}
	},
	created() {
		this.setLevel(this.node)
		this.$on('showRoot', node => {
			if (this.root[this.nodeKey] == node[this.nodeKey]) {
				this.$emit('showRoot', this.parentNode)
				this.$set(this.root, 'show', true)
			}
		})
	},
	methods: {
		setLevel(value) {
			const root = extend(true, {}, value)
			const childs = root[this.nodeChild]
			const arr = []
			if (!childs) {
				return
			}
			childs.forEach(child => {
				if (this.filter(child)) {
					if (!child.show) {
						child.show = false
					}
					if (!child.level) {
						child.level = this.node.level + 1
					}
					arr.push(child)
				}
			})
			root[this.nodeChild] = arr
			this.root = root
		},
		setStyle(item) {
			return {
				'padding-left': item.level * 10 + 'px'
			}
		},
		clickRoot(root) {
			if (root.disabled) {
				return
			}
			this.$emit('handleClick', root)
		},
		showChild(root) {
			root.show = !root.show
		},
		handleClick(e) {
			this.$emit('handleClick', e)
		}
	}
}
</script>
