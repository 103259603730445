<template>
	<div class="wy-cloud-guide">
		<div class="wy-cloud-guide_step">
			<div
				v-for="(item, index) in list"
				:key="index"
				class="wy-cloud-guide_step_item"
				:class="{ active: step >= index }"
			>
				<i v-if="index >= step" class="wy-cloud-guide_step_num">{{
					index + 1
				}}</i>
				<i
					v-if="index < step"
					class="wy-cloud-guide_step_num el-icon-check"
				></i>
				<h2 class="wy-cloud-guide_step_title">{{ item.title }}</h2>
			</div>
		</div>
		<div class="wy-cloud-guide_content">
			<component
				:is="curComponent"
				:ref="curComponent.name"
				v-bind="$attrs"
			></component>
		</div>
		<div class="wy-cloud-guide_footer">
			<div class="wy-cloud-guide_footer_tips">
				<span>{{ list[step].description }}</span>
				<span
					v-if="list[step].cloud"
					class="link"
					@click="openCloud(list[step].cloud)"
					>查看指引</span
				>
			</div>
			<div class="wy-cloud-guide_footer_btn">
				<el-button v-if="step > 0" size="small" @click="handlePrev"
					>上一步</el-button
				>
				<el-button size="small" type="primary" @click="handleNext">{{
					step == list.length - 1 ? '完成' : '下一步'
				}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CloudGuide',
	props: {
		step: {
			type: Number,
			default: 0
		},
		list: [],
		router: null,
		type: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			// 当前系统保存的步骤
			curStep: 0,
			isIframe: false
		}
	},
	computed: {
		curComponent() {
			if (this.list[this.step]) {
				this.isIframe = Boolean(this.list[this.step].isIframe)
				return this.list[this.step].component
			} else {
				return {}
			}
		}
	},
	created() {
		this.curStep = this.step
	},
	methods: {
		handlePrev() {
			if (this.step > 0) {
				this.$emit('update:step', this.step - 1)
			}
		},
		async handleNext() {
			if (!this.isIframe) {
				const name = this.curComponent.name
				const instance = this.$refs[name]
				if (!instance.checkGuide) {
					this.$message({
						type: 'warning',
						message: 'checkGuide is not found'
					})
					return
				}
				const result = await instance.checkGuide()
				if (!result || !result.success) {
					return
				}
			}
			if (this.step < this.curStep) {
				// 如果当前步骤小于系统保存的步骤，则不更新系统状态
				this.$emit('update:step', Number(this.step) + 1)
			} else {
				if (this.step < this.list.length - 1) {
					const param = {
						router: this.router,
						step: this.step,
						isInit: 0,
						type: this.type
					}
					this.$axios({
						type: 'post',
						url: '/appInit/save',
						data: param,
						loading: true
					}).then(() => {
						this.curStep = Number(this.step) + 1
						this.$emit('update:step', Number(this.step) + 1)
					})
				} else {
					const param = {
						router: this.router,
						step: this.step,
						isInit: 1,
						type: this.type
					}
					this.$axios({
						type: 'post',
						url: '/appInit/save',
						data: param,
						loading: true
					}).then(() => {
						this.$emit('finish')
					})
				}
			}
		},
		openCloud(config) {
			this.$search(config)
		}
	}
}
</script>
