<template>
	<div class="wy-design-report">
		<div class="wy-design-report_top">
			<h1 class="wy-design-report_title" data-guide="design-report-title">
				{{ title }}
			</h1>
			<div class="wy-design-report_btn-group">
				<div
					class="wy-design-report_btn"
					v-for="(item, index) in btn"
					:key="item.event"
				>
					<el-button
						v-if="showBtn(item) && !item.children"
						type="primary"
						@click="btnClick(item)"
						:data-guide="`btn-1-${item.event || item.field || item.permission || index}`"
						>{{ item.name }}</el-button
					>
					<el-dropdown
						v-if="item.children"
						split-button
						type="primary"
						@command="btnClick"
					>
						<span :data-guide="`btn-1-${item.event || item.field || item.permission || index}`">{{
							item.name
						}}</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								v-for="(v, subindex) in item.children"
								:key="v.event"
								:data-guide="`btn-1-${item.event || item.field || item.permission || index}-2-${
									v.event || v.field || v.permission || subindex
								}`"
								:command="v"
								>{{ v.name }}</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div
			v-if="showProject || query.length > 0 || $slots.query"
			class="wy-design-report_filter"
		>
			<div class="wy-design-report_normal">
				<el-form
					ref="form"
					:model="queryForm"
					:inline="true"
					:rules="rules"
					label-position="left"
				>
					<el-form-item label="所属项目">
						<el-input
							class="wy-border-bottom wy-pointer"
							placeholder="请选择项目"
							:title="curProject.name"
							:value="curProject.name"
							read-only
							@click.native="handleChangeProject"
						>
							<i
								slot="prefix"
								class="el-input__icon el-icon-search"
							></i>
						</el-input>
					</el-form-item>
					<template v-for="item in commonQueryList">
						<el-form-item
							v-if="!item.hide && queryMap[item.field]"
							:key="item.key"
							:label="item.name"
							:prop="item.field"
						>
							<template v-if="item.type === 'input'">
								<el-input
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
								></el-input>
							</template>
							<template v-if="item.type === 'select'">
								<el-select
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
									filterable
									:multiple="item.config.multi"
									@visible-change="
										selectVisibleChange(item, $event)
									"
									@change="handleChange(item.field, 'common')"
								>
									<el-option
										v-for="option in queryMap[item.field]
											.list"
										:key="getSelectValue(option, item)"
										:value="getSelectValue(option, item)"
										:label="getSelectName(option, item)"
									></el-option>
								</el-select>
							</template>
							<template v-if="item.type === 'date'">
								<el-date-picker
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
									:type="item.config.type"
									:unlink-panels="true"
									:value-format="
										setDateFormat(item.config.type)
									"
									@change="handleChange(item.field, 'common')"
								></el-date-picker>
							</template>
							<template v-if="item.type === 'box'">
								<el-input
									v-model="queryWord[item.field]"
									suffix-icon="el-icon-search"
									class="wy-border-bottom wy-pointer"
									readonly
									placeholder="请选择"
									@click.native="clickBox(item, 'common')"
								></el-input>
							</template>
						</el-form-item>
					</template>
				</el-form>
			</div>
			<div class="wy_query">
				<el-input
					v-if="showKeyword"
					v-model="keyword"
					class="wy-border-bottom"
					:placeholder="keywordPlaceholder"
					prefix-icon="el-icon-search"
				></el-input>
				<el-button
					v-if="queryList.length > 0"
					class="wy-design-report_more"
					type="text"
					@click="showPanel"
				>
					<span v-if="!panelStatus">更多高级查询</span>
					<span v-if="panelStatus">关闭高级查询</span>
					<i
						class=""
						:class="{
							'el-icon-arrow-right': !panelStatus,
							'el-icon-arrow-left': panelStatus
						}"
					></i>
				</el-button>
			</div>
		</div>
		<div v-if="getQueryWord.length > 0" class="wy-design-report_word">
			<el-tag
				v-for="item in getQueryWord"
				:key="item.id"
				closable
				@close="closeTag(item)"
			>
				{{ item.name }}:{{ item.value }}
			</el-tag>
		</div>
		<div class="wy-design-report_main">
			<design-table
				ref="designTable"
				data-guide="design-report-table"
				class="wy-design-report__main"
				:url="url"
				:name="name"
				:show-setting="showSetting"
				:show-index="showIndex"
				:enable-net-field="enableNetField"
				:show-selection="showSelection"
				:show-summary="showSummary"
				:field="field"
				:show-pages="showPages"
				:query="table.query"
				:project="curProject.id"
			></design-table>
		</div>
		<div
			class="wy-design-report_query"
			v-show="panelStatus"
			data-guide="design-report-query"
		>
			<div class="wy-design-report_bar">高级查询</div>
			<div class="wy-design-report_ctrl">
				<el-button type="primary" @click="changeQuery">确定</el-button>
				<el-button @click="cancelQuery">取消</el-button>
			</div>
			<div class="wy-design-report_list">
				<el-form
					v-if="queryList.length > 0"
					ref="form"
					:model="queryForm"
					:rules="rules"
					label-position="top"
				>
					<template v-for="item in queryList">
						<el-form-item
							v-if="!item.hide && queryMap[item.field]"
							:key="item.key"
							:label="item.name"
							:prop="item.field"
						>
							<template v-if="item.type === 'input'">
								<el-input
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
									maxlength="100"
								></el-input>
							</template>
							<template v-if="item.type === 'select'">
								<el-select
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
									filterable
									:multiple="item.config.multi"
									@visible-change="
										selectVisibleChange(item, $event)
									"
									@change="handleChange(item.field)"
								>
									<el-option
										v-for="option in queryMap[item.field]
											.list"
										:key="getSelectValue(option, item)"
										:value="getSelectValue(option, item)"
										:label="getSelectName(option, item)"
									></el-option>
								</el-select>
							</template>
							<template v-if="item.type === 'date'">
								<el-date-picker
									v-model="queryForm[item.field]"
									class="wy-border-bottom"
									:type="item.config.type"
									:unlink-panels="true"
									:value-format="
										setDateFormat(item.config.type)
									"
									@change="handleChange(item.field)"
								></el-date-picker>
							</template>
							<template v-if="item.type === 'box'">
								<el-input
									v-model="queryWord[item.field]"
									suffix-icon="el-icon-search"
									class="wy-border-bottom wy-pointer"
									readonly
									placeholder="请选择"
									@click.native="clickBox(item)"
								></el-input>
							</template>
						</el-form-item>
					</template>
				</el-form>
				<slot name="$slots.query"></slot>
			</div>
		</div>
	</div>
</template>
<script>
import designTable from '../design-table'
import queryMixin from './query'
export default {
	name: 'DesignReport',
	components: {
		designTable
	},
	mixins: [queryMixin],
	props: {
		viewPermission: String,
		auto: Boolean, // 自动初始化
		title: String, // 标题
		name: String, // 业务标识
		// 是否显示多选框
		showSelection: {
			type: Boolean,
			default: true
		},
		// 是否显示序号
		showIndex: {
			type: Boolean,
			default: true
		},
		// 是否显示关键词查询
		showKeyword: Boolean,
		// 是否显示项目切换
		showProject: {
			type: Boolean,
			default: true
		},
		// 是否显示表头设置
		showSetting: {
			type: Boolean,
			default: true
		},
		// 是否启用网络列表表头
		enableNetField: Boolean,
		// 是否显示合计行
		showSummary: Boolean,
		// 合计栏名称
		summaryName: {
			type: String,
			default: '全部合计：'
		},
		query: {
			// 高级查询列表
			type: Array,
			default() {
				return []
			}
		},
		url: String, // 列表页请求地址
		field: {
			// 列表页字段列表
			type: Array,
			default() {
				return []
			}
		},
		btn: {
			// 列表页按钮列表
			type: Array,
			default() {
				return []
			}
		},
		changeProject: Function,
		tableKey: String,
		// 是否显示分页，默认显示
		showPages: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			selection: [],
			table: {
				status: false, // 是否请求过
				list: [],
				current: 1,
				rowCount: 50,
				total: 0,
				sort: {},
				filters: {},
				query: {},
				queryWord: {},
				summary: {}
			},
			tableField: [],
			filterMap: {},
			permission: {},
			rules: {}
		}
	},
	computed: {
		queryList() {
			// 高级查询条件
			const arr = []
			this.query.forEach(item => {
				if (!item.isCommon) {
					arr.push(item)
				}
			})
			return arr
		},
		commonQueryList() {
			// 常用查询条件
			const arr = []
			this.query.forEach(item => {
				if (item.isCommon) {
					arr.push(item)
				}
			})
			return arr
		}
	},
	watch: {},
	created() {
		this.getPermission()
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			// 初始化高级查询
			this.initQuery()
			// 如果显示项目信息，就去调用当前所属项目
			this.loadProject().then(() => {
				// 初始化表格
				if (this.auto) {
					this.changeQuery()
				} else {
					this.panelStatus = true
				}
			})
		},
		getPermission() {
			const permission = []
			this.btn.forEach(item => {
				if (item.permission) {
					permission.push(item.permission)
				}
			})
			if (!this.viewPermission || permission.length === 0) {
				// 如果当前页面不需要限制查看权限
				return
			}
			this.$getPermission(this.viewPermission, permission, data => {
				this.permission = data
			})
		},
		loadProject() {
			// 用户信息
			return new Promise(resolve => {
				if (!this.showProject || !this.$root.getUserInfo) {
					resolve()
					return
				}
				// 获取项目信息
				this.$root.getUserInfo().then(data => {
					this.userInfo = data.curUser
					this.curProject = {
						id: this.userInfo.projectID,
						name: this.userInfo.projectName,
						selection: [
							{
								id: this.userInfo.projectID,
								name: this.userInfo.projectName
							}
						]
					}
					resolve()
				})
			})
		},
		showBtn(data) {
			if (data.permission) {
				return this.permission[data.permission]
			} else {
				return true
			}
		},
		btnClick(data) {
			this.$emit(data.event, data)
		},
		clickBox(data) {
			// 自定义
			this.$emit(data.config.event, {
				curValue: this.queryForm[data.field],
				curWord: this.queryWord[data.field],
				curQueryForm: this.queryForm,
				callback: ({ id, name }) => {
					// 触发事件，回调返回值
					this.queryForm[data.field] = id
					this.queryWord[data.field] = name
				}
			})
		},
		reload(current = 1) {
			this.$refs.designTable.reload(current)
		},
		reloadCurrentPage() {
			this.$refs.reloadCurrentPage.reload()
		},
		getSelection() {
			return this.$refs.reloadCurrentPage.getSelection()
		}
	}
}
</script>
