const ChangeProject = {}
import ChangeProjectPlugin from './change-project.js'

/* istanbul ignore next */
ChangeProject.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$changeProject = Vue.$changeProject = ChangeProjectPlugin
}

export default ChangeProject
