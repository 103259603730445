<template>
	<div class="wy-building-room" :class="'wy-building-room_type-' + type">
		<div class="wy-building-room_title">
			<el-select v-model="type" class="wy-border-bottom">
				<el-option
					v-if="typeList.indexOf(1) != -1"
					:value="1"
					:label="groupNameFilter"
				></el-option>
				<el-option
					v-if="typeList.indexOf(2) != -1"
					:value="2"
					label="按楼栋过滤"
				></el-option>
				<el-option
					v-if="typeList.indexOf(3) != -1"
					:value="3"
					label="按单元过滤"
				></el-option>
				<el-option
					v-if="typeList.indexOf(4) != -1"
					:value="4"
					label="按房间过滤"
				></el-option>
				<el-option
					v-if="typeList.indexOf(5) != -1"
					:value="5"
					label="按楼层过滤"
				></el-option>
			</el-select>
		</div>
		<wy-split class="wy-building-room_wrap" value="78%" min="50%" max="85%">
			<div slot="left" class="wy-building-room_content">
				<transition name="slide">
					<div
						class="wy-building-room_item wy-building-room_group"
						:class="{ active: type == 1 }"
					>
						<div class="wy-building-room_title">
							{{ groupName }}&nbsp;
							<span
								v-if="type == 1"
								class="wy-pointer"
								:class="{ 'wy-status-active': isCheckedAll }"
								@click="handleCheckAllChange"
							>
								<i
									:class="
										'iconfont' +
										(isCheckedAll
											? ' icon-checkbox-checked'
											: ' icon-checkbox-normal')
									"
								></i>
								<span class="wy-building-room_name"> 全选</span>
							</span>
						</div>
						<div class="wy-building-room_list">
							<ul>
								<li
									v-for="item in groupList"
									:key="item.id"
									:class="{
										'wy-status-active':
											curGroup.id == item.id && type != 1
									}"
									:title="item.name"
									@click="changeGroup(item)"
								>
									<i
										v-if="type == 1"
										class="iconfont"
										:class="isChecked(item, groupSelection)"
									></i>
									<span class="wy-building-room_name">{{
										item.name
									}}</span>
								</li>
							</ul>
						</div>
					</div>
				</transition>
				<transition name="slide">
					<div
						v-if="type == 2 || type == 3 || type == 4 || type == 5"
						class="wy-building-room_item wy-building-room_building"
						:class="{ active: type == 2 }"
					>
						<div class="wy-building-room_title">
							楼栋&nbsp;
							<span
								v-if="type == 2"
								class="wy-pointer"
								:class="{ 'wy-status-active': isCheckedAll }"
								@click="handleCheckAllChange"
							>
								<i
									:class="
										'iconfont' +
										(isCheckedAll
											? ' icon-checkbox-checked'
											: ' icon-checkbox-normal')
									"
								></i>
								<span class="wy-building-room_name"> 全选</span>
							</span>
						</div>
						<div class="wy-building-room_list">
							<ul>
								<li
									v-for="item in buildingList"
									:key="item.id"
									:class="{
										'wy-status-active':
											curBuilding.id == item.id &&
											type != 2
									}"
									:title="item.name"
									@click="changeBuilding(item)"
								>
									<i
										v-if="type == 2"
										class="iconfont"
										:class="
											isChecked(item, buildingSelection)
										"
									></i>
									<span class="wy-building-room_name">{{
										item.name
									}}</span>
								</li>
							</ul>
						</div>
					</div>
				</transition>
				<transition name="slide">
					<div
						v-if="type == 3 || type == 4"
						class="wy-building-room_item wy-building-room_unit"
						:class="{ active: type == 3 }"
					>
						<div class="wy-building-room_title">
							单元&nbsp;
							<span
								v-if="type == 3"
								class="wy-pointer"
								:class="{ 'wy-status-active': isCheckedAll }"
								@click="handleCheckAllChange"
							>
								<i
									:class="
										'iconfont' +
										(isCheckedAll
											? ' icon-checkbox-checked'
											: ' icon-checkbox-normal')
									"
								></i>
								<span class="wy-building-room_name"> 全选</span>
							</span>
						</div>
						<div class="wy-building-room_list">
							<ul>
								<li
									v-for="item in unitList"
									:key="item.id"
									:class="{
										'wy-status-active':
											curUnit.id == item.id && type != 3
									}"
									:title="item.name"
									@click="changeUnit(item)"
								>
									<i
										v-if="type == 3"
										class="iconfont"
										:class="isChecked(item, unitSelection)"
									></i>
									<span class="wy-building-room_name">{{
										item.name || '(单元未设置)'
									}}</span>
								</li>
							</ul>
						</div>
					</div>
				</transition>
				<transition name="slide">
					<div
						v-if="type == 4"
						class="wy-building-room_item"
						:class="{ active: type == 4 }"
					>
						<div class="wy-building-room_title">
							<span>房间</span>&nbsp;
							<span
								v-if="type == 4"
								class="wy-pointer"
								:class="{ 'wy-status-active': isCheckedAll }"
								@click="handleCheckAllChange"
							>
								<i
									:class="
										'iconfont' +
										(isCheckedAll
											? ' icon-checkbox-checked'
											: ' icon-checkbox-normal')
									"
								></i>
								<span class="wy-building-room_name"> 全选</span>
							</span>
							<el-input
								v-model="keyword"
								class="wy-border-bottom"
								placeholder="请输入房间名称"
								suffix-icon="el-icon-search"
							/>
						</div>
						<div
							class="wy-building-room_list wy-building-room_room"
						>
							<div v-for="floor in roomList" :key="floor.id">
								<h4
									v-show="!keyword"
									class="wy-building-room_floor"
								>
									楼层-{{ floor.name }}
								</h4>
								<ul>
									<li
										v-for="item in floor.list"
										v-show="isShow(item)"
										:key="item.id"
										:title="item.name"
										@click="choose(item, roomSelection)"
									>
										<i
											v-if="type == 4"
											class="iconfont"
											:class="
												isChecked(item, roomSelection)
											"
										></i>
										<span class="wy-building-room_name">{{
											item.name
										}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</transition>
				<transition name="slide">
					<div
						v-if="type == 5"
						class="wy-building-room_item wy-building-room_unit"
						:class="{ active: type == 5 }"
					>
						<div class="wy-building-room_title">
							楼层&nbsp;
							<span
								v-if="type == 5"
								class="wy-pointer"
								:class="{ 'wy-status-active': isCheckedAll }"
								@click="handleCheckAllChange"
							>
								<i
									:class="
										'iconfont' +
										(isCheckedAll
											? ' icon-checkbox-checked'
											: ' icon-checkbox-normal')
									"
								></i>
								<span class="wy-building-room_name"> 全选</span>
							</span>
						</div>
						<div class="wy-building-room_list">
							<ul>
								<li
									v-for="item in floorList"
									:key="item.id"
									:title="item.name"
									@click="choose(item, floorSelection)"
								>
									<i
										v-if="type == 5"
										class="iconfont"
										:class="isChecked(item, floorSelection)"
									></i>
									<span class="wy-building-room_name">{{
										item.name
									}}</span>
								</li>
							</ul>
						</div>
					</div>
				</transition>
			</div>
			<div
				slot="right"
				class="wy-building-room_item wy-building-room_unit selected"
			>
				<div
					class="wy-building-room_title wy-building-room_total wy-building-room_flex"
				>
					<span>
						已选择
						<em class="wy-status-active">{{ total }}</em>
						个
					</span>
					<span
						v-if="curSelection['' + type].length > 0"
						class="link"
						@click="clearSelected"
						>全部清除</span
					>
				</div>
				<div class="wy-building-room_list">
					<ul>
						<li
							v-for="item in curSelection['' + type]"
							:key="item.id"
							class="wy-building-room_flex"
							:class="{
								'wy-status-active':
									curBuilding.id == item.id && type != 2
							}"
							:title="item.name"
						>
							<span class="wy-building-room_name">{{
								item.name || '(单元未设置)'
							}}</span>
							<i
								class="el-icon-close"
								@click="
									delSelected(item, curSelection['' + type])
								"
							></i>
						</li>
					</ul>
				</div>
			</div>
		</wy-split>
	</div>
</template>
<script>
import { hasOf } from 'tool'
export default {
	name: 'WyBuildingRoom',
	props: {
		// 项目id
		projectID: String,
		// 类型
		type: {
			type: Number,
			default: 4
		},
		typeList: {
			type: Array,
			default() {
				return [1, 2, 3, 4]
			}
		},
		selection: Array,
		groupName: {
			type: String,
			default() {
				return '组团'
			}
		}
	},
	data() {
		return {
			keyword: null,
			groupList: [],
			buildingList: [],
			unitList: [],
			roomList: [],
			floorList: [],
			groupSelection: [],
			buildingSelection: [],
			unitSelection: [],
			roomSelection: [],
			floorSelection: [],
			curGroup: {},
			curBuilding: {},
			curUnit: {}
		}
	},
	computed: {
		isCheckedAll() {
			let list = this.curList[this.type + ''],
				selection = this.curSelection[this.type + '']
			if (this.type == 4) {
				// 房间过滤有分组
				let arr = []
				list.forEach(group => {
					arr = arr.concat(group.list)
				})
				list = [].concat(arr)
			}
			if (list.length === 0) {
				return false
			}
			for (const item of list) {
				const i = selection.findIndex(el => el.id === item.id)
				if (i === -1) {
					// 存在未选中的选项
					return false
				}
			}
			return true
		},
		curSelection() {
			return {
				1: this.groupSelection,
				2: this.buildingSelection,
				3: this.unitSelection,
				4: this.roomSelection,
				5: this.floorSelection
			}
		},
		curList() {
			return {
				1: this.groupList,
				2: this.buildingList,
				3: this.unitList,
				4: this.roomList,
				5: this.floorList
			}
		},
		total() {
			if (this.type == 1) {
				return this.groupSelection.length
			} else if (this.type == 2) {
				return this.buildingSelection.length
			} else if (this.type == 3) {
				return this.unitSelection.length
			} else if (this.type == 4) {
				return this.roomSelection.length
			} else if (this.type == 5) {
				return this.floorSelection.length
			} else {
				return 0
			}
		}
	},
	watch: {
		type(value, oldValue) {
			this.groupSelection = []
			this.buildingSelection = []
			this.unitSelection = []
			this.roomSelection = []
			this.floorSelection = []
			if (value > oldValue) {
				if (oldValue == 1) {
					this.getBuilding()
				} else if (oldValue == 2) {
					this.getUnit()
				} else if (oldValue == 3 && value == 4) {
					this.getRoom()
				} else if (oldValue == 3 && value == 5) {
					this.getFloor()
				}
			}
			if (oldValue == 5 && value == 3) {
				this.getUnit()
			}
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			if (!this.typeList.includes(this.type)) {
				this.type = this.typeList[this.typeList.length - 1]
			}
			if (this.type == 1 && this.selection && this.selection.length > 0) {
				this.groupSelection = this.groupSelection.concat(this.selection)
			}
			if (this.type == 2 && this.selection && this.selection.length > 0) {
				this.buildingSelection = this.buildingSelection.concat(
					this.selection
				)
			}
			if (this.type == 3 && this.selection && this.selection.length > 0) {
				this.unitSelection = this.unitSelection.concat(this.selection)
			}
			if (this.type == 4 && this.selection && this.selection.length > 0) {
				this.roomSelection = this.roomSelection.concat(this.selection)
			}
			if (this.type == 5 && this.selection && this.selection.length > 0) {
				this.floorSelection = this.floorSelection.concat(this.selection)
			}
			this.getGroup()
			this.groupNameFilter = `按${this.groupName}过滤`
		},
		getGroup() {
			// 获取组团信息
			this.groupList = []
			this.buildingList = []
			this.unitList = []
			this.roomList = []
			this.$axios({
				url: '/projectGroups/getProjectGroupListByProjectID',
				data: {
					projectID: this.projectID,
					type: 1
				}
			}).then(res => {
				this.groupList = res.data
				if (res.data.length > 0 && this.type != 1) {
					this.getBuilding()
				}
			})
		},
		getBuilding() {
			// 获取楼栋信息
			if (!this.curGroup.id) {
				this.curGroup = this.groupList[0]
			}
			this.buildingList = []
			this.unitList = []
			this.roomList = []
			this.$axios({
				url: '/buildings/getBuildingByProjectGroupId',
				data: {
					projectID: this.projectID,
					projectGroupID: this.curGroup.id
				}
			}).then(res => {
				this.buildingList = res.data
				if (res.data.length > 0 && (this.type == 3 || this.type == 4)) {
					this.getUnit()
				}
				if (res.data.length > 0 && this.type == 5) {
					this.getFloor()
				}
			})
		},
		handleCheckAllChange() {
			let list = this.curList[this.type + ''],
				selection = this.curSelection[this.type + '']
			if (this.type == 4) {
				// 房间过滤有分组
				let arr = []
				list.forEach(group => {
					arr = arr.concat(group.list)
				})
				list = [].concat(arr)
			}
			if (this.isCheckedAll) {
				let len = list.length
				while (len--) {
					const i = selection.findIndex(o => o.id === list[len].id)
					i > -1 && selection.splice(i, 1)
				}
			} else {
				list.forEach(el => {
					const i = selection.findIndex(o => o.id === el.id)
					i === -1 && selection.push(el)
				})
			}
		},
		getUnit() {
			// 获取单元信息
			if (!this.curBuilding.id) {
				this.curBuilding = this.buildingList[0]
			}
			this.unitList = []
			this.roomList = []
			this.$axios({
				url: '/buildingUnit/getBuildUnitByBuildingId',
				data: {
					projectID: this.projectID,
					buildingID: this.curBuilding.id
				}
			}).then(res => {
				this.unitList = res.data
				if (res.data.length > 0 && this.type == 4) {
					this.getRoom()
				}
			})
		},
		getRoom() {
			// 获取房间
			if (!this.curUnit.id) {
				this.curUnit = this.unitList[0]
			}
			this.roomList = []
			this.$axios({
				url: '/rooms/getAllRooms',
				data: {
					projectID: this.projectID,
					buildUnitID: this.curUnit.id
				}
			}).then(res => {
				const floor = []
				const floorMap = {}
				const roomList = []
				res.data.forEach(item => {
					if (!floorMap[item.floor]) {
						floorMap[item.floor] = []
						floor.push(item.floor)
					}
					floorMap[item.floor].push(item)
				})
				floor.forEach(item => {
					roomList.push({
						name: item,
						list: floorMap[item]
					})
				})
				this.roomList = roomList
			})
		},
		getFloor() {
			// 获取楼层信息
			if (!this.curBuilding.id) {
				this.curBuilding = this.buildingList[0]
			}
			this.floorList = []
			this.$axios({
				url: '/floor/getFloorByBuilding',
				data: {
					projectID: this.projectID,
					buildingID: this.curBuilding.id
				}
			}).then(res => {
				this.floorList = res.data
			})
		},
		changeGroup(data) {
			// 切换组团
			if (this.type == 1) {
				this.choose(data, this.groupSelection)
			}
			if (data.id == this.curGroup.id) {
				return
			}
			this.curGroup = data
			this.curBuilding = {}
			this.curUnit = {}
			if (this.type != 1) {
				this.getBuilding()
			}
		},
		changeBuilding(data) {
			// 切换楼栋
			if (this.type == 2) {
				this.choose(data, this.buildingSelection)
			}
			if (data.id == this.curBuilding.id) {
				return
			}
			this.curBuilding = data
			this.curUnit = {}
			if (this.type == 3 || this.type == 4) {
				this.getUnit()
			}
			if (this.type == 5) {
				this.getFloor()
			}
		},
		changeUnit(data) {
			// 切换单元
			if (this.type == 3) {
				this.choose(data, this.unitSelection)
			}
			if (data.id == this.curUnit.id) {
				return
			}
			this.curUnit = data
			if (this.type == 4) {
				this.getRoom()
			}
		},
		isChecked(data, list) {
			// 判断是否被选中
			const index = hasOf(list, data, 'id')
			if (index == -1) {
				return 'icon-checkbox-normal'
			} else {
				return 'icon-checkbox-checked'
			}
		},
		choose(data, list) {
			// 选择
			const index = hasOf(list, data, 'id')
			if (index == -1) {
				list.push(data)
			} else {
				list.splice(index, 1)
			}
		},
		isShow(data) {
			if (
				this.keyword &&
				data.name &&
				!data.name
					.toLocaleLowerCase()
					.includes(this.keyword.toLocaleLowerCase())
			) {
				return false
			} else {
				return true
			}
		},
		getSelection() {
			const selection = this.curSelection[this.type + '']
			return {
				type: this.type,
				selection
			}
		},
		delSelected({ id }, target) {
			let i
			i = target.findIndex(el => el.id === id)
			target.splice(i, 1)
		},
		clearSelected() {
			const selection = this.curSelection[this.type + '']
			selection.splice(0, selection.length)
		}
	}
}
</script>
