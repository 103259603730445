var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-address" },
    [
      _c(
        "el-select",
        {
          staticClass: "wy-address_province",
          class: { "wy-border-bottom": _vm.border == "bottom" },
          attrs: { disabled: _vm.disabled, placeholder: "请选择省" },
          on: { change: _vm.changeProvince },
          model: {
            value: _vm.curValue.province,
            callback: function ($$v) {
              _vm.$set(_vm.curValue, "province", $$v)
            },
            expression: "curValue.province",
          },
        },
        _vm._l(_vm.provinceList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.fullName, value: item.fullName },
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          staticClass: "wy-address_city",
          class: { "wy-border-bottom": _vm.border == "bottom" },
          attrs: { disabled: _vm.disabled, placeholder: "请选择市" },
          on: { change: _vm.changeCity },
          model: {
            value: _vm.curValue.city,
            callback: function ($$v) {
              _vm.$set(_vm.curValue, "city", $$v)
            },
            expression: "curValue.city",
          },
        },
        _vm._l(_vm.cityList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.fullName, value: item.fullName },
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          staticClass: "wy-address_area",
          class: { "wy-border-bottom": _vm.border == "bottom" },
          attrs: { disabled: _vm.disabled, placeholder: "请选择区" },
          on: { change: _vm.changeArea },
          model: {
            value: _vm.curValue.area,
            callback: function ($$v) {
              _vm.$set(_vm.curValue, "area", $$v)
            },
            expression: "curValue.area",
          },
        },
        _vm._l(_vm.areaList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.fullName, value: item.fullName },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }