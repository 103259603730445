<template>
	<div class="wy-page-tree-table">
		<div class="wy-page-tree-table_bar">
			<div v-if="showSet" class="wy-page-tree-table_set">
				<i class="el-icon-setting"></i>
				<span>列表设置</span>
			</div>
			<div class="wy-page-tree-table_total">
				已选择
				<em>{{ selection.length }}</em>
				条
			</div>
			<wy-pages
				v-if="isPaging"
				background
				small
				:current="table.current"
				:page-size="table.rowCount"
				:total="table.total"
				@changePageSize="changePageSize"
				@changeCurrentPage="changeCurrentPage"
			></wy-pages>
		</div>
		<div class="wy-page-tree-table_cont">
			<div class="wy-page-tree-table_header">
				<table class="wy-page-tree-table_table">
					<thead>
						<tr>
							<th v-if="showFolder">
								<div class="wy-page-tree-table_fold"></div>
							</th>
							<th
								v-if="multi"
								class="wy-page-tree-table_checkbox"
							>
								<i
									class="iconfont"
									:class="{
										'icon-checkbox-checked': allChecked,
										'icon-checkbox-normal': !allChecked
									}"
									@click.stop="selectAll()"
								></i>
								<div
									v-if="showFolder"
									class="wy-page-tree-table_line"
								></div>
							</th>
							<th
								v-if="showIndex"
								class="wy-page-tree-table_index"
							>
								<div class="wy-page-tree-table_box">序号</div>
								<div class="wy-page-tree-table_line"></div>
							</th>
							<th
								v-for="item in list"
								:key="item.id"
								:class="setClass('col', item.field)"
							>
								<div
									class="wy-page-tree-table_box"
									:style="setBoxStyle(null, item)"
								>
									<div class="cell">
										{{ item.name }}
									</div>
								</div>
								<div class="wy-page-tree-table_line"></div>
							</th>
						</tr>
					</thead>
				</table>
			</div>
			<div class="wy-page-tree-table_content">
				<div
					class="wy-page-tree-table_content-scroll"
					@scroll="scroll($event)"
				>
					<table class="wy-page-tree-table_table">
						<tbody>
							<tr
								v-for="(node, index) in table.rows"
								:key="node.id"
								@click.stop="chooseChild(node, index)"
							>
								<td
									v-if="showFolder"
									@click.stop="showChild(node, index)"
								>
									<div
										v-if="
											!node.isLeaf &&
											!node.showLoad &&
											node.showChild
										"
										class="wy-page-tree-table_fold"
									>
										-
									</div>
									<div
										v-if="
											!node.isLeaf &&
											!node.showLoad &&
											!node.showChild
										"
										class="wy-page-tree-table_fold"
									>
										+
									</div>
									<div
										v-if="node.isLeaf || node.showLoad"
										class="wy-page-tree-table_fold"
									></div>
									<i
										v-if="node.showLoad"
										class="iconfont icon-loading"
									></i>
								</td>
								<td
									v-if="multi"
									class="wy-page-tree-table_checkbox"
								>
									<i
										class="iconfont"
										:class="{
											'icon-checkbox-checked':
												node.checked,
											'icon-checkbox-normal':
												!node.checked
										}"
									></i>
								</td>
								<td
									v-if="showIndex"
									class="wy-page-tree-table_index"
								>
									<div
										class="wy-page-tree-table_box"
										:style="setStyle(node)"
									>
										{{ node.treeNumber }}
									</div>
								</td>
								<td
									v-for="(item, i) in list"
									:key="item.id"
									:class="setClass('col', item.field)"
								>
									<div
										class="wy-page-tree-table_box"
										:style="setBoxStyle(node, item)"
									>
										<div
											v-if="i == 0 && item.type == 'text'"
											class="wy-page-tree-table_text"
											:class="{ name: !showIndex }"
											:style="
												showIndex ? '' : setStyle(node)
											"
										>
											{{ node[item.field] }}
										</div>
										<div
											v-if="i != 0 && item.type == 'text'"
											class="wy-page-tree-table_text"
										>
											{{ node[item.field] }}
										</div>
										<slot
											v-if="item.type == 'slot'"
											:name="item.field"
											:data="node"
										></slot>
									</div>
								</td>
							</tr>
							<tr
								v-if="
									table.rows.length == 0 &&
									status == 'complete'
								"
							>
								<td
									:colspan="list.length"
									class="wy-page-tree-table_empty"
								>
									暂无数据
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { extend } from 'tool'
import wyPages from '../pages/pages.vue'

export default {
	name: 'WyTreeTable',
	components: {
		wyPages
	},
	props: {
		url: {
			type: String
		},
		list: {
			type: Array,
			default: () => {
				return []
			}
		},
		filter: {
			type: Object
		},
		auto: {
			type: Boolean,
			default: true
		},
		showSet: Boolean,
		showIndex: {
			type: Boolean,
			default: true
		},
		showFold: {
			type: Boolean,
			default: true
		},
		multi: {
			type: Boolean,
			dafault: false
		},
		isPaging: {
			type: Boolean,
			dafault: false
		},
		cleanDataFn: {
			type: Function
		},
		cleanParamsFn: {
			type: Function
		},
		idVal: {
			type: String,
			default: 'id'
		},
		parentdIdVal: {
			type: String,
			default: 'parentID'
		},
		lazy: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			table: {
				rows: [],
				current: 1,
				total: 120,
				rowCount: 50
			},
			filterData: {
				current: 1,
				total: 120,
				rowCount: 50,
				searchPhrase: ''
			},
			selection: [],
			selectedID: [],
			selectedObj: {},
			status: null,
			allChecked: false,
			showFolder: false
		}
	},
	watch: {
		showFold(val, oldVal) {
			if (val == oldVal) {
				return
			}
			this.showFolder = val
		}
	},
	mounted() {},
	created() {
		if (this.auto) {
			this.showFolder = this.showFold
			this.loadData()
		}
	},
	updated() {
		this.$nextTick(() => {
			this.updateOverFlow()
		})
	},
	methods: {
		loadData() {
			let selected = []
			if (this.selected) {
				selected = [].concat(this.selected)
			}
			for (let i = 0; i < selected.length; i++) {
				const id = selected[i].id
				if (id) {
					this.selectedID.push(id)
					this.selectedObj[id] = selected[i]
				}
			}
			this.load({}, data => {
				this.table.rows = data
				this.table.total = data.length
			})
		},
		// 全选
		selectAll() {
			this.allChecked = !this.allChecked
			if (this.allChecked) {
				this.selectedID = []
				this.table.rows.forEach(item => {
					if (typeof item.checked === 'undefined') {
						this.$set(item, 'checked', true)
					} else {
						item.checked = true
					}
					this.selectedID.push(item.id)
				})
			} else {
				this.selectedID = []
				this.table.rows.forEach(item => {
					item.checked = false
				})
			}
			this.handleSelectionChange()
		},
		load(node, cb) {
			let params = {},
				number = '',
				level = 0
			if (this.isPaging) {
				params = Object.assign({}, this.filterData, this.filter)
			}
			node = node || {}
			if (node.id) {
				params[this.parentdIdVal] = node.id
			}
			if (node.treeNumber) {
				number = node.treeNumber + '.'
			}
			if (typeof node.treeLevel != 'undefined') {
				level = parseInt(node.treeLevel) + 1
			} else {
				node.treeLevel = level = 0
			}
			if (node.child && node.child.length > 0) {
				this.setChild(node.child, number, level, cb)
				return
			}
			if (this.cleanParamsFn) {
				params = extend(true, {}, params, this.cleanParamsFn(node))
			}
			this.status = 'loading'
			this.$axios({
				url: this.url,
				data: params
			})
				.then(res => {
					let data,
						result = res.data
					if (this.cleanDataFn) {
						data = this.cleanDataFn(result.rows)
					} else {
						data = result.rows
					}
					this.setChild(data, number, level, cb)
					this.table.current = this.filterData.current
					this.table.rowCount = this.filterData.rowCount
				})
				.catch(() => {
					this.status = 'complete'
				})
		},
		// 设置子节点
		setChild(data, number, level, cb) {
			for (let i = 0; i < data.length; i++) {
				this.selectedObj[data[i].id] = data[i]
				if (!data[i].treeNumber) {
					data[i].treeNumber = number + (i + 1)
				}
				if (!data[i].treeLevel) {
					data[i].treeLevel = parseInt(level) + 1
				}
				if (this.selectedID.includes(data[i].id)) {
					data[i].checked = true
				}
			}
			cb(data)
		},
		setStyle(node) {
			return {
				position: 'relative',
				left: (node.treeLevel - 1) * 5 + 'px'
			}
		},
		// 显示/隐藏子节点
		showChild(node, index) {
			if (node.showLoad || node.isLeaf) {
				return
			}
			// 为‘-’号时
			if (node.showChild) {
				try {
					const child = node.child
					for (let i = 0; i < child.length; i++) {
						for (let j = 0; j < this.table.rows.length; j++) {
							if (child[i].id == this.table.rows[j].id) {
								this.table.rows.splice(j, 1)
							}
						}
						if (child[i].child) {
							child[i].showChild = true
							this.showChild(child[i])
						}
					}
				} catch (e) {
					console.warn(e)
				}
				node.showChild = false
				this.checkSelectAll()
				return
			}
			this.$set(node, 'showLoad', true)
			if (!this.lazy) {
				for (let i = 0; i < node.child.length; i++) {
					this.table.rows.splice(index + i + 1, 0, data[i])
				}
				this.checkSelectAll()
				this.$set(node, 'showLoad', false)
				this.$set(node, 'showChild', true)
			} else {
				this.load(node, data => {
					for (let i = 0; i < data.length; i++) {
						this.table.rows.splice(index + i + 1, 0, data[i])
					}
					this.checkSelectAll()
					this.$set(node, 'child', data)
					this.$set(node, 'showLoad', false)
					this.$set(node, 'showChild', true)
				})
			}
		},
		// 检查是否全选
		checkSelectAll() {
			let checkLength = 0
			this.table.rows.forEach(item => {
				if (item.checked) {
					checkLength++
				}
			})
			if (checkLength == this.table.rows.length) {
				this.allChecked = true
			} else {
				this.allChecked = false
			}
		},
		// 选择子节点
		chooseChild(node) {
			if (typeof node.checked === 'undefined') {
				this.$set(node, 'checked', true)
			} else {
				node.checked = !node.checked
			}

			// 默认删除当前选中的
			const index = this.selectedID.indexOf(node.id)
			if (index != -1) {
				this.selectedID.splice(index, 1)
			}

			// 如果当前选中的结果是选中状态再根据是否允许多选来赋值
			if (node.checked) {
				if (this.multi) {
					this.selectedID.push(node.id)
					if (this.selectedID.length == this.table.rows.length) {
						this.allChecked = true
					}
				} else {
					for (let i = 0; i < this.selectedID.length; i++) {
						this.selectedObj[this.selectedID[i]].checked = false
					}
					this.selectedID = [].concat(node.id)
				}
			} else {
				this.allChecked = false
			}
			this.handleSelectionChange()
		},
		// 获取选择结果
		handleSelectionChange() {
			const list = []
			for (let i = 0; i < this.selectedID.length; i++) {
				list.push(this.selectedObj[this.selectedID[i]])
			}
			if (this.multi) {
				this.selection = list
			} else {
				if (this.selectedID.length > 0) {
					this.selection = list
				} else {
					this.selection = []
				}
			}
		},
		reload(num = 1) {
			this.table.current = num
			this.selection = []
			this.selectedID = []
			this.selectedObj = {}
			this.allChecked = false
			this.loadData()
		},
		changePageSize(val) {
			this.filterData.rowCount = val
			this.loadData()
		},
		changeCurrentPage(val) {
			this.filterData.current = val
			this.loadData()
		},
		setClass(type, name) {
			const className = {}
			className['table-' + type + '-' + name] = true
			return className
		},
		setBoxStyle(tr, item) {
			const style = {}
			if (item && item.width) {
				style.width = parseInt(item.width) + 'px'
			}
			if (tr && tr.height) {
				style.height = parseInt(tr.height) + 'px'
			}
			if (item && item.height) {
				style.height = parseInt(item.height) + 'px'
			}
			return style
		},
		// 滚动条同步表头位置
		scroll(data) {
			const header = this.$el.querySelector('.wy-page-tree-table_header')
			const scroll = {
				scrollLeft: data.target.scrollLeft,
				scrollTop: data.target.scrollTop
			}

			header.scrollLeft = scroll.scrollLeft
		},
		// 重新渲染
		updateOverFlow() {
			const contentElm = this.$el.querySelector(
				'.wy-page-tree-table_content'
			)
			const width = contentElm.clientWidth
			const tableW = contentElm.querySelector(
				'.wy-page-tree-table_table'
			).offsetWidth
			try {
				this.list.forEach(item => {
					if (item.auto && !item.width) {
						this.$set(
							item,
							'width',
							width - tableW + (item.width || 200)
						)
						throw ''
					}
				})
			} catch (e) {
				console.warn(e)
			}
		},
		getSelection() {
			return this.selection
		}
	}
}
</script>
