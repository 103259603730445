import {
	delay as Delay,
	getDevice,
	nextIndex,
	openApp,
	openFile,
	setTitle
} from 'tool'
import {
	broadcast,
	dispatch,
	getCommonParams,
	getFlowSet,
	getPermission,
	getPublicParams,
	userConfig
} from './utils'

/**
 * @param Vue
 */
export default function (Vue) {
	Vue.prototype.$dispatch = dispatch
	Vue.prototype.$broadcast = broadcast
	Vue.prototype.$nextIndex = nextIndex
	Vue.prototype.$openFile = openFile
	Vue.prototype.$openApp = openApp // nouse
	Vue.prototype.$getPermission = Vue.$getPermission = getPermission.bind(
		Vue.prototype
	) // 获取权限
	Vue.prototype.$getPublicParams = Vue.$getPublicParams =
		getPublicParams.bind(Vue.prototype) // 获取项目级业务参数
	Vue.prototype.$getCommonParams = Vue.$getCommonParams =
		getCommonParams.bind(Vue.prototype) // 获取企业级业务参数
	Vue.prototype.$getFlowSet = Vue.$getFlowSet = getFlowSet.bind(Vue.prototype) // nouse 获取审批设置
	Vue.prototype.$userConfig = Vue.$userConfig = userConfig.bind(Vue.prototype) // 操作用户级数据
	Vue.prototype.$setTitle = setTitle // nouse
	Vue.prototype.$static = '/app' // nouse
	Vue.prototype.$device = Vue.$device = getDevice() // nouse 获取设备信息
	Vue.prototype.$delay = Vue.$delay = new Delay()
}
