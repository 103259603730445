import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from '../wy-table-list-box/index.vue'

let instance
const defaultOpt = {
	title: null,
	onCancel: null,
	onOk: null,
	reserveSelection: false,
	currentSelection: [],
	placeholder: '请输入名称',
	tableUrl: null,
	tableList: [],
	filter: {},
	cleanTableData: null,
	multi: true,
	tableKey: 'id',
	showSearch: true,
	httpWay: 'get',
	listType: 1,
	pageSize: [10, 20, 100],
	messageRender: null,
	keywordKey: 'searchPhrase'
}

/**
 * @param options
 */
export default function (options) {
	const VueConstructor = Vue.extend(tmp)
	const generateVue = () => {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	if (!instance) {
		generateVue()
	} else if (instance && options.reNew) {
		generateVue()
	}

	instance.zIndex = nextIndex(5000, 10000)
	const opt = Object.assign({}, defaultOpt, options)
	for (const i in opt) {
		instance[i] = opt[i]
	}

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyTableListBox',
		id: instance._uid,
		instance
	})

	return instance
}
