var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadein" } }, [
    _vm.visible
      ? _c("div", {
          staticClass: "wy-mask",
          style: {
            "z-index": _vm.zIndex,
            backgroundColor: "rgba(" + _vm.rgbColor + "," + _vm.opacity + ")",
          },
          on: { click: _vm.handleClick },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }