export default {
	props: {
		value: {
			type: Array
		},
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '400px'
		},
		multi: {
			type: Boolean,
			default: false
		}
	}
}
