import ChangeQuestion from './change-question.vue'
import ChangeQuestionPlugin from './change-question.js'

/* istanbul ignore next */
ChangeQuestion.install = function (Vue) {
	Vue.component(ChangeQuestion.name, ChangeQuestion)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$changeQuestion = Vue.$changeQuestion = ChangeQuestionPlugin
}

export default ChangeQuestion
