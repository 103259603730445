var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-keyword" },
    [
      !_vm.useSuggest
        ? _c(
            "el-input",
            {
              ref: "keyword",
              staticClass: "wy-keyword__input wy-border-bottom",
              attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
              on: { input: _vm.inputKeyword, focus: _vm.keywordFocus },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.changeKeyword($event, "enter")
                },
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _vm.useSearch
                ? _c("i", {
                    staticClass: "'wy-pointer el-input__icon el-icon-search",
                    attrs: { slot: _vm.searchSlot },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.searchHandler($event)
                      },
                    },
                    slot: _vm.searchSlot,
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.useSuggest
        ? _c(
            "el-autocomplete",
            {
              ref: "keyword",
              staticClass: "wy-keyword__input wy-border-bottom",
              attrs: {
                "popper-class": "wy-keyword-suggest",
                placeholder: _vm.placeholder,
                "popper-append-to-body": true,
                "trigger-on-focus": _vm.suggestOnFocus,
                "select-when-unmatched": true,
                "highlight-first-item": true,
                "hide-loading": false,
                "fetch-suggestions": _vm.suggestHandler,
                disabled: _vm.disabled,
              },
              on: {
                select: function ($event) {
                  return _vm.changeKeyword($event, "select")
                },
                input: _vm.inputKeyword,
                focus: _vm.keywordFocus,
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _vm.useSearch
                ? _c("i", {
                    staticClass: "wy-pointer el-input__icon el-icon-search",
                    attrs: { slot: _vm.searchSlot },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.searchHandler($event)
                      },
                    },
                    slot: _vm.searchSlot,
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }