<template>
	<div
		v-if="visible"
		class="wy-parabola"
		:class="'wy-parabola-' + direction"
		:style="style"
	>
		<div ref="content" class="wy-parabola-content" :style="contentStyle">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WyParabola',
	props: {
		target: Object,
		zIndex: {
			type: Number,
			default: 10000
		},
		visible: Boolean,
		direction: {
			type: String,
			default: 'x',
			validator: value => ['x', 'y'].include(value)
		}
	},
	data() {
		return {
			translateX: 0,
			translateY: 0,
			left: 0,
			top: 0
		}
	},
	computed: {
		style() {
			return {
				transform: `translateX(${this.translateX}px)`,
				zIndex: this.zIndex,
				left: this.left + 'px',
				top: this.top + 'px'
			}
		},
		contentStyle() {
			return {
				transform: `translateY(${this.translateY}px)`
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.$nextTick(() => {
					this.init()
				})
			}
		}
	},
	methods: {
		init() {
			const curRect = this.$el.parentNode.getBoundingClientRect()
			const targetRect = this.target.getBoundingClientRect()
			const clientWidth = this.$refs.content.clientWidth
			const clientHeight = this.$refs.content.clientHeight
			// 页面滚动尺寸
			const scrollTop =
				document.documentElement.scrollTop ||
				document.body.scrollTop ||
				0
			const scrollLeft =
				document.documentElement.scrollLeft ||
				document.body.scrollLeft ||
				0
			this.left =
				curRect.left + curRect.width / 2 + scrollLeft - clientWidth / 2
			this.top =
				curRect.top + curRect.height / 2 + scrollTop - clientHeight / 2
			// 开始动画
			this.translateX = targetRect.left - this.left
			this.translateY = targetRect.top - this.top
			setTimeout(() => {
				this.$emit('update:visible', false)
			}, 600)
		}
	}
}
</script>
