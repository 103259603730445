<template>
	<div class="wy-layout-page-set">
		<header class="wy-layout-page-set_top">
			<div class="wy-layout-page-set_header">
				<h1
					class="wy-layout-page-set_title"
					data-guide="layout-page-set-title"
				>
					{{ title }}
				</h1>
				<div class="wy-layout-page-set_btn-panel">
					<slot name="btn-group"></slot>
				</div>
			</div>
		</header>
		<section class="wy-layout-page-set_main">
			<slot name="content"></slot>
		</section>
	</div>
</template>
<script>
export default {
	name: 'WyLayoutPageSet',
	props: {
		title: String
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	methods: {}
}
</script>
