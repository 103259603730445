<template>
	<div class="wy-design-set">
		<header class="wy-design-set_header">
			<h1 class="wy-design-set_title" data-guide="design-set-title">
				{{ title }}
			</h1>
			<div class="wy-design-set_btn-group wy-fr">
				<slot name="wy-btn-group"></slot>
			</div>
		</header>
		<section class="wy-design-set_main">
			<div v-if="!isGroup">
				<table class="wy-design-set_list">
					<template v-for="(item, index) in list">
						<tr
							v-if="isVisible(item.permission, item.schema)"
							:key="index"
						>
							<th class="wy-design-set_name" :width="nameWidth">
								{{ item.name }}
							</th>
							<td class="wy-design-set_description">
								{{ item.description }}
							</td>
							<td class="wy-design-set_ctrl">
								<el-button
									v-if="item.url"
									type="text"
									:data-guide="`btn-1-${item.permission || index}`"
									@click="$openUrl(item.name, item.url)"
									>{{ buttonName }}</el-button
								>
								<a
									v-if="item.http"
									class="link"
									href="javascript: void(0);"
									:data-guide="`link-1-${item.permission || index}`"
									@click="handleRedirect(item.http)"
									>{{ buttonName }}</a
								>
							</td>
						</tr>
					</template>
				</table>
			</div>

			<div v-if="isGroup">
				<wy-panel-fold
					v-for="(group, index) in list"
					:key="index"
					:title="group.name"
				>
					<table
						v-if="list instanceof Array"
						class="wy-design-set_list"
					>
						<template v-for="(item, index) in group.list">
							<tr
								v-if="isVisible(item.permission, item.schema)"
								:key="index"
							>
								<th
									class="wy-design-set_name"
									:width="nameWidth"
								>
									{{ item.name }}
								</th>
								<td class="wy-design-set_description">
									{{ item.description }}
								</td>
								<td class="wy-design-set_ctrl">
									<el-button
										v-if="item.url"
										type="text"
										:data-guide="`btn-1-${index}`"
										@click="$openUrl(item.name, item.url)"
										>{{ buttonName }}</el-button
									>
									<a
										v-if="item.http"
										class="link"
										href="javascript: void(0);"
										:data-guide="`link-1-${index}`"
										@click="handleRedirect(item.http)"
										>{{ buttonName }}</a
									>
								</td>
							</tr>
						</template>
					</table>
				</wy-panel-fold>
			</div>
		</section>
	</div>
</template>
<script>
/**
 * @author su 2019-10-23
 * @description 该组件主要用于设置的导航页面
 * @example
 * ```js
 * list =>
 * [{
 *		permission: 'ProviderTypeView',	// 权限
 *		name: '供应商类型设置',	// 名称
 *		description: '配置系统中供应商类型的候选项，便于集团对供应商的分类及管理',	// 描述
 *		url: '/supplier/set/type',	// 访问链接
 *		http: 'https://wy.wojiacloud.com/supplier/set/type',	// 新窗口打开外部访问链接
 * 		http: this.http,	//传function 打开接口返回的外部链接
 * }]
 * ```
 */
export default {
	name: 'DesignSet',
	props: {
		// 左边名称的长度
		nameWidth: {
			type: String,
			default: '140px'
		},
		// 右边按钮名称
		buttonName: {
			type: String,
			default: '编辑'
		},
		// 页面标题
		title: {
			type: String,
			default() {
				return this.$route.meta.title
			}
		},
		// 内容list
		list: {
			type: Array
		},
		// 是否为组
		isGroup: {
			type: Boolean
		}
	},
	data() {
		return {
			permission: {}
		}
	},
	computed: {},
	watch: {
		list() {
			this.init()
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			const arr = []
			if (this.list.length === 0) {
				return
			}
			this.list.forEach(item => {
				arr.push(item.permission)
			})
			this.$getPermission('', arr, data => {
				this.permission = data
			})
		},
		isVisible(permission, schema) {
			let schShow = !schema,
				perShow = !permission
			if (
				schema instanceof Array &&
				schema.includes(this.permission.schema)
			) {
				schShow = true
			} else if (schema) {
				schShow = this.permission.schema === schema
			}
			if (permission instanceof Array) {
				const i = permission.findIndex(el => !!this.permission[el])
				if (i > -1) perShow = true
			} else if (permission) {
				perShow = !!this.permission[permission]
			}
			return schShow && perShow
		},
		async handleRedirect(http) {
			if (typeof http === 'function') {
				http = await http()
			}
			this.$openFile(http)
		}
	}
}
</script>
