var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-text-fold", class: { show: _vm.show } }, [
    _c("div", [
      _vm._v("\n\t\t" + _vm._s(_vm.currentText) + "\n\t\t"),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show && _vm.showFoldText,
              expression: "!show && showFoldText",
            },
          ],
          staticClass: "link",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.changeFold($event)
            },
          },
        },
        [_vm._v("展开")]
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show && _vm.showFoldText,
              expression: "show && showFoldText",
            },
          ],
          staticClass: "link",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.changeFold($event)
            },
          },
        },
        [_vm._v("收起")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }