const TableListBox = {}
import TableListBoxPlugin from './table-list-box.js'

/* istanbul ignore next */
TableListBox.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$tableListBox = Vue.$tableListBox = TableListBoxPlugin
}

export default TableListBox
