var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-tab", class: { isScroll: _vm.isScroll } },
    [
      _vm.isScroll
        ? _c("i", {
            staticClass: "el-icon-arrow-left",
            on: { click: _vm.prev },
          })
        : _vm._e(),
      _vm.isScroll
        ? _c("i", {
            staticClass: "el-icon-arrow-right",
            on: { click: _vm.next },
          })
        : _vm._e(),
      _c("div", { ref: "wyTabList", staticClass: "wy-tab-list" }, [
        _c(
          "div",
          {
            ref: "wyTabListContent",
            staticClass: "wy-tab-list-content",
            style: _vm.setStyle,
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }