var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-popper",
    {
      ref: "popper",
      staticClass: "wy-select",
      attrs: { trigger: "click", placement: "bottom" },
    },
    [
      _c(
        "div",
        { staticClass: "wy-select-dt" },
        [
          _vm.multi
            ? _c("wy-input", {
                attrs: {
                  "suffix-icon": "arrow-down",
                  cursor: "pointer",
                  value: _vm.names.join(),
                  noborder: _vm.noborder,
                  readonly: "",
                },
              })
            : _vm._e(),
          !_vm.multi
            ? _c("wy-input", {
                attrs: {
                  "suffix-icon": "arrow-down",
                  cursor: "pointer",
                  noborder: _vm.noborder,
                  readonly: "",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "wy-select-content",
            style: { maxHeight: _vm.height },
          },
          [
            _vm.showSearch
              ? _c(
                  "div",
                  { staticClass: "wy-select_keyword" },
                  [
                    _c("wy-input", {
                      attrs: {
                        "suffix-icon": "search",
                        size: "normal",
                        placeholder: "请输入关键词...",
                      },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._t("default"),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }