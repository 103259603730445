<template>
	<div v-if="visible" class="wy-project-box" :style="{ 'z-index': zIndex }">
		<wy-project
			v-model="value"
			:width="width"
			:height="height"
			:auto="auto"
			:multi="multiSet"
			:enabled-multi-page="enabledMultiPage"
			:props="treeProps"
			:clean-tree-data="cleanTreeData"
			@submit="submit($event)"
			@cancel="cancel"
		></wy-project>
	</div>
</template>
<script>
import wyProject from '../project/project.vue'
import mixin from './mixins'
export default {
	name: 'WyProjectBox',
	components: {
		wyProject
	},
	mixins: [mixin],
	data() {
		return {
			visible: false,
			zIndex: -100,
			multiSet: false
		}
	},
	computed: {},
	watch: {
		visible(val) {
			if (val) {
				this.auto = true
			} else {
				this.auto = false
			}
		},
		multi(val, oldValue) {
			if (val != oldValue) {
				this.multiSet = val
			}
		}
	},
	created() {},
	mounted() {},
	methods: {
		submit(data) {
			if (this.onOk(data)) {
				this.visible = false
			} else {
				this.onOk(() => {
					this.visible = false
				})
			}
		},
		cancel() {
			this.visible = false
			this.onCancel()
		}
	}
}
</script>
