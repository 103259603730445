<template>
	<div class="wy-tab-item">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'WyTabItem',
	props: {},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	beforeCreate() {
		this.$parent.list.push(this)
	},
	destroyed() {
		const index = this.$parent.list.indexOf(this)
		if (index !== -1) {
			this.$parent.list.splice(index, 1)
		}
	},
	methods: {}
}
</script>
