<template>
	<div class="wy-tab" :class="{ isScroll: isScroll }">
		<i v-if="isScroll" class="el-icon-arrow-left" @click="prev"></i>
		<i v-if="isScroll" class="el-icon-arrow-right" @click="next"></i>
		<div ref="wyTabList" class="wy-tab-list">
			<div
				ref="wyTabListContent"
				class="wy-tab-list-content"
				:style="setStyle"
			>
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WyTab',
	props: {
		autoNext: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			list: [],
			listWidth: 0,
			translateX: 0,
			duration: 500,
			moving: false,
			timer: null
		}
	},
	computed: {
		isScroll() {
			return this.listWidth < this.contentWidth + 32
		},
		contentWidth() {
			let width = 0
			this.list.forEach(item => {
				if (item.$el) {
					width += item.$el.offsetWidth
				}
			})
			return width
		},
		stepWidth() {
			if (this.list.length === 0) return 0
			return parseInt(this.contentWidth / this.list.length)
		},
		setStyle() {
			return {
				transform: `translateX(${this.translateX}px)`
			}
		}
	},
	watch: {
		list() {
			this.start()
		}
	},
	mounted() {
		window.onresize = () => {
			this.listWidth = this.$refs.wyTabList.offsetWidth
		}
		this.setWidth()
	},
	methods: {
		loaded() {
			this.setWidth()
			if (this.autoNext) {
				this.next()
			}
		},
		start() {
			this.$nextTick(() => {
				const active = this.list.find(node =>
					[...node.$el.classList].includes('active')
				)
				// 没有选中项或者没有滚动的时候重置位置
				if (!active || !this.isScroll) {
					this.move(0)
				} else {
					const range = [this.listWidth - this.contentWidth, 0] // translateX可用范围
					const seeRange = [
						Math.max(-active.$el.offsetLeft, range[0]),
						Math.min(
							this.listWidth -
								active.$el.offsetLeft -
								active.$el.offsetWidth,
							range[1]
						)
					] // 选中菜单可见时translateX可用范围
					if (this.translateX > seeRange[1]) this.move(seeRange[1])
					if (this.translateX < seeRange[0]) this.move(seeRange[0])
				}
			})
		},
		setWidth() {
			this.listWidth = this.$refs.wyTabList.offsetWidth
		},
		prev() {
			let translateX = this.translateX
			if (translateX < 0) {
				translateX += Math.min(this.stepWidth, -translateX)
			}
			this.move(translateX)
		},
		next() {
			this.setWidth()
			let translateX = this.translateX
			if (!this.isScroll) {
				translateX = 0
			} else {
				if (this.contentWidth + this.translateX > this.listWidth) {
					translateX -= Math.min(
						this.stepWidth,
						this.contentWidth + this.translateX - this.listWidth
					)
				}
			}
			this.move(translateX)
		},
		move(translateX) {
			this.moving = true
			this.translateX = translateX
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.moving = false
			}, this.duration)
		}
	}
}
</script>
