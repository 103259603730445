<template>
	<div class="wy-panel-fold" :class="{ show: show }">
		<div class="wy-panel-fold_title">
			<i
				class="el-icon-caret-bottom"
				:class="{ close: !show }"
				@click="changeFoldStatus"
			></i>
			<h4 class="wy-panel-fold_h4title" @click="changeFoldStatus">
				{{ title }}
			</h4>
			<slot name="btn"></slot>
		</div>
		<el-collapse-transition>
			<div v-show="show" class="wy-panel-fold_content">
				<slot></slot>
			</div>
		</el-collapse-transition>
	</div>
</template>

<script>
export default {
	name: 'WyPanelFold',
	props: {
		title: String,
		showFold: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			show: true
		}
	},
	watch: {
		showFold(newVal) {
			this.show = newVal
		}
	},
	created() {
		this.show = this.showFold
	},
	methods: {
		changeFoldStatus() {
			this.show = !this.show
		}
	}
}
</script>
