<template>
	<div class="wy-address">
		<el-select
			v-model="curValue.province"
			class="wy-address_province"
			:class="{ 'wy-border-bottom': border == 'bottom' }"
			:disabled="disabled"
			placeholder="请选择省"
			@change="changeProvince"
		>
			<el-option
				v-for="item in provinceList"
				:key="item.id"
				:label="item.fullName"
				:value="item.fullName"
			>
			</el-option>
		</el-select>
		<el-select
			v-model="curValue.city"
			class="wy-address_city"
			:class="{ 'wy-border-bottom': border == 'bottom' }"
			:disabled="disabled"
			placeholder="请选择市"
			@change="changeCity"
		>
			<el-option
				v-for="item in cityList"
				:key="item.id"
				:label="item.fullName"
				:value="item.fullName"
			>
			</el-option>
		</el-select>
		<el-select
			v-model="curValue.area"
			class="wy-address_area"
			:class="{ 'wy-border-bottom': border == 'bottom' }"
			:disabled="disabled"
			placeholder="请选择区"
			@change="changeArea"
		>
			<el-option
				v-for="item in areaList"
				:key="item.id"
				:label="item.fullName"
				:value="item.fullName"
			>
			</el-option>
		</el-select>
	</div>
</template>

<script>
export default {
	name: 'WyAddress',
	props: {
		value: {
			type: Object,
			default: () => {
				return {
					province: null,
					city: null,
					area: null
				}
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		border: {
			type: String,
			default: 'all',
			validator: value => ['all', 'none', 'bottom'].includes(value)
		}
	},
	data() {
		return {
			provinceList: [],
			cityList: [],
			areaList: [],
			curValue: {
				province: null,
				city: null,
				area: null
			}
		}
	},
	watch: {
		value(val, oldValue) {
			if (val == oldValue) {
				return
			}
			if (this.curValue.province != val.province) {
				this.getCityData('2', val.province)
			}
			if (this.curValue.city != val.city) {
				this.getCityData('3', val.city)
			}
			this.curValue = val
		}
	},
	created() {
		if (this.value.province) {
			this.curValue = this.value
			this.getCityData('2', this.curValue.province)
			this.getCityData('3', this.curValue.city)
		}
		this.getCityData('1')
	},
	methods: {
		// 加载地点数据
		getCityData(level, name) {
			this.$axios({
				url: '/region/getRegion',
				data: {
					fullName: name ? encodeURIComponent(name) : '',
					level
				}
			}).then(res => {
				if (res.data) {
					if (level == '1') {
						this.provinceList = res.data
					}
					if (level == '2') {
						this.cityList = res.data
						if (!this.curValue.city) {
							this.curValue.city = res.data[0].fullName
							this.getCityData('3', this.curValue.city)
						}
					}
					if (level == '3') {
						this.areaList = res.data
						if (!this.curValue.area) {
							this.curValue.area = res.data[0].fullName
						}
					}
				}
			})
		},
		changeProvince() {
			this.curValue.city = null
			this.curValue.area = null
			this.getCityData('2', this.curValue.province)
			this.$emit('input', this.curValue)
		},
		changeCity() {
			this.curValue.area = null
			this.getCityData('3', this.curValue.city)
			this.$emit('input', this.curValue)
		},
		changeArea() {
			this.$emit('input', this.curValue)
		}
	}
}
</script>
