<template>
	<div class="wy-col" :style="style">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'WyCol',
	props: {
		order: {
			type: Number,
			default: 0
		},
		flexGrow: {
			type: Number,
			default: 0
		},
		flexShrink: {
			type: Number,
			default: 0
		},
		flexBasis: {
			type: String,
			default: 'auto'
		},
		alignSelf: {
			type: String,
			default: 'auto',
			validator: value =>
				[
					'auto',
					'flex-start',
					'flex-end',
					'center',
					'baseline',
					'stretch'
				].includes(value)
		}
	},
	data() {
		return {
			project: {}
		}
	},
	computed: {
		style() {
			return {
				order: this.order,
				flexGrow: this.flexGrow,
				flexShrink: this.flexShrink,
				flexBasis: this.flexBasis,
				alignSelf: this.alignSelf
			}
		}
	},
	watch: {},
	mounted() {},
	methods: {}
}
</script>
