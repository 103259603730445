import { version } from '../package.json'
import Address from './address'
import Alert from './alert'
import Box from './box'
import BuildingRoom from './building-room'
import Button from './button'
import Calendar from './calendar'
import ChangeProject from './change-project'
import ChangeQuestion from './change-question'
import Checkbox from './checkbox'
import Col from './col'
import Cropper from './cropper'
import DatePicker from './date-picker'
import DesignList from './design-list'
import DesignListCheckbox from './design-list-checkbox'
import DesignListNumberRange from './design-list-number-range'
import DesignListQuery from './design-list-query'
import DesignListSchema from './design-list-schema'
import DesignReport from './design-report'
import DesignSet from './design-set'
import DesignTable from './design-table'
import DesignTableColumn from './design-table-column'
import Editor from './editor'
import ExportFile from './export-file'
import Form from './form'
import FormItem from './form-item'
import Guide from './guide'
import Header from './header'
import JspFrame from './jsp-frame'
import Keyword from './keyword'
import LayoutAdd from './layout-add'
import LayoutPage from './layout-page'
import LayoutPageList from './layout-page-list'
import LayoutPageSet from './layout-page-set'
import LayoutPageTab from './layout-page-tab'
import LayoutPageTable from './layout-page-table'
import LayoutReport from './layout-report'
import LayoutSet from './layout-set'
import Loader from './loader'
import Mask from './mask'
import MoneyDefineBox from './money-define-box'
import Pages from './pages'
import PanelFold from './panel-fold'
import Parabola from './parabola'
import Popper from './popper'
import Progress from './progress'
import ProgressV2 from './progress-v2'
import Project from './project'
import ProjectBox from './project-box'
import ProjectTreeBox from './project-tree-box'
import Question from './question'
import QuestionBox from './question-box'
import Row from './row'
import Select from './select'
import ShowPic from './show-pic'
import Shrink from './shrink'
import Split from './split'
import Step from './step'
import Steps from './steps'
import Tab from './tab'
import TabItem from './tab-item'
import Table from './table'
import TableList from './table-list'
import TableListBox from './table-list-box'
import TextFold from './text-fold'
import Tree from './tree'
import TreeAndTable from './tree-and-table'
import TreeAndTableBox from './tree-and-table-box'
import TreeBox from './tree-box'
import TreeTable from './tree-table'
import UploadFile from './upload-file'
import WyBox from './wy-box'
import WyProjectBox from './wy-project-box'
import WyTableListBox from './wy-table-list-box'
import WyTreeBox from './wy-tree-box'

import directives from './directives'
import filters from './filters'
import plugins from './plugins'

const install = function (Vue, opts = {}) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$CLOUDUI = Vue.$CLOUDUI = {
		size: opts.size || '',
		zIndex: opts.zIndex || 5000
	}

	Vue.use(directives)
	Vue.use(filters)
	Vue.use(plugins)

	Vue.use(Address)
	Vue.use(Alert)
	Vue.use(Box)
	Vue.use(BuildingRoom)
	Vue.use(Button)
	Vue.use(Calendar)
	Vue.use(ChangeProject)
	Vue.use(ChangeQuestion)
	Vue.use(Checkbox)
	Vue.use(Col)
	Vue.use(Cropper)
	Vue.use(DatePicker)
	Vue.use(DesignList)
	Vue.use(DesignListCheckbox)
	Vue.use(DesignListNumberRange)
	Vue.use(DesignListQuery)
	Vue.use(DesignListSchema)
	Vue.use(DesignReport)
	Vue.use(DesignSet)
	Vue.use(DesignTable)
	Vue.use(DesignTableColumn)
	Vue.use(Editor)
	Vue.use(ExportFile)
	Vue.use(Form)
	Vue.use(FormItem)
	Vue.use(Guide)
	Vue.use(Header)
	Vue.use(JspFrame)
	Vue.use(Keyword)
	Vue.use(LayoutAdd)
	Vue.use(LayoutPage)
	Vue.use(LayoutPageList)
	Vue.use(LayoutPageSet)
	Vue.use(LayoutPageTab)
	Vue.use(LayoutPageTable)
	Vue.use(LayoutReport)
	Vue.use(LayoutSet)
	Vue.use(Loader)
	Vue.use(Mask)
	Vue.use(MoneyDefineBox)
	Vue.use(Pages)
	Vue.use(PanelFold)
	Vue.use(Parabola)
	Vue.use(Popper)
	Vue.use(Progress)
	Vue.use(ProgressV2)
	Vue.use(Project)
	Vue.use(ProjectBox)
	Vue.use(ProjectTreeBox)
	Vue.use(Question)
	Vue.use(QuestionBox)
	Vue.use(Row)
	Vue.use(Select)
	Vue.use(ShowPic)
	Vue.use(Shrink)
	Vue.use(Split)
	Vue.use(Step)
	Vue.use(Steps)
	Vue.use(Tab)
	Vue.use(TabItem)
	Vue.use(Table)
	Vue.use(TableList)
	Vue.use(TableListBox)
	Vue.use(TextFold)
	Vue.use(Tree)
	Vue.use(TreeAndTable)
	Vue.use(TreeAndTableBox)
	Vue.use(TreeBox)
	Vue.use(TreeTable)
	Vue.use(UploadFile)
	Vue.use(WyBox)
	Vue.use(WyProjectBox)
	Vue.use(WyTableListBox)
	Vue.use(WyTreeBox)
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
	install(window.Vue)
}

export default {
	version,
	install,
	Address,
	Alert,
	Box,
	BuildingRoom,
	Button,
	Calendar,
	ChangeProject,
	ChangeQuestion,
	Checkbox,
	Col,
	Cropper,
	DatePicker,
	DesignList,
	DesignListCheckbox,
	DesignListNumberRange,
	DesignListQuery,
	DesignListSchema,
	DesignReport,
	DesignSet,
	DesignTable,
	DesignTableColumn,
	Editor,
	ExportFile,
	Form,
	FormItem,
	Guide,
	Header,
	JspFrame,
	Keyword,
	LayoutAdd,
	LayoutPage,
	LayoutPageList,
	LayoutPageSet,
	LayoutPageTab,
	LayoutPageTable,
	LayoutReport,
	LayoutSet,
	Loader,
	Mask,
	MoneyDefineBox,
	Pages,
	PanelFold,
	Parabola,
	Popper,
	Progress,
	ProgressV2,
	Project,
	ProjectBox,
	ProjectTreeBox,
	Question,
	QuestionBox,
	Row,
	Select,
	ShowPic,
	Shrink,
	Split,
	Step,
	Steps,
	Tab,
	TabItem,
	Table,
	TableList,
	TableListBox,
	TextFold,
	Tree,
	TreeAndTable,
	TreeAndTableBox,
	TreeBox,
	TreeTable,
	UploadFile,
	WyBox,
	WyProjectBox,
	WyTableListBox,
	WyTreeBox
}
