<template>
	<div class="wy-tree">
		<div
			v-if="root[nodeName]"
			class="wy-tree-root"
			@click="handleClick(root)"
			v-text="root[nodeName]"
		></div>
		<template v-for="item in root[nodeChild]">
			<wy-tree-item
				v-if="filter(item)"
				:key="item[nodeKey]"
				:parent-node="root"
				:node="item"
				:cur-node="curNode"
				@handleClick="handleClick($event)"
			>
			</wy-tree-item>
		</template>
	</div>
</template>
<script>
import { extend } from 'tool'
import wyTreeItem from './tree-item.vue'
export default {
	name: 'WyTree',
	components: {
		wyTreeItem
	},
	provide() {
		return {
			nodeKey: this.nodeKey,
			nodeName: this.nodeName,
			nodeChild: this.nodeChild,
			filter: this.filter
		}
	},
	props: {
		data: Object,
		nodeKey: {
			type: String,
			default: 'id'
		},
		nodeName: {
			type: String,
			default: 'name'
		},
		nodeChild: {
			type: String,
			default: 'child'
		},
		filter: Function,
		currentNode: Object
	},
	data() {
		return {
			root: {},
			curNode: null
		}
	},
	computed: {},
	watch: {
		data(value) {
			const root = extend(true, {}, value)
			const childs = root[this.nodeChild]
			const arr = []
			childs.forEach(child => {
				if (this.filter(child)) {
					child.level = 1
					if (!child.show) {
						child.show = false
					}
					arr.push(child)
				}
			})
			root[this.nodeChild] = arr
			this.root = root
		},
		currentNode(value) {
			this.curNode = value
		}
	},
	created() {
		this.curNode = this.currentNode
	},
	mounted() {},
	methods: {
		handleClick(e) {
			this.curNode = e
			this.$emit('click', e)
		}
	}
}
</script>
