import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from './loader.vue'

const VueConstructor = Vue.extend(tmp)
let timer,
	word = []

/**
 * @param Vue
 * @param proto
 */
function loader() {
	this.instance = new VueConstructor({
		el: document.createElement('div'),
		methods: {}
	})
	document.body.appendChild(this.instance.$el)

	return this
}

loader.prototype.show = function (content, noLimitTime) {
	if (word.length > 0) return
	word.push(content)
	// 设置定时器，防止loading阻塞页面
	if (!noLimitTime) {
		// 如果有传参数  则不限制时间
		clearTimeout(timer)
		timer = setTimeout(() => {
			this.instance.num = 0
			this.instance.visible = false
		}, 30000)
	}
	this.instance.content = word[0]
	if (this.instance.num > 0) {
		this.instance.num++
		return
	}
	this.instance.num++
	this.instance.zIndex = nextIndex(10000, 20000)
	this.instance.visible = true
}

loader.prototype.hide = function () {
	// 设置定时器，防止loading阻塞页面
	clearTimeout(timer)
	timer = setTimeout(() => {
		this.instance.num = 0
		this.instance.visible = false
	}, 30000)
	this.instance.num--
	if (this.instance.num > 0) {
		word.splice(0, 1)
		this.instance.content = word[0]
		return
	}
	this.instance.num = 0
	word = []
	this.instance.visible = false
}

export default loader
