import hidden from './hidden'
import dragArea from './drag-area'

const install = function (Vue) {
	Vue.directive(hidden.name, hidden)
	Vue.directive(dragArea.name, dragArea)
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
	install(window.Vue)
}

export default {
	install
}
