var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-layout-add" }, [
    _c("div", { staticClass: "wy-layout-add_top" }, [
      _c(
        "h1",
        {
          staticClass: "wy-layout-add_title",
          attrs: { "data-guide": "layout-add-title" },
        },
        [
          _c("el-tooltip", { attrs: { content: "点击查看功能指引" } }, [
            _vm.intro
              ? _c("i", {
                  staticClass: "iconfont icon-guide wy-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.showIntro(true)
                    },
                  },
                })
              : _vm._e(),
          ]),
          _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t"),
        ],
        1
      ),
      _c("div", { staticClass: "wy-layout-add_btn-group" }, [_vm._t("btn")], 2),
    ]),
    _vm.$slots.panel
      ? _c("div", { staticClass: "wy-layout-add_panel" }, [_vm._t("panel")], 2)
      : _vm._e(),
    _c("div", { staticClass: "wy-layout-add_main" }, [_vm._t("content")], 2),
    _vm.$slots.footer
      ? _c(
          "div",
          { staticClass: "wy-layout-add_footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }