var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-tree" },
    [
      _vm.root[_vm.nodeName]
        ? _c("div", {
            staticClass: "wy-tree-root",
            domProps: { textContent: _vm._s(_vm.root[_vm.nodeName]) },
            on: {
              click: function ($event) {
                return _vm.handleClick(_vm.root)
              },
            },
          })
        : _vm._e(),
      _vm._l(_vm.root[_vm.nodeChild], function (item) {
        return [
          _vm.filter(item)
            ? _c("wy-tree-item", {
                key: item[_vm.nodeKey],
                attrs: {
                  "parent-node": _vm.root,
                  node: item,
                  "cur-node": _vm.curNode,
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.handleClick($event)
                  },
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }