var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "wy-alert", style: { "z-index": _vm.zIndex } },
          [
            _c(
              "div",
              { staticClass: "wy-alert_box", style: { width: _vm.width } },
              [
                _c(
                  "div",
                  { staticClass: "wy-alert_header" },
                  [
                    !_vm.$slots.title ? [_vm._v(_vm._s(_vm.title))] : _vm._e(),
                    _vm.$slots.title ? _vm._t("title") : _vm._e(),
                  ],
                  2
                ),
                _vm.$slots.default
                  ? _c(
                      "div",
                      {
                        staticClass: "wy-alert_content",
                        style: { height: _vm.height, maxHeight: _vm.maxHeight },
                      },
                      [_vm._t("default")],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "wy-alert_footer" },
                  [
                    !_vm.$slots.footer
                      ? [
                          _c(
                            "wy-button",
                            {
                              attrs: { theme: "" },
                              on: { click: _vm.handleOk },
                            },
                            [_vm._v(_vm._s(_vm.okBtnName))]
                          ),
                          _c("wy-button", { on: { click: _vm.handleCancel } }, [
                            _vm._v(_vm._s(_vm.cancelBtnName)),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.$slots.footer ? _vm._t("footer") : _vm._e(),
                  ],
                  2
                ),
                _vm.showClose
                  ? _c("i", {
                      staticClass: "wy-alert_close iconfont icon-del",
                      attrs: { title: "关闭" },
                      on: { click: _vm.handleClose },
                    })
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }