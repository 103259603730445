<template>
	<div
		v-show="visible"
		class="wy-question-box"
		:style="{ 'z-index': zIndex }"
	>
		<wy-question
			v-model="value"
			:width="width"
			:height="height"
			:multi="multi"
			@submit="submit($event)"
			@cancel="cancel"
		></wy-question>
	</div>
</template>
<script>
import mixin from '../question/mixins'
import wyQuestion from '../question'
export default {
	name: 'WyQuestionBox',
	components: {
		wyQuestion
	},
	mixins: [mixin],
	props: {
		onOk: Function,
		onCancel: Function
	},
	data() {
		return {
			visible: false,
			zIndex: -100
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submit(data) {
			if (this.onOk(data)) {
				this.visible = false
			} else {
				this.onOk(() => {
					this.visible = false
				})
			}
		},
		cancel() {
			this.visible = false
			this.onCancel()
		}
	}
}
</script>
