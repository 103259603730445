<template>
	<div class="wy-layout-report">
		<header class="wy-layout-report_top">
			<div class="wy-layout-report_header">
				<h1
					class="wy-layout-report_title"
					data-guide="layout-report-title"
				>
					{{ title }}
				</h1>
				<div class="wy-layout-report_tab" v-if="showList && showChart">
					<el-button
						type="text"
						data-guide="layout-report-view-1"
						:class="{ grey: viewTab == 2 }"
						@click="changeView(1)"
					>
						<i class="iconfont icon-tab-chart"></i>图表视图
					</el-button>
					<span>|</span>
					<el-button
						type="text"
						data-guide="layout-report-view-2"
						:class="{ grey: viewTab == 1 }"
						@click="changeView(2)"
					>
						<i class="iconfont icon-tab-list"></i>列表视图
					</el-button>
				</div>
				<div class="wy-layout-report_btn-panel">
					<slot name="btn-group"></slot>
				</div>
			</div>
			<div v-if="!$slots.defaultSearch" class="wy-layout-report_bar">
				<div class="wy-layout-report_project">
					<span>所属项目：</span>
					<el-input
						v-if="showProject != 'none'"
						key="project-no-default-search"
						class="wy-border-bottom"
						placeholder="请选择项目"
						:title="curProject.name"
						:value="curProject.name"
						:clearable="projectClearable"
						read-only
						@click.native="changeProject"
						@clear="handleClear"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
						></i>
						<i
							slot="clearfix"
							class="el-input__icon el-icon-search"
						></i>
					</el-input>
					<span v-if="showProject == 'none'">{{
						curProject.name
					}}</span>
				</div>
				<div v-if="showFilter" class="wy-layout-report_filter">
					<template v-for="(item, index) in filterResult">
						<el-tag
							v-if="item.value"
							:key="item.id"
							:closable="item.closable"
							size="small"
							type="info"
							:disable-transitions="false"
							@click.native="showPanel = !showPanel"
							@close="removeFilter(item, index)"
						>
							<span v-if="item.name">{{ item.name }}:</span>
							<span>{{ item.value }}</span>
						</el-tag>
					</template>
				</div>
				<div class="wy-layout-report_search">
					<el-select
						v-if="typeList.length > 0"
						v-model="searchType"
						class="col-1 wy-border-bottom"
						placeholder="请选择搜索类型"
						@change="changeSearchType"
					>
						<el-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
					<el-input
						v-if="showSearch"
						v-model="keyword"
						class="wy-border-bottom"
						:placeholder="placeholder"
						:style="{ width: searchWidth }"
						:maxlength="maxlength"
						@input="changeKeyword"
						@change="keywordChangeSearch"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
							@click="search"
						></i>
					</el-input>
				</div>
				<el-button
					v-if="showFilter"
					class="wy-layout-report_more"
					type="text"
					@click="switchPanel"
				>
					<span v-if="!showPanel">更多高级查询</span>
					<span v-if="showPanel">关闭高级查询</span>
					<i
						class
						:class="{
							'el-icon-arrow-right': !showPanel,
							'el-icon-arrow-left': showPanel
						}"
					></i>
				</el-button>
			</div>
			<div v-if="$slots.defaultSearch" class="wy-layout-report_bar">
				<div class="wy-layout-report_project">
					<span>所属项目：</span>
					<el-input
						v-if="showProject != 'none'"
						key="project-default-search"
						class="wy-border-bottom wy-pointer"
						placeholder="请选择项目"
						:title="curProject.name"
						:value="curProject.name"
						:clearable="projectClearable"
						read-only
						@click.native="changeProject"
						@clear="handleClear"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
						></i>
					</el-input>
					<span v-if="showProject == 'none'">{{
						curProject.name
					}}</span>
				</div>
				<div class="wy-layout-report_default_search">
					<slot name="defaultSearch"></slot>
				</div>
				<div class="wy-layout-report_search">
					<el-select
						v-if="typeList.length > 0"
						v-model="searchType"
						class="col-1 border-bottom"
						placeholder="请选择搜索类型"
						@change="changeSearchType"
					>
						<el-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
					<el-input
						v-if="showSearch"
						v-model="keyword"
						class="wy-border-bottom"
						:placeholder="placeholder"
						:style="{ width: searchWidth }"
						:maxlength="maxlength"
						@input="changeKeyword"
						@change="keywordChangeSearch"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
							@click="search"
						></i>
					</el-input>
				</div>
				<el-button
					v-if="showFilter"
					class="wy-layout-report_more"
					type="text"
					@click="switchPanel"
				>
					<span v-if="!showPanel">更多高级查询</span>
					<span v-if="showPanel">关闭高级查询</span>
					<i
						class
						:class="{
							'el-icon-arrow-right': !showPanel,
							'el-icon-arrow-left': showPanel
						}"
					></i>
				</el-button>
			</div>
			<div
				v-if="showFilter && $slots.defaultSearch"
				class="wy-layout-report_filter wy-filter-bottom"
			>
				<template v-for="(item, index) in filterResult">
					<el-tag
						v-if="item.value"
						:key="item.id"
						:closable="item.closable"
						size="small"
						type="info"
						:disable-transitions="false"
						@click.native="showPanel = !showPanel"
						@close="removeFilter(item, index)"
					>
						<span v-if="item.name">{{ item.name }}:</span>
						<span>{{ item.value }}</span>
					</el-tag>
				</template>
			</div>
		</header>
		<section v-if="$slots.total" class="wy-layout-report_total-bar">
			<slot name="total"></slot>
		</section>
		<section
			v-if="showChart"
			v-show="viewTab == 1"
			class="wy-layout-report_main"
		>
			<slot name="chart"></slot>
		</section>
		<section
			v-if="showList"
			v-show="viewTab == 2"
			class="wy-layout-report_main"
		>
			<slot name="content"></slot>
		</section>
		<transition name="slide">
			<div
				class="wy-layout-report_filter-panel"
				data-guide="layout-report_filter"
				v-show="showPanel"
				v-if="showFilter"
			>
				<h3 class="wy-layout-report_title">高级查询</h3>
				<div v-if="ready" class="wy-layout-report_filter-content">
					<el-form
						class="wy-layout-report_filter-form"
						:model="filterForm"
						label-position="top"
					>
						<el-form-item
							v-for="item in filter"
							v-show="!filterMap[item.key].hidden"
							:key="item.key"
							:label="item.name"
						>
							<template v-if="item.type == 'input'">
								<el-input
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
								></el-input>
							</template>
							<template v-if="item.type == 'select'">
								<el-select
									v-model="filterForm[item.key]"
									value-key="id"
									class="wy-border-bottom"
									:filterable="item.filterable"
									:multiple="item.multiple"
									@change="changeSelect(item, $event)"
									@visible-change="
										changeSelectVisible(item, $event)
									"
								>
									<el-option
										v-if="item.isSelectAll"
										key="all"
										label="全选"
										:value="selectAllOption"
										title="全选"
									></el-option>
									<el-option
										v-for="option in filterMap[item.key]
											.list"
										:key="option.id"
										:label="option.name"
										:value="option"
										:title="option.name"
									></el-option>
								</el-select>
							</template>
							<template v-if="item.type == 'date'">
								<el-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									value-format="yyyy-MM-dd"
									align="right"
									type="date"
									placeholder="选择日期"
								></el-date-picker>
							</template>
							<template v-if="item.type == 'month'">
								<el-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									value-format="yyyy-MM"
									align="right"
									type="month"
									placeholder="选择日期"
									:clearable="item.clearable"
									:editable="false"
									@change="changeMonth(item, $event)"
								></el-date-picker>
							</template>
							<template v-if="item.type == 'year'">
								<el-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									value-format="yyyy"
									align="right"
									type="year"
									placeholder="选择年"
								></el-date-picker>
							</template>
							<!--时间段选择-->
							<template v-if="item.type == 'time'">
								<el-time-picker
									v-model="filterForm[item.key]"
									arrow-control
									class="wy-border-bottom"
									:picker-options="{
										selectableRange: '18:30:00 - 20:30:00'
									}"
									placeholder="选择时间"
								></el-time-picker>
							</template>
							<!--自定义日期选择-->
							<template v-if="item.type == 'dateRangeDefine'">
								<el-row :gutter="20" style="display: flex">
									<el-col style="padding-right: 0">
										<el-date-picker
											v-model="filterForm[item.key][0]"
											:disabled="
												item.disabled &&
												item.disabled[0]
											"
											class="wy-border-bottom"
											value-format="yyyy-MM-dd"
											align="right"
											type="date"
											placeholder="选择日期"
											:picker-options="
												item.startPickerOptions
											"
											:clearable="item.clearable"
											:editable="false"
										></el-date-picker>
									</el-col>
									<el-col :span="1" style="padding: 0">
										<span>至</span>
									</el-col>
									<el-col>
										<el-date-picker
											v-model="filterForm[item.key][1]"
											:disabled="
												item.disabled &&
												item.disabled[1]
											"
											class="wy-border-bottom"
											value-format="yyyy-MM-dd"
											align="right"
											type="date"
											placeholder="选择日期"
											:picker-options="
												item.endPickerOptions
											"
											:clearable="item.clearable"
											:editable="false"
										></el-date-picker>
									</el-col>
								</el-row>
							</template>
							<!--自定义日期时间选择-->
							<template v-if="item.type == 'dateTimeRangeDefine'">
								<el-row :gutter="20" style="display: flex">
									<el-col style="padding-right: 0">
										<el-date-picker
											v-model="filterForm[item.key][0]"
											type="datetime"
											placeholder="选择日期时间"
											value-format="yyyy-MM-dd HH:mm:ss"
											class="wy-border-bottom"
										>
										</el-date-picker>
									</el-col>
									<el-col :span="1" style="padding: 0">
										<span>至</span>
									</el-col>
									<el-col>
										<el-date-picker
											v-model="filterForm[item.key][1]"
											type="datetime"
											placeholder="选择日期时间"
											value-format="yyyy-MM-dd HH:mm:ss"
											class="wy-border-bottom"
										>
										</el-date-picker>
									</el-col>
								</el-row>
							</template>
							<!--日期区间选择-->
							<template v-if="item.type == 'dateRange'">
								<wy-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									:clearable="item.clearable"
								></wy-date-picker>
							</template>
							<template v-if="item.type == 'dateTimeRange'">
								<el-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									type="datetimerange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									value-format="yyyy-MM-dd HH:mm:ss"
								>
								</el-date-picker>
							</template>
							<!--月份区间选择-->
							<template
								v-if="item.type == 'monthRange'"
								class="wy-block"
							>
								<wy-date-picker
									v-model="filterForm[item.key]"
									class="wy-border-bottom"
									type="monthrange"
									range-separator="至"
									start-placeholder="开始时间"
									end-placeholder="结束时间"
									value-format="yyyy-MM"
									format="yyyy-MM"
									:clearable="item.clearable"
								></wy-date-picker>
							</template>
							<!--自定义月份区间选择-->
							<template v-if="item.type == 'monthRangeDefine'">
								<el-row :gutter="20" style="display: flex">
									<el-col style="padding-right: 0">
										<el-date-picker
											v-model="filterForm[item.key][0]"
											:disabled="
												item.disabled &&
												item.disabled[0]
											"
											class="wy-border-bottom"
											value-format="yyyy-MM"
											align="right"
											type="month"
											placeholder="选择日期"
											:picker-options="
												item.startPickerOptions
											"
											:clearable="item.clearable"
											:editable="false"
										></el-date-picker>
									</el-col>
									<el-col :span="1" style="padding: 0">
										<span>至</span>
									</el-col>
									<el-col>
										<el-date-picker
											v-model="filterForm[item.key][1]"
											:disabled="
												item.disabled &&
												item.disabled[1]
											"
											class="wy-border-bottom"
											value-format="yyyy-MM"
											align="right"
											type="month"
											placeholder="选择日期"
											:picker-options="
												item.endPickerOptions
											"
											:clearable="item.clearable"
											:editable="false"
										></el-date-picker>
									</el-col>
								</el-row>
							</template>
							<!--数量区间选择-->
							<template v-if="item.type == 'numRange'">
								<el-row :gutter="20" style="display: flex">
									<el-col>
										<el-input
											v-model="filterForm[item.key][0]"
											class="wy-border-bottom"
											:placeholder="
												item.minPlaceholder || 0
											"
											@keyup.native="
												checkVal(item.key, 0)
											"
										></el-input>
									</el-col>
									<el-col :span="2">
										<span>-</span>
									</el-col>
									<el-col>
										<el-input
											v-model="filterForm[item.key][1]"
											class="wy-border-bottom"
											:placeholder="
												item.maxPlaceholder || 100
											"
											@keyup.native="
												checkVal(item.key, 1)
											"
										></el-input>
									</el-col>
								</el-row>
							</template>
							<!--项目选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'project'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeProject(item.key)"
								></el-input>
							</template>
							<!--供应商选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'provider'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeProvider(item)"
								></el-input>
							</template>
							<!--第三方问题分类选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'question'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeQuestion(item.key)"
								></el-input>
							</template>
							<!--员工选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'employee'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeEmployee(item)"
								></el-input>
							</template>
							<!--仓库选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'warehouse'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeWarehouse(item)"
								></el-input>
							</template>
							<!--组织选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'orgunit'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeOrgunits(item)"
								></el-input>
							</template>
							<!--房间选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'cusRoom'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeRoom(item)"
								></el-input>
							</template>
							<!--系统角色选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'orgRole'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeOrgRole(item)"
								></el-input>
							</template>
							<!--招商顾问选择-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'investConsultant'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeInvestConsultant(item)"
								></el-input>
							</template>
							<!--房间范围：组团-楼栋-单元-房间-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'buildingRoom'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeBuildingRoom(item)"
								></el-input>
							</template>
							<!--产品类型-->
							<template
								v-if="
									item.type == 'box' &&
									item.boxType == 'productType'
								"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="changeProductType(item)"
								></el-input>
							</template>
							<!--自定义弹出框-->
							<template
								v-if="item.type == 'box' && !item.boxType"
							>
								<el-input
									v-model="filterForm[item.key].name"
									class="wy-border-bottom wy-pointer"
									suffix-icon="el-icon-search"
									readonly="readonly"
									@click.native="handleClickBox(item)"
								></el-input>
							</template>
						</el-form-item>
					</el-form>
				</div>
				<div class="wy-layout-report_ctrl">
					<el-button type="primary" @click="submitForm"
						>查询</el-button
					>
					<el-button @click="cancelForm">取消</el-button>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import wyDatePicker from '../date-picker'
export default {
	name: 'WyLayoutReport',
	components: {
		wyDatePicker
	},
	props: {
		title: String,
		showList: {
			type: Boolean,
			default: true
		},
		showChart: {
			type: Boolean,
			default: false
		},
		showProject: {
			type: String,
			default: 'none',
			validator: value => ['none', 'multi', 'single'].includes(value)
		},
		// 是否启用项目切换，默认显示
		enabledProject: {
			type: Boolean,
			default: true
		},
		// 项目可清除
		projectClearable: {
			type: Boolean,
			default: false
		},
		// 设置默认项目
		defaultProject: {
			type: [Object, Boolean],
			default: true
		},
		showFilter: {
			type: Boolean,
			default: true
		},
		filter: {
			type: Array,
			default() {
				return []
			}
		},
		placeholder: {
			type: String,
			default: '请输入内容'
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		searchWidth: {
			type: String,
			default: '200px'
		},
		showView: {
			type: Boolean,
			default: false
		},
		isShowPanel: {
			type: Boolean,
			default: false
		},
		defaultTag: {
			type: Array,
			default() {
				return []
			}
		},
		ctrlPanel: {
			type: Boolean,
			default: false
		},
		typeList: {
			type: Array,
			default() {
				return []
			}
		},
		defaultSearchType: {
			type: String,
			default: ''
		},
		changeImmediately: {
			// 切换多选立即执行  不需要等select隐藏
			type: Boolean,
			default: false
		},
		clerarKeyWord: {
			// 切换搜索类型 清空搜索内容
			type: Boolean,
			default: true
		},
		maxlength: {
			type: String,
			default: ''
		},
		// 最大项目个数
		maxProject: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			viewTab: 2,
			form: {},
			keyword: null,
			showPanel: false,
			filterForm: {},
			filterResult: [],
			filterData: {},
			filterMap: {},
			timeout: null,
			lastVal: '',
			curProject: {
				name: null,
				id: null,
				selection: []
			},
			selectVisible: null,
			searchType: null,
			selectAllOption: {
				id: 'all',
				name: '全选'
			},
			ready: false
		}
	},
	computed: {
		...mapState({
			userInfo: state => state.user.curUser
		})
	},
	created() {
		this.init()
	},
	methods: {
		async init() {
			// 加载默认项目
			await this.loadProject()

			if (this.isShowPanel) {
				this.showPanel = true
			}
			if (this.defaultTag.length > 0) {
				this.filterResult = [].concat(this.defaultTag)
				this.defaultTag.forEach(item => {
					this.$set(this.filterData, item.id, item.value)
				})
			}
			if (this.showFilter && this.filter) {
				// 如果显示高级查询面板
				this.createFilter()
			}
			if (this.showChart) {
				this.viewTab = 1
			}
			if (this.defaultSearchType) {
				this.searchType = this.defaultSearchType
			}
		},
		createFilter() {
			// 创建查询面板
			this.filter.forEach(item => {
				if (item.type == 'box' && !item.value) {
					item.value = {
						id: null,
						name: null,
						selection: []
					}
				}
				if (item.type == 'numRange' && !item.value) {
					item.value = []
				}
				if (item.type == 'dateRangeDefine' && !item.value) {
					item.value = []
				}
				if (item.type == 'dateTimeRangeDefine' && !item.value) {
					item.value = []
				}
				if (item.type == 'monthRangeDefine' && !item.value) {
					item.value = []
				}
				this.$set(this.filterForm, item.key, item.value)
				this.$set(this.filterMap, item.key, item)
			})
			this.ready = true
		},
		changeView(tab) {
			this.viewTab = tab
			this.$emit('changeView', tab)
		},
		switchPanel() {
			// 切换高级查询面板开关
			this.showPanel = !this.showPanel
		},
		hidePanle() {
			this.showPanel = false
		},
		changeKeyword() {
			this.timeout && clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.$emit('changeKeyword', this.keyword)
			}, 500)
		},
		// 深业需求 模糊查询要求取消实时查询 故增加change事件
		keywordChangeSearch() {
			this.$emit('keywordChangeSearch', this.keyword)
		},
		changeSearchType() {
			if (this.clerarKeyWord) {
				this.keyword = ''
			}
			this.$emit('changeSearchType', this.searchType)
		},
		clearKeyword() {
			this.keyword = ''
		},
		search() {
			this.$emit('search')
		},
		removeFilter(item, index, type) {
			this.filterResult.splice(index, 1)
			this.filterData[item.id] = null
			this.filter.forEach(list => {
				if (list.key == item.id) {
					if (
						typeof this.filterForm[item.id] == 'object' &&
						this.filterForm[item.id] != null
					) {
						if (this.filterForm[item.id] instanceof Array) {
							this.filterForm[item.id] = []
						} else {
							this.filterForm[item.id] = {
								id: null,
								name: null
							}
						}
					} else {
						this.filterForm[item.id] = null
					}
					// 将下拉选项设置显示条件的一起清除
					if (list.type == 'select') {
						this.changeSelect(list, { id: null, name: null })
					}
					if (list.linkKey) {
						// 如果有关联项  则删除关联项
						this.filterData[list.linkKey] = null
						const _index = Object.keys(this.filterMap).indexOf(
							list.linkKey
						)
						this.filterResult.splice(_index, 1)
						if (
							typeof this.filterForm[list.linkKey] == 'object' &&
							this.filterForm[list.linkKey] != null
						) {
							if (
								this.filterForm[list.linkKey] instanceof Array
							) {
								this.filterForm[list.linkKey] = []
							} else {
								this.filterForm[list.linkKey] = {
									id: null,
									name: null
								}
							}
						} else {
							this.filterForm[list.linkKey] = null
						}
					}
				}
			})
			// 清除值为null的项
			const filterData = Object.assign({}, this.filterData)
			for (const z in filterData) {
				if (!filterData[z]) {
					delete filterData[z]
				}
			}
			this.filterData = filterData
			if (!type) {
				this.$emit('query', this.filterData)
			}
		},
		// 修改高级筛选表单值, key表示字段名   增加type参数  控制多次发送请求刷新列表
		changeFilterForm(key, val, type) {
			const _index = Object.keys(this.filterMap).indexOf(key)
			const obj = this.filterResult.find(item => {
				return item.id === key // 筛选出匹配数据
			})
			if (obj && !val) {
				this.removeFilter(obj, _index, type)
			}
			this.filterForm[key] = val
		},
		// 修改高级筛选列表属性, key表示字段名，attr表示对于属性名
		changeFilterMap(key, attr, val) {
			if (attr == 'hidden' && val) {
				// 删除关联对象
				const objIndex = this.filterResult.findIndex(item => {
					return item.id === key // 筛选出匹配数据
				})
				if (objIndex && objIndex > -1) {
					this.filterResult.splice(objIndex, 1)
				}
			}
			this.filterMap[key][attr] = val
		},
		changeFilterResult(key, val) {
			const objIndex = this.filterResult.findIndex(item => {
				return item.id === key // 筛选出匹配数据
			})
			if (objIndex !== -1) {
				this.filterResult[objIndex].id = val.id
				this.filterResult[objIndex].value = val.value
			}
		},
		// 下拉选项设置回调
		changeSelect(item, data) {
			// 设置全选按钮
			if (item.isSelectAll) {
				const valList = [].concat(data)
				const vals = []
				if (!this.filterMap[item.key].selectAll) {
					this.$set(this.filterMap[item.key], 'selectAll', false)
				}
				valList.forEach(item => {
					vals.push(item.id)
				})
				if (this.filterMap[item.key].selectAll) {
					if (
						vals.length === item.list.length &&
						vals.includes('all')
					) {
						vals.shift()
						valList.shift()
						this.filterForm[item.key] = valList
						this.filterMap[item.key].selectAll = false
					}
					if (
						!vals.includes('all') &&
						item.list.length === vals.length
					) {
						this.filterForm[item.key] = []
						this.filterMap[item.key].selectAll = false
					}
				} else {
					if (vals.includes('all')) {
						this.filterForm[item.key] = [
							this.selectAllOption
						].concat(item.list)
						this.filterMap[item.key].selectAll = true
					}
					if (
						!vals.includes('all') &&
						item.list.length === vals.length
					) {
						vals.unshift('all')
						valList.unshift(this.selectAllOption)
						this.filterForm[item.key] = valList
						this.filterMap[item.key].selectAll = true
					}
				}
			}

			// 当select为多选时 需要在下拉框消失时再触发change事件  不然会频繁发送请求
			if (this.changeImmediately) {
				if (item.onChange && typeof item.onChange == 'function') {
					item.onChange(data)
				}
			} else {
				if (
					item.onChange &&
					typeof item.onChange == 'function' &&
					!this.selectVisible
				) {
					item.onChange(data)
				}
			}
		},
		changeSelectVisible(item, data) {
			this.selectVisible = data
			// 下拉框关闭时触发 change回调
			if (!data && item.onChange && typeof item.onChange == 'function') {
				item.onChange(this.filterForm[item.key])
			}
		},
		// 修改月份 联动修改
		changeMonth(item, data) {
			if (item.onChange && typeof item.onChange == 'function') {
				item.onChange(data)
			}
		},
		checkVal(key, type) {
			if (this.filterForm[key][type]) {
				// 匹配正整数以及两位小数
				const correctVal = this.filterForm[key][type].match(
					/^([0-9]{1,}[.]{0,1}[0-9]{0,2})$/gi
				)
				if (correctVal) {
					this.lastVal = correctVal[0]
					this.$set(this.filterForm[key], type, correctVal[0])
				} else {
					this.$set(this.filterForm[key], type, this.lastVal)
				}
			}
		},
		// 左树右表弹窗选择, 房间选择
		changeRoom(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: list.title,
				type: list.boxType,
				multi: list.multi,
				currentSelection: selection,
				tableFilter: list.filter || {},
				placeholder: '请输入房间/客户名称',
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.roomcusId)
						names.push(item.customerName)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		/**
		 * @function loadProject
		 * @description Promise 获取用户项目信息
		 */
		loadProject() {
			// 不传入默认项目或showProject为false，都不会初始化项目
			if (!this.defaultProject || !this.showProject) return
			// 开启projectClearable之后除非传入了初始化的defaultProject，否则不会初始化项目
			if (this.defaultProject === true && !this.projectClearable) {
				this.curProject = {
					id: this.userInfo.projectID,
					name: this.userInfo.projectName,
					selection: [
						{
							id: this.userInfo.projectID,
							name: this.userInfo.projectName
						}
					]
				}
			} else if (typeof this.defaultProject === 'object') {
				// 使用defaultProject数据初始化项目
				this.curProject = JSON.parse(
					JSON.stringify(this.defaultProject)
				)
				const ids = this.curProject.id.split(',')
				const names = this.curProject.name.split(',')
				this.curProject.selection = []
				ids.forEach((el, i) => {
					this.curProject.selection.push({
						id: el,
						name: names[i]
					})
				})
			}
		},
		/**
		 * @function handleClear
		 * @private
		 * @description 项目清理
		 */
		handleClear() {
			this.curProject = {
				name: null,
				id: null,
				selection: []
			}
			this.$emit('changeProject', [])
		},
		// 选择项目范围
		changeProject(e) {
			// 是否启用项目切换
			if (
				!this.enabledProject ||
				e.target.tagName.toLowerCase() !== 'input'
			) {
				return
			}
			this.$projectTreeBox({
				title: '选择项目',
				type: 'orgProject',
				multi: this.showProject == 'multi',
				currentSelection: this.curProject.selection,
				onOk: (data, cb) => {
					if (
						this.maxProject &&
						this.maxProject < data.selection.length
					) {
						// 判断选择项目个数是否超过最大值
						this.$message({
							type: 'warning',
							message: '最多可选择' + this.maxProject + '个项目'
						})
						return
					}
					this.curProject = data
					this.$emit('changeProject', this.curProject)
					cb()
				}
			})
		},
		// 选择供应商
		changeProvider(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '供应商选择',
				type: 'provider',
				multi: list.multi,
				currentSelection: selection,
				tableFilter: {
					isEnabled: 1
				},
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择问题分类
		changeQuestion(key) {
			let id = []
			if (this.filterForm[key] && this.filterForm[key].id) {
				id = this.filterForm[key].id.split(',')
			}
			this.$changeQuestion({
				value: id,
				onOk: res => {
					let names = [],
						name
					if (res.id.length == 0) {
						this.$message({
							type: 'warning',
							message: '请至少选择一个问题类型'
						})
						return false
					}
					res.list.forEach(item => {
						names.push(item.name)
					})
					if (names.length > 3) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[key] = {
						id: res.id.join(','),
						name
					}
					return true
				},
				onClose: () => {}
			})
		},
		// 选择员工
		changeEmployee(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择员工',
				type: 'employee',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		changeWarehouse(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择仓库',
				type: 'warehouse',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择组织
		changeOrgunits(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择组织',
				type: 'orgUnitAndProject',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择系统角色
		changeOrgRole(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择角色',
				type: 'orgRole',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 招商顾问
		changeInvestConsultant(list) {
			let projectID = [],
				selection
			this.curProject.selection.forEach(item => {
				projectID.push(item.id)
			})
			selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择招商顾问',
				type: 'investConsultant',
				multi: list.multi,
				placeholder: list.placeholder || '',
				reserveSelection: true,
				currentSelection: selection,
				treeFilter: {
					projectID: projectID.join(',')
				},
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 组团、楼栋、单元、房间选择
		changeBuildingRoom(opt) {
			const obj = this.filterForm[opt.key]
			const projectID = []
			this.curProject.selection.forEach(item => {
				projectID.push(item.id)
			})
			this.$buildingRoom({
				options: {
					typeList: opt.typeList,
					type: obj.type,
					selection: obj.selection,
					projectID: projectID.join(',')
				},
				onOk: instance => {
					return new Promise(resolve => {
						let result = instance.getSelection(),
							ids = [],
							names = [],
							name = null
						result.selection.forEach(item => {
							ids.push(item.id)
							names.push(item.name)
						})
						if (names.length > 1) {
							name = names[0] + '等' + names.length + '个'
						} else {
							name = names.join(',')
						}
						if (ids.length > 0) {
							if (result.type == 1) {
								// 组团
								name = name + '组团'
							} else if (result.type == 2) {
								// 楼栋
								name = name + '楼栋'
							} else if (result.type == 3) {
								// 单元
								name = name + '单元'
							} else if (result.type == 5) {
								name = name + '楼层'
							}
						}
						this.filterForm.buildingRoomType = result.type
						this.filterForm[opt.key] = {
							type: result.type,
							typeList: opt.typeList,
							id: ids.join(','),
							name,
							selection: result.selection
						}
						resolve()
					})
				}
			})
		},
		changeProductType(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择产品类型',
				type: 'productType',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		handleClickBox(opt) {
			// 自定义弹出框事件
			opt.event(this.filterForm[opt.key]).then(res => {
				this.filterForm[opt.key] = res
			})
		},
		submitForm() {
			const obj = {}
			const name = []
			for (const key in this.filterForm) {
				let value = this.filterForm[key]
				if (value instanceof Array) {
					if (this.filterMap[key].type == 'select') {
						const names = []
						const ids = []
						value.forEach(item => {
							names.push(item.name)
							ids.push(item.id)
						})
						value = {
							id: ids.join(','),
							name: names.join(',')
						}
					} else if (
						(this.filterMap[key].type == 'dateRange' ||
							this.filterMap[key].type == 'dateTimeRange' ||
							this.filterMap[key].type == 'monthRange' ||
							this.filterMap[key].type == 'numRange' ||
							this.filterMap[key].type == 'dateRangeDefine' ||
							this.filterMap[key].type == 'dateTimeRangeDefine' ||
							this.filterMap[key].type == 'monthRangeDefine') &&
						value.length > 0
					) {
						const valArr = [].concat(value)
						if (
							!this.filterMap[key].notRequire &&
							((valArr[0] && !valArr[1]) ||
								(!valArr[0] && valArr[1]))
						) {
							this.$message({
								type: 'warning',
								message: '请补全区间'
							})
							return
						}
						if (
							(this.filterMap[key].type == 'numRange' &&
								parseFloat(valArr[0]) >
									parseFloat(valArr[1])) ||
							(this.filterMap[key].type != 'numRange' &&
								valArr[0] > valArr[1])
						) {
							// 字符串数字大小判断 unicode码
							this.$message({
								type: 'warning',
								message: `${this.filterMap[key].name}结束区间不能小于开始区间`
							})
							return
						}
						if (
							valArr[0] ||
							valArr[0] === 0 ||
							valArr[1] ||
							valArr[1] === 0
						) {
							value = valArr[0] + '~' + valArr[1]
						}
					}
				}
				if (!this.filterMap[key]) {
					obj[key] = value
				} else if (value && typeof value == 'object') {
					obj[key] = value.id
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable,
						linkKey: this.filterMap[key].linkKey
							? this.filterMap[key].linkKey
							: ''
					})
				} else if (this.filterMap[key].type == 'box') {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable,
						linkKey: this.filterMap[key].linkKey
							? this.filterMap[key].linkKey
							: ''
					})
				} else {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value,
						closable: !this.filterMap[key].closable,
						linkKey: this.filterMap[key].linkKey
							? this.filterMap[key].linkKey
							: ''
					})
				}
			}
			if (!this.ctrlPanel) {
				this.hidePanle()
			}
			this.filterResult = name
			this.filterData = Object.assign({}, this.filterData, obj)
			this.$emit('query', this.filterData)
		},
		cancelForm() {
			this.hidePanle()
		}
	}
}
</script>
