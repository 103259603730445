var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-layout-page" }, [
    _c("header", { staticClass: "wy-layout-page_top" }, [
      _c("div", { staticClass: "wy-layout-page_header" }, [
        _c(
          "h1",
          {
            staticClass: "wy-layout-page_title",
            attrs: { "data-guide": "layout-page-title" },
          },
          [
            _c("el-tooltip", { attrs: { content: "点击查看功能指引" } }, [
              _vm.intro
                ? _c("i", {
                    staticClass: "iconfont icon-guide",
                    on: {
                      click: function ($event) {
                        return _vm.showIntro(true)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wy-layout-page_btn-panel" },
          [_vm._t("btn-group")],
          2
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "wy-layout-page_main" },
      [_vm._t("content")],
      2
    ),
    _c("footer", { staticClass: "wy-layout-page_bottom" }, [
      _c("div", { staticClass: "wy-layout-page_footer" }, [_vm._t("foot")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }