<template>
	<div class="wy-cropper">
		<div class="wy-cropper-box">
			<img
				v-show="croppable"
				id="image"
				ref="cropper"
				:src="url"
				alt="Picture"
			/>
		</div>
	</div>
</template>
<script>
import Cropper from 'cropperjs'
export default {
	name: 'WyCropper',
	props: {
		image: String,
		cropWidth: {
			type: Number,
			default: 680
		},
		cropHeight: {
			type: Number,
			default: 360
		},
		cropperSet: {
			type: Object,
			default: () => ({}),
			description: 'cropper插件配置'
		}
	},
	data() {
		return {
			url: null,
			cropper: null,
			croppable: false, // copper准备完毕
			set: {
				aspectRatio: 1,
				minContainerWidth: 680,
				minContainerHeight: 360,
				viewMode: 1,
				autoCropArea: 0.7,
				minCropBoxWidth: 100,
				minCropBoxHeight: 100
			}
		}
	},
	computed: {},
	watch: {
		image() {
			this.$set(this.set, 'aspectRatio', this.cropWidth / this.cropHeight)
			this.init()
		}
	},
	created() {},
	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el)
		}
	},
	methods: {
		init() {
			this.url = this.image
			this.$nextTick(() => {
				this.$refs.cropper.onload =
					this.$refs.cropper.onreadystatechange = () => {
						this.cropper = new Cropper(this.$refs.cropper, {
							...this.set,
							...this.cropperSet,
							ready() {
								this.croppable = true
							}
						})
					}
			})
		},
		submit() {
			return new Promise(resolve => {
				const data = this.cropper.getData()
				const crop =
					'crop(x=' +
					Math.abs(parseInt(data.x)) +
					',y=' +
					Math.abs(parseInt(data.y)) +
					',w=' +
					Math.abs(parseInt(data.width)) +
					',h=' +
					Math.abs(parseInt(data.height)) +
					')'
				resolve(crop)
			})
		},
		cropCancel() {
			this.url = null
			this.cropper = null
			this.croppable = false
		}
	}
}
</script>
