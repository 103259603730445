var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "normal",
      attrs: { data: _vm.list, "row-key": "field", "default-expand-all": true },
    },
    [
      _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
      _c("el-table-column", {
        attrs: { label: "默认显示", width: "80px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-switch", {
                  model: {
                    value: scope.row.show,
                    callback: function ($$v) {
                      _vm.$set(scope.row, "show", $$v)
                    },
                    expression: "scope.row.show",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "排序", width: "90px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm.showUp(scope.row)
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.upField(scope.row)
                          },
                        },
                      },
                      [_vm._v("上移")]
                    )
                  : _vm._e(),
                _vm.showDown(scope.row)
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.downField(scope.row)
                          },
                        },
                      },
                      [_vm._v("下移")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }