var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-design-query-number-range" },
    [
      _c("el-input", {
        staticClass: "wy-design-query-number-range_start wy-border-bottom",
        attrs: { size: "mini", placeholder: "最小值" },
        on: { input: _vm.input },
        model: {
          value: _vm.start,
          callback: function ($$v) {
            _vm.start = $$v
          },
          expression: "start",
        },
      }),
      _c("span", { staticClass: "wy-design-query-number-range_space" }, [
        _vm._v("-"),
      ]),
      _c("el-input", {
        staticClass: "wy-design-query-number-range_end wy-border-bottom",
        attrs: { size: "mini", placeholder: "最大值" },
        on: { input: _vm.input },
        model: {
          value: _vm.end,
          callback: function ($$v) {
            _vm.end = $$v
          },
          expression: "end",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }