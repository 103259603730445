var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-tree-item" },
    [
      _c(
        "div",
        {
          staticClass: "wy-tree-item-root",
          class: { active: _vm.isActive },
          style: _vm.setStyle(_vm.root),
          attrs: { title: _vm.root[_vm.nodeName] },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.clickRoot(_vm.root)
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "hidden",
                rawName: "v-hidden",
                value:
                  !_vm.root[_vm.nodeChild] ||
                  _vm.root[_vm.nodeChild].length == 0,
                expression: "!root[nodeChild] || root[nodeChild].length == 0",
              },
            ],
            staticClass: "iconfont icon-triangle-right",
            class: { down: _vm.root.show },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showChild(_vm.root)
              },
            },
          }),
          _c("span", {
            staticClass: "wy-tree-item_name",
            domProps: { textContent: _vm._s(_vm.root[_vm.nodeName]) },
          }),
        ]
      ),
      _vm._l(_vm.root[_vm.nodeChild], function (item) {
        return [
          _vm.filter(item)
            ? _c("wy-tree-item", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.root.show,
                    expression: "root.show",
                  },
                ],
                key: item[_vm.nodeKey],
                attrs: {
                  "parent-node": _vm.root,
                  node: item,
                  "cur-node": _vm.curNode,
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.handleClick($event)
                  },
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }