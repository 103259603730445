var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-header" },
    [
      _c(
        "wy-row",
        { staticClass: "wy-header__row" },
        [
          _c("wy-col", [
            _c("h1", { staticClass: "wy-header_title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _vm.showProject
            ? _c(
                "wy-col",
                {
                  staticClass: "wy-header_project",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changeProject($event)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-change" }),
                  _vm._v(_vm._s(_vm.name) + "\n\t\t"),
                ]
              )
            : _vm._e(),
          _c("wy-col", [_vm._t("btn")], 2),
        ],
        1
      ),
      _vm._t("filter"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }