<template>
	<el-table
		class="normal"
		:data="list"
		row-key="field"
		:default-expand-all="true"
	>
		<el-table-column label="名称" prop="name"></el-table-column>
		<!-- <el-table-column label="表头宽度" width="80px">
			<template slot-scope="scope">
				<el-input v-model.trim="scope.row.width"></el-input>
			</template>
		</el-table-column> -->
		<el-table-column label="默认显示" width="80px">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.show"> </el-switch>
			</template>
		</el-table-column>
		<el-table-column label="排序" width="90px">
			<template slot-scope="scope">
				<el-button
					v-if="showUp(scope.row)"
					type="text"
					@click="upField(scope.row)"
					>上移</el-button
				>
				<el-button
					v-if="showDown(scope.row)"
					type="text"
					@click="downField(scope.row)"
					>下移</el-button
				>
			</template>
		</el-table-column>
	</el-table>
</template>
<script>
import { extend } from 'tool'
export default {
	name: 'DesignTableSetBox',
	props: {
		tableField: Array
	},
	data() {
		return {
			list: []
		}
	},
	computed: {},
	watch: {},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.list = extend(true, [], this.tableField)
		},
		searchNode(arr, row, key) {
			let result
			/**
			 * @param arr
			 * @param target
			 */
			function search(arr, target) {
				for (let i = 0; i < arr.length; i++) {
					if (arr[i][key] === target[key]) {
						result = {
							list: arr,
							index: i
						}
						return true
					}
					if (
						arr[i].children &&
						arr[i].children.length > 0 &&
						search(arr[i].children, target)
					) {
						return true
					}
				}
			}
			search(arr, row)
			return result
		},
		showUp(row) {
			const result = this.searchNode(this.list, row, 'field')
			if (result.index === 0) {
				return false
			} else {
				return true
			}
		},
		showDown(row) {
			const result = this.searchNode(this.list, row, 'field')
			if (result.index === result.list.length - 1) {
				return false
			} else {
				return true
			}
		},
		upField(row) {
			const result = this.searchNode(this.list, row, 'field')
			if (result.index === 0) {
				return
			}
			result.list.splice(result.index, 1)
			result.list.splice(result.index - 1, 0, row)
		},
		downField(row) {
			const result = this.searchNode(this.list, row, 'field')
			if (result.index === result.list.length - 1) {
				return
			}
			result.list.splice(result.index, 1)
			result.list.splice(result.index + 1, 0, row)
		},
		submit() {
			return this.list
		}
	}
}
</script>
