const ProjectBox = {}
import ProjectBoxPlugin from './project-box.js'

/* istanbul ignore next */
ProjectBox.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$projectBox = Vue.$projectBox = ProjectBoxPlugin
}

export default ProjectBox
