import tmp from './show-pic.vue'

const defaultOpt = {
	title: '',
	width: '800px',
	height: '500px',
	index: 0,
	list: [],
	auto: false,
	enableDragAndScale: true, // 是否允许拖拽缩放 - 默认允许
	defaultMax: false,
	showMax: true
}

/**
 * @param opt
 */
export default function (opt = {}) {
	if (!opt.list || opt.list.length === 0) {
		return
	}
	const config = Object.assign({}, defaultOpt, opt)
	this.$box({
		title: config.title,
		template: tmp,
		showBtn: false,
		showHeader: !!opt.title,
		width: config.width,
		height: config.height,
		defaultMax: config.defaultMax,
		showMax: config.showMax,
		templateOpt: config
	})
}
