<template>
	<el-form
		ref="form"
		:model="form"
		label-position="left"
		label-width="100px"
		:rules="rules"
	>
		<el-form-item label="方案名称" prop="name">
			<el-input
				v-model.trim="form.name"
				class="wy-border-bottom"
				maxlength="16"
				show-word-limit
			></el-input>
		</el-form-item>
	</el-form>
</template>
<script>
export default {
	name: 'DesignListSchema',
	props: {
		// 方案列表
		list: {
			type: Array
		}
	},
	data() {
		return {
			form: {
				name: null
			},
			rules: {
				name: [
					{
						required: true,
						message: '请填写方案名称'
					},
					{
						validator: (rule, value, callback) => {
							const index = this.list.findIndex(
								p => p.name === name
							)
							if (index !== -1) {
								callback(new Error('方案名称已存在'))
							} else {
								callback()
							}
						}
					}
				]
			}
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submit() {
			return new Promise(resolve => {
				this.$refs.form.validate(status => {
					if (!status) {
						return
					}
					resolve(this.form.name)
				})
			})
		}
	}
}
</script>
