var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "wy-question-box",
      style: { "z-index": _vm.zIndex },
    },
    [
      _c("wy-question", {
        attrs: { width: _vm.width, height: _vm.height, multi: _vm.multi },
        on: {
          submit: function ($event) {
            return _vm.submit($event)
          },
          cancel: _vm.cancel,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }