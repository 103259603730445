<template>
	<div class="wy-header">
		<wy-row class="wy-header__row">
			<wy-col>
				<h1 class="wy-header_title">{{ title }}</h1>
			</wy-col>
			<wy-col
				v-if="showProject"
				class="wy-header_project"
				@click.native="changeProject"
			>
				<i class="iconfont icon-change"></i>{{ name }}
			</wy-col>
			<wy-col>
				<slot name="btn"></slot>
			</wy-col>
		</wy-row>
		<slot name="filter"> </slot>
	</div>
</template>
<script>
export default {
	name: 'WyHeader',
	props: {
		// 标题
		title: {
			type: String
		},
		// 是否显示项目
		showProject: {
			type: Boolean
		}
	},
	data() {
		return {
			project: null,
			projectID: []
		}
	},
	computed: {
		name() {
			let name
			if (
				!this.project &&
				this.$store.state.user &&
				this.$store.state.user.enterprise
			) {
				name = this.$store.state.user.enterprise.name
			} else {
				name = this.project
			}
			return name
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		changeProject() {
			this.$changeProject({
				value: this.projectID, //  projectID
				onOk: res => {
					this.projectID = res.id
					if (res.org) {
						this.project = res.org.name
					} else if (res.id.length > 1) {
						this.project =
							res.map[res.id[0]].name +
							'等' +
							res.id.length +
							'个项目'
					} else {
						this.project = res.map[res.id[0]].name
					}
					this.$emit('changeProject', res)
					return true
				}
			})
		}
	}
}
</script>
