var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-box",
    {
      attrs: { visible: _vm.visible, width: "600px", height: "400px" },
      on: { onOk: _vm.save, onCancel: _vm.cancel, onClose: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          staticClass: "wy-project-tree-box_bar",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h3", { staticClass: "wy-project-tree-box_title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("span", { staticClass: "wy-project-tree-box_total" }, [
            _vm._v("\n\t\t\t已选择\n\t\t\t"),
            _c("em", { staticClass: "link" }, [
              _vm._v(_vm._s(_vm.selection.length)),
            ]),
            _vm._v("个\n\t\t"),
          ]),
          _c("el-input", {
            staticClass: "wy-project-tree-box_search wy-border-bottom",
            attrs: {
              placeholder: "请输入名称",
              "prefix-icon": "el-icon-search",
            },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.treeProjectData,
          props: _vm.treeProps,
          "check-on-click-node": true,
          "default-checked-keys": _vm.checked,
          "filter-node-method": _vm.filterNode,
          "empty-text": _vm.treeEmptyText,
          "default-expanded-keys": _vm.defaultExpandedKeys,
          "node-key": "id",
          "show-checkbox": _vm.multi,
        },
        on: { "check-change": _vm.checkChange, "node-click": _vm.nodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var data = ref.data
              return _c("div", { class: { active: _vm.isChecked(data) } }, [
                data.isCompany == 3
                  ? _c("i", { staticClass: "iconfont icon-project" })
                  : _vm._e(),
                _c("span", { staticClass: "wy-project-tree-box_name" }, [
                  _vm._v(_vm._s(data.name)),
                ]),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }