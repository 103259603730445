<template>
	<div
		class="wy-form-item"
		:style="setStyle"
		:class="[
			vertical ? 'wy-form-item_vertical' : 'wy-form-item_default',
			formSize ? 'wy-form-item_' + formSize : '',
			{ required: required }
		]"
	>
		<label
			v-if="label"
			class="wy-form-item_label"
			:style="setLabelStyle"
			v-text="label"
		></label>
		<slot></slot>
		<div
			v-if="message"
			class="wy-form-item_error"
			:style="setErrorStyle"
			v-text="message"
		></div>
	</div>
</template>
<script>
import { regex } from 'tool'
export default {
	name: 'WyFormItem',
	inject: ['labelWidth', 'vertical', 'rules', 'formSize'],
	props: {
		// 表单项目名称
		label: {
			type: String
		},
		// 表单项目长度
		width: {
			type: String
		},
		// 表单项目样式
		inline: {
			type: Boolean
		},
		// 表单验证规则名称
		ruleName: {
			type: String
		},
		// 表单验证不通过的错误信息
		errorMessage: {
			type: String
		}
	},
	data() {
		return {
			rule: null,
			message: null,
			required: null
		}
	},
	computed: {
		setStyle() {
			const style = {}
			if (this.labelWidth && !this.vertical) {
				style['padding-left'] = this.labelWidth
			}
			if (this.inline) {
				style.display = 'inline-block'
			}
			if (this.width) {
				style.width = this.width
			}
			return style
		},
		setLabelStyle() {
			if (this.labelWidth && !this.vertical) {
				return {
					'margin-left': '-' + this.labelWidth,
					width: this.labelWidth,
					float: 'left'
				}
			}
			return {}
		},
		setErrorStyle() {
			if (this.labelWidth && !this.vertical) {
				return {
					left: this.labelWidth
				}
			}
			return {}
		}
	},
	watch: {
		errorMessage(value) {
			this.message = value
		}
	},
	created() {},
	mounted() {
		if (this.ruleName && this.rules) {
			this.rule = this.rules[this.ruleName]
			this.$on('wy.form.input', this.validateInput)
			this.$on('wy.form.blur', this.validateBlur)
			this.$on('wy.form.change', this.validateChange)
			this.$dispatch('wyForm', 'wy.form.addField', [this])
			try {
				this.rule.forEach(rule => {
					if (rule.required) {
						throw true
					}
				})
			} catch (e) {
				this.required = e
			}
		}
	},
	beforeDestroy() {
		this.$dispatch('wyForm', 'wy.form.removeField', [this])
	},
	methods: {
		validate(type, data, callback) {
			let status = true
			this.message = null
			try {
				this.rule.forEach(rule => {
					const trigger = rule.trigger
					if (trigger && type && !trigger.includes(type)) {
						return
					}
					if (rule.required && !data) {
						throw rule.message
					}
					// 手机号码
					if (rule.mobile && !regex.mobile.test(data)) {
						throw rule.message
					}
					// 长度判断
					if (rule.min && data && data.length < rule.min) {
						throw rule.message
					}
					// 长度判断
					if (rule.max && data && data.length > rule.max) {
						throw rule.message
					}
					// 规则校验
					if (
						typeof rule.validate == 'function' &&
						!rule.validate(data)
					) {
						throw rule.message
					}
				})
			} catch (e) {
				this.message = e
				status = false
			}
			if (typeof callback == 'function') {
				callback(status)
			}
		},
		validateInput(data) {
			this.validate('input', data)
		},
		validateBlur(data) {
			this.validate('blur', data)
		},
		validateChange(data) {
			this.validate('change', data)
		},
		submit() {
			return false
		}
	}
}
</script>
