import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from './export-file.vue'

const VueConstructor = Vue.extend(tmp)
const defaultOpt = {
	isSync: false,
	isTasks: false,
	type: 'post',
	params: null,
	paramID: 'params',
	filterParams: {},
	showAnimation: true
}

let instance

/**
 * @param opt
 */
export default function exportFile(opt) {
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}
	Object.assign(instance, defaultOpt)
	for (const i in opt) {
		if (i in opt) {
			instance[i] = opt[i]
		}
	}
	instance.zIndex = nextIndex(5000, 10000)

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyExportFile',
		id: instance._uid,
		instance
	})

	return instance
}
