<template>
	<button
		class="wy-button"
		:type="type"
		:class="[
			theme ? 'wy-button_theme' : 'wy-button_default',
			curSize ? 'wy-button_' + curSize : ''
		]"
		@click="handleClick"
	>
		<span><slot></slot></span>
	</button>
</template>
<script>
export default {
	name: 'WyButton',
	inject: {
		formSize: {
			default: ''
		}
	},
	props: {
		// 按钮主题
		theme: {
			type: Boolean
		},
		// 按钮类型
		type: {
			type: String
		},
		// 禁用
		disabled: {
			type: Boolean
		},
		// 按钮大小
		size: {
			type: String,
			default: 'big',
			validator: value => ['big', 'normal', 'mini'].includes(value)
		}
	},
	data() {
		return {
			curSize: null,
			timeout: null,
			enable: true
		}
	},
	created() {
		if (this.formSize) {
			this.curSize = this.formSize
		} else {
			this.curSize = this.size
		}
	},
	methods: {
		handleClick() {
			if (this.disabled) {
				return
			}
			if (!this.enable) {
				return
			}
			this.enable = false
			setTimeout(() => {
				this.enable = true
			}, 500)
			this.$emit('click')
		}
	}
}
</script>
