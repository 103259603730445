<template>
	<div class="wy-design-query-number-range">
		<el-input
			v-model="start"
			size="mini"
			class="wy-design-query-number-range_start wy-border-bottom"
			placeholder="最小值"
			@input="input"
		></el-input>
		<span class="wy-design-query-number-range_space">-</span>
		<el-input
			v-model="end"
			size="mini"
			class="wy-design-query-number-range_end wy-border-bottom"
			placeholder="最大值"
			@input="input"
		></el-input>
	</div>
</template>
<script>
export default {
	name: 'DesignListNumberRange',
	props: {
		value: Array
	},
	data() {
		return {
			start: null,
			end: null
		}
	},
	watch: {
		value() {
			this.init()
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			if (this.value && this.value.length > 0) {
				this.start = this.value[0] || ''
				this.end = this.value[1] || ''
			} else {
				this.start = null
				this.end = null
			}
		},
		input() {
			this.$delay.register(
				'queryNumberRange',
				() => {
					this.start = parseFloat(this.start) || ''
					this.end = parseFloat(this.end) || ''
					if (
						(this.start && !this.end) ||
						(!this.start && this.end)
					) {
						this.$message({
							type: 'warning',
							message: '请补全区间'
						})
						return
					}
					// 如果最大值比最小值小，则清空最大值
					if (this.end && this.start && this.end < this.start) {
						this.end = ''
					} else {
						this.$emit('input', [this.start, this.end])
					}
				},
				500
			)
		}
	}
}
</script>
