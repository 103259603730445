import fix from './fix'
import date from './date'
import toThousand from './toThousand'
import atoc from './atoc'

const install = function (Vue) {
	Vue.filter(fix.name, fix.fn)
	Vue.filter(date.name, date.fn)
	Vue.filter(toThousand.name, toThousand.fn)
	Vue.filter(atoc.name, atoc.fn)
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
	install(window.Vue)
}

export default {
	install
}
