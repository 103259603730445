var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-panel-fold", class: { show: _vm.show } },
    [
      _c(
        "div",
        { staticClass: "wy-panel-fold_title" },
        [
          _c("i", {
            staticClass: "el-icon-caret-bottom",
            class: { close: !_vm.show },
            on: { click: _vm.changeFoldStatus },
          }),
          _c(
            "h4",
            {
              staticClass: "wy-panel-fold_h4title",
              on: { click: _vm.changeFoldStatus },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
          ),
          _vm._t("btn"),
        ],
        2
      ),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "wy-panel-fold_content",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }