<template>
	<div class="wy-project" :style="{ width: width }">
		<div class="wy-project-box">
			<div class="wy-project-header">
				<h2 class="wy-project_title">选择项目范围</h2>
				<el-input
					v-model="keyword"
					noborder
					placeholder="请输入项目名称..."
					class="wy-border-bottom"
					suffix-icon="el-icon-search"
					@change="keywordInput"
				>
				</el-input>
			</div>
			<div class="wy-project-content" :style="{ height: height }">
				<div class="wy-project-content-tree">
					<el-tree
						class="wy-project_normal"
						:default-expanded-keys="[-1]"
						node-key="id"
						:data="orgTree"
						:props="treeProps"
						@node-click="handleClickTree"
					></el-tree>
				</div>
				<div class="wy-project-content-list">
					<wy-table
						ref="table"
						url="/projects/getEmployeeProjectV2"
						:enabled-multi-page="enabledMultiPage"
						:auto="auto"
						:multi="multi"
						:filter="filter"
						:selected-rows="projectList"
					>
						<el-table-column
							prop="name"
							label="项目名称"
						></el-table-column>
					</wy-table>
				</div>
			</div>
			<div class="wy-project-footer">
				<button
					class="wy-project_btn wy-project_btn-theme"
					theme
					@click="submit"
				>
					确定
				</button>
				<button class="wy-project_btn" @click="cancel">取消</button>
			</div>
		</div>
	</div>
</template>
<script>
import mixin from './mixins'
export default {
	name: 'WyProject',
	mixins: [mixin],
	data() {
		return {
			orgTree: [],
			projectList: [],
			keyword: null,
			treeProps: {
				label: 'name',
				children: 'child'
			},
			filter: {
				search: '',
				id: ''
			}
		}
	},
	computed: {},
	watch: {
		value(val) {
			if (val) {
				this.projectList = val
			} else {
				this.projectList = []
			}
		}
	},
	created() {
		if (this.auto) {
			this.loadData()
			if (this.value) {
				this.projectList = this.value
			}
		}
	},
	methods: {
		loadData() {
			// 获取组织树
			this.$axios({
				url: '/projects/getOrgUnitsByEmpId'
			}).then(res => {
				this.orgTree = [
					{
						id: -1,
						name: '全部',
						child: res.data.areas
					}
				]
			})
		},
		handleClickTree(data) {
			// 选中当前组织
			if (data.id == -1) {
				this.filter.id = null
			} else {
				this.filter.id = data.id
			}
			this.$refs.table.reload()
		},
		submit() {
			const selection = this.$refs.table.selection
			const id = []
			selection.forEach(item => {
				id.push(item.id)
			})
			this.$emit('submit', {
				id,
				list: selection
			})
		},
		cancel() {
			this.$emit('cancel')
		},
		keywordInput(val) {
			this.filter.search = encodeURIComponent(val)
			this.$refs.table.reload()
		}
	}
}
</script>
