<!--
 * @Description:
 * @Author:
 * @LastEditors: qxia
 * @Date: 2019-09-18 10:23:35
 * @LastEditTime: 2022-02-09 10:10:19
 -->
<template>
	<div class="wy-table">
		<div v-if="showBar" class="wy-table_bar">
			<div v-if="showSet" class="wy-table_set">
				<i class="el-icon-setting"></i>
				<span>列表设置</span>
			</div>
			<div v-if="isShowPagesTop" class="wy-table_top">
				<div v-if="showSelection" class="wy-table_total">
					已选择
					<em>{{ selection.length }}</em
					>条
				</div>
				<wy-pages
					background
					small
					:page-sizes="pageSizes"
					:current="table.current"
					:page-size="table.rowCount"
					:total="table.total"
					@changePageSize="changePageSize"
					@changeCurrentPage="changeCurrentPage"
				></wy-pages>
			</div>
		</div>
		<el-table
			ref="table"
			height="100%"
			class="normal"
			:border="true"
			:data="table.rows"
			:row-key="tableKey"
			:show-summary="showSummary"
			:summary-method="getSummaries"
			:row-class-name="className || tableRowClassName"
			v-loading="loading"
			:empty-text="tableEmptyText"
			:tooltip-effect="tooltipEffect"
			v-bind="$attrs"
			@row-click="handleRowClick"
			@selection-change="handleSelectionChange"
			@expand-change="expandChange"
			@sort-change="sortChange"
		>
			<el-table-column
				v-if="multi && showSelection"
				:reserve-selection="reserveSelection"
				type="selection"
				width="55"
			></el-table-column>
			<el-table-column v-if="!multi && showSelection" width="55">
				<template slot-scope="scope">
					<i
						v-if="!isChecked(scope.row)"
						class="iconfont icon-radio-normal"
					></i>
					<i
						v-if="isChecked(scope.row)"
						class="iconfont icon-radio-checked"
					></i>
				</template>
			</el-table-column>
			<el-table-column
				v-if="showIndex"
				type="index"
				width="55"
				label="序号"
			></el-table-column>
			<slot></slot>
		</el-table>
		<div v-if="!isShowPagesTop" class="wy-table_bottom">
			<div v-if="showSelection" class="wy-table_total">
				已选择
				<em>{{ selection.length }}</em
				>条
			</div>
			<wy-pages
				background
				small
				:current="table.current"
				:page-size="table.rowCount"
				:total="table.total"
				@changePageSize="changePageSize"
				@changeCurrentPage="changeCurrentPage"
			></wy-pages>
		</div>
	</div>
</template>
<script>
import wyPages from '../pages/pages.vue'
export default {
	name: 'WyTable',
	components: {
		wyPages
	},
	inheritAttrs: false,
	props: {
		url: String,
		auto: {
			type: Boolean,
			default: true
		},
		filter: {
			type: Object
		},
		showSelection: {
			type: Boolean,
			default: true
		},
		showIndex: {
			type: Boolean,
			default: true
		},
		showSet: Boolean,
		selectedRows: {
			// 默认选中的集合[]
			type: Array,
			default() {
				return []
			}
		},
		maxSelect: {
			// 最大选择条数
			type: Number
		},
		multi: {
			// 是否可以多选行
			type: Boolean,
			default: true
		},
		// 是否跨页多选
		reserveSelection: Boolean,
		tableKey: {
			type: String,
			default: 'id'
		},
		// 是否显示合计行
		showSummary: {
			type: Boolean,
			default: false
		},
		tooltipEffect: {
			type: String,
			default: 'light'
		},
		getSummaries: Function,
		rowClick: Function,
		expandChange: {
			type: Function,
			default() {}
		},
		// 设置table的Class
		className: Function,
		setData: Function,
		// 分页是否显示在下面
		isShowPagesTop: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			default: 'get'
		},
		showBar: {
			type: Boolean,
			default: true
		},
		// 分页数
		pageSizes: Array
	},
	data() {
		return {
			table: {
				rows: [],
				current: 1,
				total: 0,
				rowCount: 50
			},
			selection: [],
			curSelection: [],
			filterData: {
				current: 1,
				total: 120,
				rowCount: 50,
				searchPhrase: ''
			},
			status: false,
			tableLoaded: false,
			loading: false
		}
	},
	computed: {
		tableEmptyText() {
			if (this.tableLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		},
		total() {
			return this.curSelection.length + this.selection.length
		}
	},
	watch: {
		selectedRows() {
			this.init()
		}
	},
	created() {
		this.init()
	},
	mounted() {},
	methods: {
		init() {
			// 初始化设置选中的值
			const arr = []
			this.selectedRows.forEach(item => {
				if (typeof item == 'string' || typeof item == 'number') {
					arr.push({
						id: item
					})
				} else {
					arr.push(item)
				}
			})
			this.curSelection = arr
			this.selection = []
			if (this.auto) {
				this.loadData()
			}
		},
		loadData() {
			this.filterData = Object.assign({}, this.filterData, this.filter)
			this.tableLoaded = false
			this.loading = true
			this.$axios({
				url: this.url,
				data: this.filterData,
				loading: true,
				type: this.type
			}).then(res => {
				this.setData
					? (this.table = this.setData(res.data))
					: (this.table = res.data)
				this.tableLoaded = true
				// 设置选中状态
				this.$nextTick(() => {
					this.setTableSelection()
					this.$refs.table.doLayout()
					this.loading = false
				})
			})
		},
		toggleRowExpansion(row) {
			this.$refs.table.toggleRowExpansion(row)
		},
		setTableSelection() {
			if (this.curSelection.length == 0) {
				return
			}
			const selectionID = []
			const deleteIndex = []
			this.curSelection.forEach(item => {
				selectionID.push(item[this.tableKey])
				if (!this.multi) {
					this.selection = [].concat(item)
				}
			})
			this.table.rows.forEach(item => {
				const index = selectionID.indexOf(item[this.tableKey])
				if (index != -1) {
					deleteIndex.push(index)
					this.$refs.table.toggleRowSelection(item, true)
				}
			})
			deleteIndex.sort((a, b) => {
				return a - b
			})
			while (deleteIndex.length > 0) {
				const index = deleteIndex.pop()
				this.curSelection.splice(index, 1)
			}
		},
		reload(num = 1, needClear) {
			// 重载第一页
			this.table.current = num
			this.filterData.current = num
			this.selection = []
			if (needClear) {
				// 消除reserveSelection为true的影响
				this.$refs.table.clearSelection()
			}
			this.loadData()
		},
		reloadCurrentPage() {
			// 刷新当前页
			this.loadData()
		},
		clearSelection() {
			this.curSelection = []
			this.selection = []
		},
		changePageSize(page) {
			this.filterData.current = 1
			this.filterData.rowCount = page
			this.loadData()
		},
		changeCurrentPage(page) {
			this.filterData.current = page
			this.loadData()
		},
		handleSelectionChange(selection) {
			// 选中事件监听
			if (this.multi) {
				this.selection = selection
			}
		},
		// 排序
		sortChange(val) {
			this.$emit('sortChange', val)
		},
		isChecked(data) {
			if (
				this.selection.length > 0 &&
				data[this.tableKey] == this.selection[0][this.tableKey]
			) {
				return true
			}
			return false
		},
		getSelection() {
			return this.selection.concat(this.curSelection)
		},
		// added by saqqdy on 20190910
		doLayout() {
			this.$refs.table.doLayout()
		},
		handleRowClick(row, column, event) {
			if (this.multi) {
				this.$refs.table.toggleRowSelection(row)
			} else {
				this.selection = [].concat(row)
				this.$refs.table.toggleRowSelection(row, true)
			}
			if (this.rowClick && typeof this.rowClick == 'function') {
				this.rowClick(row, column, event)
			}
		},
		tableRowClassName({ row }) {
			if (
				this.selection.find(
					p => p[this.tableKey] === row[this.tableKey]
				)
			) {
				return 'current-row'
			}
			return ''
		}
	}
}
</script>
