var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table-column",
    {
      attrs: {
        fixed: _vm.data.fixed,
        "min-width": _vm.data.width || "120px",
        align: _vm.data.align,
        label: _vm.data.name,
        prop: _vm.data.field,
        sortable: _vm.sortable(_vm.data),
        filters: _vm.setFilters(_vm.data),
        "column-key": _vm.data.field,
        "show-overflow-tooltip": _vm.data.showOverflowTooltip,
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function (scope) {
            return [
              _c("table-expand", {
                attrs: {
                  field: _vm.data.field,
                  config: _vm.data,
                  row: scope.row,
                  render: _vm.data.render,
                },
              }),
            ]
          },
        },
        {
          key: "header",
          fn: function (scope) {
            return [
              _c("span", [_vm._v(_vm._s(scope.column.label))]),
              _vm.data.tips
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "wy-design-table-column_item",
                      attrs: { effect: "dark", content: _vm.data.tips },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    _vm._l(_vm.list, function (item) {
      return _c("wy-table-column", {
        key: item.field,
        attrs: { data: item, index: _vm.index },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }