<template>
	<div class="wy-question" :style="{ width: width }">
		<div class="wy-question__box">
			<div class="wy-question-header">
				<h2 class="wy-question_title">选择问题类型</h2>
			</div>
			<div class="wy-question-content" :style="{ height: height }">
				<div class="wy-question-content-tree">
					<el-tree
						ref="tree"
						:props="treeProps"
						node-key="id"
						:load="loadNode"
						check-strictly
						lazy
						show-checkbox
						@check-change="handleClickTree"
						@node-click="nodeClick"
					>
					</el-tree>
				</div>
			</div>
			<div class="wy-question-footer">
				<button
					class="wy-question_btn wy-question_btn-theme"
					theme
					@click="submit"
				>
					确定
				</button>
				<button class="wy-question_btn" @click="cancel">取消</button>
			</div>
		</div>
	</div>
</template>
<script>
import mixin from './mixins'
export default {
	name: 'WyQuestion',
	mixins: [mixin],
	data() {
		return {
			question: [],
			keyword: null,
			treeProps: {
				label: 'name',
				children: 'child',
				isLeaf: 'isLeaf'
			},
			selection: {}
		}
	},
	computed: {},
	watch: {
		value(val) {
			this.question = val
		}
	},
	created() {
		if (this.value) {
			this.question = this.value
		}
	},
	methods: {
		loadData(id, cb) {
			// 获取组织树
			this.$axios({
				url: '/questionType/getQuestionTypes',
				data: {
					id
				}
			}).then(res => {
				const data = res.data
				if (cb && typeof cb == 'function') {
					cb(data)
				}
			})
		},
		// 加载子节点
		loadNode(node, resolve) {
			let id = ''
			if (node.data) {
				id = node.data.id
			}
			this.loadData(id, data => {
				resolve(data)
			})
		},
		// 设置当前点击节点状态
		handleClickTree(data, checked) {
			if (this.multi) {
				this.selection = this.$refs.tree.getCheckedNodes()
			} else {
				if (checked) {
					this.selection = [data]
					this.$refs.tree.setCheckedNodes([data])
				}
			}
		},
		// 节点点击时的回调
		nodeClick(data) {
			if (!this.multi) {
				this.selection = [data]
				this.$refs.tree.setCheckedNodes([data])
			}
		},
		submit() {
			const id = []
			this.selection.forEach(item => {
				id.push(item.id)
			})
			this.$emit('submit', {
				id,
				list: this.selection
			})
		},
		cancel() {
			this.$emit('cancel')
		}
	}
}
</script>
