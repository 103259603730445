var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        "label-position": "left",
        "label-width": "100px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "方案名称", prop: "name" } },
        [
          _c("el-input", {
            staticClass: "wy-border-bottom",
            attrs: { maxlength: "16", "show-word-limit": "" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }