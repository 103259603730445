<template>
	<el-table-column
		:fixed="data.fixed"
		:min-width="data.width || '120px'"
		:align="data.align"
		:label="data.name"
		:prop="data.field"
		:sortable="sortable(data)"
		:filters="setFilters(data)"
		:column-key="data.field"
		:show-overflow-tooltip="data.showOverflowTooltip"
	>
		<template slot-scope="scope">
			<table-expand
				:field="data.field"
				:config="data"
				:row="scope.row"
				:render="data.render"
			></table-expand>
		</template>
		<wy-table-column
			v-for="item in list"
			:key="item.field"
			:data="item"
			:index="index"
		></wy-table-column>
		<template slot="header" slot-scope="scope">
			<span>{{ scope.column.label }}</span>
			<el-tooltip
				v-if="data.tips"
				class="wy-design-table-column_item"
				effect="dark"
				:content="data.tips"
			>
				<i class="el-icon-question"></i>
			</el-tooltip>
		</template>
	</el-table-column>
</template>
<script>
import tableExpand from './expand.js'
export default {
	name: 'WyTableColumn',
	components: {
		tableExpand
	},
	props: {
		data: Object,
		index: Number
	},
	data() {
		return {}
	},
	computed: {
		list() {
			if (this.data.children && this.data.children.length > 0) {
				return this.data.children.filter(p => p.show)
			} else {
				return []
			}
		}
	},
	methods: {
		sortable(data) {
			if (data.showSort) {
				return 'custom'
			} else {
				return false
			}
		},
		setFilters(data) {
			if (!data.showFilter) {
				return
			}
			if (data.filterConfig.type === 0) {
				// 固定值
				const arr = []
				data.filterConfig.list.forEach(item => {
					arr.push({
						text: item.name,
						value: item.value
					})
				})
				return arr
			} else if (
				data.filterConfig.type === 1 &&
				this.filterMap[data.field]
			) {
				// 动态值
				const arr = []
				const name = data.filterConfig.fieldName
				const value = data.filterConfig.fieldValue
				this.filterMap[data.field].forEach(item => {
					arr.push({
						text: item[name],
						value: item[value]
					})
				})
				return arr
			}
		}
	}
}
</script>
