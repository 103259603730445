/* eslint-disable eqeqeq */
import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from './tree-and-table-box.vue'

let instance
const defaultOpt = {
	// 标题
	title: null,
	// 取消事件
	onCancel: null,
	// 确认事件
	onOk: null,
	// 保留已选
	reserveSelection: false,
	// 自动勾选已选项
	currentSelection: [],
	// 清除树的数据
	cleanTreeData: null,
	// 清除表的数据
	cleanTableData: null,
	// 是否多选
	multi: true,
	// 树的过滤条件
	treeFilter: {},
	// 表的过滤条件
	tableFilter: {},
	// 表格的key 用来勾选时做区分
	tableKey: 'id',
	// 表格的name
	tableKeyName: 'name',
	// 懒加载
	lazy: false,
	// 查询子级需要的参数，可传字符串、数组、对象(字段映射)
	defineLazyID: ['id'],
	// 选填，查询table需要从tree拿到的参数，在原有的传string类型上做了扩展。可传字符串、数组、对象(字段映射)
	defineID: 'id',
	//
	tableList: [],
	// 是否可以勾选
	selectable: null,
	// 表格行点击额外执行的方法
	tableRowClick: null
}

/**
 * @param options
 */
export default function (options) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	instance.zIndex = nextIndex(5000, 10000)

	const opt = Object.assign({}, defaultOpt, options)
	for (const i in opt) {
		instance[i] = opt[i]
	}
	if (options.type === 'orgRole') {
		// 角色
		instance.treeUrl = '/orgUnits/getAllOrgUnitByVue'
		instance.tableUrl = '/jobRole/getAllJobRoleList'
		instance.placeholder = '请输入角色名称'
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'number',
				name: '编码'
			},
			{
				id: 'orgName',
				name: '所属组织'
			}
		]
	} else if (options.type === 'org') {
		// 组织
		instance.treeUrl = '/orgUnits/getAllOrgUnitByVue'
		instance.tableUrl = '/orgUnits/getOrgUnitList'
		instance.placeholder = '请输入组织名称'
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'number',
				name: '编码'
			}
		]
	} else if (options.type === 'orgProject') {
		// 项目
		instance.treeUrl = '/orgUnits/getAllOrgUnitByVue'
		instance.tableUrl = '/projects/getAllProjectList'
		instance.placeholder = '请输入项目名称'
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'number',
				name: '编码'
			}
		]
	} else if (options.type === 'orgEmployee') {
		// 组织
		instance.treeUrl = '/orgUnits/getAllOrgUnitByVue'
		instance.tableUrl = '/employees/getEmployeeByOrgUnitIDByVue'
		instance.placeholder = '请输入姓名'
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'phone',
				name: '手机号'
			}
		]
	} else if (options.type === 'parkingPosition') {
		// 车场车位
		instance.treeUrl = '/parkingManager/getParkingAndSubareaList'
		instance.tableUrl = '/rooms/getRoomCusChargeAndEmptyByVue'
		// 判断是否自定义显示字段
		if (!instance.tableList.length) {
			instance.tableList = [
				{
					id: 'buildingName',
					name: '车场'
				},
				{
					id: 'buildUnitName',
					name: '分区'
				},
				{
					id: 'name',
					name: '车位'
				},
				{
					id: 'customerType',
					name: '客户类型'
				},
				{
					id: 'customerName',
					name: '客户'
				}
			]
		}
	} else if (options.type === 'cusRoom') {
		// 房间客户选择
		instance.treeUrl = '/rooms/getBuildingAndUnitAndFloor'
		instance.tableUrl = '/rooms/getRoomCusChargeAndEmptyByVue'
		// 判断是否自定义显示字段
		if (!instance.tableList.length) {
			instance.tableList = [
				{
					id: 'buildingName',
					name: '楼栋'
				},
				{
					id: 'buildUnitName',
					name: '单元'
				},
				{
					id: 'name',
					name: '房间'
				},
				{
					id: 'customerType',
					name: '客户类型'
				},
				{
					id: 'customerName',
					name: '客户'
				}
			]
		}
	} else if (options.type === 'rooms') {
		// 房间选择
		instance.treeUrl = '/rooms/getBuildingAndUnitAndFloor'
		instance.tableUrl = '/rooms/getRoomListByCus'
		instance.tableList = [
			{
				id: 'buildingName',
				name: '楼栋'
			},
			{
				id: 'buildUnitName',
				name: '单元'
			},
			{
				id: 'name',
				name: '房间'
			}
		]
	} else if (options.type === 'parkList') {
		// 房间客户选择
		instance.treeUrl = '/parkingManager/getParkingList'
		instance.tableUrl = '/parkingCarportWaiting/getCarportListByParams'
		instance.defineID = 'parkingId'
		instance.tableList = [
			{
				id: 'parkingName',
				name: '车场'
			},
			{
				id: 'subareName',
				name: '分区'
			},
			{
				id: 'carportName',
				name: '车位号'
			}
		]
	} else if (options.type === 'warehouse') {
		// 仓库
		instance.treeUrl = '/orgUnits/getAllOrgUnitV2'
		instance.tableUrl = '/storeHouse/list'
		instance.defineID = 'orgID'
		instance.placeholder = '请输入仓库名称'
		instance.cleanTableData = function (data) {
			data.forEach(item => {
				if (item.property == 1) {
					this.$set(item, 'propertyName', '原料仓库')
				} else if (item.property == 2) {
					this.$set(item, 'propertyName', '消耗品仓库')
				} else if (item.property == 3) {
					this.$set(item, 'propertyName', '生产仓库')
				} else if (item.property == 4) {
					this.$set(item, 'propertyName', '备件仓库')
				} else if (item.property == 5) {
					this.$set(item, 'propertyName', '综合仓库')
				}
			})
			return data
		}
		instance.tableList = [
			{
				id: 'name',
				name: '仓库名称'
			},
			{
				id: 'number',
				name: '仓库编码'
			},
			{
				id: 'propertyName',
				name: '仓库属性'
			}
		]
	} else if (options.type === 'employee') {
		// 员工选择
		instance.treeUrl = '/orgUnits/getAllOrgUnit'
		instance.tableUrl = '/employees/getAllEmployees'
		instance.defineID = 'orgunitID'
		instance.placeholder = '请输入姓名'
		instance.cleanTreeData = function (data) {
			return [].concat(data.enterpriseVo)
		}
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'phone',
				name: '手机号'
			},
			{
				id: 'number',
				name: '编号'
			}
		]
	} else if (options.type === 'orgUnitAndProject') {
		// 所有组织和部门
		instance.treeUrl = '/orgUnits/getAllOrgUnit'
		instance.tableUrl = '/orgUnits/getAllOrgUnitAndProject'
		instance.defineID = 'orgunitID'
		instance.placeholder = '请输入组织名称'
		instance.cleanTreeData = function (data) {
			return [].concat(data.enterpriseVo)
		}
		instance.cleanTableData = function (data) {
			data.forEach(item => {
				if (item.type == 0) {
					this.$set(item, 'typeName', '区域')
				} else if (item.type == 1) {
					this.$set(item, 'typeName', '公司')
				} else if (item.type == 2) {
					this.$set(item, 'typeName', '部门')
				} else if (item.type == 3) {
					this.$set(item, 'typeName', '项目')
				}
			})
			return data
		}
		instance.tableList = [
			{
				id: 'name',
				name: '名称'
			},
			{
				id: 'typeName',
				name: '形态'
			}
		]
	} else if (options.type === 'provider') {
		instance.treeUrl = '/providerType/getProviderTypes'
		instance.tableUrl = '/provider/getProviderPageList'
		instance.defineID = 'providerTypes'
		instance.placeholder = '请输入供应商名称'
		instance.tableList = [
			{
				id: 'name',
				name: '供应商名称'
			}
		]
	} else if (options.type === 'materiel') {
		instance.treeUrl = '/materielType/getAllMaterielType'
		instance.tableUrl = '/materiel/getMaterielList'
		instance.defineID = 'materielTypeID'
		instance.placeholder = '请输入物料名称、物料编码'
		instance.cleanTableData = function (data) {
			data.forEach(item => {
				if (item.property == 1) {
					this.$set(item, 'propertyName', '易耗品')
				} else if (item.property == 2) {
					this.$set(item, 'propertyName', '固定资产')
				}
			})
			return data
		}
		instance.tableList = [
			{
				id: 'name',
				name: '物料名称'
			},
			{
				id: 'number',
				name: '物料编码'
			},
			{
				id: 'meteringName',
				name: '计量单位'
			},
			{
				id: 'materielTypeName',
				name: '物料类型'
			},
			{
				id: 'specificationModel',
				name: '规格型号'
			},
			{
				id: 'propertyName',
				name: '属性'
			},
			{
				id: 'remark',
				name: '备注'
			}
		]
	} else if (options.type === 'parkingCarport') {
		// 车场车卡
		instance.treeUrl = '/parkingManager/getParkingList'
		instance.tableUrl = '/parkingManager/getCardByParkingId'
		instance.defineID = 'parkingId'
		instance.tableList = [
			{
				id: 'parkingName',
				name: '车场'
			},
			{
				id: 'cardTypeName',
				name: '车卡类型'
			},
			{
				id: 'cardNo',
				name: '车卡号'
			},
			{
				id: 'carNo',
				name: '车牌号'
			},
			{
				id: 'customerName',
				name: '客户'
			}
		]
	} else if (options.type === 'investConsultant') {
		// 招商顾问
		instance.treeUrl = '/invTeam/getTeamsV2'
		instance.tableUrl = '/invTeam/getTeamMemberByTeamIDV2'
		instance.defineID = 'teamID'
		instance.tableList = [
			{
				id: 'name',
				name: '姓名'
			},
			{
				id: 'phone',
				name: '电话'
			}
		]
	} else if (options.type === 'qualityCheckPoint') {
		// 品质管理检查点
		instance.treeUrl = '/qualityDetailPoint/getQualityPointTree'
		instance.tableUrl = '/qualityDetailPoint/getAllQualityDetailPoint'
		instance.defineID = 'qualityItemId'
		instance.tableList = [
			{
				id: 'number',
				name: '编码'
			},
			{
				id: 'name',
				name: '检查点'
			},
			{
				id: 'score',
				name: '分值'
			}
		]
	} else if (options.type === 'employeeByRole') {
		// 员工选择（按角色）
		instance.treeUrl = '/jobRole/getJobTree'
		instance.tableUrl = '/employees/getAllEmployeesByRoleId'
		instance.tableList = [
			{
				id: 'name',
				name: '姓名'
			},
			{
				id: 'phone',
				name: '电话'
			}
		]
	} else if (options.type === 'rentContract') {
		// 所有租赁合同
		instance.treeUrl = '/orgUnits/getOrgUnitsForTree'
		instance.tableUrl = '/rentContract/rentContractList?bigContractType=1' // bigType=2物业合同 bigType=1租赁合同
		instance.defineID = 'orgUnitsIDList'
		instance.placeholder = '搜索合同编号、乙方、资源'
		instance.cleanTreeData = function (data) {
			return [].concat(data)
		}
		instance.cleanTableData = function (data) {
			data.forEach(item => {
				if (item.status === -2) {
					this.$set(item, 'statusStr', '草稿')
				} else if (item.status === -1) {
					this.$set(item, 'statusStr', '作废')
				} else if (item.status === 0) {
					this.$set(item, 'statusStr', '暂存')
				} else if (item.status === 1) {
					this.$set(item, 'statusStr', '审批中')
				} else if (item.status === 2) {
					this.$set(item, 'statusStr', '审核通过')
				} else if (item.status === 3) {
					this.$set(item, 'statusStr', '执行中')
				} else if (item.status === 4) {
					this.$set(item, 'statusStr', '退租中')
				} else if (item.status === 5) {
					this.$set(item, 'statusStr', '执行中（已退租）')
				} else if (item.status === 6) {
					this.$set(item, 'statusStr', '变更中')
				} else if (item.status === 7) {
					this.$set(item, 'statusStr', '已变更')
				} else if (item.status === 8) {
					this.$set(item, 'statusStr', '续签中')
				} else if (item.status === 9) {
					this.$set(item, 'statusStr', '执行中（已续签）')
				} else if (item.status === 10) {
					this.$set(item, 'statusStr', '转租中')
				} else if (item.status === 11) {
					this.$set(item, 'statusStr', '已转租')
				} else if (item.status === 12) {
					this.$set(item, 'statusStr', '已退租')
				} else if (item.status === 13) {
					this.$set(item, 'statusStr', '已续签')
				}
			})
			return data
		}
		instance.tableList = [
			{
				id: 'number',
				name: '合同编号',
				width: '160px'
			},
			{
				id: 'name',
				name: '合同名称',
				width: '120px'
			},
			{
				id: 'projectName',
				name: '所属项目',
				width: '120px'
			},
			{
				id: 'partAName',
				name: '甲方',
				width: '120px'
			},
			{
				id: 'partBName',
				name: '乙方',
				width: '120px'
			},
			{
				id: 'productName',
				name: '租赁资源',
				width: '120px'
			},
			{
				id: 'startDateStr',
				name: '合同开始日期',
				width: '120px'
			},
			{
				id: 'endDateStr',
				name: '合同结束日期',
				width: '120px'
			},
			{
				id: 'statusStr',
				name: '合同状态',
				width: '120px'
			}
		]
	} else if (options.type === 'productType') {
		// 产品类型
		instance.treeUrl = '/productTypes/getProductTypeByComponentV2'
		instance.tableUrl = '/productTypes/getProductTypeByPageV2'
		instance.tableList = [
			{
				id: 'number',
				name: '编码'
			},
			{
				id: 'name',
				name: '名称'
			}
		]
	}

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyTreeAndTableBox',
		id: instance._uid,
		instance
	})

	return instance
}
