<template>
	<div class="wy-table-list">
		<div class="wy-table-list-bar">
			<h3 class="wy-table-list_title"></h3>
			<el-input
				v-if="showSearch"
				v-model="table.searchPhrase"
				class="wy-table-list_search wy-border-bottom"
				:placeholder="placeholder"
				prefix-icon="el-icon-search"
				clearable
			>
			</el-input>
		</div>
		<div class="wy-table-list__content" :class="{ multi: multi }">
			<div class="wy-table-list-table">
				<el-table
					ref="table"
					height="100%"
					class="normal"
					:row-key="tableKey"
					:empty-text="tableEmptyText"
					:data="table.list"
					@selection-change="changeSelection"
					@row-click="handleRowClick"
				>
					<el-table-column
						v-if="multi"
						:reserve-selection="reserveSelection"
						type="selection"
						width="55"
					>
					</el-table-column>
					<el-table-column v-if="!multi" width="55">
						<template slot-scope="scope">
							<i
								v-if="!isChecked(scope.row)"
								class="iconfont icon-radio-normal"
							></i>
							<i
								v-if="isChecked(scope.row)"
								class="iconfont icon-radio-checked"
							></i>
						</template>
					</el-table-column>
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column
						v-for="item in tableList"
						:key="item.id"
						:width="item.width"
						:prop="item.id"
						:label="item.name"
					>
						<template slot-scope="scope">
							<div
								v-if="item.clean"
								v-html="item.clean(scope.row)"
							></div>
							<div v-if="!item.clean">
								{{ scope.row[item.id] }}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="wy-table-list-pages">
				<div class="wy-message"></div>
				<wy-pages
					:current="table.current"
					:page-size="table.rowCount"
					:page-sizes="[10, 20, 100]"
					:total="table.total"
					@changePageSize="changePageSize"
					@changeCurrentPage="changeCurrentPage"
				>
				</wy-pages>
			</div>
		</div>
		<div v-if="multi" class="wy-table-list_result">
			<div class="wy-table-list__bar">
				<span class="wy-selection">已选择{{ selection.length }}个</span>
				<el-button type="text" @click="clearAll">全部清除</el-button>
			</div>
			<div class="wy-table-list__list">
				<ul>
					<li v-for="(item, index) in selection" :key="index">
						<span>{{ item[defineName] }}</span>
						<i
							class="el-icon-close"
							@click="clear(item, index)"
						></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import wyPages from '../pages'
export default {
	name: 'WyTableList',
	components: {
		wyPages
	},
	props: {
		reserveSelection: {
			type: Boolean,
			default: true
		},
		tableUrl: String,
		tableList: Array,
		filter: Object,
		cleanTableData: Function,
		currentSelection: Array,
		placeholder: {
			type: String,
			default: '请输入名称'
		},
		multi: {
			type: Boolean,
			default: true
		},
		tableKey: {
			type: String,
			default: 'id'
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		messageRender: Function,
		visible: {
			type: Boolean,
			default: false
		},
		defineName: {
			type: String,
			default: 'name'
		}
	},
	data() {
		return {
			table: {
				list: [],
				current: 1,
				rowCount: 20,
				total: 0,
				searchPhrase: ''
			},
			tableLoaded: false,
			searchTimeout: null,
			selection: [],
			curSelection: [],
			hasMessageAppend: false
		}
	},
	computed: {
		tableEmptyText() {
			if (this.tableLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.init()
			} else {
				this.hasMessageAppend = false
				this.selection = []
			}
		},
		'table.searchPhrase'(newVal, oldVal) {
			if (newVal == oldVal) {
				return
			}
			this.$delay.register('tableList', this.changeKeyword, 500)
		}
	},
	created() {},
	mounted() {},
	methods: {
		init() {
			if (this.currentSelection && this.currentSelection.length > 0) {
				if (this.multi) {
					this.$nextTick(() => {
						this.currentSelection.forEach(item => {
							this.$refs.table.toggleRowSelection(item, true)
						})
					})
				} else {
					this.selection = [].concat(this.currentSelection)
				}
			}
			this.getTableData()
		},
		createMessage() {
			if (this.messageRender && !this.hasMessageAppend) {
				this.messageInstance = new Vue({
					render: this.messageRender
				}).$mount()
				this.$nextTick(() => {
					this.$el
						.querySelector('.wy-message')
						.appendChild(this.messageInstance.$el)
				})
				this.hasMessageAppend = true
			}
		},
		getTableData() {
			const filterData = Object.assign(
				{},
				{
					current: this.table.current,
					rowCount: this.table.rowCount,
					searchPhrase: this.table.searchPhrase
						? encodeURIComponent(this.table.searchPhrase)
						: null
				},
				this.filter
			)
			this.tableLoaded = false
			this.$axios({
				url: this.tableUrl,
				data: filterData
			}).then(res => {
				this.tableLoaded = true
				if (
					this.cleanTableData &&
					typeof this.cleanTableData == 'function'
				) {
					this.table.list = this.cleanTableData(res.data)
				} else {
					this.table.list = res.data.rows
				}
				this.table.current = res.data.current
				this.table.rowCount = res.data.rowCount
				this.table.total = res.data.total
				this.$nextTick(() => {
					this.createMessage()
				})
			})
		},
		reload() {
			this.table.current = 1
			this.table.searchPhrase = null
			this.getTableData()
		},
		handleRowClick(row) {
			if (this.multi) {
				this.$refs.table.toggleRowSelection(row)
			} else {
				this.selection = [].concat(row)
				this.$refs.table.toggleRowSelection(row, true)
			}
		},
		changeKeyword() {
			this.table.current = 1
			this.getTableData()
		},
		changePageSize(rowCount) {
			this.table.current = 1
			this.table.rowCount = rowCount
			this.getTableData()
		},
		changeCurrentPage(current) {
			this.table.current = current
			this.getTableData()
		},
		changeSelection(selection) {
			if (this.multi) {
				this.selection = selection
			}
		},
		isChecked(data) {
			if (
				this.selection.length > 0 &&
				data[this.tableKey] == this.selection[0][this.tableKey]
			) {
				return true
			}
			return false
		},
		getSelection() {
			return this.selection
		},
		save() {
			const selection = this.getSelection()
			if (!this.onOk || typeof this.onOk != 'function') {
				this.visible = false
				return
			}
			this.onOk(selection, () => {
				this.visible = false
			})
		},
		hide() {
			this.visible = false
		},
		cancel() {
			this.visible = false
			typeof this.onCancel == 'function' && this.onCancel()
		},
		clear(row, index) {
			this.selection.splice(index, 1)
			this.$refs.table.toggleRowSelection(row, false)
		},
		clearAll() {
			this.selection = []
			this.curSelection = []
			this.$refs.table.clearSelection()
		}
	}
}
</script>
