<template>
	<div
		ref="wySplit"
		class="wy-split-box"
		:class="mode + (moving ? ' moving' : '')"
	>
		<slot>
			<div class="wy-split-panel wy-split-panel-1" :style="panelStype">
				<slot v-if="mode === 'horizontal'" name="left"></slot>
				<slot v-else name="top"></slot>
			</div>
			<div
				v-if="
					(mode === 'horizontal' && $slots.left && $slots.right) ||
					(mode === 'vertical' && $slots.top && $slots.bottom)
				"
				class="wy-split-bar"
				@mousedown.stop="mousedown"
			>
				<span></span><span></span><span></span><span></span>
			</div>
			<div class="wy-split-panel wy-split-panel-2">
				<slot v-if="mode === 'horizontal'" name="right"></slot>
				<slot v-else name="bottom"></slot>
			</div>
		</slot>
	</div>
</template>
<script>
import { fixNumber, off, on } from 'tool'
export default {
	name: 'WySplit',
	props: {
		mode: {
			type: String,
			default: 'horizontal',
			validator: v => ['horizontal', 'vertical'].includes(v)
		},
		// 像素或者百分比
		value: {
			type: [Number, String],
			default: '50%',
			validator: v => /^([0-9]+)(%|px)?$/.test(v)
		},
		// 最小像素或百分比
		min: {
			type: [Number, String],
			default: 0
		},
		// 最大像素或百分比
		max: {
			type: [Number, String],
			default: '100%'
		}
	},
	data() {
		return {
			size: 50,
			suffix: '%',
			pos: {},
			boxSize: 0,
			moving: false
		}
	},
	computed: {
		px() {
			return Math.round((this.size / 100) * this.boxSize)
		},
		panelStype() {
			let size = 0 + this.size
			if (this.mode === 'horizontal') {
				if (!this.$slots.right) {
					size = 100
				} else if (!this.$slots.left) {
					size = 0
				}
			} else {
				if (!this.$slots.bottom) {
					size = 100
				} else if (!this.$slots.top) {
					size = 0
				}
			}
			return {
				[this.mode === 'horizontal' ? 'width' : 'height']: size + '%'
			}
		}
	},
	watch: {
		value: {
			handler(val) {
				if (!val) return
				if (this.suffix === '%') {
					// 输入%
					this.size = fixNumber(parseFloat(val), 2)
				} else if (this.boxSize > 0) {
					// 输入px
					this.size = fixNumber(
						(parseFloat(val) * 100) / this.boxSize,
						2
					)
				}
			}
		},
		size(val) {
			const v = (this.suffix !== '%' ? this.px : val) + this.suffix
			if (v != this.value && this.px !== 0) this.$emit('input', v)
		}
	},
	created() {
		String(this.value).replace(/^([0-9]+)(%|px)?$/, (a, b, c) => {
			if (c === '%') {
				this.size = this.calculateSize(parseInt(b))
			}
			this.suffix = c || ''
		})
	},
	mounted() {
		this.boxSize = parseInt(
			this.mode === 'horizontal'
				? this.$refs.wySplit.clientWidth
				: this.$refs.wySplit.clientHeight
		)
		if (this.suffix !== '%') {
			// 输入px或空单位
			this.size = this.calculateSize(
				fixNumber((parseInt(this.value) * 100) / this.boxSize, 2)
			)
		}
	},
	methods: {
		/**
		 * @private
		 * @description 获取偏移量
		 * @param {object} el 元素对象
		 */
		getTransform(el) {
			let transformMatrix =
					el.style.WebkitTransform ||
					getComputedStyle(el, '').getPropertyValue(
						'-webkit-transform'
					) ||
					el.style.transform ||
					getComputedStyle(el, '').getPropertyValue('transform'),
				matrix = transformMatrix.match(/\-?[0-9]+\.?[0-9]*/g),
				x,
				y
			if (matrix) {
				x = parseInt(matrix[12] || matrix[4] || 0) // translate x
				y = parseInt(matrix[13] || matrix[5] || 0) // translate y
				return { x, y }
			}
			return { x: 0, y: 0 }
		},
		/**
		 * @private
		 * @description 计算出不超范围的size
		 * @param {number} size
		 */
		calculateSize(size) {
			if (String(this.min).indexOf('%') > 1) {
				size = Math.max(size, parseInt(this.min))
			} else if (this.boxSize > 0) {
				size = Math.max(
					size,
					fixNumber((parseInt(this.min) * 100) / this.boxSize, 2)
				)
			}
			if (String(this.max).indexOf('%') > 1) {
				size = Math.min(size, parseInt(this.max))
			} else if (this.boxSize > 0) {
				size = Math.min(
					size,
					fixNumber((parseInt(this.max) * 100) / this.boxSize, 2)
				)
			}
			return size
		},
		/**
		 * @private
		 * @description 获取鼠标位置
		 * @param {object} el 元素对象
		 */
		getPosition(el) {
			let x = 0,
				y = 0,
				left = 0,
				top = 0
			while (el.offsetParent) {
				const t = this.getTransform(el) // 备用方案：el.getBoundingClientRect();
				x += el.offsetLeft + t.x
				y += el.offsetTop + t.y
				el = el.offsetParent
			}
			// 处理当元素处于滚动之后的情况
			while (el.parentNode) {
				left += el.scrollLeft
				top += el.scrollTop
				el = el.parentNode
			}
			return { x, y, left, top }
		},
		/**
		 * @private
		 * @description 鼠标点击缩放条事件
		 * @param {object} e event
		 */
		mousedown(e) {
			let el = e.target
			this.boxSize = parseInt(
				this.mode === 'horizontal'
					? this.$refs.wySplit.clientWidth
					: this.$refs.wySplit.clientHeight
			)
			while (el.parentNode) {
				if (el.parentNode.className.includes('wy-split-box')) {
					this.pos = this.getPosition(el.parentNode)
					break
				}
				el = el.parentNode
			}
			e.preventDefault()
			this.$emit('move-start')
			on(document, 'mousemove', this.handleMouseMove)
			on(document, 'mouseup', this.handleMouseUp)
		},
		/**
		 * @private
		 * @description 鼠标移动事件
		 * @param {object} e event
		 */
		handleMouseMove(e) {
			this.moving = true
			this.$emit('moving', e)
			this.$delay.register(
				'wySplitOnmouseMove',
				() => {
					let size
					if (this.mode === 'horizontal') {
						size = e.pageX + this.pos.left - this.pos.x
					} else {
						size = e.pageY + this.pos.top - this.pos.y
					}
					this.size = this.calculateSize(
						fixNumber((size * 100) / this.boxSize, 2)
					)
				},
				50,
				true
			)
			e.preventDefault()
		},
		/**
		 * @private
		 * @description 鼠标弹起事件
		 * @param {object} e event
		 */
		handleMouseUp() {
			this.moving = false
			this.$emit('move-end')
			off(document, 'mousemove', this.handleMouseMove)
			off(document, 'mouseup', this.handleMouseUp)
		}
	}
}
</script>
