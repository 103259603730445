const ProgressV2 = {}
import ProgressV2Plugin from './progress-v2.js'

/* istanbul ignore next */
ProgressV2.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$progressV2 = Vue.$progressV2 = ProgressV2Plugin
}

export default ProgressV2
