<template>
	<transition name="fade">
		<div
			v-if="curVisible"
			class="wy-box-wrap"
			:style="{ 'z-index': zIndex }"
			tabindex="-1"
		>
			<div
				ref="wyBox"
				class="wy-box"
				:class="{
					'wy-box__one-btn': btns === 1,
					'wy-box__two-btn': btns === 2,
					'wy-box__no-btn': btns === 0
				}"
				:style="{ width: mWidth || width, maxWidth: !max ? maxW : '' }"
			>
				<div
					v-if="showHeader"
					class="wy-box-header"
					:class="{ 'wy-box__slot-title': $slots.title }"
				>
					<template v-if="!$slots.title">{{ title }}</template>
					<slot v-if="$slots.title" name="title"></slot>
				</div>
				<div
					v-if="$slots.default"
					class="wy-box-content"
					:class="{ 'wy-no-header': !showHeader }"
					:style="{
						height: mHeight || height,
						maxHeight: !max ? maxH : ''
					}"
				>
					<slot></slot>
				</div>
				<div v-if="showFooter" class="wy-box-footer">
					<template v-if="!$slots.footer">
						<button
							v-if="showOkBtn"
							class="wy-box__btn-theme"
							:class="
								loading
									? 'wy-box__btn__disabled'
									: 'wy-box__btn'
							"
							autofocus
							theme
							:disabled="loading"
							@click="handleOk"
						>
							{{ okBtnName }}
						</button>
						<button
							v-if="showCancelBtn"
							class="wy-box__btn"
							@click="handleCancel"
						>
							{{ cancelBtnName }}
						</button>
					</template>
					<slot v-if="$slots.footer" name="footer"></slot>
				</div>
				<div class="wy-box-ico">
					<div class="wy-box-filter">
						<slot name="filter"></slot>
					</div>
					<i
						v-if="showMax && max"
						class="wy-box-max iconfont icon-tuichuzhuanhuan"
						title="恢复默认"
						@click="handleMax"
					></i>
					<i
						v-if="showMax && !max"
						class="wy-box-max iconfont icon-quanping"
						title="最大化"
						@click="handleMax"
					></i>
					<i
						v-if="showClose"
						class="wy-box-close iconfont icon-del"
						title="关闭"
						@click="handleClose"
					></i>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import { nextIndex, off, on } from 'tool'

export default {
	name: 'WyBox',
	/**
	 * Props 接受父组件的传值
	 *
	 * @property {string} title 标题
	 * @property {boolean} visible 是否显示
	 */
	props: {
		title: {
			type: String,
			default: '提示'
		},
		visible: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: '600px'
		},
		maxWidth: {
			type: String
		},
		height: String,
		maxHeight: {
			type: String,
			default: '480px'
		},
		okBtnName: {
			type: String,
			default: '确定'
		},
		cancelBtnName: {
			type: String,
			default: '取消'
		},
		defaultMax: {
			type: Boolean,
			default: false
		},
		showOkBtn: {
			type: Boolean,
			default: true
		},
		showCancelBtn: {
			type: Boolean,
			default: true
		},
		showHeader: {
			type: Boolean,
			default: true
		},
		showClose: {
			type: Boolean,
			default: true
		},
		showFooter: {
			type: Boolean,
			default: true
		},
		showMax: {
			type: Boolean,
			default: true
		},
		message: {
			type: String
		},
		onOk: Function,
		onCancel: Function,
		onClose: Function,
		loading: {
			//控制按钮禁用状态
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			zIndex: 0,
			instance: null,
			isAppendBody: false,
			curVisible: false,
			maxW: '600px',
			maxH: '480px',
			mWidth: null,
			mHeight: null,
			max: false
		}
	},
	computed: {
		btns() {
			if (this.showMax) {
				if (this.showClose) return 2
				return 1
			} else {
				if (this.showClose) return 1
				return 0
			}
		}
	},
	watch: {
		visible() {
			this.init()
		}
	},
	created() {},
	mounted() {
		this.init()
	},
	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el)
			this.isAppendBody = false
		}
	},
	methods: {
		/**
		 * @function init
		 * @private
		 * @description 初始化
		 */
		init() {
			this.curVisible = this.visible
			this.max = this.defaultMax
			this.maxH = this.maxHeight
			this.maxW = this.maxWidth || this.width
			this.calculate()
			if (this.curVisible) {
				document.body.appendChild(this.$el)
				this.$nextTick(() => {
					on(window, 'resize', this.reSize)
				})
				this.zIndex = nextIndex(5000, 10000)
				this.isAppendBody = true
				this.$plugin.push({
					name: 'WyPluginBox',
					id: this._uid,
					instance: this
				})
			}
		},
		fixNumber(n) {
			n = parseInt(n)
			if (n % 2 !== 0) n = n + 1
			return n
		},
		reSize() {
			this.$delay.register('windowReSize', this.calculate, 500)
		},
		calculate() {
			const c =
				110 - (this.showFooter ? 0 : 60) - (this.showHeader ? 0 : 50) // herder=50和footer=60高度
			const p = this.showHeader ? 0 : 40
			const maxW = this.fixNumber(
				window.innerWidth > 320 ? window.innerWidth - 20 : 300
			)
			const maxH = this.fixNumber(
				window.innerHeight > 300 + c
					? window.innerHeight - c - 60 + p
					: 240 + p
			)
			const maxHeight = parseInt(
				this.maxHeight || this.height || maxH || 480
			)
			const maxWidth = parseInt(
				this.maxWidth || this.width || maxW || 600
			)
			this.maxW = Math.min(maxWidth, maxW) + 'px'
			this.maxH = Math.min(maxHeight, maxH) + 'px'
			if (this.max) {
				this.mWidth = maxW + 'px'
				this.mHeight = maxH + 'px'
			}
		},
		/**
		 * @function handleOk
		 * @private
		 * @description 触发确认按钮回调
		 */
		handleOk() {
			this.$emit('onOk')
		},
		/**
		 * @function handleCancel
		 * @private
		 * @description 触发取消按钮回调
		 */
		handleCancel() {
			this.$emit('onCancel')
		},
		/**
		 * @function handleClose
		 * @private
		 * @description 触发关闭按钮回调
		 */
		handleClose() {
			this.$emit('onClose')
		},
		/**
		 * @function handleMax
		 * @description 窗口对大化
		 */
		handleMax() {
			const c =
				110 - (this.showFooter ? 0 : 60) - (this.showHeader ? 0 : 50) // herder=50和footer=60高度
			const p = this.showHeader ? 0 : 40
			const w = this.fixNumber(
				window.innerWidth > 320 ? window.innerWidth - 20 : 300
			)
			const h = this.fixNumber(
				window.innerHeight > 300 + c
					? window.innerHeight - c - 60 + p
					: 240 + p
			)
			if (!this.max) {
				this.max = true
				this.mWidth = w + 'px'
				this.mHeight = h + 'px'
			} else {
				this.max = false
				this.mWidth = null
				this.mHeight = null
			}
		},
		/**
		 * @function hide
		 * @description 关闭弹出框，在页面切换的时候会判断在vue.$plugin里遍历关闭弹出框
		 */
		hide() {
			off(window, 'resize', this.reSize)
			this.curVisible = false
		}
	}
}
</script>
