const TreeBox = {}
import TreeBoxPlugin from './tree-box.js'

/* istanbul ignore next */
TreeBox.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$treeBox = Vue.$treeBox = TreeBoxPlugin
}

export default TreeBox
