export default {
	props: {
		value: {
			type: Array
		},
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '400px'
		},
		multi: {
			type: Boolean,
			default: false
		},
		enabledMultiPage: {
			// 是否允许跨页选择
			type: Boolean,
			default: false
		},
		auto: {
			// 是否加载
			type: Boolean,
			default: true
		},
		cleanTreeData: Function,
		treeProps: {
			type: Object,
			default() {
				return {
					label: 'name',
					children: 'child',
					isLeaf: 'isLeaf'
				}
			}
		}
	}
}
