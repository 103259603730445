import QuestionBox from './question-box.vue'
import QuestionBoxPlugin from './question-box.js'

/* istanbul ignore next */
QuestionBox.install = function (Vue) {
	Vue.component(QuestionBox.name, QuestionBox)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$questionBox = Vue.$questionBox = QuestionBoxPlugin
}

export default QuestionBox
