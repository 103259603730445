var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-box",
    {
      staticClass: "wy-uploadFile_box",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        loading: _vm.loading,
        width: "800px",
        height: "400px",
      },
      on: { onOk: _vm.submit, onCancel: _vm.cancel, onClose: _vm.cancel },
    },
    [
      _c("div", { staticClass: "wy-uploadFile" }, [
        _c(
          "div",
          { staticClass: "wy-uploadFile_bar" },
          [
            _c(
              "div",
              { staticClass: "wy-uploadFile_type" },
              [
                _vm.typeList.length > 1
                  ? _c(
                      "el-select",
                      {
                        staticClass: "wy-border-bottom",
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _vm.typeList.includes(1)
                          ? _c(
                              "el-option",
                              {
                                key: "1",
                                attrs: { value: "1", label: "仅添加新数据" },
                              },
                              [_vm._v("仅添加新数据")]
                            )
                          : _vm._e(),
                        _vm.typeList.includes(2)
                          ? _c(
                              "el-option",
                              {
                                key: "2",
                                attrs: { value: "2", label: "添加并覆盖数据" },
                              },
                              [_vm._v("添加并覆盖数据")]
                            )
                          : _vm._e(),
                        _vm.typeList.includes(3)
                          ? _c(
                              "el-option",
                              {
                                key: "3",
                                attrs: { value: "3", label: "仅覆盖已有数据" },
                              },
                              [_vm._v("仅覆盖已有数据")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.typeList.length <= 1 && _vm.barTip
                  ? _c("p", { staticClass: "wy-uploadFile_bar_tip" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.barTip) + "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._l(_vm.customButtons, function (item) {
              return [
                !item.children || item.children.length === 0
                  ? _c(
                      "el-button",
                      {
                        key: item.name,
                        staticClass: "wy-downloadBtn",
                        attrs: {
                          type: item.type || "primary",
                          plain: item.isPlainBtn,
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.customButtonClick(item.event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  : [
                      item.event
                        ? _c(
                            "el-dropdown",
                            {
                              key: item.name + "_withEvent",
                              attrs: {
                                "split-button": "",
                                type: item.type || "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.customButtonClick(item.event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(item.name) +
                                  "\n\t\t\t\t\t\t"
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(item.children, function (btn) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: btn.field,
                                      attrs: { disabled: !btn.event },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.customButtonClick(
                                            btn.event
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(btn.name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-dropdown",
                            { key: item.name },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: item.type || "primary" } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(item.name) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(item.children, function (btn) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: btn.field,
                                      attrs: { disabled: !btn.event },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.customButtonClick(
                                            btn.event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(btn.name) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                    ],
              ]
            }),
            _vm.isShowTemp
              ? _c(
                  "el-button",
                  {
                    staticClass: "wy-downloadBtn",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.downloadTmp },
                  },
                  [_vm._v(_vm._s(_vm.downloadBtnName))]
                )
              : _vm._e(),
            _c(
              "el-upload",
              {
                staticClass: "wy-el-upload",
                attrs: {
                  action: "/jar/api/file/tempFiles",
                  name: "files",
                  multiple: false,
                  "show-file-list": false,
                  "on-success": _vm.handleUploadSuccess,
                  "before-upload": _vm.beforeUpload,
                },
              },
              [
                _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                  _vm._v(_vm._s(_vm.uploadBtnName)),
                ]),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "wy-uploadFile_table-flex" },
          [
            _c(
              "el-table",
              {
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  height: "320px",
                  "row-class-name": _vm.tableRowClassName,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "50" },
                }),
                _vm._l(_vm.tableItem, function (item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: item.width,
                    },
                  })
                }),
                _c("el-table-column", {
                  attrs: { prop: "result", label: "校验结果", width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.result
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(scope.row.result)),
                              ])
                            : _vm._e(),
                          !scope.row.result
                            ? _c(
                                "span",
                                { staticStyle: { color: "#00d1a0" } },
                                [_vm._v("通过")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wy-uploadFile_result" },
          [
            _c("span", [
              _vm._v("\n\t\t\t\t已校验通过：\n\t\t\t\t"),
              _c("span", { staticStyle: { color: "#00d1a0" } }, [
                _vm._v(_vm._s(_vm.passNum)),
              ]),
              _vm._v("条，\n\t\t\t"),
            ]),
            _c("span", [
              _vm._v("\n\t\t\t\t校验未通过：\n\t\t\t\t"),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.unPassNum)),
              ]),
              _vm._v("条。\n\t\t\t"),
            ]),
            _vm.typeList.length > 0 &&
            _vm.unPassNum > 0 &&
            _vm.downloadReportURL
              ? _c(
                  "el-link",
                  {
                    staticClass: "wy-uploadFile_downBtn",
                    attrs: { type: "primary", underline: false },
                    on: { click: _vm.downLoadReport },
                  },
                  [_vm._v("\n\t\t\t\t下载错误报告\n\t\t\t")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.btnList.length > 0
        ? _c(
            "div",
            {
              staticClass: "wy-uploadFile_ctrl-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm._l(_vm.btnList, function (item, index) {
                return _c(
                  "el-button",
                  {
                    key: index,
                    staticClass: "wy-uploadFile_btn",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.uploadEvent(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              _c(
                "el-button",
                { staticClass: "wy-uploadFile_btn", on: { click: _vm.cancel } },
                [_vm._v("\n\t\t\t取消\n\t\t")]
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }