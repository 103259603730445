var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-box",
    {
      staticClass: "wy-treeAndTable-box",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "960px",
        height: "400px",
      },
      on: { onOk: _vm.save, onCancel: _vm.cancel, onClose: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          staticClass: "wy-treeAndTable-box_header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _c("el-input", {
            staticClass: "wy-border-bottom",
            attrs: {
              placeholder: _vm.placeholder,
              "prefix-icon": "el-icon-search",
              clearable: "",
            },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c("tree-and-table", {
        ref: "treeAndTable",
        attrs: {
          "tree-url": _vm.treeUrl,
          "table-url": _vm.tableUrl,
          "table-list": _vm.tableList,
          "current-selection": _vm.currentSelection,
          keyword: _vm.keyword,
          multi: _vm.multi,
          "tree-filter": _vm.treeFilter,
          "table-filter": _vm.tableFilter,
          "table-key": _vm.tableKey,
          "clean-tree-data": _vm.cleanTreeData,
          "clean-table-data": _vm.cleanTableData,
          "table-key-name": _vm.tableKeyName,
          "reserve-selection": _vm.reserveSelection,
          "define-i-d": _vm.defineID,
          type: _vm.type,
          lazy: _vm.lazy,
          "define-lazy-i-d": _vm.defineLazyID,
          "level-name": _vm.levelName,
          "limit-levels": _vm.limitLevels,
          "tree-key": _vm.treeKey,
          "show-node-all": _vm.showNodeAll,
          "show-clear": _vm.showClear,
          selectable: _vm.selectable,
          "table-row-click": _vm.tableRowClick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }