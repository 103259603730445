import ExportFile from './export-file.vue'
import ExportFilePlugin from './export-file.js'

/* istanbul ignore next */
ExportFile.install = function (Vue) {
	Vue.component(ExportFile.name, ExportFile)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$exportFile = Vue.$exportFile = ExportFilePlugin
}

export default ExportFile
