var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wy-box",
    {
      attrs: { visible: _vm.visible, width: "600px", height: "400px" },
      on: { onOk: _vm.save, onCancel: _vm.cancel, onClose: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          staticClass: "wy-tree-box-bar",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h3", { staticClass: "wy-tree-box_title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("span", { staticClass: "wy-tree-box_total" }, [
            _vm._v("已选择"),
            _c("em", { staticClass: "link" }, [
              _vm._v(_vm._s(_vm.selection.length)),
            ]),
            _vm._v("个"),
          ]),
          _c("el-input", {
            staticClass: "wy-tree-box_search wy-border-bottom",
            attrs: {
              placeholder: _vm.placeholder,
              "prefix-icon": "el-icon-search",
            },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c("el-tree", {
        ref: "tree",
        staticClass: "normal",
        attrs: {
          data: _vm.treeData,
          props: _vm.treeProps,
          "check-on-click-node": true,
          "default-checked-keys": _vm.checked,
          "filter-node-method": _vm.filterNode,
          "empty-text": _vm.treeEmptyText,
          "node-key": _vm.nodeKey,
          "show-checkbox": _vm.multi || _vm.checkStrictly,
          "highlight-current": !_vm.multi && !_vm.checkStrictly,
          "check-strictly": _vm.checkStrictly ? true : false,
          "default-expand-all": _vm.expandLevel === -1,
          "default-expanded-keys": _vm.defaultExpandedKeys,
        },
        on: { "check-change": _vm.checkChange, "node-click": _vm.nodeClick },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return _vm.type != "orgProject"
                  ? _c("div", {}, [
                      _c(
                        "span",
                        {
                          staticClass: "wy-tree-box_name",
                          class: {
                            "wy-tree-box_cant-hoose":
                              _vm.type == "org" &&
                              data.isCompany !== 1 &&
                              data.isCompany !== 2,
                          },
                        },
                        [_vm._v(_vm._s(data.name))]
                      ),
                    ])
                  : _vm._e()
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }