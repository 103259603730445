var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-layout-set" }, [
    _c("header", { staticClass: "wy-layout-set_header" }, [
      _c(
        "h1",
        {
          staticClass: "wy-layout-set_title",
          attrs: { "data-guide": "layout-set-title" },
        },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
      ),
    ]),
    _c("section", { staticClass: "wy-layout-set_main" }, [
      _c(
        "table",
        { staticClass: "wy-layout-set_list" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              (!item.permission || _vm.permission[item.permission]) &&
              (!item.schema || item.schema.includes(_vm.permission.schema))
                ? _c("tr", { key: index }, [
                    _c(
                      "th",
                      {
                        staticClass: "wy-layout-set_name",
                        attrs: { width: _vm.nameWidth },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "wy-layout-set_description" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(item.description) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "wy-layout-set_ctrl" },
                      [
                        item.url
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  "data-guide":
                                    "btn-1-" + (item.permission || index),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$openUrl(item.name, item.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonName))]
                            )
                          : _vm._e(),
                        item.http
                          ? _c(
                              "router-link",
                              {
                                staticClass: "link",
                                attrs: {
                                  to: item.http,
                                  target: "_blank",
                                  "data-guide":
                                    "link-1-" + (item.permission || index),
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonName))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }