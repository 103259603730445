import cropperTemplate from './cropper.vue'

class Cropper {
	constructor(instance) {
		this.instance = instance
		this.canvas = document.createElement('canvas')
		this.image = null // 可传入链接、base64、canvas和input file
		this.type = 'url' // url base64 canvas file
		this.maxWidth = 960
		this.maxHeight = 960
		this.quality = 0.8
		this.zoom = false // 小图默认不做放大处理
		this.output = 'base64' // base64 blob
		this.outputImage = null // 导出的图片格式 auto=自动 image/png image/jpeg
		this.imageReady = null // 图片初始化完成
		this.success = null // 转换完成
		// document.body.appendChild(this.canvas);
	}

	/**
	 * init
	 *
	 * @description 裁图初始化
	 * @param {string} image 图片，可传入链接、base64、canvas和input file
	 * @param {string} type 图片类型，url base64 canvas file
	 * @param {string} cropWidth 裁剪宽度，默认640
	 * @param {string} cropHeight 裁剪高度，默认360
	 * @param {object} cropperSet copper配置
	 * @param {Object, Boolean} compress 是否启用压缩，或传入压缩配置
	 * @returns {Promise} 返回一个Promise
	 */
	async crop({
		title,
		image,
		type,
		cropWidth = 640,
		cropHeight = 360,
		cropperSet = {},
		compress = false
	}) {
		if (compress) {
			let config = { image, type }
			if (compress instanceof Object) config = { ...config, ...compress }
			image = await this.compress(config)
		}
		return new Promise((resolve, reject) => {
			this.instance.$box({
				title: title || '裁剪图片',
				template: cropperTemplate,
				width: '720px',
				height: '360px',
				showMax: false,
				templateOpt: {
					cropWidth,
					cropHeight,
					cropperSet,
					image: image.url
				},
				onOk: async instance => {
					const crop = await instance.submit()
					resolve({
						crop,
						file: image.file,
						blob: image.blob,
						url: image.url
					})
					return true
				},
				onCancel: () => {
					reject(new Error('cancel'))
				},
				onClose: () => {
					reject(new Error('close'))
				}
			})
		})
	}

	/**
	 * getImage
	 *
	 * @description 处理数据源 将所有数据源都处理成为图片对象
	 * @param {string} image 图片，可传入链接、base64、canvas和input file
	 * @param {string} type 图片类型，url base64 canvas file
	 * @param {Function} cb 回调方法
	 */
	getImage(image, type, cb) {
		const img = new Image()
		img.crossOrigin = 'anonymous'
		new Promise(resolve => {
			if (
				this.outputImage === 'auto' &&
				[('url', 'base64', 'file')].includes(type)
			) {
				this.outputImage = this.getFileType(image, type)
			}
			if (type === 'url' || type === 'blob') {
				img.src = image
				resolve(img)
			} else if (type === 'base64') {
				img.src = image
				resolve(img)
			} else if (type === 'canvas') {
				img.src = image.toDataURL(this.outputImage, this.quality)
				resolve(img)
			} else if (type === 'file') {
				this.getBase64(image).then(base64str => {
					img.src = base64str
					resolve(img)
				})
			}
		}).then(img => {
			if (cb) {
				img.onload = img.onreadystatechange = () => {
					cb(img)
				}
			}
		})
	}

	/**
	 * getFileType
	 *
	 * @description 获取文件类型
	 * @param {String, Object} file file对象
	 * @param url
	 * @param {string} type 类型
	 */
	getFileType(url, type) {
		if (!url) {
			return null
		} else if (type === 'url') {
			let mineType = /\.[^\.]+$/
				.exec(url)[0]
				.toLowerCase()
				.replace(/^\./, '')
			if (mineType === 'jpg') mineType = 'jpeg'
			return 'image/' + mineType
		} else if (type === 'base64') {
			return url
				.split(',')[0]
				.match(/:(.*?);/)[1]
				.toLowerCase()
		} else if (type === 'file') {
			return url.type || null
		}
		return null
	}

	/**
	 * getBase64
	 *
	 * @description 获取base64图像
	 * @param {object} file input file对象
	 * @param {Function} cb 回调方法
	 */
	getBase64(file) {
		return new Promise(resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = function (e) {
				const base64Img = e.target.result
				resolve(base64Img)
			}
		})
	}

	/**
	 * getSize
	 *
	 * @description 获取转换图片大小
	 * @param {object} img 已挂载的图片
	 * @returns {object} 返回对象
	 */
	getSize(img) {
		const _img = {
			w: img.width,
			h: img.height,
			scale: 1
		}
		if (_img.w <= this.maxWidth && _img.h <= this.maxHeight && !this.zoom) {
			// 图片拉伸比例，当图片尺寸偏小且不允许放大处理时，需要回传图片缩小比例给canvas绘图方法执行缩放
			_img.scale = parseFloat(_img.w / this.maxWidth)
			return _img
		} else {
			const scale = parseFloat(_img.w / _img.h)
			const sizeMw = {
				w: this.maxWidth,
				h: parseInt(this.maxWidth / scale),
				scale: 1
			}
			const sizeMh = {
				w: parseInt(this.maxHeight * scale),
				h: this.maxHeight,
				scale: 1
			}
			if (sizeMw.h <= this.maxHeight) {
				return sizeMw
			}
			if (sizeMh.w <= this.maxWidth) {
				return sizeMh
			}
			return {
				w: this.maxWidth,
				h: this.maxHeight,
				scale: 1
			}
		}
	}

	/**
	 * drawToCanvas
	 *
	 * @param img
	 * @param theW
	 * @param theH
	 * @param realW
	 * @param realH
	 * @description 将相关图片对象画到canvas里面去
	 */
	drawToCanvas(img, theW, theH, realW, realH) {
		return new Promise(resolve => {
			const ctx = this.canvas.getContext('2d')
			let imageData
			this.canvas.width = theW
			this.canvas.height = theH
			ctx.drawImage(img, 0, 0, realW, realH, 0, 0, theW, theH)
			if (!this.outputImage) {
				imageData = ctx.getImageData(0, 0, theW, theH).data
				for (let index = 3; index < imageData.length; index += 4) {
					if (imageData[index] !== 255) {
						this.outputImage = 'image/png'
						break
					} else {
						this.outputImage = 'image/jpeg'
					}
				}
			}
			// 获取base64字符串及canvas对象传给success函数。
			const base64str = this.canvas.toDataURL(
				this.outputImage,
				this.quality
			)
			resolve(base64str, this.canvas)
		})
	}

	/**
	 * dataURLtoBlob
	 *
	 * @param dataUrl
	 * @param ext
	 * @description base64转Blob
	 * @returns {object} 返回Blob对象
	 */
	dataURLtoBlob(dataUrl, ext) {
		const arr = dataUrl.split(',')
		const mime = ext || arr[0].match(/:(.*?);/)[1]
		const bstr = atob(arr[1])
		let n = bstr.length
		const u8arr = new Uint8Array(n)
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n)
		}
		return new Blob([u8arr], { type: mime })
	}

	/**
	 * blobToFile
	 *
	 * @param blob
	 * @param fileName
	 * @description Blob转File对象（Blob加上时间戳）
	 * @returns {object} 返回文件对象
	 */
	blobToFile(blob, fileName) {
		blob.lastModifiedDate = new Date()
		blob.name = fileName
		return blob
	}

	/**
	 * compress
	 *
	 * @description 压缩图片
	 * @param {object} opt 参数
	 * @returns {string} 图片
	 */
	compress(opt = {}) {
		return new Promise(resolve => {
			this.image = opt.image || null
			this.type = opt.type || 'url'
			this.maxWidth = opt.maxWidth || 960
			this.maxHeight = opt.maxHeight || 960
			this.quality = opt.quality || 0.8
			this.zoom = opt.zoom || false
			this.output = opt.output || 'base64'
			this.outputImage = opt.outputImage || null
			this.getImage(opt.image, opt.type, img => {
				const realSize = {
					w: img.width,
					h: img.height
				}
				// 计算尺寸
				const size = this.getSize(img)
				// 重绘图片
				this.drawToCanvas(
					img,
					size.w,
					size.h,
					realSize.w,
					realSize.h,
					size.scale
				).then((base64, canvas) => {
					const blob = this.dataURLtoBlob(base64)
					const file = this.blobToFile(
						blob,
						('upload.' + this.outputImage &&
							this.outputImage.replace(/\S+\//, '')) ||
							'jpeg'
					)
					let url = ''
					// file = new window.File([data.file], 'new.jpeg', { type: 'image/jpeg' });
					window.URL = window.URL || window.webkitURL
					url = window.URL.createObjectURL(blob)
					if (this.output === 'blob') {
						resolve({
							file,
							blob,
							url,
							canvas
						})
					} else if (this.output === 'file') {
						resolve({
							file,
							blob,
							url,
							canvas
						})
					} else {
						resolve({
							file,
							blob,
							url: base64,
							canvas
						})
					}
				})
			})
		})
	}
}

export default Cropper
