<template>
	<wy-box
		:visible="visible"
		width="600px"
		height="400px"
		@onOk="save"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div slot="title" class="wy-tree-box-bar">
			<h3 class="wy-tree-box_title">{{ title }}</h3>
			<span class="wy-tree-box_total"
				>已选择<em class="link">{{ selection.length }}</em
				>个</span
			>
			<el-input
				v-model="keyword"
				class="wy-tree-box_search wy-border-bottom"
				:placeholder="placeholder"
				prefix-icon="el-icon-search"
			>
			</el-input>
		</div>
		<el-tree
			ref="tree"
			class="normal"
			:data="treeData"
			:props="treeProps"
			:check-on-click-node="true"
			:default-checked-keys="checked"
			:filter-node-method="filterNode"
			:empty-text="treeEmptyText"
			:node-key="nodeKey"
			:show-checkbox="multi || checkStrictly"
			:highlight-current="!multi && !checkStrictly"
			:check-strictly="checkStrictly ? true : false"
			:default-expand-all="expandLevel === -1"
			:default-expanded-keys="defaultExpandedKeys"
			@check-change="checkChange"
			@node-click="nodeClick"
		>
			<div v-if="type == 'orgProject'" slot-scope="{ data }">
				<i v-if="data.isCompany == 3" class="iconfont icon-project"></i>
				<span class="wy-tree-box_name">{{ data.name }}</span>
			</div>
			<div v-if="type != 'orgProject'" slot-scope="{ data }">
				<span
					class="wy-tree-box_name"
					:class="{
						'wy-tree-box_cant-hoose':
							type == 'org' &&
							data.isCompany !== 1 &&
							data.isCompany !== 2
					}"
					>{{ data.name }}</span
				>
			</div>
		</el-tree>
	</wy-box>
</template>
<script>
export default {
	name: 'WyTreeBox',
	props: {
		type: String,
		placeholder: {
			type: String,
			default: '请输入关键词...'
		},
		title: String,
		onCancel: Function,
		onOk: Function,
		currentSelection: Array,
		treeUrl: String,
		filter: Object,
		cleanTreeData: Function,
		treeProps: {
			type: Object,
			default() {
				return {
					label: 'name',
					children: 'child',
					isLeaf: 'isLeaf'
				}
			}
		},
		leafOnly: Boolean,
		multi: {
			type: Boolean,
			default: true
		},
		nodeKey: {
			type: String,
			default: 'id'
		},
		// added by saqqdy on 20190925
		checkStrictly: {
			type: [Boolean, String],
			default: false // 'checkParent'
		},
		expandLevel: {
			type: Number,
			default: -1,
			required: false,
			validator: val => /^-?\d+$/.test(val)
		}
	},
	data() {
		return {
			visible: false,
			treeLoaded: false,
			checked: [],
			treeData: [],
			keyword: null,
			selection: [],
			defaultExpandedKeys: []
		}
	},
	computed: {
		treeEmptyText() {
			if (this.treeLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.keyword = ''
				this.init()
			}
		},
		keyword: {
			handler(nV, oV) {
				if (oV !== null) this.$refs.tree.filter(nV)
			},
			immediate: false
		}
	},
	created() {},
	methods: {
		init() {
			if (this.currentSelection) {
				const checked = []
				this.currentSelection.forEach(item => {
					checked.push(item.id)
				})
				this.checked = checked
				this.selection = [].concat(this.currentSelection)
			}
			this.getTreeData()
		},
		// 获取默认展开的key
		getExpandKeys(list, level = 2) {
			if (level > 0 && list.length > 0) {
				list.forEach(item => {
					this.defaultExpandedKeys.push(item.id)
					if (
						level - 1 > 0 &&
						item[this.treeProps.children].length > 0
					) {
						this.getExpandKeys(
							item[this.treeProps.children],
							level - 1
						)
					}
				})
			}
		},
		getTreeData() {
			this.treeLoaded = false
			this.$axios({
				url: this.treeUrl,
				data: this.treeFilter
			}).then(res => {
				this.treeLoaded = true
				let data = []
				this.treeLoaded = true
				if (
					this.cleanTreeData &&
					typeof this.cleanTreeData == 'function'
				) {
					data = this.cleanTreeData(res.data)
				} else {
					data = [].concat(res.data)
				}
				this.treeData = data
				this.expandLevel !== -1 &&
					this.getExpandKeys(data, this.expandLevel)
				if (this.selection.length > 0 && !this.multi) {
					this.$nextTick(() => {
						this.$refs.tree.setCurrentKey(
							this.selection[0][this.nodeKey]
						)
					})
				}
			})
		},
		filterNode(value, data) {
			if (!value) {
				return true
			}
			value = value.toLocaleUpperCase()
			return data[this.treeProps.label]
				.toLocaleUpperCase()
				.includes(value)
		},
		checkChange() {
			if (this.multi) {
				this.selection = this.$refs.tree.getCheckedNodes(this.leafOnly)
			}
		},
		nodeClick(data) {
			if (!this.multi) {
				this.selection = [].concat(data)
			}
		},
		save() {
			let selection = this.selection
			if (this.multi) {
				selection = this.$refs.tree.getCheckedNodes(this.leafOnly)
			}
			if (!this.onOk || typeof this.onOk != 'function') {
				this.visible = false
				return
			}
			this.onOk(selection, () => {
				this.visible = false
			})
		},
		cancel() {
			this.visible = false
			typeof this.onCancel == 'function' && this.onCancel()
		}
	}
}
</script>
