<template>
	<div v-if="visible" class="wy-progress row align-center justify-center">
		<div v-if="showProgress" class="wy-progress_cont">
			<div class="wy-title">{{ title }}</div>
			<div class="wy-loader-gif row justify-center">
				<img
					:src="require('../../src/img/loading-1.gif')"
					alt="加载中"
				/>
			</div>
			<p class="text-center wy-progress_tip">执行中请稍后</p>
			<div class="wy-progress__cont">
				<el-progress
					:percentage="percentage"
					status="success"
				></el-progress>
				<p class="text-center">{{ progress }}/{{ max }}</p>
			</div>
			<!--<el-progress type="circle" :percentage="percentage"></el-progress>-->
		</div>
	</div>
</template>
<script>
export default {
	props: {
		keyVal: {
			type: String
		},
		showProgress: {
			type: Boolean,
			default: true
		},
		sendTime: {
			type: Number,
			default: 2000
		},
		title: {
			type: String,
			default: '处理进度'
		}
	},
	data() {
		return {
			percentage: 0,
			timer: null,
			visible: false,
			progress: 0,
			max: 0
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.init()
			}
		}
	},
	created() {},
	methods: {
		init() {
			this.progress = 0
			this.max = 0
			this.percentage = 0
			this.$nextTick(() => {
				if (!this.showProgress) {
					this.$loader.show('正在处理文件，请稍后...')
				}
				const _this = this
				this.timer = setInterval(() => {
					this.$loader.hide()
					this.getProgress(this.keyVal, function (data) {
						setTimeout(() => {
							_this.visible = false
							_this.$loader.hide()
							_this.onSuccess(data)
						}, 1000)
					})
				}, this.sendTime)
			})
		},
		getProgress(keyVal, callback) {
			if (!this.showProgress) {
				this.$loader.show('正在处理文件，请稍后...')
			}
			this.$axios({
				url: '/progress',
				data: {
					key: keyVal
				}
			}).then(res => {
				this.progress = res.data.progress
				this.max = res.data.max
				this.percentage = (res.data.progress / res.data.max) * 100
				if (res.data.status == 'SUCCESS') {
					this.$loader.hide()
					this.timer && clearTimeout(this.timer)
					callback(res.data)
				} else if (res.data.status == 'FAILURE') {
					this.visible = false
					clearTimeout(this.timer)
					this.$message({
						type: 'warning',
						message: '处理失败'
					})
				}
			})
		}
	}
}
</script>
