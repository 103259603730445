<template>
	<span
		class="wy-checkbox"
		:class="{ disabled: disabled, checked: isChecked }"
		@click="handleClick"
	>
		<i
			class="iconfont"
			:class="{
				'icon-checkbox-normal': !isChecked,
				'icon-checkbox-checked': isChecked
			}"
		></i>
		<slot></slot>
	</span>
</template>
<script>
export default {
	name: 'WyCheckbox',
	props: {
		// ？
		value: {
			type: Array,
			default() {
				return []
			}
		},
		// 禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 已选
		checked: {
			type: Boolean,
			default: false
		},
		// 选项值
		label: {
			type: String
		}
	},
	computed: {
		isChecked() {
			return this.checked || this.value.includes(this.label)
		}
	},
	watch: {
		value(value) {
			this.$dispatch('wyFormItem', 'wy.form.change', value)
		}
	},
	created() {},
	methods: {
		handleClick() {
			if (this.disabled) {
				return
			}
			const val = [].concat(this.value)
			const index = val.indexOf(this.label)
			if (index == -1 && this.label) {
				val.push(this.label)
			} else {
				val.splice(index, 1)
			}
			this.$emit('input', val)
		}
	}
}
</script>
