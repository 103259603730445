const Progress = {}
import ProgressPlugin from './progress.js'

/* istanbul ignore next */
Progress.install = function (Vue) {
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$progress = Vue.$progress = ProgressPlugin
}

export default Progress
