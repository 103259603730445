var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "wy-progress-v2 row align-center justify-center" },
        [
          _vm.showProgress
            ? _c("div", { staticClass: "wy-progress-v2_cont" }, [
                _c("div", { staticClass: "wy-progress-v2_title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("div", { staticClass: "wy-loader-gif row justify-center" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../src/img/loading-1.gif"),
                      alt: "加载中",
                    },
                  }),
                ]),
                _c("p", { staticClass: "text-center wy-progress-v2_tip" }, [
                  _vm._v(_vm._s(_vm.message)),
                ]),
                _c(
                  "div",
                  { staticClass: "wy-progress-v2__progress" },
                  [
                    _c("el-progress", {
                      attrs: { percentage: _vm.percentage, status: "success" },
                    }),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.percentage) + "/100%"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }