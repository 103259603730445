var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-date-picker" },
    [
      _c(
        "el-date-picker",
        _vm._b(
          {
            ref: "start",
            class: _vm.$attrs.className || "wy-border-bottom",
            attrs: {
              "picker-options": _vm.pickerOptionsStart,
              placeholder: _vm.startPlaceholder,
              type: _vm.dateConfig.type,
              format: _vm.dateConfig.fmt,
            },
            on: { change: _vm.change, blur: _vm.blur, focus: _vm.focus },
            model: {
              value: _vm.start,
              callback: function ($$v) {
                _vm.start = $$v
              },
              expression: "start",
            },
          },
          "el-date-picker",
          _vm.$attrs,
          false
        )
      ),
      _vm._v("\n\t" + _vm._s(_vm.rangeSeparator) + "\n\t"),
      _c(
        "el-date-picker",
        _vm._b(
          {
            ref: "end",
            class: _vm.$attrs.className || "wy-border-bottom",
            attrs: {
              "picker-options": _vm.pickerOptionsEnd,
              placeholder: _vm.endPlaceholder,
              type: _vm.dateConfig.type,
              format: _vm.dateConfig.fmt,
            },
            on: { change: _vm.change, blur: _vm.blur, focus: _vm.focus },
            model: {
              value: _vm.end,
              callback: function ($$v) {
                _vm.end = $$v
              },
              expression: "end",
            },
          },
          "el-date-picker",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }