<template>
	<wy-box
		:title="title"
		:visible="visible"
		class="wy-treeAndTable-box"
		width="960px"
		height="400px"
		@onOk="save"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div slot="title" class="wy-treeAndTable-box_header">
			<h3>{{ title }}</h3>
			<el-input
				v-model="keyword"
				class="wy-border-bottom"
				:placeholder="placeholder"
				prefix-icon="el-icon-search"
				clearable
			></el-input>
		</div>
		<tree-and-table
			ref="treeAndTable"
			:tree-url="treeUrl"
			:table-url="tableUrl"
			:table-list="tableList"
			:current-selection="currentSelection"
			:keyword="keyword"
			:multi="multi"
			:tree-filter="treeFilter"
			:table-filter="tableFilter"
			:table-key="tableKey"
			:clean-tree-data="cleanTreeData"
			:clean-table-data="cleanTableData"
			:table-key-name="tableKeyName"
			:reserve-selection="reserveSelection"
			:define-i-d="defineID"
			:type="type"
			:lazy="lazy"
			:define-lazy-i-d="defineLazyID"
			:level-name="levelName"
			:limit-levels="limitLevels"
			:tree-key="treeKey"
			:show-node-all="showNodeAll"
			:show-clear="showClear"
			:selectable="selectable"
			:table-row-click="tableRowClick"
		></tree-and-table>
	</wy-box>
</template>
<script>
import treeAndTable from '../tree-and-table'
import mixin from '../tree-and-table/mixins'
export default {
	name: 'WyTreeAndTableBox',
	components: {
		treeAndTable
	},
	mixins: [mixin],
	props: {
		title: String
	},
	data() {
		return {
			visible: false,
			keyword: null
		}
	},
	computed: {},
	watch: {},
	created() {
		this.keyword = null
	},
	mounted() {},
	methods: {
		save() {
			this.keyword = null
			const selection = this.$refs.treeAndTable.getSelection()
			if (!this.onOk || typeof this.onOk != 'function') {
				this.visible = false
				return
			}
			this.onOk(selection, () => {
				this.visible = false
			})
		},
		cancel() {
			this.visible = false
			typeof this.onCancel == 'function' && this.onCancel()
			this.keyword = null
		}
	}
}
</script>
