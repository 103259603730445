<template>
	<iframe
		class="wy-iframe"
		:src="curUrl"
		frameborder="0"
		sandbox="allow-same-origin allow-forms allow-scripts allow-popups"
		width="100%"
		height="100%"
		@load="loaded"
	>
	</iframe>
</template>
<script>
export default {
	name: 'JspFrame',
	props: {
		url: String
	},
	data() {
		return {
			isDev: process.env.NODE_ENV === 'development'
		}
	},
	computed: {
		curUrl() {
			if (this.isDev) {
				return `https://wy.wojiayun.cn/thirdUser/auth2?access_token=${this.$store.state.token}&moduleType=${this.url}&frame=vue`
			} else {
				return `/jsp/thirdUser/auth2?access_token=${this.$store.state.token}&moduleType=${this.url}&frame=vue`
			}
		}
	},
	created() {
		this.$loader.show()
	},
	methods: {
		loaded() {
			this.$loader.hide()
		}
	}
}
</script>
