import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from '../wy-tree-box/index.vue'

let instance

/**
 * @param options
 */
export default function (options) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}
	for (const i in options) {
		instance[i] = options[i]
	}
	instance.zIndex = nextIndex(5000, 10000)
	if (options.type === 'orgProject') {
		instance.treeUrl = '/orgUnits/getAllOrgUnitV2'
		instance.leafOnly = true
		instance.cleanTreeData = data => {
			return [].concat(data)
		}
	} else if (options.type === 'authOrgProject') {
		instance.treeUrl = '/projects/getOrgUnitsTreeByEmpId'
		instance.leafOnly = false
		instance.cleanTreeData = data => {
			return [].concat(data)
		}
	} else if (options.type === 'org') {
		// 组织
		instance.treeUrl = '/orgUnits/getAllOrgUnitByVue'
		instance.leafOnly = false
		instance.cleanTreeData = data => {
			return [].concat(data)
		}
	}
	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyTreeBox',
		id: instance._uid,
		instance
	})

	return instance
}
