import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from '../wy-project-box/index.vue'

const defaultOpt = {
	width: '800px',
	height: '400px',
	auto: false,
	multi: false,
	treeProps: null,
	cleanTreeData: Function,
	onCancel: Function,
	onOk: Function
}

let instance

/**
 * @param opt
 */
export default function changeProject(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.value = options.value
	instance.width = options.width
	instance.height = options.height
	instance.auto = options.auto
	instance.treeProps = options.treeProps
	instance.cleanTreeData = options.cleanTreeData
	instance.onCancel = options.onCancel
	instance.onOk = options.onOk
	instance.multi = options.multi

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyChangeProject',
		id: instance._uid,
		instance
	})

	return instance
}
