var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wySplit",
      staticClass: "wy-split-box",
      class: _vm.mode + (_vm.moving ? " moving" : ""),
    },
    [
      _vm._t("default", [
        _c(
          "div",
          {
            staticClass: "wy-split-panel wy-split-panel-1",
            style: _vm.panelStype,
          },
          [_vm.mode === "horizontal" ? _vm._t("left") : _vm._t("top")],
          2
        ),
        (_vm.mode === "horizontal" && _vm.$slots.left && _vm.$slots.right) ||
        (_vm.mode === "vertical" && _vm.$slots.top && _vm.$slots.bottom)
          ? _c(
              "div",
              {
                staticClass: "wy-split-bar",
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    return _vm.mousedown($event)
                  },
                },
              },
              [_c("span"), _c("span"), _c("span"), _c("span")]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "wy-split-panel wy-split-panel-2" },
          [_vm.mode === "horizontal" ? _vm._t("right") : _vm._t("bottom")],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }