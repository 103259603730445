<template>
	<form class="wy-form" onsubmit="return false;" :rules="rules">
		<slot></slot>
	</form>
</template>
<script>
export default {
	name: 'WyForm',
	provide() {
		return {
			labelWidth: this.labelWidth,
			labelHeight: this.labelHeight,
			vertical: this.vertical,
			rules: this.rules,
			validateStatus: false,
			formSize: this.size
		}
	},
	props: {
		// 整个表单对象
		model: {
			type: Object
		},
		// 上下结构
		vertical: {
			type: Boolean
		},
		disabled: {
			type: Boolean
		},
		// label 宽度
		labelWidth: {
			type: String
		},
		// 表单验证的规则
		rules: {
			type: Object
		},
		// 表格大小
		size: {
			type: String,
			default: 'big',
			validator: value => ['big', 'normal', 'mini'].includes(value)
		}
	},
	data() {
		return {
			fields: []
		}
	},
	created() {
		this.$on('wy.form.addField', field => {
			if (field) {
				this.fields.push(field)
			}
		})
		this.$on('wy.form.removeField', field => {
			if (field.name) {
				this.fields.splice(this.fields.indexOf(field), 1)
			}
		})
	},
	methods: {
		submit(callback) {
			let count = 0
			// 如果需要验证的fields为空，调用验证时立刻返回callback
			if (this.fields.length === 0 && callback) {
				callback()
				return
			}
			this.fields.forEach(field => {
				field.validate(null, this.model[field.ruleName], status => {
					if (status) {
						count++
					}
					if (count == this.fields.length) {
						callback()
					}
				})
			})
		}
	}
}
</script>
