var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "wy-parabola",
          class: "wy-parabola-" + _vm.direction,
          style: _vm.style,
        },
        [
          _c(
            "div",
            {
              ref: "content",
              staticClass: "wy-parabola-content",
              style: _vm.contentStyle,
            },
            [_vm._t("default")],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }