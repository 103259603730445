<template>
	<div class="wy-layout-page-tab">
		<div class="wy-layout-page-tab_container">
			<div class="wy-layout-page-tab_btn-panel">
				<slot name="btn-group"></slot>
			</div>
			<slot name="tab"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WyLayoutPageTab'
}
</script>
