import UploadFile from './upload-file.vue'
import UploadFilePlugin from './upload-file.js'

/* istanbul ignore next */
UploadFile.install = function (Vue) {
	Vue.component(UploadFile.name, UploadFile)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$uploadFile = Vue.$uploadFile = UploadFilePlugin
}

export default UploadFile
