var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wy-form-item",
      class: [
        _vm.vertical ? "wy-form-item_vertical" : "wy-form-item_default",
        _vm.formSize ? "wy-form-item_" + _vm.formSize : "",
        { required: _vm.required },
      ],
      style: _vm.setStyle,
    },
    [
      _vm.label
        ? _c("label", {
            staticClass: "wy-form-item_label",
            style: _vm.setLabelStyle,
            domProps: { textContent: _vm._s(_vm.label) },
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.message
        ? _c("div", {
            staticClass: "wy-form-item_error",
            style: _vm.setErrorStyle,
            domProps: { textContent: _vm._s(_vm.message) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }