var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "wy-project-box", style: { "z-index": _vm.zIndex } },
        [
          _c("wy-project", {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              auto: _vm.auto,
              multi: _vm.multiSet,
              "enabled-multi-page": _vm.enabledMultiPage,
              props: _vm.treeProps,
              "clean-tree-data": _vm.cleanTreeData,
            },
            on: {
              submit: function ($event) {
                return _vm.submit($event)
              },
              cancel: _vm.cancel,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }