<template>
	<div class="wy-date-picker">
		<el-date-picker
			v-bind="$attrs"
			ref="start"
			v-model="start"
			:class="$attrs.className || 'wy-border-bottom'"
			:picker-options="pickerOptionsStart"
			:placeholder="startPlaceholder"
			:type="dateConfig.type"
			:format="dateConfig.fmt"
			@change="change"
			@blur="blur"
			@focus="focus"
		></el-date-picker>
		{{ rangeSeparator }}
		<el-date-picker
			v-bind="$attrs"
			ref="end"
			v-model="end"
			:class="$attrs.className || 'wy-border-bottom'"
			:picker-options="pickerOptionsEnd"
			:placeholder="endPlaceholder"
			:type="dateConfig.type"
			:format="dateConfig.fmt"
			@change="change"
			@blur="blur"
			@focus="focus"
		></el-date-picker>
	</div>
</template>
<script>
import { extend } from 'tool'
import dayjs from 'dayjs'

export default {
	name: 'WyDatePicker',
	inheritAttrs: false,
	props: {
		pickerOptions: {
			type: Object,
			default: () => ({
				disabledDate() {
					return false
				}
			})
		},
		value: {
			type: [Array],
			default: () => ['', '']
		},
		format: {
			type: String
		},
		rangeSeparator: {
			type: String,
			default: '至'
		},
		type: {
			type: String,
			default: 'daterange'
		},
		startPlaceholder: {
			type: String,
			default: '请选择'
		},
		endPlaceholder: {
			type: String,
			default: '请选择'
		}
	},
	data() {
		return {
			start: this.value && this.value.length > 0 ? this.value[0] : '',
			end: this.value && this.value.length > 1 ? this.value[1] : ''
		}
	},
	computed: {
		dateConfig() {
			if (this.type === 'yearrange')
				return { fmt: this.format || 'yyyy', type: 'year' }
			if (this.type === 'monthrange')
				return { fmt: this.format || 'yyyy-MM', type: 'month' }
			return { fmt: this.format || 'yyyy-MM-dd', type: 'date' }
		},
		pickerOptionsStart() {
			const check = this.pickerOptions
			const end = Number(dayjs(this.end).format('YYYYMMDD'))
			const date = [this.start, this.end]
			return {
				disabledDate(time) {
					const t = Number(dayjs(time).format('YYYYMMDD'))
					return (
						(check && check.disabledDate(time, date, 'start')) ||
						(end && t > end)
					)
				}
			}
		},
		pickerOptionsEnd() {
			const check = this.pickerOptions
			const start = Number(dayjs(this.start).format('YYYYMMDD'))
			const date = [this.start, this.end]
			return {
				disabledDate(time) {
					const t = Number(dayjs(time).format('YYYYMMDD'))
					return (
						(check && check.disabledDate(time, date, 'end')) ||
						(start && t < start)
					)
				}
			}
		},
		listeners() {
			const o = extend(true, {}, this.$listeners)
			delete o.change
			return o
		}
	},
	watch: {
		value(nv) {
			this.start = nv ? nv[0] : ''
			this.end = nv ? nv[1] : ''
		},
		start(nv) {
			if (nv && !this.end) this.$refs.end.focus()
			this.$emit('input', nv ? [nv, this.end] : null)
		},
		end(nv) {
			this.$emit('input', nv ? [this.start, nv] : null)
		}
	},
	created() {},
	mounted() {},
	methods: {
		change() {
			this.$emit(
				'change',
				this.start || this.end ? [this.start, this.end] : null
			)
		},
		blur(data) {
			this.$emit('blur', data)
		},
		focus(data) {
			this.$emit('focus', data)
		}
	}
}
</script>
