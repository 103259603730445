import { extend, isArray } from 'tool'
export default {
	data() {
		return {
			queryOldForm: {},
			queryForm: {},
			queryWord: {},
			queryOldWord: {},
			queryMap: {},
			curProject: {},
			keyword: null,
			panelStatus: false,
			relationMap: {},
			useWyDatePicker: ['daterange', 'monthrange', 'yearrange']
		}
	},
	computed: {
		keywordPlaceholder() {
			return this.placeholder || '请输入关键词'
		},
		getQueryWord() {
			const word = []
			for (const key in this.table.query) {
				if (this.table.query[key]) {
					word.push({
						id: key,
						name: this.queryMap[key].name,
						value: this.queryOldWord[key]
					})
				}
			}
			return word
		}
	},
	watch: {
		keyword() {
			this.$delay.register('designReport', this.changeKeyWord, 500)
		},
		queryForm: {
			handler(newValue) {
				this.$emit('changeQueryForm', newValue)
			},
			deep: true
		}
	},
	methods: {
		initQuery() {
			const query = this.query
			const relationMap = {}
			const word = {}
			const form = {}
			const rules = {}
			query.forEach(item => {
				// 设置查询map
				this.queryMap[item.field] = item
				// 设置关联字段关系
				if (item.relation) {
					// 如果当前字段有关联关系
					item.relation.forEach(relation => {
						if (!relationMap[relation]) {
							relationMap[relation] = [].concat(item.field)
						} else {
							relationMap[relation].push(item.field)
						}
					})
				}
				if (item.required) {
					// 必填
					// 校验wy-date-picker日期需要特殊处理
					if (this.useWyDatePicker.includes(item.config.type)) {
						rules[item.field] = [].concat({
							required: true,
							validator: (rule, val, cb) => {
								if (
									rule.required &&
									(!val || !val[0] || !val[1])
								) {
									cb(new Error(rule.message))
								} else {
									cb()
								}
							},
							message: item.name + '为必填项'
						})
					} else {
						rules[item.field] = [].concat({
							required: true,
							message: item.name + '为必填项'
						})
					}
				} else if (
					item.type === 'date' &&
					this.useWyDatePicker.includes(item.config.type)
				) {
					rules[item.field] = [].concat({
						required: false,
						validator: (rule, val, cb) => {
							if (val && (!val[0] || !val[1])) {
								cb(new Error(rule.message))
							} else {
								cb()
							}
						},
						message: '请填写完整日期区间'
					})
				}
				// 如果传入自定义的rule，合并进去
				if (item.rule) {
					if (!rules[item.field]) rules[item.field] = []
					rules[item.field].push({
						validator: item.rule
					})
				}
				if (item.value !== null) {
					form[item.field] = item.value
					word[item.field] = item.value
				} else {
					form[item.field] = null
					word[item.field] = null
				}
				if (item.type === 'select' && item.config.type === 0) {
					// 下拉框固定值
					this.$set(
						this.queryMap[item.field],
						'list',
						item.config.list
					)
				}
			})
			this.relationMap = relationMap
			this.queryForm = form
			this.queryWord = word
			this.rules = rules
		},
		getSelectValue(option, item) {
			if (item.config.fieldValue) {
				return option[item.config.fieldValue]
			} else {
				return option.value
			}
		},
		getSelectName(option, item) {
			if (item.config.fieldName) {
				return option[item.config.fieldName]
			} else {
				return option.name
			}
		},
		handleChangeProject() {
			// 选择项目
			this.$projectTreeBox({
				title: '选择项目',
				type: 'orgProject',
				currentSelection: this.curProject.selection,
				multi: true,
				onOk: (data, cb) => {
					if (data.selection.length === 0) {
						return
					}
					this.curProject = data
					this.table.project = data.id
					this.setQueryItemVisible(data.selection.length)
					this.handleChange('project')
					this.changeQuery()
					cb()
				}
			})
		},
		setQueryItemVisible(length) {
			// 设置多项目隐藏查询条件
			let visible = true
			if (length > 1) {
				// 多项目
				visible = false
			}
			for (const i in this.queryMap) {
				const item = this.queryMap[i]
				if (item.hideByProjects) {
					this.changeField(item.field, { visible })
				}
			}
		},
		changeKeyWord() {
			// 关键词查询
			if (this.keyword) {
				this.table.keyword = encodeURIComponent(this.keyword)
			} else {
				this.table.keyword = null
			}
			this.table.current = 1
			this.$refs.designTable.reloadKeyword(this.keyword)
		},
		showPanel(status) {
			if (typeof status === 'boolean') {
				this.panelStatus = status
			} else {
				this.panelStatus = !this.panelStatus
			}
		},
		selectVisibleChange(item, status) {
			const list = this.queryMap[item.field].list
			const relation = this.queryMap[item.field].relation
			const query = {
				project: this.table.project
			}
			if (
				status &&
				(!list || list.length === 0) &&
				item.config.type === 1
			) {
				// 下拉框显示时，下拉列表选项为空，且为动态取值时
				if (relation) {
					relation.forEach(item => {
						if (item === 'project') {
							query[item] = this.table.project
						} else if (isArray(this.queryForm[item])) {
							query[item] = this.queryForm[item].join(',')
						} else {
							query[item] = this.queryForm[item]
						}
					})
				}
				this.$axios({
					url: item.config.url,
					data: query
				}).then(res => {
					this.$set(this.queryMap[item.field], 'list', res.data)
				})
			}
			// 下拉框关闭时， 触发onChange事件
			if (
				!status &&
				item.onChange &&
				typeof item.onChange == 'function'
			) {
				item.onChange(this.queryForm[item.field])
			}
		},
		setDateFormat(type) {
			if (['date', 'dates', 'daterange'].includes(type)) {
				return 'yyyy-MM-dd'
			} else if (['month', 'monthrange'].includes(type)) {
				return 'yyyy-MM'
			} else if (['year', 'yearrange'].includes(type)) {
				return 'yyyy'
			} else if (['datetime', 'datetimerange'].includes(type)) {
				return 'yyyy-MM-dd HH:mm:ss'
			}
		},
		saveQuery(form) {
			const query = {}
			for (const i in form) {
				if (isArray(form[i])) {
					query[i] = form[i].join(',')
				} else if (form[i]) {
					query[i] = form[i]
				}
			}
			this.table.query = query
			this.queryOldWord = extend(true, {}, this.queryWord)
			this.queryOldForm = extend(true, {}, this.queryForm)
			this.$refs.designTable.reloadQuery({
				query,
				project: this.table.project
			})
			this.showPanel(false)
		},
		saveQueryWord(form) {
			this.queryWord = form
		},
		cancelQuery() {
			this.queryForm = extend(true, {}, this.queryOldForm)
			this.showPanel(false)
		},
		changeQuery() {
			this.setQueryWord()
			if (this.$slots.query) {
				// 插槽方式加载查询条件
				this.$emit('changeQuery', this.queryForm)
			} else {
				if (this.$refs.form) {
					this.$refs.form.validate(status => {
						if (!status) {
							this.showPanel(true)
							return
						}
						this.saveQuery(this.queryForm)
					})
				} else {
					this.saveQuery(this.queryForm)
				}
			}
		},
		setQueryWord() {
			for (const field in this.queryMap) {
				// 设置选项的文本内容
				// 关联是否需要清空的值

				const set = this.queryMap[field]
				const value = this.queryForm[field]
				// field === project 时，set为null
				if (!set) {
					continue
				}
				// 如果是下拉框
				if (
					set.type === 'select' &&
					set.config.multi &&
					isArray(value)
				) {
					// 如果是多选下拉框
					const result = []
					value.forEach(id => {
						const fieldValue = set.config.fieldValue || 'value'
						const fieldName = set.config.fieldName || 'name'
						const cur = this.queryMap[field].list.find(
							p => p[fieldValue] === id
						)
						result.push(cur[fieldName])
					})
					this.queryWord[field] = result.join(',')
				} else if (
					set.type === 'select' &&
					!set.config.multi &&
					value
				) {
					// 如果是单选下拉框
					const fieldValue = set.config.fieldValue || 'value'
					const fieldName = set.config.fieldName || 'name'
					const cur = this.queryMap[field].list.find(
						p => p[fieldValue] === value
					)
					this.queryWord[field] = cur[fieldName]
				} else if (set.type === 'date' && isArray(value)) {
					// 日期选择框区间
					this.queryWord[field] = value.join('~')
				} else if (set.type !== 'box') {
					this.queryWord[field] = value
				}
			}
		},
		changeField(field, map) {
			for (const i in map) {
				if (map.hasOwnProperty(i)) {
					if (i === 'visible') {
						// 显示隐藏
						// this.queryMap[field].hide = !map[i];
						this.$set(this.queryMap[field], 'hide', !map[i])
						this.queryForm[field] = null
						this.queryWord[field] = null
						this.queryMap[field].list = []
					} else if (i === 'value') {
						// 改变值
						this.queryForm[field] = map[i]
						this.queryWord[field] = map.word || map[i]
					} else if (i === 'list') {
						this.queryMap[field].list = []
						this.queryForm[field] = null
						this.queryWord[field] = null
					}
				}
			}
		},
		handleChange(field, type) {
			// 值选择发生变化时
			// 设置选项的文本内容
			// 关联是否需要清空的值
			if (this.relationMap[field]) {
				// 如果有受影响的字段

				this.relationMap[field].forEach(item => {
					// 清空表单对应的值
					this.queryForm[item] = null
					this.queryWord[item] = null
					if (
						this.queryMap[item].type === 'select' &&
						this.queryMap[item].config.type === 1
					) {
						// 如果是下拉框，且动态取值的，重新赋值为空，下次点击重新获取
						this.$set(this.queryMap[item], 'list', [])
					}
				})
			}
			if (type === 'common') {
				this.changeQuery()
			}
		},
		closeTag(data) {
			// 关闭筛选
			this.changeField(data.id, { value: null })
			this.changeQuery()
		},
		getCurrentData() {
			// 获取当前所有信息，extend避免直接修改数据
			return extend(true, {}, this.table)
		},
		getCurrentProject() {
			// 获取当前所选项目
			return this.curProject
		},
		resetQuery(arr) {
			// 重设查询
			arr.forEach(item => {
				if (this.queryForm[item.key]) {
					this.queryForm[item.key] = item.value
					this.queryWord[item.key] = item.word
				}
			})
			this.changeQuery()
		}
	}
}
