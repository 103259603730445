<template>
	<transition name="fadein">
		<div
			v-if="visible"
			class="wy-mask"
			:style="{
				'z-index': zIndex,
				backgroundColor: 'rgba(' + rgbColor + ',' + opacity + ')'
			}"
			@click="handleClick"
		></div>
	</transition>
</template>
<script>
export default {
	name: 'WyMask',
	props: {
		opacity: {
			type: Number,
			default: 0.4
		},
		color: {
			type: String,
			default: '#000000'
		},
		container: String,
		onClick: [Function, Promise]
	},
	data() {
		return {
			num: 0,
			content: null,
			zIndex: 10000,
			visible: false
		}
	},
	computed: {
		rgbColor() {
			let color = this.color.toLowerCase(),
				reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
			if (color && reg.test(color)) {
				const colorChange = []
				if (color.length === 4) {
					let colorNew = '#'
					for (let i = 1; i < 4; i += 1) {
						colorNew += color
							.slice(i, i + 1)
							.concat(color.slice(i, i + 1))
					}
					color = colorNew
				}
				for (let i = 1; i < 7; i += 2) {
					colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
				}
				return colorChange.join(',')
			}
			return '0,0,0'
		}
	},
	watch: {},
	methods: {
		async handleClick() {
			if (typeof this.onClick === 'function') {
				this.visible = !this.onClick()
			} else if (this.onClick instanceof Promise) {
				this.visible = !(await this.onClick())
			} else {
				this.visible = false
				typeof this.instance.$destroy === 'function' &&
					this.instance.$destroy()
			}
		}
	}
}
</script>
