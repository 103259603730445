var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticClass: "wy-iframe",
    attrs: {
      src: _vm.curUrl,
      frameborder: "0",
      sandbox: "allow-same-origin allow-forms allow-scripts allow-popups",
      width: "100%",
      height: "100%",
    },
    on: { load: _vm.loaded },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }