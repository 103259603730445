import { openFile } from 'tool'
import Vue from 'vue'

/**
 * 获取权限
 *
 * @module Common
 * @exports {Function} getPermission
 * @param {string} name -页面主权限，例如页面查看，页面修改，页面新增
 * @param {Array} opts   -页面操作权限，例如新增按钮，修改按钮
 * @param {boolean} cb -回调， 支持回调和promise2种方式
 * @returns {Promise} promise
 */
export function getPermission(name, opts = [], cb) {
	return new Promise(resolve => {
		if (name && !opts.includes(name)) {
			opts.push(name)
		}
		this.$axios({
			url: '/menus/getPermissions',
			type: 'get',
			data: {
				opts: opts.join(',')
			},
			loading: true,
			error: true
		})
			.then(res => {
				if (!res.data[name] && name) {
					if (this instanceof Vue) {
						this.$closeUrl({
							url: '/noPermission'
						})
					}
					return
				}
				if (cb && typeof cb == 'function') {
					cb(res.data)
				}
				resolve(res.data)
			})
			.catch(() => {
				if (this instanceof Vue) {
					this.$closeUrl({
						url: '/noPermission'
					})
				}
			})
	})
}

/**
 * 获取用户配置
 *
 * @module Common
 * @exports {Function} userConfig
 * @param {string} type -get：获取，post：保存
 * @param {string} configType   -业务类型
 * @param {string} configKey -配置类型
 * @param {string} configValue -配置内容，如果是对象类型需转为JSON字符串
 * @param {string} configContent -配置内容，大文本，如果是对象类型需转为JSON字符串
 * @param {string} projectID -projectID
 * @returns {Promise} promise
 */
export function userConfig({
	type,
	configType,
	configKey,
	configValue,
	configContent,
	projectID
}) {
	return new Promise((resolve, reject) => {
		let url,
			data = {
				configType,
				configKey,
				configValue,
				configContent
			}
		// 只支持单项目
		if (projectID && !projectID.includes(',')) {
			data.projectID = projectID
		}
		if (typeof configValue === 'object') {
			reject('configValue is object!')
			return
		}
		if (type === 'get') {
			// 获取
			url = '/users/config/getConfig'
		} else if (type === 'post') {
			// 保存
			url = '/users/config/save'
		} else if (type === 'delete') {
			// 删除
			url = '/users/config/save'
		}
		this.$axios({
			url,
			type,
			data,
			unique: false
		}).then(res => {
			try {
				resolve(JSON.parse(res.data[configKey].configContent))
			} catch (e) {
				resolve()
			}
		})
	})
}

/**
 * 导出文件
 *
 * @module Common
 * @exports {Function} exportFile
 * @description 使用post导出文件，先提交参数，返回临时文件地址
 * @param {string} url -接口请求地址
 * @param {Array} params   -页面查询参数
 * @returns {Promise} promise
 */
export function exportFile(url, params) {
	return new Promise(resolve => {
		for (const i in params) {
			if (typeof params[i] === 'object') {
				params[i] = JSON.stringify(params[i])
			}
		}
		this.$axios({
			url,
			type: 'post',
			data: params,
			cancelPending: true,
			loading: true
		}).then(res => {
			openFile(res.data)
			resolve(res.data)
		})
	})
}

/**
 * 获取公共参数
 *
 * @module Common
 * @exports {Function} getPublicParams
 * @param projectID
 * @param {Array} opts   -参数的数组
 * @param {boolean} cb -回调， 支持回调和promise2种方式
 * @returns {Promise} promise
 */
export function getPublicParams(opts = [], projectID) {
	return new Promise((resolve, reject) => {
		this.$axios({
			url: '/menus/getParameterValueByOpts',
			type: 'get',
			data: {
				opts: opts.join(','),
				projectID
			},
			loading: true,
			error: true
		})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

/**
 * 获取常用配置
 *
 * @module Common
 * @exports {Function} getCommonParams
 * @param {Array} opts   -企业级参数是以逗号分割
 * @param {boolean} cb -回调， 支持回调和promise2种方式
 * @returns {Promise} promise
 */
export function getCommonParams(opts = []) {
	return new Promise((resolve, reject) => {
		this.$axios({
			url: '/menus/getEcIDParameterValueByOpts',
			type: 'get',
			data: {
				opts: opts.join(',')
			},
			loading: true,
			error: true
		})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

/**
 * 获取审批流配置
 *
 * @module Common
 * @exports {Function} getFlowSet
 * @param {Array} opts   -参数的数组
 * @param {boolean} cb -回调， 支持回调和promise2种方式
 * @returns {Promise} promise
 */
export function getFlowSet(opts = []) {
	return new Promise((resolve, reject) => {
		this.$axios({
			url: '/flowSetting/getFlowType',
			type: 'get',
			data: {
				opts: opts.join(',')
			},
			loading: true,
			error: true
		})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 消息提示
/**
 * @param data
 */
export function notify(data) {
	try {
		if (Notification.permission == 'granted') {
			// 浏览器的通知
			const notice = new Notification(data.title, {
				body: data.message,
				icon: 'https://wy.kdwjy.com/favicon.ico'
			})
			if (typeof data.time !== 'number') {
				data.time = 5000
			}
			notice.onclick = () => {
				parent.focus()
				if (data.onClick) {
					data.onClick()
				}
				notice.close()
			}
			setTimeout(() => {
				notice && notice.close()
			}, data.time)
		}
		this.$notify({
			title: data.title,
			message: data.message,
			onClick: data.onClick,
			duration: data.time
		})
	} catch (e) {
		console.warn(e)
	}
}

// 向上
/**
 * @param componentName
 * @param eventName
 * @param params
 */
export function dispatch(componentName, eventName, params) {
	let parent = this.$parent || this.$root,
		name = parent.$options.name
	// 寻找父级，如果父级不是符合的组件名，则循环向上查找
	while (parent && (!name || name !== componentName)) {
		parent = parent.$parent
		if (parent) {
			name = parent.$options.name
		}
	}
	// 找到符合组件名称的父级后，触发其事件。整体流程类似jQuery的closest方法
	if (parent) {
		parent.$emit.apply(parent, [eventName].concat(params))
	}
}

// 向下
/**
 * @param componentName
 * @param eventName
 * @param params
 */
export function broadcast(componentName, eventName, params) {
	broadcastFn.call(this, componentName, eventName, params)
}

/**
 * @param componentName
 * @param eventName
 * @param params
 */
function broadcastFn(componentName, eventName, params) {
	this.$children.forEach(child => {
		const name = child.$options.name
		if (name === componentName) {
			child.$emit.apply(child, [eventName].concat(params))
		} else {
			broadcast.apply(child, [componentName, eventName].concat([params]))
		}
	})
}
