import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from './progress-v2.vue'

const defaultOpt = {
	url: '', // 查询进度所需url
	filter: {},
	limit: 50, // 轮询次数限制
	showProgress: true, // 显示进度条orloading
	cleanData: null, // 数据清理
	success: null, // 查询完成回调
	error: null, // 报错回调
	interval: 2000, // 设置查询间隔 默认2S
	title: '处理进度',
	message: '处理中，请稍后...'
}

let instance

/**
 * @param opt
 */
export default function uploadFile(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.url = options.url
	instance.showProgress = options.showProgress
	instance.title = options.title
	instance.message = options.message
	instance.limit = options.limit
	instance.filter = options.filter
	instance.interval = options.interval
	instance.cleanData = options.cleanData
	instance.success = options.success
	instance.error = options.error

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyProgressV2',
		id: instance._uid,
		instance
	})

	return instance
}
