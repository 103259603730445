export default {
	props: {
		type: String,
		reserveSelection: {
			type: Boolean,
			default: true
		},
		treeUrl: String,
		tableUrl: String,
		tableList: Array,
		cleanTreeData: Function,
		cleanTableData: Function,
		currentSelection: Array,
		placeholder: {
			type: String,
			default: '请输入名称'
		},
		multi: {
			type: Boolean,
			default: true
		},
		treeFilter: Object,
		tableFilter: Object,
		tableKey: {
			type: String,
			default: 'id'
		},
		tableKeyName: {
			type: String,
			default: 'name'
		},
		defineID: {
			type: [String, Array, Object],
			default: 'id'
		},
		// added by saqqdy on 20190911
		treeKey: {
			type: String,
			default: 'id'
		},
		lazy: {
			type: Boolean,
			default: false
		},
		// 加载子集需要传的参数
		defineLazyID: {
			type: [String, Array, Object],
			default() {
				return ['id']
			}
		},
		levelName: {
			type: String,
			default: 'level'
		},
		limitLevels: {
			type: Number,
			default: null
		},
		showNodeAll: {
			type: Boolean,
			default: false
		},
		selectable: Function,
		tableRowClick: Function
	}
}
