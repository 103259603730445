<template>
	<div v-if="ready" class="wy-design-list">
		<div class="wy-design-list_top">
			<div class="wy-design-list_top_title">
				<h1 class="wy-design-list_title" data-guide="design-list-title">
					<el-tooltip content="点击查看功能指引">
						<i
							v-if="intro"
							class="iconfont icon-guide wy-pointer"
							@click="showIntro(true)"
						></i>
					</el-tooltip>
					{{ title }}
				</h1>
				<!--
                  @author su 2019-10-17
                  @description $slots.titleBar 主要用于在标题后显示的插槽，例如自定义tab菜单
                -->
				<div v-if="showChartBtn" class="wy-design-list_title-tab">
					<el-button
						type="text"
						data-guide="design-list-view-1"
						:class="{ grey: viewTab === 2 }"
						@click="changeView(1)"
					>
						<i class="iconfont icon-tab-list"></i>列表视图
					</el-button>
					<span class="span_line">|</span>
					<el-button
						type="text"
						data-guide="design-list-view-2"
						:class="{ grey: viewTab === 1 }"
						@click="changeView(2)"
					>
						<i class="iconfont icon-tab-chart"></i>轻分析视图
					</el-button>
				</div>
				<div v-if="$slots.titleBar" class="wy-design-list_title-bar">
					<slot name="titleBar"></slot>
				</div>
				<div class="wy-design-list_space"></div>
				<div v-show="viewTab === 1" class="wy-design-list_btn-group">
					<!--
                      @author su 2019-10-17
                      @description 从btn配置读取按钮，支持按钮合并更多样式
                    -->
					<template v-for="(item, index) in btnMap">
						<div :key="item.field" class="wy-design-list_btn">
							<el-button
								v-if="!item.children"
								:data-guide="`btn-1-${
									item.event ||
									item.field ||
									item.permission ||
									index
								}`"
								:type="item.type || 'primary'"
								:plain="item.isPlain || false"
								@click="btnClick(item)"
								>{{ item.name }}</el-button
							>
							<el-dropdown
								v-else
								:split-button="!!item.event"
								:type="item.type || 'primary'"
								@command="btnClick"
								@click.stop="btnClick(item.event ? item : null)"
							>
								<span
									v-if="item.event"
									:data-guide="`btn-1-${
										item.event ||
										item.field ||
										item.permission ||
										index
									}`"
									>{{ item.name }}</span
								>
								<el-button
									v-else
									:type="item.type || 'primary'"
									:plain="item.isPlain || false"
									:data-guide="`btn-1-${
										item.field || item.permission || index
									}`"
									>{{ item.name
									}}<i
										class="el-icon-arrow-down el-icon--right"
									></i
								></el-button>
								<el-dropdown-menu slot="dropdown">
									<template
										v-for="(v, subindex) in item.children"
									>
										<el-dropdown-item
											:key="subindex"
											:data-guide="`btn-1-${
												item.event ||
												item.field ||
												item.permission ||
												index
											}-2-${
												v.event ||
												v.field ||
												v.permission ||
												subindex
											}`"
											:props="v.name"
											:command="v"
											>{{ v.name }}</el-dropdown-item
										>
									</template>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</template>
					<slot v-if="$slots.btn" name="btn"></slot>
				</div>
			</div>
			<div
				v-if="
					showProject ||
					query.length > 0 ||
					$slots.query ||
					showKeyword
				"
				class="wy-design-list_filter"
			>
				<!--
                  @author su 2019-10-17
                  @description 项目显示
                -->
				<div class="wy-design-list_project">
					<template v-if="showProject && defaultProject">
						<span>所属项目：</span>
						<el-input
							v-if="enabledProject"
							class="wy-border-bottom wy-pointer"
							placeholder="请选择项目"
							:clearable="projectClearable"
							:title="curProject.name"
							:value="curProject.name"
							read-only
							@click.native="handleChangeProject"
							@clear="handleClear"
						>
							<i
								slot="prefix"
								class="el-input__icon el-icon-search"
							></i>
						</el-input>
						<span v-if="!enabledProject">{{
							curProject.name
						}}</span>
					</template>
				</div>

				<!--
                  @author su 2019-10-17
                  @description 跟在所属项目后的，其他内容显示
                -->
				<div class="wy-design-list_other">
					<slot name="projectBar"></slot>
				</div>

				<!--
                  @author su 2019-10-17
                  @description 关键词查询
                -->
				<el-select
					v-if="
						showKeyword &&
						searchTypeList.length > 0 &&
						viewTab === 1
					"
					v-model="searchType"
					class="wy-border-bottom"
					:style="{ width: searchTypeWidth }"
					@change="changeSearchType"
				>
					<el-option
						v-for="item in searchTypeList"
						:key="item.id"
						:value="item.id"
						:label="item.name"
					></el-option>
				</el-select>

				<!--
                  @author saqqdy 2020-04-25
                  @description 关键词查询
                -->
				<wy-keyword
					v-if="loadSuggest && showKeyword && viewTab === 1"
					ref="keyword"
					v-model="keyword"
					:placeholder="placeholder"
					:disabled="
						(isLoadingData && !keyword.value) ||
						keywordConfig.disabled
					"
					:suggest="suggestHandler"
					:search="searchHandler"
					:auto-select="keywordConfig.autoSelect"
					:use-suggest="useSuggest"
					:use-search="useSearch"
					:use-fuzzy="useFuzzy"
					:search-slot="searchSlot"
					:style="{ width: keywordConfig.width }"
					@input="keywordInput"
					@search="keywordSearch"
					@select="keywordSelect"
				></wy-keyword>

				<!--
                  @author su 2019-10-17
                  @description 高级查询面板显示开关
                -->
				<div
					v-if="!query || query.length > 0"
					class="wy-design-list_query"
					:class="{ active: panelStatus }"
				>
					<el-button type="text" @click="showPanel">
						<span v-if="!panelStatus">更多高级查询</span>
						<span v-if="panelStatus">关闭高级查询</span>
						<i
							class=""
							:class="{
								'el-icon-arrow-right': !panelStatus,
								'el-icon-arrow-down': panelStatus
							}"
						></i>
					</el-button>
				</div>
			</div>

			<!--
              @author su 2019-10-17
              @description 高级查询
            -->
			<DesignListQuery
				v-if="query.length > 0"
				ref="query"
				data-guide="design-list-query"
				:auto="auto"
				:name="name"
				:query="query"
				:project="table.project"
				:query-label-width="queryLabelWidth"
				:visible="panelStatus"
				:use-last-query-schema="useLastQuerySchema"
				:schema-search-immediate="schemaSearchImmediate"
				:default-expend-query-form="defaultExpendQueryForm"
				:is-loading-data="isLoadingData"
				@change="changeQuery"
				@cancel="cancelQuery"
				@clickBox="clickBox"
				@validate="validate"
			></DesignListQuery>
		</div>

		<!--
			@author su 2019-10-17async
			@description 高级查询显示筛选结果
		-->
		<div v-if="queryWord.length > 0" class="wy-design-list_word">
			<el-tag
				v-for="item in queryWord"
				:key="item.id"
				closable
				:title="item.value"
				@close="closeTag(item)"
			>
				{{ item.name }}:{{
					item.value.length > 60
						? item.value.substr(0, 60) + '...'
						: item.value
				}}
			</el-tag>
		</div>

		<!--
			@author su 2019-10-17
			@description $slots.total 显示统计插槽
		-->
		<div v-if="$slots.total && viewTab === 1" class="wy-design-list_total">
			<slot name="total"></slot>
		</div>

		<section
			v-if="tabList && tabList.length"
			class="row wy-design-list_tabs-bar"
		>
			<div
				v-for="tab in tabList"
				:key="tab.id"
				class="col-1 wy-design-list_tab-wrapper"
				:class="{ active: curTab === tab.id }"
				@click="changeTab(tab)"
			>
				<div
					class="wy-design-list_tab-item wy-design-list_tab-item-total"
				>
					<div class="wy-design-list_tab-name">{{ tab.name }}</div>
					<div class="wy-design-list_total-num">{{ tab.value }}</div>
				</div>
			</div>
		</section>

		<!--
			@author su 2019-10-17
			@description design-table 列表
		-->
		<div class="wy-design-main row">
			<wy-split
				v-show="viewTab === 1"
				:value="splitMin"
				:min="splitMin"
				:max="splitMax"
			>
				<div v-if="$slots.left" slot="left">
					<slot name="left"></slot>
				</div>
				<design-table
					slot="right"
					ref="designTable"
					data-guide="design-list-table"
					class="wy-design-table_main col-1"
					:url="url"
					:table-data="tableData"
					:expand-table-data="expandTableData"
					:name="name"
					:show-setting="showSetting"
					:show-index="showIndex"
					:enable-net-field="enableNetField"
					:show-selection="showSelection"
					:show-summary="showSummary"
					:summary-name="summaryName"
					:show-pages="showPages"
					:field="field"
					:expand-filed="expandFiled"
					:query="table.query"
					:project="curProject.id"
					:table-key="tableKey"
					:define-params="defineParams"
					:table-lazy="tableLazy"
					:table-load="tableLoad"
					:table-tree-props="tableTreeProps"
					:keyword-type="searchType"
					:keyword="keywordValue"
					:keyword-i-d="keyword.id"
					:page-sizes="pageSizes"
					:expand-table-width="expandTableWidth"
					:table-row-class-name="tableRowClassName"
					:span-method="spanMethod"
					@loadingChange="loadingChange"
					@updateTableProps="updateTableProps"
				></design-table>
			</wy-split>
			<iframe
				v-show="viewTab === 2"
				class="wy-design-chart-iframe"
				:src="chartViewUrl"
			></iframe>
		</div>
	</div>
</template>
<script>
/**
 * @module	components/design-list
 * @author su 2019-10-17
 * @description 页面模板，高级查询为上下展示式
 */
import { mapState } from 'vuex'
import { extend, isArray } from 'tool'
import DesignListQuery from '../design-list-query/index.vue'
import wySplit from '../split'
import wyKeyword from '../keyword'
import designTable from '../design-table'
export default {
	name: 'DesignList',
	components: {
		DesignListQuery,
		wySplit,
		wyKeyword,
		designTable
	},
	props: {
		// 页面查看的权限字段
		viewPermission: {
			type: String,
			default: ''
		},
		// 页面初始化是否加载table数据
		auto: {
			type: Boolean
		},
		// 页面标题
		title: {
			type: String,
			default() {
				if (this.$route.meta.title) {
					return this.$route.meta.title
				} else {
					return ''
				}
			}
		},
		// 页面指引
		intro: {
			type: Object,
			default: () => null
		},
		// 业务标识，必填，使用页面的路由name传入，作为唯一标示
		name: {
			type: String,
			required: true,
			default() {
				if (this.$route && this.$route.name) {
					return this.$route.name
				}
				return ''
			}
		},
		// 关键词默认显示
		placeholder: {
			type: String,
			default: '请输入关键词'
		},
		// 是否显示多选框
		showSelection: {
			type: Boolean,
			default: true
		},
		// 是否显示序号
		showIndex: {
			type: Boolean,
			default: true
		},
		// 是否显示关键词查询
		showKeyword: {
			type: Boolean
		},
		// 是否显示项目切换，默认显示
		showProject: {
			type: Boolean,
			default: true
		},
		// 是否启用项目切换，默认显示
		enabledProject: {
			type: Boolean,
			default: true
		},
		// 项目切换是否多选，默认多选
		multiProject: {
			type: Boolean,
			default: true
		},
		// 项目可清除
		projectClearable: {
			type: Boolean,
			default: false
		},
		// 是否显示表头设置，默认显示
		showSetting: {
			type: Boolean,
			default: true
		},
		// 是否显示分页，默认显示
		showPages: {
			type: Boolean,
			default: true
		},
		// 是否启用动态网络列表表头
		enableNetField: {
			type: Boolean
		},
		// 是否显示合计行
		showSummary: {
			type: Boolean
		},
		// 合计栏名称
		summaryName: {
			type: String,
			default: '全部合计：'
		},
		// 高级查询列表
		query: {
			type: Array,
			default() {
				return []
			}
		},
		// 额外的查询参数，由业务代入
		defineParams: {
			type: Object,
			default() {
				return {}
			}
		},
		// 高级查询的标题宽度
		queryLabelWidth: {
			type: String,
			default: '120px'
		},
		// 列表页请求地址
		url: {
			type: String,
			default: ''
		},
		// table的数据，可以是对象直接传入，也可以是函数方法返回
		tableData: {
			type: [Object, Function]
		},
		// 是否显示轻分析
		showChart: {
			type: [Boolean, Object],
			default: false
		},
		// 视图默认类型，默认为列表视图
		viewType: {
			type: Number,
			default: 1
		},
		// 列表页字段列表
		field: {
			type: Array,
			default() {
				return []
			}
		},
		// 列表页按钮列表
		btn: {
			type: Array,
			default() {
				return []
			}
		},
		// 列表页的唯一标示
		tableKey: {
			type: String
		},
		// 设置默认项目
		defaultProject: {
			type: [Object, Boolean],
			default: true
		},
		// 列表页搜索类型
		searchTypeList: {
			type: Array,
			default() {
				return []
			}
		},
		// 展开行的数据，可以是对象直接传入，也可以是函数方法返回
		expandTableData: {
			type: [Object, Function]
		},
		// 展开行列表页字段列表
		expandFiled: {
			type: Array,
			default() {
				return []
			}
		},
		// 针对轻分析计算页面总合计
		getTotalData: {
			type: Function
		},
		// 是否只显示轻分析视图
		isQing: {
			type: Boolean,
			default: false
		},
		// 树形数据懒加载方法
		tableLoad: {
			type: Function
		},
		// 是否懒加载
		tableLazy: {
			type: Boolean,
			default: false
		},
		tableTreeProps: {
			type: Object,
			default: () => ({
				children: 'children',
				hasChildren: 'hasChildren'
			}),
			description: '树形数据'
		},
		// 使用上次的查询方案
		useLastQuerySchema: {
			type: Boolean,
			default: false
		},
		// 切换查询方案立即搜索
		schemaSearchImmediate: {
			type: Boolean,
			default: true
		},
		// 最大项目个数
		maxProject: {
			type: Number,
			default: 0
		},
		// 是否默认展开页内的高级查询表单
		defaultExpendQueryForm: {
			type: Boolean,
			default: false
		},
		// keyword配置
		keywordConfig: {
			type: Object,
			default() {
				return {}
			}
		},
		// 分页设置
		pageSizes: {
			type: Array,
			default() {
				return [20, 50, 100]
			}
		},
		// 表格展开项宽度
		expandTableWidth: {
			type: String
		},
		// 关键词默认显示
		splitMin: {
			type: String,
			default: '30%'
		},
		// 关键词默认显示
		splitMax: {
			type: String,
			default: '70%'
		},
		searchTypeWidth: {
			type: String,
			default: '200px'
		},
		tabList: {
			type: Array
		},
		// 表格高亮行
		tableRowClassName: {
			type: [String, Array, Function]
		},
		// 合并行与合并列
		spanMethod: {
			type: [Array, Object, Function]
		}
	},
	data() {
		return {
			ready: false,
			// viewTab 视图类型
			viewTab: this.viewType,
			viewTabListLoaded: false, // 查询参数是否有变化后，列表视图是否重新请求过数据
			viewTabQingLoaded: false, // 查询参数是否有变化，轻分析视图是否重新请求过数据
			chartViewUrl: null, // chartViewUrl 轻分析地址
			selection: [], // table被选中的集合
			isLoadingData: false, // 正在加载table数据
			loadSuggest: true,
			table: {
				status: false, // 是否请求过
				list: [],
				current: 1,
				rowCount: 50,
				total: 0,
				sort: {},
				filters: {},
				query: {},
				queryWord: {},
				summary: {}
			},
			tableField: [],
			filterMap: {},
			queryWord: [],
			permission: {},
			curProject: {},
			panelStatus: false,
			keyword: {
				value: '',
				id: null
			},
			keywordValue: null, // keyword.value去除空格的值
			searchType: null,
			queryData: {}, //  切换项目时需要获取到当前的已有条件
			curTab: null // 统计栏默认高亮
		}
	},
	computed: {
		...mapState({
			userInfo: state => state.user.curUser
		}),
		searchOnChange() {
			if (this.keywordConfig.handler === 'change') {
				return true
			}
			return false
		},
		useSuggest() {
			const suggest = this.keywordConfig.suggest
			if (typeof suggest === 'function') {
				return true
			} else if (typeof suggest === 'object' && suggest.event) {
				if (!suggest.schema || !this.searchType) return true // 没有限制或者没有searchType
				if (
					suggest.schema instanceof Array &&
					suggest.schema.includes(this.searchType)
				)
					return true // 符合限制条件
				if (suggest.schema === this.searchType) return true // 符合限制条件
			}
			return false
		},
		useSearch() {
			const search = this.keywordConfig.search
			if (typeof search === 'function') {
				return true
			} else if (typeof search === 'object' && search.event) {
				if (!search.schema || !this.searchType) return true // 没有限制或者没有searchType
				if (
					search.schema instanceof Array &&
					search.schema.includes(this.searchType)
				)
					return true // 符合限制条件
				if (search.schema === this.searchType) return true // 符合限制条件
			}
			return false
		},
		useFuzzy() {
			const suggest = this.keywordConfig.suggest
			if (typeof suggest === 'object') {
				if (suggest.noFuzzy === true) {
					return false // noFuzzy优先级最高
				} else if (suggest.fuzzySchema) {
					if (
						(suggest.fuzzySchema instanceof Array &&
							!suggest.fuzzySchema.includes(this.searchType)) ||
						(typeof suggest.fuzzySchema === 'string' &&
							suggest.fuzzySchema !== this.searchType)
					)
						return false // 符合限制条件
				}
			}
			return true
		},
		searchSlot() {
			const search = this.keywordConfig.search
			if (typeof search === 'object' && search.slot === 'suffix') {
				return search.slot
			}
			return 'prefix'
		},
		btnMap() {
			const btn = extend(true, [], this.btn)
			let	len = btn.length
			while (len--) {
				// 没有权限
				if (!this.showBtn(btn[len])) {
					btn.splice(len, 1)
					continue
				}
				if (btn[len].children) {
					let l = btn[len].children.length,
						firstVisibleItem = null,
						firstVisibleItemIndex = -1 // 第一个有权限的子项
					while (l--) {
						// 没有权限
						if (!this.showBtn(btn[len].children[l])) {
							btn[len].children.splice(l, 1)
						}
						firstVisibleItem = btn[len].children[l]
						firstVisibleItemIndex = l
					}
					// 如果父按钮没有设置event且父按钮的field与第一个有权限的子项一致，则点击父按钮直接调用子按钮功能
					if (
						!btn[len].event &&
						firstVisibleItem &&
						btn[len].field &&
						btn[len].field === firstVisibleItem.field
					) {
						btn[len] = extend(true, {}, btn[len], firstVisibleItem)
						btn[len].children.splice(firstVisibleItemIndex, 1)
					}
					if (btn[len].children.length === 0) delete btn[len].children // 删除空数组
				}
				// 没有子项没有event
				if (!btn[len].children && !btn[len].event) {
					btn.splice(len, 1)
				}
			}
			return btn
		},
		showChartBtn() {
			if (typeof this.showChart === 'object') {
				return this.showBtn(this.showChart)
			}
			return this.showChart
		}
	},
	watch: {
		viewType(value) {
			this.viewTab = value
		},
		keyword(nv) {
			if (nv) {
				this.keywordValue =
					(nv.value && nv.value.replace(/(^\s*)|(\s*$)/g, '')) || ''
			}
		},
		searchType(value) {
			if (value && this.ready) {
				this.loadSuggest = false
				this.$nextTick(() => {
					this.loadSuggest = true
					this.$nextTick(() => {
						this.$refs.keyword && this.$refs.keyword.focus()
					})
				})
			}
		}
	},
	created() {
		this.init()
		if (this.isQing) {
			this.viewTab = 2
		}
	},
	mounted() {
		this.showIntro()
	},
	methods: {
		/**
		 * @function init
		 * @description async 初始化，查询页面权限和btn的权限 -> 获取用户项目信息 -> 如果不自动加载table，则显示查询面板
		 */
		async init() {
			// 设置搜索类型初始值
			if (this.searchTypeList.length > 0) {
				this.searchType = this.searchTypeList[0].id
			}
			if (this.tabList && this.tabList.length) {
				this.curTab = this.tabList[0].id
			}
			// 如果显示项目信息，就去调用当前所属项目
			await this.getPermission()
			await this.loadProject()
			// 初始化表格
			if (!this.auto) {
				this.panelStatus = true
			}
			// 初始化keyword查询
			this.keyword = {
				value: this.keywordConfig.keyword || '',
				id: this.keywordConfig.keywordID || null
			}
			// 初始化searchType
			if (this.keywordConfig.searchType)
				this.searchType = this.keywordConfig.searchType
			this.ready = true
			// 没有高级查询条件时调用
			if (this.auto && this.query.length === 0) {
				if (this.isQing) {
					this.getChartUrl({
						query: this.table.query,
						defineParams: this.defineParams,
						project: this.curProject.id
					})
				} else {
					this.$nextTick(() => {
						this.reload()
					})
				}
			}
		},
		/**
		 * @function getPermission
		 * @description Promise 查询权限
		 */
		getPermission() {
			return new Promise(resolve => {
				const permissionChart =
					this.showChart && typeof this.showChart === 'object'
						? this.getBtnPermission([this.showChart])
						: [] // 轻分析权限配置
				const permission = permissionChart.concat(
					this.getBtnPermission(this.btn)
				)
				if (!this.viewPermission && permission.length === 0) {
					// 如果当前页面不需要限制查看权限
					resolve()
					return
				}
				this.$getPermission(this.viewPermission, permission, data => {
					this.permission = data
					resolve()
				})
			})
		},
		/**
		 * @function searchHandler
		 * @description 点击关键词框的搜索按钮
		 */
		searchHandler() {
			let search = this.keywordConfig.search
			if (typeof search === 'object') search = search.event
			if (search && typeof search === 'function') {
				return search(this.searchType, this.keyword)
			} else {
				console.warn('请传入正确的方法')
			}
		},
		/**
		 * @function suggestHandler
		 * @description 点击关键词框的搜索按钮
		 */
		suggestHandler() {
			let suggest = this.keywordConfig.suggest
			if (typeof suggest === 'object') suggest = suggest.event
			if (suggest && typeof suggest === 'function') {
				return suggest(this.keywordValue, this.searchType, this.keyword)
			} else {
				console.warn('请传入正确的方法')
			}
		},
		getBtnPermission(arr) {
			let permission = []
			arr.forEach(item => {
				if (item.permission) {
					permission.push(item.permission)
				}
				if (item.children) {
					permission = permission.concat(
						this.getBtnPermission(item.children)
					)
				}
			})
			return permission
		},
		/**
		 * @function changeView
		 * @description 切换视图
		 * @param type
		 * @params {number} type 视图类型
		 */
		changeView(type) {
			if (type === this.viewTab) {
				return
			}
			this.viewTab = type
			if (this.$refs.query) {
				this.$refs.query.save()
				return
			}
			if (type === 1) {
				// 列表视图
				!this.viewTabListLoaded &&
					this.$refs.designTable.reloadQuery({
						query: this.table.query,
						defineParams: this.defineParams,
						project: this.curProject.id
					})
				this.viewTabListLoaded = true
				this.$nextTick(() => {})
			} else if (type === 2) {
				// 轻分析视图
				!this.viewTabQingLoaded &&
					this.getChartUrl({
						query: this.table.query,
						defineParams: this.defineParams,
						project: this.curProject.id
					})
				this.viewTabQingLoaded = true
			}
		},
		/**
		 * @function loadProject
		 * @description Promise 获取用户项目信息
		 */
		loadProject() {
			return new Promise(resolve => {
				// 不传入默认项目或showProject为false，都不会初始化项目
				if (!this.defaultProject || !this.showProject) {
					resolve()
					return
				}
				// 开启projectClearable之后除非传入了初始化的defaultProject，否则不会初始化项目
				if (this.defaultProject === true && !this.projectClearable) {
					this.$set(this.table, 'project', this.userInfo.projectID)
					this.curProject = {
						id: this.userInfo.projectID,
						name: this.userInfo.projectName,
						selection: [
							{
								id: this.userInfo.projectID,
								name: this.userInfo.projectName
							}
						]
					}
				} else if (typeof this.defaultProject === 'object') {
					// 使用defaultProject数据初始化项目
					this.curProject = JSON.parse(
						JSON.stringify(this.defaultProject)
					)
					this.$set(this.table, 'project', this.curProject.id)
					const ids = this.curProject.id.split(',')
					const names = this.curProject.name.split(',')
					this.curProject.selection = []
					ids.forEach((el, i) => {
						this.curProject.selection.push({
							id: el,
							name: names[i]
						})
					})
				}
				resolve()
			})
		},
		/**
		 * @function loadingChange
		 * @private
		 * @description 加载tableData事件
		 * @param {boolean} loading 加载tableData状态
		 */
		loadingChange(loading) {
			this.isLoadingData = loading
		},
		/**
		 * @param root0
		 * @param {.prop
		 * @param {.data
		 * @private
		 * @param root0.prop
		 * @param root0.data
		 * @description design-table参数变化时，同步到design-list
		 * @param {object} { prop, data }
		 */
		updateTableProps({ prop, data }) {
			this.$set(this.table, prop, data)
		},
		/**
		 * @function handleClear
		 * @private
		 * @description 项目清理
		 */
		handleClear() {
			this.curProject = {}
			this.table.project = ''
			if (this.$refs.query) {
				this.$refs.query.setQueryItemVisible(0)
				this.$refs.query.handleChange('project')
			}
			this.changeQuery(this.queryData)
			this.$emit('changeProject', [])
		},
		/**
		 * @param e
		 * @function handleChangeProject
		 * @private
		 * @description 项目切换
		 */
		handleChangeProject(e) {
			// 是否启用项目切换
			if (
				!this.enabledProject ||
				e.target.tagName.toLowerCase() !== 'input'
			) {
				return
			}
			this.$projectTreeBox({
				title: '选择项目',
				type: 'orgProject',
				currentSelection: this.curProject.selection || [],
				multi: this.multiProject,
				onOk: (data, cb) => {
					if (data.selection.length === 0) {
						this.$message({
							type: 'warning',
							message: '请选择项目'
						})
						return
					}
					if (
						this.maxProject &&
						this.maxProject < data.selection.length
					) {
						// 判断选择项目个数是否超过最大值
						this.$message({
							type: 'warning',
							message: '最多可选择' + this.maxProject + '个项目'
						})
						return
					}
					if (this.curProject.id !== data.id) {
						this.viewTabListLoaded = false
						this.viewTabQingLoaded = false
						this.curProject = data
						this.table.project = data.id
						if (this.$refs.query) {
							this.$refs.query.setQueryItemVisible(
								data.selection.length
							)
							this.$refs.query.handleChange('project')
							this.$refs.query.save(true).then(() => {
								this.$emit('changeProject', data)
							})
						} else {
							this.changeQuery(this.queryData)
							this.$emit('changeProject', data)
						}
					}
					cb()
				}
			})
		},

		/**
		 * @function showBtn
		 * @description 根据权限判断是否显示按钮
		 * @param root0.canShow
		 * @param root0
		 * @param root0.permission
		 * @params {string} permission 权限字段
		 * @params {Promise} canShow 按钮显示的回调函数，传入当前权限信息
		 */
		showBtn({ permission, canShow }) {
			let boo = true
			if (typeof canShow === 'function') {
				boo = canShow({ permission: this.permission })
			}
			if (permission) {
				boo = boo && this.permission[permission]
			}
			return boo
		},
		/**
		 * @function btnClick
		 * @private
		 * @description 按钮点击事件
		 * @param data
		 * @params {object} data 按钮对象
		 */
		btnClick(data) {
			if (!data) return
			if (typeof data.event === 'string') {
				this.$emit(data.event, data)
			} else if (typeof data.event === 'function') {
				data.event(data)
			}
		},
		/**
		 * @function search
		 * @private
		 * @description 关键词搜索
		 */
		search() {
			// 关键词查询

			this.$nextTick(() => {
				this.$delay.register(
					'designListKeywordSearch',
					() => {
						if (this.keywordValue) {
							this.table.keyword = this.keywordValue
							this.table.keywordID = this.keyword.id
							this.table.searchType = this.searchType
						} else {
							this.table.keyword = null
							this.table.keywordID = null
						}
						this.table.current = 1
						if (this.$refs.query) {
							this.$refs.query.save(true)
						} else {
							this.$refs.designTable.reload()
						}
					},
					500
				)
			})
		},
		/**
		 * @param data
		 * @function keywordInput
		 * @private
		 * @description 关键词输入事件
		 */
		keywordInput(data) {
			// 使用change时，搜索框为空也执行搜索 全空格不搜索
			if (
				data.value &&
				(this.searchOnChange || !this.useFuzzy || !data.value.trim())
			)
				return
			// 关键词查询
			this.search()
		},
		/**
		 * @param data
		 * @function keywordSelect
		 * @private
		 * @description 回车选中关键词
		 */
		keywordSelect(data) {
			if (!this.searchOnChange) return
			// 禁用模糊查询时，没有id不查询
			if (!data.id && !this.useFuzzy) {
				data.value &&
					this.$message({
						message: '所输入内容暂无匹配结果',
						type: 'warning'
					})
				return
			}
			// 关键词查询
			this.search()
		},
		/**
		 * @function keywordSearch
		 * @private
		 * @description 放大镜选中关键词，开始搜索
		 */
		keywordSearch() {
			this.search()
		},
		/**
		 * @function changeSearchType
		 * @private
		 * @description 切换搜索类型
		 */
		changeSearchType() {
			// 重置
			this.$set(this.keyword, 'id', null)
			// 精确搜索，没有ID不执行
			if (!this.keyword.value || (!this.useFuzzy && this.keyword.value)) {
				return
			}
			// 关键词查询
			this.search()
		},
		/**
		 * @function cancelQuery
		 * @private
		 * @description 取消查询
		 */
		cancelQuery() {
			this.showPanel(false)
		},
		/**
		 * @function clickBox
		 * @private
		 * @param root0.data
		 * @description 自定义查询事件
		 * @params {object} {event, data} 自定义事件对象
		 * @param root0
		 * @param root0.event
		 * @params {string} event 事件名称
		 * @params {object} data 事件对象
		 */
		clickBox({ event, data }) {
			this.$emit(event, data)
		},
		/**
		 * @function closeTag
		 * @private
		 * @description 关闭筛选
		 * @param data
		 * @params {object} data 查询结果对象
		 */
		closeTag(data) {
			this.$refs.query.clearField(data.id, { value: null })
		},
		/**
		 * @param data
		 * @function changeQuery
		 * @description 按照query查询条件加载数据
		 * @params {object} {} query的对象集合
		 */
		changeQuery(data) {
			const query = {}
			const word = {}
			if ('value' in data) {
				this.queryData = data //  存储当前查询条件 切换项目用到
				for (const i in data.value) {
					let value = data.value[i]
					if (isArray(value)) {
						value = value.join(',')
					}
					if (value) {
						query[i] = value
					}
				}
				// 列表没有高级查询时会报错，加个兼容
				if (data.word && data.word.length) {
					data.word.forEach(item => {
						word[item.id] = item
					})
				}
				this.queryWord = data.word
			}
			this.table.queryWord = word
			if (JSON.stringify(query) !== JSON.stringify(this.table.query)) {
				this.viewTabListLoaded = false
				this.viewTabQingLoaded = false
				this.table.query = query
			}
			if (this.viewTab === 1) {
				// 列表视图
				;(!this.viewTabListLoaded || data.forceLoad) &&
					this.$nextTick(() => {
						this.$refs.designTable.reloadQuery({
							query,
							defineParams: this.defineParams,
							project: this.table.project || this.curProject.id
						})
					})
				this.viewTabListLoaded = true
			} else if (
				this.viewTab === 2 &&
				(!this.viewTabQingLoaded || data.forceLoad)
			) {
				// 轻分析视图
				this.getChartUrl({
					query,
					defineParams: this.defineParams,
					project: this.curProject.id
				})
				if (
					this.getTotalData &&
					typeof this.getTotalData === 'function'
				) {
					this.getTotalData()
				}
				this.viewTabQingLoaded = true
			}
			this.$emit('query', JSON.parse(JSON.stringify(query)))
			if (
				!this.viewTabListLoaded ||
				!this.viewTabQingLoaded ||
				data.forceLoad
			)
				this.showPanel(false)
		},
		/**
		 * @param data
		 * @function validate
		 * @description 按照query查询条件更新数据
		 * @params {object} {} query的对象集合
		 */
		validate(data) {
			const query = {}
			const word = {}
			if ('value' in data) {
				this.queryData = data //  存储当前查询条件 切换项目用到
				for (const i in data.value) {
					let value = data.value[i]
					if (isArray(value)) {
						value = value.join(',')
					}
					if (value) {
						query[i] = value
					}
				}
				// 列表没有高级查询时会报错，加个兼容
				if (data.word && data.word.length) {
					data.word.forEach(item => {
						word[item.id] = item
					})
				}
				this.queryWord = data.word
			}
			this.table.queryWord = word
			this.table.query = query
			this.showPanel(true)
		},
		/**
		 * @function reload
		 * @param id
		 * @description 重新加载表格数据
		 * @param current
		 * @params {number} current 加载第几页
		 */
		reload(current = 1, id) {
			this.$refs.designTable.reload(current, id)
		},
		/**
		 * @param id
		 * @function reloadCurrentPage
		 * @description 重新加载当前页表格数据
		 */
		reloadCurrentPage(id) {
			this.$refs.designTable.reload(id)
		},
		/**
		 * @function getSelection
		 * @description 获取当前选中的数据
		 * @returns {Array} 返回选中数据的集合
		 */
		getSelection() {
			return this.$refs.designTable.getSelection()
		},
		/**
		 * @function getCurrentProject
		 * @description 获取当前项目信息
		 * @returns {object} 返回当前项目信息
		 */
		getCurrentProject() {
			// 获取当前所选项目
			return this.curProject
		},
		/**
		 * @function getCurrentData
		 * @description 获取当前页面全部信息
		 * @returns {object} {filter, query}返回当前页面全部信息
		 */
		getCurrentData() {
			// 获取当前所有信息，extend避免直接修改数据
			return extend(true, {}, this.table)
		},
		getCurrentParam() {
			const formArr = this.$refs.query.setQueryWord()
			const obj = {}
			formArr.forEach(item => {
				this.$set(obj, item.id, item.value)
			})
			return obj
		},
		/**
		 * @function showPanel
		 * @description 显示关闭查询面板
		 * @param status
		 * @params {boolean} status 显示或关闭查询面板true/false
		 */
		showPanel(status) {
			if (typeof status === 'boolean') {
				this.panelStatus = status
			} else {
				this.panelStatus = !this.panelStatus
			}
		},
		/**
		 * @param root0
		 * @param root0.query
		 * @param root0.defineParams
		 * @param root0.project
		 * @function getChartUrl
		 * @description 获取轻分析地址
		 * @params {type} name description
		 */
		getChartUrl({ query, defineParams, project }) {
			this.$axios({
				type: 'post',
				url: '/qing/program/load',
				data: {
					name: this.name,
					query: JSON.stringify(query),
					defineParams: JSON.stringify(defineParams),
					project
				}
			}).then(res => {
				this.chartViewUrl = res.data.url
			})
		},
		changeField(field, map) {
			this.$refs.query.changeField(field, map)
		},
		/**
		 * @param replay
		 * @function showIntro
		 * @description 页面指引
		 * @params
		 */
		showIntro(replay) {
			if (this.intro) {
				this.$nextTick(() => {
					this.$intro({
						el: this.$el,
						options: this.intro,
						replay
					})
				})
			}
		},
		/**
		 * @param item
		 * @function changeTab
		 * @description tab点击事件
		 * @params {string} id 当前点击的tab id
		 */
		changeTab(item) {
			this.curTab = item.id
			this.$emit('tabChange', item)
		}
	}
}
</script>
