var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-steps", class: { flex: _vm.flex } }, [
    _c(
      "div",
      { staticClass: "wy-steps-tab" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "wy-steps_item",
            class: { active: index == _vm.step, finish: index < _vm.step },
          },
          [
            index >= _vm.step
              ? _c("span", { staticClass: "wy-steps_index" }, [
                  _vm._v(_vm._s(index + 1)),
                ])
              : _vm._e(),
            index < _vm.step
              ? _c("span", {
                  staticClass: "wy-steps_index iconfont icon-correct",
                })
              : _vm._e(),
            _c("h4", { staticClass: "wy-steps_title" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            index < _vm.list.length - 1
              ? _c("div", { staticClass: "wy-steps_border" })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "wy-steps_content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }