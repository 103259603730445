import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from './progress.vue'

const defaultOpt = {
	keyVal: '', // 查询进度所需key
	showProgress: true, // 显示进度条orloading
	onSuccess: Function, // 查询完成回调
	sendTime: 2000, // 设置查询间隔 默认2S
	title: '处理进度'
}

let instance

/**
 * @param opt
 */
export default function uploadFile(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.keyVal = options.keyVal
	instance.showProgress = options.showProgress
	instance.title = options.title
	instance.sendTime = options.sendTime
	instance.onSuccess = options.onSuccess

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyProgress',
		id: instance._uid,
		instance
	})

	return instance
}
