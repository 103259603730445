var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wy-popper",
      on: {
        click: _vm.handleClick,
        mouseenter: _vm.handleMouseenter,
        mouseleave: _vm.handleMouseleave,
      },
    },
    [
      _vm._t("default"),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            ref: "popper",
            staticClass: "wy-popper-content",
            style: _vm.setStyle,
            on: {
              mouseenter: _vm.handleMouseenter,
              mouseleave: _vm.handleMouseleave,
            },
          },
          [_vm._t("content")],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }