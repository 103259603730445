<template>
	<div class="wy-editor" :style="{ height: `${height}px` }">
		<quill-editor
			ref="QuillEditor"
			v-model="content"
			class="wy-ql-editor"
			:options="editorOption"
			:style="{ height: `${height - 66}px` }"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@change="onEditorChange($event)"
		>
		</quill-editor>
		<el-upload
			class="wy-el-upload-group"
			action="/api/file/uploadFiles"
			:on-success="handleUploadSuccess"
		>
			<el-button size="small" type="primary">点击上传</el-button>
		</el-upload>
	</div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
	name: 'WyEditor',
	components: {
		quillEditor
	},
	props: {
		// 富文本框高度
		height: {
			type: Number,
			default: () => {
				return 300
			}
		},
		// 富文本内容
		value: {
			type: String
		},
		// 是否禁用
		disabled: {
			type: Boolean
		},
		// 输入提示
		placeholder: {
			type: String,
			default: '请输入文本'
		}
	},
	data() {
		return {
			editorOption: {
				modules: {
					toolbar: {
						// 工具栏
						container: [
							['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
							['blockquote', 'code-block'], // 引用，代码块
							[{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
							[{ list: 'ordered' }, { list: 'bullet' }], // 列表
							[{ script: 'sub' }, { script: 'super' }], // 上下标
							[{ indent: '-1' }, { indent: '+1' }], // 缩进
							[{ direction: 'rtl' }], // 文本方向
							[{ size: ['small', false, 'large', 'huge'] }], // 字体大小
							[{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
							[{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
							[{ font: [] }], // 字体
							[{ align: [] }], // 对齐方式
							['clean'], // 清除字体样式
							['image', 'video'] // 上传图片、上传视频
						],
						handlers: {
							// 图片上传
							image: function (value) {
								if (value) {
									document
										.querySelector(
											'.el-upload-group .el-button'
										)
										.click()
								} else {
									this.quill.format('image', false)
								}
							}
						}
					}
				},
				readOnly: this.disabled,
				theme: 'snow',
				placeholder: this.placeholder
			},
			content: null
		}
	},
	watch: {
		value(newVal) {
			this.content = newVal
		}
	},
	created() {
		this.content = this.value
	},
	mounted() {},
	methods: {
		// quill富文本组件上传图片
		handleUploadSuccess(res) {
			// 获取富文本组件实例
			const quill = this.$refs.QuillEditor.quill
			// 如果上传成功
			if (res.data.length) {
				// 获取光标所在位置
				const length = quill.getSelection().index
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(length, 'image', res.data[0].url)
				// 调整光标到最后
				quill.setSelection(length + 1)
			} else {
				this.$message({
					type: 'error',
					message: '图片上传失败'
				})
			}
		},
		onEditorBlur() {
			// 失去焦点事件
			this.$emit('blur', this.content)
		},
		onEditorFocus(editor) {
			// 获得焦点事件
			this.$emit('focus', editor)
		},
		onEditorChange() {
			// 内容改变事件
			this.$emit('change', this.content)
		}
	}
}
</script>
