<template>
	<div class="wy-keyword">
		<!--
            @author saqqdy 2020-04-15
            @description 关键词查询建议
        -->
		<el-input
			v-if="!useSuggest"
			ref="keyword"
			v-model="keyword"
			class="wy-keyword__input wy-border-bottom"
			:placeholder="placeholder"
			:disabled="disabled"
			@input="inputKeyword"
			@keyup.enter.native="changeKeyword($event, 'enter')"
			@focus="keywordFocus"
		>
			<i
				v-if="useSearch"
				:slot="searchSlot"
				class="'wy-pointer el-input__icon el-icon-search"
				@click.stop="searchHandler"
			></i>
		</el-input>
		<!--
            @author saqqdy 2020-04-15
            @description 关键词查询建议
        -->
		<el-autocomplete
			ref="keyword"
			v-model="keyword"
			class="wy-keyword__input wy-border-bottom"
			popper-class="wy-keyword-suggest"
			v-if="useSuggest"
			:placeholder="placeholder"
			:popper-append-to-body="true"
			:trigger-on-focus="suggestOnFocus"
			:select-when-unmatched="true"
			:highlight-first-item="true"
			:hide-loading="false"
			:fetch-suggestions="suggestHandler"
			:disabled="disabled"
			@select="changeKeyword($event, 'select')"
			@input="inputKeyword"
			@focus="keywordFocus"
		>
			<i
				v-if="useSearch"
				:slot="searchSlot"
				class="wy-pointer el-input__icon el-icon-search"
				@click.stop="searchHandler"
			></i>
		</el-autocomplete>
	</div>
</template>

<script>
/**
 * @module	components/keyword
 * @author saqqdy 2020-04-25
 * @description keyword组件
 */
export default {
	name: 'WyKeyword',
	components: {},
	props: {
		// 搜索建议方法
		suggest: {
			type: Function
		},
		// 聚焦时开始加载搜索建议
		suggestOnFocus: {
			type: Boolean,
			default: false
		},
		// 放大镜方法
		search: {
			type: Function
		},
		// 聚焦自动选中文字
		autoSelect: {
			type: Boolean,
			default: true
		},
		// 禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 是否开启搜索建议
		useSuggest: {
			type: Boolean,
			default: false
		},
		// 是否开启放大镜搜索
		useSearch: {
			type: Boolean,
			default: false
		},
		// 是否开启模糊查询
		useFuzzy: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: '请输入关键词'
		},
		value: {
			type: Object,
			default: () => ({})
		},
		// 搜索插槽
		searchSlot: {
			type: String,
			default: 'suffix'
		}
	},
	data() {
		return {
			keyword: '',
			keywordID: null,
			activeKeyword: {}
		}
	},
	computed: {},
	watch: {
		value(nv) {
			this.keyword = nv ? nv.value : ''
			this.keywordID = nv ? nv.id : null
		},
		// keyword(nv, ov) {
		// 	this.$emit('input', { value: nv, id: this.keywordID });
		// },
		keywordID(nv) {
			this.$emit('input', { value: this.keyword, id: nv })
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.keyword =
				this.value && this.value.value ? this.value.value : ''
			this.keywordID = this.value && this.value.id ? this.value.id : null
		},
		focus() {
			if (this.useSuggest) {
				this.$refs.keyword.$el.querySelector('input').focus()
			} else {
				this.$refs.keyword.focus()
			}
		},
		/**
		 * @param data
		 * @param type
		 * @function changeKeyword
		 * @private
		 * @description 回车或失焦事件
		 */
		changeKeyword(data, type) {
			// input回车 text
			// complate回车 Object {value: '', id: ''}
			const o = {
				value: this.keyword,
				id: null
			}
			// autocomplate回车
			if (type === 'select') {
				o.value = data.value
				if (data.id) {
					o.id = data.id
					this.keywordID = data.id
					this.activeKeyword = Object.assign({}, o)
				} else if (
					this.activeKeyword.id &&
					data.value === this.activeKeyword.value
				) {
					o.id = this.activeKeyword.id
				}
			} else {
				o.value = data
			}
			this.$emit('select', { ...o })
		},
		/**
		 * @param e
		 * @function keywordFocus
		 * @private
		 * @description 聚焦选中关键词
		 */
		keywordFocus(e) {
			if (!this.keyword || !this.autoSelect) return
			e.target.selectionStart = 0
			e.target.selectionEnd = e.target.value.length
			this.$emit('focus', e)
			e.stopPropagation()
			e.preventDefault()
			return false
		},
		/**
		 * @param data
		 * @function inputKeyword
		 * @private
		 * @description 关键词搜索
		 */
		inputKeyword(data) {
			if (!data) {
				this.$emit('input', {
					value: '',
					id: null
				})
			} else if (!this.useSuggest) {
				this.$emit('input', {
					value: data,
					id: null
				})
			}
		},
		/**
		 * @function searchHandler
		 * @description 点击关键词框的搜索按钮
		 */
		searchHandler() {
			if (this.search && typeof this.search === 'function') {
				this.search().then(data => {
					if (data) {
						this.keyword = data.value
						this.keywordID = data.id
						this.activeKeyword = {
							value: data.value,
							id: data.id
						}
						this.$emit('search', {
							value: this.keyword,
							id: this.keywordID
						})
					}
				})
			}
		},
		/**
		 * @param query
		 * @param cb
		 * @function suggestHandler
		 * @description 搜索建议
		 */
		suggestHandler(query, cb) {
			this.keywordID = null
			if (this.activeKeyword.id && query === this.activeKeyword.value) {
				this.keywordID = this.activeKeyword.id
			}
			query &&
				this.$emit('input', {
					value: query || '',
					id: this.keywordID
				})
			// 关键词查询
			this.$delay.register(
				'getKeywordSuggestions',
				() => {
					// 查询搜索建议
					if (
						!!this.keyword &&
						this.suggest &&
						typeof this.suggest === 'function'
					) {
						this.suggest().then(data => {
							cb([].concat(data))
						})
					} else {
						cb([])
					}
				},
				500
			)
		}
	}
}
</script>
