<template>
	<transition
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave"
		@after-leave="afterLeave"
	>
		<slot></slot>
	</transition>
</template>
<script>
import { addClass, removeClass } from 'tool'

export default {
	name: 'WyShrink',
	props: {},
	data() {
		return {
			old: {}
		}
	},
	computed: {},
	watch: {},
	mounted() {},
	methods: {
		getComputedStyle(dom, attr) {
			return dom.currentStyle
				? dom.currentStyle[attr]
				: getComputedStyle(dom, false)[attr]
		},
		beforeEnter(el) {
			el.style.height = '0'
			addClass(el, 'wy-shrink')
			this.old.marginTop = this.getComputedStyle(el, 'marginTop')
			this.old.paddingTop = this.getComputedStyle(el, 'paddingTop')
			this.old.paddingBottom = this.getComputedStyle(el, 'paddingBottom')
			el.style.paddingTop = '0'
			el.style.paddingBottom = '0'
		},
		enter(el) {
			if (el.scrollHeight === 0) {
				el.style.height = ''
			} else {
				el.style.height =
					el.scrollHeight -
					parseInt(this.old.paddingTop) -
					parseInt(this.old.paddingBottom) +
					'px'
			}
			el.style.marginTop = this.old.marginTop
			el.style.paddingTop = this.old.paddingTop
			el.style.paddingBottom = this.old.paddingBottom
		},
		afterEnter(el) {
			el.style.height = ''
			removeClass(el, 'wy-shrink')
		},
		beforeLeave(el) {
			addClass(el, 'wy-shrink')
			el.style.height =
				el.scrollHeight -
				parseInt(el.style.paddingTop) -
				parseInt(el.style.paddingBottom) +
				'px'
			this.old.marginTop = this.getComputedStyle(el, 'marginTop')
			this.old.paddingTop = this.getComputedStyle(el, 'paddingTop')
			this.old.paddingBottom = this.getComputedStyle(el, 'paddingBottom')
		},
		leave(el) {
			if (el.scrollHeight !== 0) {
				el.style.height = '0'
				el.style.marginTop = '0'
				el.style.paddingTop = '0'
				el.style.paddingBottom = '0'
			}
		},
		afterLeave(el) {
			removeClass(el, 'wy-shrink')
			el.style.height = ''
			el.style.marginTop = this.old.marginTop
			el.style.paddingTop = this.old.paddingTop
			el.style.paddingBottom = this.old.paddingBottom
		}
	}
}
</script>
