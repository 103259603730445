<template>
	<div
		v-if="visible"
		ref="wyProgress"
		class="wy-export-file"
		:style="{ 'z-index': zIndex }"
	>
		<div class="wy-export-file_cont">
			<div class="wy-export-file_title">{{ title }}</div>
			<div v-if="isTasks && !isSync" class="wy-export-file_center">
				<div v-if="taskMsg" class="wy-export-file_msg">
					{{ taskMsg }}
				</div>
				<div v-else class="wy-export-file_bar">
					<div class="wy-loader-gif row justify-center">
						<img
							:src="require('../../src/img/loading-1.gif')"
							alt="加载中"
						/>
						<wy-parabola
							:visible.sync="parabolaVisible"
							:target="animationTarget"
							direction="y"
						>
							<i
								data-v-d63932fa=""
								title="我的后台任务"
								class="iconfont icon-ziliaoxiazai"
							></i>
						</wy-parabola>
					</div>
				</div>
				<div class="wy-export-file_btn">
					<el-button type="primary" @click="goTasks"
						>前往我的后台任务</el-button
					>
					<el-button type="default" @click="hide">知道了</el-button>
				</div>
			</div>
			<div v-else class="wy-export-file_center">
				<div class="wy-export-file_bar">
					<div class="wy-loader-gif row justify-center">
						<img
							:src="require('../../src/img/loading-1.gif')"
							alt="加载中"
						/>
					</div>
					<div class="wy-export-file_progress">
						<el-progress
							:percentage="percentage"
							status="success"
						></el-progress>
						<p class="text-center">{{ percentage }}/100%</p>
					</div>
					<p class="text-center wy-export-file_tip">执行中请稍后</p>
				</div>
				<div class="wy-export-file_btn">
					<el-button v-if="percentage === 100" @click="hide"
						>已完成</el-button
					>
				</div>
			</div>
			<iframe
				v-if="progress === max"
				ref="iframe"
				class="wy-export-file_iframe"
				:src="downUrl"
				@load="loaded"
			></iframe>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WyExportFile',
	props: {
		url: {
			type: String
		},
		queryUrl: {
			type: String,
			default: '/progress'
		},
		time: {
			type: Number,
			default: 2000
		},
		title: {
			type: String,
			default: '正在导出'
		},
		isSync: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'post',
			validator: v => ['post', 'get'].includes(v)
		},
		// 是否后台任务导出
		isTasks: {
			type: Boolean,
			default: false
		},
		cleanMessage: Function,
		// 导出附带的查询条件，提交 url 时用到，将被filterParams替代
		params: [Object, String],
		// 查询参数属性名称，将被filterParams替代
		paramID: {
			type: String,
			default: 'params'
		},
		// 筛选推荐数据，替代params和paramID
		filterParams: {
			type: Object,
			required: false,
			default: () => ({})
		},
		onError: Function,
		onSuccess: Function,
		showAnimation: {
			type: Boolean,
			default: true
		},
		animationTarget: {
			type: Object,
			default() {
				return document.querySelector('.page-header .task .iconfont')
			}
		}
	},
	data() {
		return {
			id: null,
			timer: null,
			visible: false,
			progress: 0,
			max: 100,
			downUrl: null,
			zIndex: 5000,
			taskMsg: null,
			parabolaVisible: false
		}
	},
	computed: {
		percentage() {
			if (this.max > 0) {
				return (this.progress / this.max) * 100
			} else {
				return 100
			}
		}
	},
	watch: {
		visible(value) {
			if (value) {
				this.init()
			}
		}
	},
	created() {},
	methods: {
		init() {
			this.progress = 0
			this.max = 100
			if (this.isSync) {
				this.downUrl = '/jar' + this.url
				this.timer = setTimeout(() => {
					this.progress = 100
				}, 1000)
			} else {
				this.submit()
			}
			if (this.showAnimation) {
				this.$nextTick(() => {
					this.parabolaVisible = true
				})
			}
		},
		loaded() {
			this.progress = 100
		},
		submit() {
			// 提交查询
			this.$axios({
				type: this.type,
				url: this.url,
				data: Object.assign(
					{},
					{
						[this.paramID]: this.params
					},
					this.filterParams
				),
				error: true
			})
				.then(res => {
					// 后台任务导出的不需要查进度
					if (this.isTasks) {
						// 后台任务消息
						if (this.cleanMessage instanceof Function) {
							this.taskMsg =
								this.cleanMessage(res.data) || res.msg
						} else {
							this.taskMsg = res.msg
						}
					} else {
						this.id = res.data
						this.query()
					}
				})
				.catch(err => {
					if (typeof this.onError === 'function') {
						this.onError(err)
						this.hide()
					} else {
						this.$message({
							type: 'error',
							message: '导出失败，请稍后重试'
						})
					}
				})
		},
		goTasks() {
			this.$openUrl('我的任务中心', '/user/task/all')
		},
		query() {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.$axios({
					url: this.queryUrl,
					data: {
						id: this.id
					},
					error: true
				})
					.then(res => {
						this.progress = res.data.now
						this.max = res.data.max
						if (this.progress === this.max) {
							// 如果执行完成，则说明导出文件已经生成
							clearTimeout(this.timer)
							// 转发到java服务添加/jar前缀
							this.downUrl = '/jar' + res.data.url
							if (typeof this.onSuccess === 'function') {
								this.onSuccess(res.data.url)
							}
							return
						}
						this.query()
					})
					.catch(err => {
						this.hide()
						clearTimeout(this.timer)
						if (typeof this.onError === 'function') {
							this.onError(err)
						} else {
							this.$message({
								type: 'error',
								message: '导出失败，请稍后重试'
							})
						}
					})
			}, this.time)
		},
		hide() {
			clearTimeout(this.timer)
			this.visible = false
		}
	}
}
</script>
