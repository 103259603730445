import BuildingRoom from './index.vue'
import BuildingRoomPlugin from './building-room.js'

/* istanbul ignore next */
BuildingRoom.install = function (Vue) {
	Vue.component(BuildingRoom.name, BuildingRoom)
	Vue.prototype.$buildingRoom = Vue.$buildingRoom = BuildingRoomPlugin
}

export default BuildingRoom
