var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-design-report" }, [
    _c("div", { staticClass: "wy-design-report_top" }, [
      _c(
        "h1",
        {
          staticClass: "wy-design-report_title",
          attrs: { "data-guide": "design-report-title" },
        },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
      ),
      _c(
        "div",
        { staticClass: "wy-design-report_btn-group" },
        _vm._l(_vm.btn, function (item, index) {
          return _c(
            "div",
            { key: item.event, staticClass: "wy-design-report_btn" },
            [
              _vm.showBtn(item) && !item.children
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-guide":
                          "btn-1-" +
                          (item.event ||
                            item.field ||
                            item.permission ||
                            index),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.btnClick(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                : _vm._e(),
              item.children
                ? _c(
                    "el-dropdown",
                    {
                      attrs: { "split-button": "", type: "primary" },
                      on: { command: _vm.btnClick },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            "data-guide":
                              "btn-1-" +
                              (item.event ||
                                item.field ||
                                item.permission ||
                                index),
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(item.children, function (v, subindex) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: v.event,
                              attrs: {
                                "data-guide":
                                  "btn-1-" +
                                  (item.event ||
                                    item.field ||
                                    item.permission ||
                                    index) +
                                  "-2-" +
                                  (v.event ||
                                    v.field ||
                                    v.permission ||
                                    subindex),
                                command: v,
                              },
                            },
                            [_vm._v(_vm._s(v.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm.showProject || _vm.query.length > 0 || _vm.$slots.query
      ? _c("div", { staticClass: "wy-design-report_filter" }, [
          _c(
            "div",
            { staticClass: "wy-design-report_normal" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.queryForm,
                    inline: true,
                    rules: _vm.rules,
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属项目" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "wy-border-bottom wy-pointer",
                          attrs: {
                            placeholder: "请选择项目",
                            title: _vm.curProject.name,
                            value: _vm.curProject.name,
                            "read-only": "",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleChangeProject($event)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-search",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.commonQueryList, function (item) {
                    return [
                      !item.hide && _vm.queryMap[item.field]
                        ? _c(
                            "el-form-item",
                            {
                              key: item.key,
                              attrs: { label: item.name, prop: item.field },
                            },
                            [
                              item.type === "input"
                                ? [
                                    _c("el-input", {
                                      staticClass: "wy-border-bottom",
                                      model: {
                                        value: _vm.queryForm[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            item.field,
                                            $$v
                                          )
                                        },
                                        expression: "queryForm[item.field]",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              item.type === "select"
                                ? [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "wy-border-bottom",
                                        attrs: {
                                          filterable: "",
                                          multiple: item.config.multi,
                                        },
                                        on: {
                                          "visible-change": function ($event) {
                                            return _vm.selectVisibleChange(
                                              item,
                                              $event
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.handleChange(
                                              item.field,
                                              "common"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.queryForm[item.field],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              item.field,
                                              $$v
                                            )
                                          },
                                          expression: "queryForm[item.field]",
                                        },
                                      },
                                      _vm._l(
                                        _vm.queryMap[item.field].list,
                                        function (option) {
                                          return _c("el-option", {
                                            key: _vm.getSelectValue(
                                              option,
                                              item
                                            ),
                                            attrs: {
                                              value: _vm.getSelectValue(
                                                option,
                                                item
                                              ),
                                              label: _vm.getSelectName(
                                                option,
                                                item
                                              ),
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              item.type === "date"
                                ? [
                                    _c("el-date-picker", {
                                      staticClass: "wy-border-bottom",
                                      attrs: {
                                        type: item.config.type,
                                        "unlink-panels": true,
                                        "value-format": _vm.setDateFormat(
                                          item.config.type
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleChange(
                                            item.field,
                                            "common"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            item.field,
                                            $$v
                                          )
                                        },
                                        expression: "queryForm[item.field]",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              item.type === "box"
                                ? [
                                    _c("el-input", {
                                      staticClass:
                                        "wy-border-bottom wy-pointer",
                                      attrs: {
                                        "suffix-icon": "el-icon-search",
                                        readonly: "",
                                        placeholder: "请选择",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.clickBox(item, "common")
                                        },
                                      },
                                      model: {
                                        value: _vm.queryWord[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryWord,
                                            item.field,
                                            $$v
                                          )
                                        },
                                        expression: "queryWord[item.field]",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wy_query" },
            [
              _vm.showKeyword
                ? _c("el-input", {
                    staticClass: "wy-border-bottom",
                    attrs: {
                      placeholder: _vm.keywordPlaceholder,
                      "prefix-icon": "el-icon-search",
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  })
                : _vm._e(),
              _vm.queryList.length > 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "wy-design-report_more",
                      attrs: { type: "text" },
                      on: { click: _vm.showPanel },
                    },
                    [
                      !_vm.panelStatus
                        ? _c("span", [_vm._v("更多高级查询")])
                        : _vm._e(),
                      _vm.panelStatus
                        ? _c("span", [_vm._v("关闭高级查询")])
                        : _vm._e(),
                      _c("i", {
                        class: {
                          "el-icon-arrow-right": !_vm.panelStatus,
                          "el-icon-arrow-left": _vm.panelStatus,
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.getQueryWord.length > 0
      ? _c(
          "div",
          { staticClass: "wy-design-report_word" },
          _vm._l(_vm.getQueryWord, function (item) {
            return _c(
              "el-tag",
              {
                key: item.id,
                attrs: { closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.closeTag(item)
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(item.name) +
                    ":" +
                    _vm._s(item.value) +
                    "\n\t\t"
                ),
              ]
            )
          }),
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "wy-design-report_main" },
      [
        _c("design-table", {
          ref: "designTable",
          staticClass: "wy-design-report__main",
          attrs: {
            "data-guide": "design-report-table",
            url: _vm.url,
            name: _vm.name,
            "show-setting": _vm.showSetting,
            "show-index": _vm.showIndex,
            "enable-net-field": _vm.enableNetField,
            "show-selection": _vm.showSelection,
            "show-summary": _vm.showSummary,
            field: _vm.field,
            "show-pages": _vm.showPages,
            query: _vm.table.query,
            project: _vm.curProject.id,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.panelStatus,
            expression: "panelStatus",
          },
        ],
        staticClass: "wy-design-report_query",
        attrs: { "data-guide": "design-report-query" },
      },
      [
        _c("div", { staticClass: "wy-design-report_bar" }, [
          _vm._v("高级查询"),
        ]),
        _c(
          "div",
          { staticClass: "wy-design-report_ctrl" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.changeQuery } },
              [_vm._v("确定")]
            ),
            _c("el-button", { on: { click: _vm.cancelQuery } }, [
              _vm._v("取消"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wy-design-report_list" },
          [
            _vm.queryList.length > 0
              ? _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.queryForm,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _vm._l(_vm.queryList, function (item) {
                      return [
                        !item.hide && _vm.queryMap[item.field]
                          ? _c(
                              "el-form-item",
                              {
                                key: item.key,
                                attrs: { label: item.name, prop: item.field },
                              },
                              [
                                item.type === "input"
                                  ? [
                                      _c("el-input", {
                                        staticClass: "wy-border-bottom",
                                        attrs: { maxlength: "100" },
                                        model: {
                                          value: _vm.queryForm[item.field],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              item.field,
                                              $$v
                                            )
                                          },
                                          expression: "queryForm[item.field]",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                item.type === "select"
                                  ? [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "wy-border-bottom",
                                          attrs: {
                                            filterable: "",
                                            multiple: item.config.multi,
                                          },
                                          on: {
                                            "visible-change": function (
                                              $event
                                            ) {
                                              return _vm.selectVisibleChange(
                                                item,
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.handleChange(
                                                item.field
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.queryForm[item.field],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                item.field,
                                                $$v
                                              )
                                            },
                                            expression: "queryForm[item.field]",
                                          },
                                        },
                                        _vm._l(
                                          _vm.queryMap[item.field].list,
                                          function (option) {
                                            return _c("el-option", {
                                              key: _vm.getSelectValue(
                                                option,
                                                item
                                              ),
                                              attrs: {
                                                value: _vm.getSelectValue(
                                                  option,
                                                  item
                                                ),
                                                label: _vm.getSelectName(
                                                  option,
                                                  item
                                                ),
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                item.type === "date"
                                  ? [
                                      _c("el-date-picker", {
                                        staticClass: "wy-border-bottom",
                                        attrs: {
                                          type: item.config.type,
                                          "unlink-panels": true,
                                          "value-format": _vm.setDateFormat(
                                            item.config.type
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleChange(item.field)
                                          },
                                        },
                                        model: {
                                          value: _vm.queryForm[item.field],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              item.field,
                                              $$v
                                            )
                                          },
                                          expression: "queryForm[item.field]",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                item.type === "box"
                                  ? [
                                      _c("el-input", {
                                        staticClass:
                                          "wy-border-bottom wy-pointer",
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          readonly: "",
                                          placeholder: "请选择",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clickBox(item)
                                          },
                                        },
                                        model: {
                                          value: _vm.queryWord[item.field],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryWord,
                                              item.field,
                                              $$v
                                            )
                                          },
                                          expression: "queryWord[item.field]",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._t("$slots.query"),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }