<template>
	<div class="wy-layout-page-table">
		<header class="wy-layout-page-table_top">
			<div class="wy-layout-page-table_header">
				<h1
					class="wy-layout-page-table_title"
					data-guide="layout-page-table-title"
				>
					<el-tooltip content="点击查看功能指引">
						<i
							v-if="intro"
							class="iconfont icon-guide wy-pointer"
							@click="showIntro(true)"
						></i>
					</el-tooltip>
					{{ title }}
				</h1>
				<div class="wy-layout-page-table_btn-group">
					<slot name="btn-group"></slot>
				</div>
			</div>
			<div class="wy-layout-page-table_bar">
				<div class="wy-layout-page-table_project">
					<span>所属项目：</span>
					<el-input
						v-if="showProject !== 'none'"
						class="wy-border-bottom wy-pointer"
						placeholder="请选择项目"
						:title="curProject.name"
						:value="curProject.name"
						readonly
						@click.native="changeProject"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
						></i>
					</el-input>
					<span v-if="showProject === 'none'">{{
						curProject.name
					}}</span>
				</div>
				<div class="wy-layout-page-table_filter-group">
					<slot name="filter-group"></slot>
				</div>
				<div class="wy-layout-page-table_btn-panel">
					<el-input
						v-model="keyword"
						class="wy-border-bottom"
						:placeholder="placeholder"
						:style="{ width: searchWidth + 'px' }"
						@input="changeKeyword"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
						></i>
					</el-input>
				</div>
			</div>
		</header>
		<section class="wy-layout-page-table_center">
			<div class="wy-layout-page-table_main">
				<slot name="content"></slot>
			</div>
			<transition name="slide">
				<div
					v-if="showPanel"
					v-show="showPanel"
					class="wy-layout-page-table_panel"
					:style="{ width: panelWidth + 'px' }"
					@click.stop
				>
					<slot name="panel"></slot>
				</div>
			</transition>
		</section>
	</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
	name: 'WyLayoutPageTable',
	props: {
		title: String,
		placeholder: {
			type: String,
			default: '请输入内容'
		},
		// keyword: String,
		searchWidth: {
			type: String,
			default: '200',
			validator: val => /^(\-|\+)?\d+(\.\d+)?$/.test(val)
		},
		showView: {
			type: Boolean,
			default: false
		},
		showProject: {
			type: String,
			default: 'none',
			validator: value =>
				['none', 'multi', 'single', 'false'].includes(value),
			description: '显示项目切换'
		},
		isShowPanel: {
			type: Boolean,
			default: false
		},
		panelWidth: {
			type: String,
			default: '340',
			validator: val => /^(\-|\+)?\d+(\.\d+)?$/.test(val)
		},
		tabList: {
			type: Array,
			default() {
				return []
			}
		},
		typeList: {
			type: Array,
			default() {
				return []
			}
		},
		project: {
			type: Object,
			default: null
		},
		intro: Object,
		name: String
	},
	data() {
		return {
			showPanel: false,
			keyword: null,
			filterResult: [], // 筛选条件显示的数组
			filterData: {}, // 筛选条件的查询结果键值
			timeout: null,
			curProject: {
				name: null,
				id: null,
				selection: []
			},
			userInfo: {}
		}
	},
	computed: {
		...mapState({
			curUser: state => state.user.curUser
		})
	},
	watch: {
		isShowPanel: {
			handler(nv) {
				this.showPanel = !!(nv && !this.showPanel)
			},
			immediate: true,
			deep: false
		}
	},
	created() {
		this.filterData.projectID =
			(this.project && this.project.id) || this.curUser.projectID
		this.filterData.projectName =
			(this.project && this.project.name) || this.curUser.projectName
		this.curProject = {
			id: (this.project && this.project.id) || this.curUser.projectID,
			name:
				(this.project && this.project.name) || this.curUser.projectName,
			selection: [
				{
					id:
						(this.project && this.project.id) ||
						this.curUser.projectID,
					name:
						(this.project && this.project.name) ||
						this.curUser.projectName
				}
			]
		}
	},
	mounted() {
		this.showIntro()
	},
	methods: {
		showIntro(replay) {
			if (this.intro) {
				this.$nextTick(() => {
					this.$intro({
						el: this.$el,
						options: this.intro,
						replay
					})
				})
			}
		},
		changeKeyword() {
			this.timeout && clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.$emit('changeKeyword', this.keyword)
			}, 500)
		},
		/**
		 * @description 选择项目范围
		 */
		changeProject() {
			this.$projectTreeBox({
				title: '选择项目',
				type: 'orgProject',
				multi: this.showProject == 'multi',
				currentSelection: this.curProject.selection,
				onOk: (data, cb) => {
					this.curProject = data
					this.filterData.projectID = data.id
					this.filterData.projectName = data.name
					this.submitForm()
					cb()
				}
			})
		},
		submitForm() {
			let obj = {},
				name = [],
				filterData
			this.filterResult = name
			filterData = Object.assign({}, this.filterData, obj)
			// 清除值为null的项
			for (const z in filterData) {
				if (filterData[z] === null) {
					delete filterData[z]
				}
			}
			this.filterData = filterData
			this.$emit('query', this.filterData)
		}
	}
}
</script>
