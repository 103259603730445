<template>
	<div
		v-show="visible"
		class="wy-design-list-query"
		:class="{ fixed: userQueryType === 1, relative: userQueryType === 2 }"
	>
		<div v-if="userQueryType === 1" class="wy-design-list-query_top-bar">
			<h3 class="wy-design-list-query_top-bar_title">高级查询</h3>
			<el-select
				v-if="name"
				v-model="userQuerySchema"
				popper-class="wy-design-list-query_top-bar-schema"
				class="wy-design-list-query_top-bar_schema"
				size="mini"
				placeholder="请选择查询方案"
				value-key="name"
				:disabled="isLoadingData"
				@change="changeQuerySchema"
			>
				<el-option
					label="默认查询方案"
					:value="userQueryDefault"
				></el-option>
				<el-option
					v-for="item in userQueryConfig"
					:key="item.name"
					:label="item.name"
					:value="item"
				>
					<span>{{ item.name }}</span>
					<el-button
						type="text"
						class="wy-design-list-query_delete el-icon-close"
						title="删除查询方案"
						@click.stop="delUserConfig(item)"
					></el-button>
				</el-option>
			</el-select>
			<div class="wy-design-list-query_set">
				<el-tooltip
					class="wy-design-list-query_item"
					effect="dark"
					content="侧边栏显示高级查询"
					placement="top-start"
				>
					<i
						class="iconfont icon-query-1 wy-design-list-query_active"
					></i>
				</el-tooltip>
				<el-tooltip
					class="wy-design-list-query_item"
					effect="dark"
					content="页面显示高级查询"
					placement="top-start"
				>
					<i
						class="iconfont icon-query-2"
						@click="changeShowType(2)"
					></i>
				</el-tooltip>
			</div>
		</div>
		<el-form
			v-if="query.length > 0"
			ref="form"
			v-loading="loading"
			size="small"
			:model="queryForm"
			:rules="rules"
			:style="{
				maxHeight:
					expendQueryForm || userQueryType === 1 ? null : '200px'
			}"
			:inline-message="userQueryType === 2"
			:label-position="labelPosition"
			:label-width="queryLabelWidth"
		>
			<template v-for="item in queryList">
				<el-form-item
					v-if="queryMap[item.field] && !queryMap[item.field].hide"
					:key="item.key"
					:label="item.name + '：'"
					:prop="item.field"
				>
					<!--输入框-->
					<template v-if="item.type === 'input'">
						<el-input
							v-model="queryForm[item.field]"
							class="wy-border-bottom"
							placeholder="请输入"
						></el-input>
					</template>
					<!--区间输入框-->
					<template v-if="item.type === 'numRange'">
						<DesignListNumberRange
							v-model="queryForm[item.field]"
						></DesignListNumberRange>
					</template>
					<!--多选框/单选框/下拉选择框-->
					<template
						v-if="
							item.type === 'radio' ||
							item.type === 'checkbox' ||
							item.type === 'select'
						"
					>
						<DesignListCheckbox
							v-model="queryForm[item.field]"
							:multi="item.type !== 'radio'"
							:type="userQueryType"
							:list="queryMap[item.field].list"
							:field-value="getFieldValue(item)"
							:field-name="getFieldName(item)"
							:is-define="queryMap[item.field].isDefine"
							:is-required="queryMap[item.field].required"
							:define-type="queryMap[item.field].defineType"
							:define-date-type="
								queryMap[item.field].defineDateType
							"
							:define-picker-options="
								queryMap[item.field].definePickerOptions
							"
						></DesignListCheckbox>
					</template>
					<!--日期选择框-->
					<template v-if="item.type === 'date'">
						<wy-date-picker
							v-if="useWyDatePicker.includes(item.config.type)"
							v-model="queryForm[item.field]"
							class="query-date-picker"
							class-name="wy-border-bottom"
							:picker-options="
								(item.config && item.config.pickerOptions) ||
								null
							"
							:type="item.config.type"
							:unlink-panels="true"
							:value-format="setDateFormat(item.config.type)"
							placeholder="请选择"
							start-placeholder="请选择"
							end-placeholder="请选择"
							@change="handleChange(item.field)"
						></wy-date-picker>
						<el-date-picker
							v-else
							v-model="queryForm[item.field]"
							class="query-date-picker wy-border-bottom"
							:picker-options="
								(item.config && item.config.pickerOptions) ||
								null
							"
							:type="item.config.type"
							:unlink-panels="true"
							:value-format="setDateFormat(item.config.type)"
							placeholder="请选择"
							start-placeholder="请选择"
							end-placeholder="请选择"
							@change="handleChange(item.field)"
						></el-date-picker>
					</template>
					<!--弹出框-->
					<template v-if="item.type === 'box'">
						<el-input
							suffix-icon="el-icon-search"
							class="wy-pointer wy-border-bottom"
							:value="queryWord[item.field]"
							readonly
							placeholder="请选择"
							@click.native="clickBox(item)"
						></el-input>
					</template>
				</el-form-item>
			</template>
		</el-form>
		<div class="wy-design-list-query_btn-group">
			<el-button
				class="wy-design-list-query__btn"
				type="primary"
				size="mini"
				:disabled="isLoadingData"
				@click="save(true)"
				>查询</el-button
			>
			<el-button
				class="wy-design-list-query__btn"
				type="primary"
				size="mini"
				:disabled="isLoadingData"
				@click="saveConfig"
				>查询并保存筛选</el-button
			>
			<el-button
				class="wy-design-list-query__btn"
				size="mini"
				@click="cancel"
				>取消</el-button
			>
			<div v-if="userQueryType === 2" class="wy-design-list-query_set">
				<el-select
					v-if="name"
					v-model="userQuerySchema"
					popper-class="wy-design-list-query_top-bar-schema"
					class="wy-design-list-query_top-bar_schema"
					size="mini"
					placeholder="请选择查询方案"
					value-key="name"
					:disabled="isLoadingData"
					@change="changeQuerySchema"
				>
					<el-option
						label="默认查询方案"
						:value="userQueryDefault"
					></el-option>
					<el-option
						v-for="item in userQueryConfig"
						:key="item.name"
						:label="item.name"
						:value="item"
					>
						<span>{{ item.name }}</span>
						<el-button
							type="text"
							class="wy-design-list-query_delete el-icon-close"
							title="删除查询方案"
							@click.stop="delUserConfig(item)"
						></el-button>
					</el-option>
				</el-select>
				<el-tooltip
					class="wy-design-list-query_item"
					effect="dark"
					content="侧边栏显示高级查询"
					placement="top-start"
				>
					<i
						class="iconfont icon-query-1"
						@click="changeShowType(1)"
					></i>
				</el-tooltip>
				<el-tooltip
					class="wy-design-list-query_item"
					effect="dark"
					content="页面显示高级查询"
					placement="top-start"
				>
					<i
						class="iconfont icon-query-2 wy-design-list-query_active"
					></i>
				</el-tooltip>
				<el-tooltip
					v-if="expendQueryForm"
					class="wy-design-list-query_item"
					effect="dark"
					content="收缩显示搜索表单"
					placement="top-start"
				>
					<i
						class="iconfont icon-zoom-in"
						@click="expendQueryForm = !expendQueryForm"
					></i>
				</el-tooltip>
				<el-tooltip
					v-else
					class="wy-design-list-query_item"
					effect="dark"
					content="展开显示搜索表单"
					placement="top-start"
				>
					<i
						class="iconfont icon-zoom-out"
						@click="expendQueryForm = !expendQueryForm"
					></i>
				</el-tooltip>
			</div>
		</div>
	</div>
</template>
<script>
import { extend, isArray } from 'tool'
import DesignListCheckbox from '../design-list-checkbox/index.vue'
import DesignListNumberRange from '../design-list-number-range/index.vue'
import DesignListSchema from '../design-list-schema/index.vue'
export default {
	name: 'DesignListQuery',
	components: {
		DesignListCheckbox,
		DesignListNumberRange
	},
	props: {
		name: String,
		panelStatus: Boolean,
		project: String,
		query: Array,
		visible: Boolean,
		auto: Boolean,
		queryLabelWidth: {
			type: String,
			default: '120px'
		},
		// 是否默认展开页内的高级查询表单
		defaultExpendQueryForm: {
			type: Boolean,
			default: false
		},
		// 使用上次的查询方案
		useLastQuerySchema: {
			type: Boolean,
			default: false
		},
		// 切换查询方案立即搜索
		schemaSearchImmediate: {
			type: Boolean,
			default: true
		},
		// tableData的加载状态
		isLoadingData: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			queryOldForm: {},
			queryForm: {},
			queryOldWord: {},
			queryMap: {},
			queryWord: {},
			relationMap: {},
			rules: {},
			loading: true,
			isInit: false,
			// 用户查询方案显示
			userQuerySchema: {
				name: 'default'
			},
			useWyDatePicker: ['daterange', 'monthrange', 'yearrange'],
			// 默认查询条件
			userQueryDefault: {},
			// 用户查询方案自定义配置
			userQueryConfig: [],
			// 用户高级查询显示自定义配置 1：侧边栏布局；2：页面流布局
			userQueryType: 1,
			expendQueryForm: false // 是否展开搜索表单，这个参数仅在页面显示 高级查询条件时可用
		}
	},
	computed: {
		queryList() {
			// 高级查询条件
			const arr = []
			this.query.forEach(item => {
				arr.push(item)
			})
			return arr
		},
		labelPosition() {
			if (this.userQueryType === 1) {
				return 'top'
			} else if (this.userQueryType === 2) {
				return 'left'
			}
			return 'top'
		}
	},
	watch: {
		visible(value) {
			if (value) {
				// 显示时
				this.initSelectData()
			}
		},
		project() {
			this.handleChange('project')
			this.getUserQueryConfig()
		}
	},
	created() {
		this.init()
	},
	methods: {
		/**
		 * @function init
		 * @description 初始化
		 */
		async init() {
			this.expendQueryForm = this.defaultExpendQueryForm
			this.userQueryType = await this.getUserQueryType()
			await this.getUserQueryConfig()
			this.initData()
			// 显示时
			if (this.visible) {
				await this.initSelectData()
			}
		},
		/**
		 * @function init
		 * @description 初始化数据，form、map
		 */
		async initData() {
			const query = this.query
			const queryMap = {}
			const queryWord = {}
			const relationMap = {}
			const form = {}
			const rules = {}
			for (const item of query) {
				// 设置查询map
				// 设置关联字段关系
				if (item.relation) {
					// 如果当前字段有关联关系
					item.relation.forEach(relation => {
						if (!relationMap[relation]) {
							relationMap[relation] = [].concat(item.field)
						} else {
							relationMap[relation].push(item.field)
						}
					})
				}
				if (item.required) {
					// 必填
					// 校验wy-date-picker日期需要特殊处理
					if (this.useWyDatePicker.includes(item.config.type)) {
						rules[item.field] = [].concat({
							required: true,
							validator: (rule, val, cb) => {
								if (
									rule.required &&
									(!val || !val[0] || !val[1])
								) {
									cb(new Error(rule.message))
								} else {
									cb()
								}
							},
							message: item.name + '为必填项'
						})
					} else {
						rules[item.field] = [].concat({
							required: true,
							message: item.name + '为必填项'
						})
					}
				} else if (
					item.type === 'date' &&
					this.useWyDatePicker.includes(item.config.type)
				) {
					rules[item.field] = [].concat({
						required: false,
						validator: (rule, val, cb) => {
							if (val && (!val[0] || !val[1])) {
								cb(new Error(rule.message))
							} else {
								cb()
							}
						},
						message: '请填写完整日期区间'
					})
				}
				// 如果传入自定义的rule，合并进去
				if (item.rule) {
					if (!rules[item.field]) rules[item.field] = []
					rules[item.field].push({
						validator: item.rule
					})
				}
				if (item.value !== null) {
					if (item.type === 'box' && typeof item.value === 'object') {
						form[item.field] = item.value.id || null
						queryWord[item.field] = item.value.name || null
					} else {
						form[item.field] = item.value
						queryWord[item.field] = item.value
					}
				} else {
					form[item.field] = null
					queryWord[item.field] = null
				}
				if (item.type === 'checkbox') {
					// 多选框
					form[item.field] = form[item.field] || []
					item.list = item.config.list || []
					//  如果存在自定义设置  defineType 是自定义类型  目前只支持 date和numRange defineDateType为日期格式选择
					if (item.config.define) {
						item.defineType = item.config.define.type || ''
						item.defineDateType = item.config.define.dateType || ''
						item.definePickerOptions =
							item.config.define.pickerOptions || null
					}
				}
				if (item.type === 'radio') {
					// 多选框
					item.list = item.config.list || []
					//  如果存在自定义设置  defineType 是自定义类型  目前只支持 date和numRange
					if (item.config.define) {
						item.defineType = item.config.define.type || ''
						item.defineDateType = item.config.define.dateType || ''
						item.definePickerOptions =
							item.config.define.pickerOptions || null
					}
				}
				queryMap[item.field] = item
			}
			this.queryMap = queryMap
			this.queryWord = queryWord
			this.relationMap = relationMap
			this.queryForm = form
			this.queryOldForm = extend(true, {}, form)
			this.userQueryDefault = {
				name: 'default',
				value: Object.assign({}, form),
				word: Object.assign({}, queryWord)
			}
			this.rules = rules
			this.loading = false
			this.isInit = true

			// 如果有设置默认查询方案
			this.useLastQuerySchema && (await this.setInitSchema())
			if (this.auto) {
				this.save()
			}
		},
		/**
		 * @function setInitSchema
		 * @description 设置初始化的查询方案
		 */
		setInitSchema() {
			return new Promise(resolve => {
				const o = this.userQueryConfig.find(
					el => el.isActive && el.isActive === true
				)
				if (o) {
					this.userQuerySchema = o
					this.changeQuerySchema(true)
				}
				resolve()
			})
		},
		/**
		 * @function initSelectData
		 * @description 初始化checkbox、radio类型的list列表
		 */
		async initSelectData() {
			this.loading = true
			for (const field in this.queryMap) {
				const type = this.queryMap[field].type
				if (
					['checkbox', 'radio', 'select'].includes(type) &&
					this.queryMap[field].list.length === 0 &&
					this.queryMap[field].config.type === 1
				) {
					this.queryMap[field].list = await this.getSelectList(
						this.queryMap[field]
					)
				}
			}
			this.loading = false
		},
		/**
		 * @function getUserQueryType
		 * @description 获取页面的查询显示方式
		 */
		getUserQueryType() {
			return new Promise(resolve => {
				this.$userConfig({
					type: 'get',
					configType: 'designListQuery',
					configKey: 'queryShowType'
				}).then(data => {
					if (data === 2) {
						resolve(2)
					} else {
						resolve(1)
					}
				})
			})
		},
		/**
		 * @function getUserConfig
		 * @description 获取页面的查询方案
		 */
		getUserQueryConfig() {
			return new Promise(resolve => {
				if (!this.name) {
					resolve([])
				}
				this.$userConfig({
					type: 'get',
					configType: this.name,
					configKey: 'query',
					projectID: this.project
				}).then(data => {
					if (data) {
						this.userQueryConfig = [].concat(data)
						resolve()
					} else {
						this.userQueryConfig = []
						resolve()
					}
				})
			})
		},
		/**
		 * @param data
		 * @function getUserConfig
		 * @description 获取动态值
		 */
		getSelectList(data) {
			return new Promise(resolve => {
				const relation = data.relation
				if (data.config.type === 0) {
					// 固定值
					resolve(data.config.list)
				} else if (!data.list || data.list.length === 0) {
					// 动态值为空时
					const query = {
						project: this.project
					}
					if (relation) {
						relation.forEach(item => {
							if (item !== 'project') {
								if (isArray(this.queryForm[item])) {
									query[item] = this.queryForm[item].join(',')
								} else {
									query[item] = this.queryForm[item]
								}
							}
						})
					}
					if (data.config.url) {
						// 从url去获取
						this.$axios({
							url: data.config.url,
							data: query
						}).then(res => {
							resolve(res.data)
						})
					} else if (
						data.config.event &&
						typeof data.config.event === 'function'
					) {
						data.config.event(query).then(data => {
							resolve(data)
						})
					}
				}
			})
		},
		/**
		 * @function getFieldValue
		 * @private
		 * @description 获取当前字段的显示配置
		 * @param item
		 * @params {object} item 字段配置对象
		 */
		getFieldValue(item) {
			if (item.config.fieldValue) {
				return item.config.fieldValue
			} else {
				return 'value'
			}
		},
		/**
		 * @function getFieldName
		 * @private
		 * @description 获取当前字段的显示配置
		 * @param item
		 * @params {object} item 字段配置对象
		 */
		getFieldName(item) {
			if (item.config.fieldName) {
				return item.config.fieldName
			} else {
				return 'name'
			}
		},
		/**
		 * @function getFieldName
		 * @private
		 * @description 获取当前字段的日期类型
		 * @param type
		 * @params {string} type 日期类型
		 */
		setDateFormat(type) {
			if (['date', 'dates', 'daterange'].includes(type)) {
				return 'yyyy-MM-dd'
			} else if (['month', 'monthrange'].includes(type)) {
				return 'yyyy-MM'
			} else if (['year', 'yearrange'].includes(type)) {
				return 'yyyy'
			} else if (['datetime', 'datetimerange'].includes(type)) {
				return 'yyyy-MM-dd HH:mm:ss'
			}
		},
		/**
		 * @function setQueryWord
		 * @private
		 * @description 设置查询结果的文字信息
		 */
		setQueryWord() {
			const word = []
			for (const field in this.queryMap) {
				// 设置选项的文本内容
				// 关联是否需要清空的值
				const data = this.queryMap[field]
				let	value = this.queryForm[field]
				// field === project 时，set为null
				if (!data) {
					continue
				}
				// 如果是下拉框
				if (data.type === 'checkbox') {
					// 如果是多选下拉框
					const result = []
					value = (value && [].concat(value)) || []
					value.forEach(id => {
						const fieldValue = data.config.fieldValue || 'value'
						const fieldName = data.config.fieldName || 'name'
						const cur = this.queryMap[field].list.find(
							p => p[fieldValue] === id
						)
						if (cur) {
							result.push(cur[fieldName])
						}
					})
					if (result.length > 0) {
						this.queryWord[field] = result.join(',')
					} else {
						//  自定义选项的value本身为Array
						if (value.length > 0) {
							const val = value[0]
							this.queryWord[field] = val[0].replace(',', '~')
						} else {
							this.queryWord[field] = ''
						}
					}
				} else if (data.type === 'radio') {
					//  针对自定义选项 为区分自定义与常规选项 自定义选项的值为Array
					if (isArray(value) && value.length > 0) {
						const val = value[0]
						this.queryWord[field] = val[0].replace(',', '~')
					} else {
						const fieldValue = data.config.fieldValue || 'value'
						const fieldName = data.config.fieldName || 'name'
						const cur = this.queryMap[field].list.find(
							p => p[fieldValue] === value
						)
						if (cur) {
							this.queryWord[field] = cur[fieldName]
						} else {
							this.queryWord[field] = ''
						}
					}
				} else if (data.type === 'date' && isArray(value)) {
					// 日期选择框区间
					this.queryWord[field] = value.join('~')
				} else if (data.type === 'numRange' && isArray(value)) {
					// 数量选择框区间
					if (value[0] && value[1]) {
						this.queryWord[field] = value.join('~')
					} else if (value[0]) {
						this.queryWord[field] = '>' + value
					} else if (value[1]) {
						this.queryWord[field] = '<' + value
					}
				} else if (data.type === 'box') {
					this.queryWord[field] = this.queryWord[field]
				} else {
					this.queryWord[field] = value
				}
				if (this.queryWord[field]) {
					word.push({
						id: field,
						name: data.name,
						value: this.queryWord[field]
					})
				}
			}
			return word
		},
		/**
		 * @param field
		 * @param map
		 * @function changeField
		 * @private
		 * @description 改变字段配置
		 */
		changeField(field, map) {
			for (const i in map) {
				if (map.hasOwnProperty(i)) {
					if (i === 'visible') {
						// 显示隐藏
						this.queryMap[field].hide = !map[i]
						this.queryForm[field] = null
						this.queryWord[field] = null
						this.queryMap[field].list = []
					} else if (i === 'value') {
						// 改变值
						this.queryForm[field] = map[i]
						this.queryWord[field] = map.word || map[i]
					} else if (i === 'list') {
						this.queryMap[field].list = []
						this.queryForm[field] = null
						this.queryWord[field] = null
					}
				}
			}
		},
		/**
		 * @param field
		 * @function clearField
		 * @private
		 * @description 清空字段的值
		 */
		clearField(field) {
			if (this.queryMap[field].type === 'checkbox') {
				this.queryForm[field] = []
			} else {
				this.queryForm[field] = null
			}
			this.queryWord[field] = null
			this.save()
		},
		/**
		 * @param arr
		 * @function resetQuery
		 * @private
		 * @description 还原查询条件
		 */
		resetQuery(arr) {
			arr.forEach(item => {
				if (this.queryForm[item.key]) {
					this.queryForm[item.key] = item.value
					this.queryMap[item.key].word = item.word
				}
			})
			this.save()
		},
		/**
		 * @param data
		 * @function clickBox
		 * @private
		 * @description 自定义事件
		 */
		clickBox(data) {
			// 自定义
			// 直接传入方法
			if (typeof data.config.event === 'function') {
				data.config.event({
					curValue: this.queryForm[data.field],
					curWord: this.queryWord[data.field],
					curQueryForm: this.queryForm,
					callback: ({ id, name }) => {
						// 触发事件，回调返回值
						this.queryForm[data.field] = id
						this.queryWord[data.field] = name
						this.handleChange(data.field)
					}
				})
			} else {
				// 传入事件名称
				this.$emit('clickBox', {
					event: data.config.event,
					data: {
						curValue: this.queryForm[data.field],
						curWord: this.queryWord[data.field],
						curQueryForm: this.queryForm,
						callback: ({ id, name }) => {
							// 触发事件，回调返回值
							this.queryForm[data.field] = id
							this.queryWord[data.field] = name
							this.handleChange(data.field)
						}
					}
				})
			}
		},
		/**
		 * @param length
		 * @function setQueryItemVisible
		 * @private
		 * @description 设置多项目隐藏查询条件
		 */
		setQueryItemVisible(length) {
			// 设置多项目隐藏查询条件
			let visible = true
			if (length > 1) {
				// 多项目
				visible = false
			}
			for (const i in this.queryMap) {
				const item = this.queryMap[i]
				if (item.hideByProjects) {
					this.changeField(item.field, { visible })
				}
			}
		},
		/**
		 * @param field
		 * @function handleChange
		 * @private
		 * @description 值选择发生变化时
		 */
		handleChange(field) {
			// 值选择发生变化时
			// 设置选项的文本内容
			// 关联是否需要清空的值
			if (this.relationMap[field]) {
				// 如果有受影响的字段
				this.relationMap[field].forEach(item => {
					// 清空表单对应的值
					this.queryWord[item] = null
					this.queryForm[item] = null
					this.queryMap[item].word = null
					if (
						['checkbox', 'radio'].includes(
							this.queryMap[item].type
						) &&
						this.queryMap[item].config.type === 1
					) {
						// 如果是下拉框，且动态取值的，重新赋值为空，下次点击重新获取
						this.getSelectList(this.queryMap[item])
						this.queryForm[item] = []
					}
				})
			}
		},
		/**
		 * @function save
		 * @private
		 * @description 查询
		 * @param {boolean} forceLoad 是否强制重新搜索
		 */
		save(forceLoad = false) {
			return new Promise(resolve => {
				const word = this.setQueryWord()
				if (this.$refs.form) {
					this.$refs.form.validate(status => {
						if (!status) {
							this.$emit('validate', {
								value: this.queryForm,
								word
							})
							return
						}
						this.$emit('change', {
							value: this.queryForm,
							word,
							forceLoad
						})

						this.queryOldForm = extend(true, {}, this.queryForm)
						resolve()
					})
				} else {
					this.$emit('change', {
						value: this.queryForm,
						word,
						forceLoad
					})
					this.queryOldForm = extend(true, {}, this.queryForm)
					resolve()
				}
			})
		},
		/**
		 * @function saveConfig
		 * @private
		 * @description 触发保存并保存查询条件
		 */
		saveConfig() {
			// 查询成功后保存查询条件
			this.$box({
				title: '保存查询方案',
				width: '400px',
				height: '80px',
				showMax: false,
				template: DesignListSchema,
				templateOpt: {
					list: this.userQueryConfig
				},
				onOk: instance => {
					return new Promise(resolve => {
						instance.submit().then(name => {
							const userQueryConfig = {
								name,
								value: Object.assign({}, this.queryForm),
								word: Object.assign({}, this.queryWord)
							}
							this.userQueryConfig.push(userQueryConfig)
							this.userQuerySchema = userQueryConfig
							this.changeQuerySchema()
							resolve()
						})
					})
				}
			})
		},
		/**
		 * @function cancel
		 * @private
		 * @description 取消查询
		 */
		cancel() {
			this.queryForm = extend(true, {}, this.queryOldForm)
			this.$emit('cancel')
		},
		/**
		 * @param type
		 * @function changeShowType
		 * @private
		 * @description 切换显示类型
		 */
		changeShowType(type) {
			this.userQueryType = type
			// 查询成功后保存查询条件
			this.$userConfig({
				type: 'post',
				configType: 'designListQuery',
				configKey: 'queryShowType',
				configContent: this.userQueryType
			})
		},
		/**
		 * @function changeQuerySchema
		 * @param data
		 * @private
		 * @description 切换查询方案
		 * @param {boolean} isInit 是否页面初始化，如果是，不需要保存
		 */
		changeQuerySchema(data) {
			const config = this.userQuerySchema
			const queryForm = {}
			const queryWord = {}
			const searchImmediate =
				data instanceof Object &&
				data.hasOwnProperty('schemaSearchLater')
					? !data.schemaSearchLater
					: this.schemaSearchImmediate // 动态配置参数schemaSearchLater优先级比schemaSearchImmediate框架设置要高，如果设置了schemaSearchLater为
			for (const i in config.value) {
				if (config.value.hasOwnProperty(i)) {
					queryForm[i] = config.value[i]
					queryWord[i] = config.word[i]
				}
			}
			this.queryForm = queryForm
			this.queryWord = queryWord

			searchImmediate &&
				this.$nextTick(() => {
					this.save(true)
					// 设置当前激活的方案为默认方案，页面初始化时，data传入的是true
					data !== true && this.switchActiveSchema()
				})
		},
		/**
		 * @function switchActiveSchema
		 * @private
		 * @description 切换初始化查询方案
		 */
		switchActiveSchema() {
			const config = this.userQuerySchema
			this.userQueryConfig.forEach(el => {
				if (el.name === config.name && !el.isActive) {
					el.isActive = true
				} else {
					el.isActive = false
				}
			})
			// 保存方案
			this.$userConfig({
				type: 'post',
				configType: this.name,
				configKey: 'query',
				configContent: JSON.stringify(this.userQueryConfig)
			})
		},
		delUserConfig(data) {
			const index = this.userQueryConfig.findIndex(
				p => p.name === data.name
			)
			if (index !== -1) {
				this.userQueryConfig.splice(index, 1)
				this.$userConfig({
					type: 'post',
					configType: this.name,
					configKey: 'query',
					configContent: JSON.stringify(this.userQueryConfig)
				}).then(() => {
					if (this.userQuerySchema.name === data.name) {
						this.userQuerySchema = this.userQueryDefault
						this.changeQuerySchema()
					}
				})
			}
		}
	}
}
</script>
