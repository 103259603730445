var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wy-question", style: { width: _vm.width } },
    [
      _c("div", { staticClass: "wy-question__box" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "wy-question-content", style: { height: _vm.height } },
          [
            _c(
              "div",
              { staticClass: "wy-question-content-tree" },
              [
                _c("el-tree", {
                  ref: "tree",
                  attrs: {
                    props: _vm.treeProps,
                    "node-key": "id",
                    load: _vm.loadNode,
                    "check-strictly": "",
                    lazy: "",
                    "show-checkbox": "",
                  },
                  on: {
                    "check-change": _vm.handleClickTree,
                    "node-click": _vm.nodeClick,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "wy-question-footer" }, [
          _c(
            "button",
            {
              staticClass: "wy-question_btn wy-question_btn-theme",
              attrs: { theme: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("\n\t\t\t\t确定\n\t\t\t")]
          ),
          _c(
            "button",
            { staticClass: "wy-question_btn", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wy-question-header" }, [
      _c("h2", { staticClass: "wy-question_title" }, [_vm._v("选择问题类型")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }