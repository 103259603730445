import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from './question-box.vue'

const defaultOpt = {
	width: '600px',
	height: '400px',
	multi: false,
	onCancel: Function,
	onOk: Function
}

let instance

/**
 * @param opt
 */
export default function changeQuestion(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.value = options.value
	instance.width = options.width
	instance.height = options.height
	instance.onCancel = options.onCancel
	instance.onOk = options.onOk
	instance.multi = options.multi

	Vue.nextTick(() => {
		instance.visible = true
	})
	Vue.$plugin.push({
		name: 'question-box',
		id: instance._uid,
		instance
	})

	return instance
}
