<template>
	<div class="wy-layout-page">
		<header class="wy-layout-page_top">
			<div class="wy-layout-page_header">
				<h1 class="wy-layout-page_title" data-guide="layout-page-title">
					<el-tooltip content="点击查看功能指引">
						<i
							v-if="intro"
							class="iconfont icon-guide"
							@click="showIntro(true)"
						></i>
					</el-tooltip>
					{{ title }}
				</h1>
				<div class="wy-layout-page_btn-panel">
					<slot name="btn-group"></slot>
				</div>
			</div>
		</header>
		<section class="wy-layout-page_main">
			<slot name="content"></slot>
		</section>
		<footer class="wy-layout-page_bottom">
			<div class="wy-layout-page_footer">
				<slot name="foot"></slot>
			</div>
		</footer>
	</div>
</template>
<script>
export default {
	name: 'WyLayoutPage',
	props: {
		title: String,
		intro: Object,
		name: String
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.showIntro()
	},
	methods: {
		showIntro(replay) {
			if (this.intro) {
				this.$intro({
					el: this.$el,
					options: this.intro,
					replay
				})
			}
		}
	}
}
</script>
