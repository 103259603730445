<template>
	<div class="wy-design-query-checkbox">
		<!--侧边栏的选择查询采用select下拉-->
		<template v-if="type === 1">
			<el-select
				v-model="selectVal"
				class="wy-border-bottom"
				filterable
				:multiple="multi"
				clearable
				@change="handleClick"
			>
				<el-option
					v-for="item in list"
					:key="item[fieldValue]"
					:value="item[fieldValue]"
					:label="item[filedName]"
				></el-option>
				<el-option
					v-if="isDefine"
					label="自定义"
					value="define"
				></el-option>
			</el-select>
			<li v-if="defineActive && defineType == 'date'">
				<wy-date-picker
					v-if="['daterange', 'monthrange'].includes(defineDateType)"
					v-model="defineVal"
					class="wy-border-bottom wy-design-query-checkbox_define"
					:type="defineDateType"
					:picker-options="definePickerOptions"
					:value-format="setDateFormat(defineDateType)"
					:unlink-panels="true"
					@change="changeDate"
				></wy-date-picker>
				<el-date-picker
					v-else
					v-model="defineVal"
					class="wy-border-bottom wy-design-query-checkbox_define"
					:type="defineDateType"
					:picker-options="definePickerOptions"
					:value-format="setDateFormat(defineDateType)"
					:unlink-panels="true"
					@change="changeDate"
				></el-date-picker>
			</li>
			<li v-if="defineActive && defineType == 'numRange'">
				<DesignListNumberRange
					v-model="defineVal"
					class="wy-design-query-checkbox_define"
					@input="changeNum"
				></DesignListNumberRange>
			</li>
			<li v-if="defineActive && defineType == 'input'">
				<el-input
					v-model="defineVal"
					size="mini"
					class="wy-border-bottom"
					@input="changeDate"
				></el-input>
			</li>
		</template>
		<template v-if="type === 2">
			<ul>
				<li
					:class="{ active: curValue.length === 0 && !defineActive }"
					@click="handleClick(null)"
				>
					<span v-if="!isRequired">不限{{ JSON.stringify() }}</span>
				</li>
				<li
					v-for="item in list"
					:key="item[fieldValue]"
					:class="{ active: curValue.includes(item[fieldValue]) }"
					@click="handleClick(item)"
				>
					<span>{{ item[filedName] }}</span>
				</li>
				<li
					v-if="isDefine"
					:class="{ active: defineActive }"
					@click="handleClick('define')"
				>
					<span>自定义</span>
				</li>
				<li v-if="defineActive && defineType == 'date'">
					<wy-date-picker
						v-if="
							['daterange', 'monthrange'].includes(defineDateType)
						"
						v-model="defineVal"
						class="wy-border-bottom"
						:type="defineDateType"
						:picker-options="definePickerOptions"
						:value-format="setDateFormat(defineDateType)"
						:unlink-panels="true"
						@change="changeDate"
					></wy-date-picker>
					<el-date-picker
						v-else
						v-model="defineVal"
						class="wy-border-bottom"
						:type="defineDateType"
						:picker-options="definePickerOptions"
						:value-format="setDateFormat(defineDateType)"
						:unlink-panels="true"
						@change="changeDate"
					></el-date-picker>
				</li>
				<li v-if="defineActive && defineType == 'numRange'">
					<DesignListNumberRange
						v-model="defineVal"
						@input="changeNum"
					></DesignListNumberRange>
				</li>
				<li v-if="defineActive && defineType == 'input'">
					<el-input
						v-model="defineVal"
						size="mini"
						class="wy-border-bottom"
						@input="changeDate"
					></el-input>
				</li>
			</ul>
		</template>
	</div>
</template>
<script>
import { isArray } from 'tool'
import DesignListNumberRange from '../design-list-number-range/index.vue'
export default {
	name: 'DesignListCheckbox',
	components: {
		DesignListNumberRange
	},
	props: {
		// 高级查询的布局方式，1：流式布局，2：侧边栏布局
		type: {
			type: Number
		},
		// 多选时为数组，单选时为字符串
		value: {
			type: [Array, String]
		},
		// 选项的列表
		list: {
			type: Array
		},
		// 字段值的key
		fieldValue: {
			type: String,
			default: 'value'
		},
		// 字段显示的key
		filedName: {
			type: String,
			default: 'name'
		},
		// 是否为多选
		multi: {
			type: Boolean
		},
		isDefine: Boolean,
		isRequired: Boolean,
		defineType: {
			type: String,
			default: 'date'
		},
		defineDateType: {
			type: String,
			default: 'dateRange'
		},
		definePickerOptions: {
			type: Object,
			default: () => ({
				disabledDate() {
					return false
				}
			})
		}
	},
	data() {
		return {
			curValue: '', // 当前的值
			defineActive: false,
			defineVal: [],
			selectVal: []
		}
	},
	watch: {
		value() {
			this.init()
		}
	},
	created() {
		if (this.multi) {
			this.selectVal = []
		} else {
			this.selectVal = ''
		}
		this.init()
	},
	methods: {
		/**
		 * @function init
		 * @description 初始化，将props的value传入
		 */
		init() {
			const dateType = ['date', 'month', 'year', 'datetime']
			if (this.value && this.value.length > 0) {
				const _arr = this.value[0]
				if (isArray(_arr) && _arr.length > 0) {
					//  自定义
					this.defineActive = true
					const val = _arr[0].split(',')
					//  日期不为区间 或 类型为自定义的输入框
					if (
						dateType.includes(this.defineDateType) ||
						this.defineType === 'input'
					) {
						this.defineVal = val[0]
					} else {
						this.defineVal = [val[0], val[1]]
					}
					if (this.multi) {
						this.selectVal = ['define']
					} else {
						this.selectVal = 'define'
					}
				} else {
					// 非自定义选项
					if (this.multi) {
						this.selectVal = [].concat(this.value)
					} else {
						this.selectVal = this.value
					}
				}
				this.curValue = [].concat(this.value)
			} else {
				this.curValue = []
				this.defineActive = false
				this.defineVal = []
				if (this.multi) {
					this.selectVal = []
				} else {
					this.selectVal = ''
				}
			}
		},
		/**
		 * @function handleClick
		 * @description 选中事件
		 * @param item
		 * @params {string} item 选中的对象，不限则为null
		 */
		handleClick(item) {
			// 判断是否有值，如果没有值，则表示不限制，
			if (item) {
				//  侧边栏下拉框
				if (this.type === 1) {
					//  多选时
					if (this.multi) {
						//  不选择自定义
						if (!item.includes('define')) {
							this.curValue = [].concat(item)
						} else {
							//  直接点击自定义 弹出自定义
							if (item.length == 0 && item[0] == 'define') {
								this.defineActive = true
							} else if (
								item.length > 1 &&
								item.indexOf('define') == 0
							) {
								//  已选自定义 点击其他选项时
								this.defineActive = false
								this.selectVal.splice(0, 1)
							} else {
								this.defineActive = true
								this.selectVal = ['define']
							}
						}
						this.curValue = [].concat(this.selectVal)
					} else {
						//  单选
						if (item == 'define') {
							this.defineActive = true
						} else {
							this.defineActive = false
						}
						this.curValue = [].concat(this.selectVal)
					}
					this.defineVal = []
				} else {
					if (item == 'define') {
						//  点击自定义 显示日期、数量区间  清空当前value
						this.defineActive = true
						this.defineVal = []
						this.curValue = []
						return
					}
					if (this.defineActive) {
						// 判断第一次点击除自定义之外的选项 清空当前value
						this.defineActive = false
						this.curValue = []
					}
					const index = this.curValue.indexOf(item[this.fieldValue])
					if (index !== -1) {
						// 如果在已选中列表，则删除
						this.curValue.splice(index, 1)
					} else {
						// 如果不在已选列表，则添加
						if (this.multi) {
							// 如果是多选，则追加
							this.curValue.push(item[this.fieldValue])
						} else {
							// 如果是单选，则直接改变值
							this.curValue = [].concat(item[this.fieldValue])
						}
					}
				}
			} else {
				// 不限
				this.curValue = []
			}
			if (this.multi) {
				// 如果是多选,则返回数组
				this.$emit('input', this.curValue)
			} else {
				// 如果是单选,则返回字符串
				this.$emit(
					'input',
					this.curValue.length > 0 ? this.curValue[0] : null
				)
			}
		},
		/**
		 * @function handleChange
		 * @description 针对日期选择 选择日期之后赋值  为了区分是自定义还是非自定义选项 传值数组
		 */
		changeDate() {
			if (isArray(this.defineVal)) {
				this.curValue = [this.defineVal.join(',')]
			} else {
				this.curValue = [this.defineVal]
			}
			this.$emit('input', [this.curValue])
		},
		/**
		 * @param type
		 * @function setDateFormat
		 * @description 针对日期选择 进行格式化显示
		 * @params {type} 日期显示格式
		 */
		setDateFormat(type) {
			if (['date', 'dates', 'daterange'].includes(type)) {
				return 'yyyy-MM-dd'
			} else if (['month', 'monthrange'].includes(type)) {
				return 'yyyy-MM'
			} else if (['year'].includes(type)) {
				return 'yyyy'
			} else if (['datetime', 'datetimerange'].includes(type)) {
				return 'yyyy-MM-dd HH:mm:ss'
			}
		},
		/**
		 * @param num
		 * @function changeNum
		 * @description 针对数量区间 点击确定时进行赋值 为了区分是自定义还是非自定义选项 传值数组
		 * @params {num} 数字区间 Array
		 */
		changeNum(num) {
			this.curValue = [num.join(',')]
			this.$emit('input', [this.curValue])
		}
	}
}
</script>
