var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "wy-box_wrap",
            style: { "z-index": _vm.zIndex },
            attrs: { tabindex: "-1" },
          },
          [
            _c(
              "div",
              {
                ref: "wyBox",
                staticClass: "wy-box",
                class: {
                  "wy-box_one-btn": _vm.btns === 1,
                  "wy-box_two-btn": _vm.btns === 2,
                  "wy-box_no-btn": _vm.btns === 0,
                },
                style: {
                  width: _vm.mWidth || _vm.options.width,
                  maxWidth: !_vm.max ? _vm.maxW : "",
                },
              },
              [
                _vm.options.showHeader
                  ? _c("div", {
                      staticClass: "wy-box_header",
                      domProps: { innerHTML: _vm._s(_vm.options.title) },
                    })
                  : _vm._e(),
                _vm.options.message && !_vm.options.template
                  ? _c("div", {
                      staticClass: "wy-box_content",
                      style: {
                        height: _vm.mHeight || _vm.options.height,
                        maxHeight: !_vm.max ? _vm.maxH : "",
                        minHeight: _vm.mHeight,
                      },
                      domProps: { innerHTML: _vm._s(_vm.options.message) },
                    })
                  : _vm._e(),
                _vm.options.template && !_vm.options.message
                  ? _c(
                      "div",
                      {
                        staticClass: "wy-box_content",
                        class: { "wy-box_no-header": !_vm.options.showHeader },
                        style: {
                          height: _vm.mHeight || _vm.options.height,
                          maxHeight: !_vm.max ? _vm.maxH : "",
                          minHeight: _vm.mHeight,
                        },
                      },
                      [_c("div")]
                    )
                  : _vm._e(),
                _vm.options.showBtn
                  ? _c("div", { staticClass: "wy-box_footer" }, [
                      _vm.options.showOkBtn
                        ? _c(
                            "button",
                            {
                              staticClass: "wy-box_btn wy-box_btn-theme",
                              on: { click: _vm.handleOk },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.options.okBtnName) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.options.showCancelBtn
                        ? _c(
                            "button",
                            {
                              staticClass: "wy-box_btn",
                              on: { click: _vm.handleCancel },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.options.cancelBtnName) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "wy-box_ico" }, [
                  _c("div", { staticClass: "wy-box_filter" }),
                  _vm.options.showMax && _vm.max
                    ? _c("i", {
                        staticClass: "wy-box_max iconfont icon-tuichuzhuanhuan",
                        attrs: { title: "恢复默认" },
                        on: { click: _vm.handleMax },
                      })
                    : _vm._e(),
                  _vm.options.showMax && !_vm.max
                    ? _c("i", {
                        staticClass: "wy-box_max iconfont icon-quanping",
                        attrs: { title: "最大化" },
                        on: { click: _vm.handleMax },
                      })
                    : _vm._e(),
                  _vm.options.showClose
                    ? _c("i", {
                        staticClass: "wy-box_close iconfont icon-del",
                        attrs: { title: "关闭" },
                        on: { click: _vm.handleClose },
                      })
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }