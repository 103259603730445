<template>
	<div class="wy-text-fold" :class="{ show: show }">
		<div>
			{{ currentText }}
			<span
				v-show="!show && showFoldText"
				class="link"
				@click.stop="changeFold"
				>展开</span
			>
			<span
				v-show="show && showFoldText"
				class="link"
				@click.stop="changeFold"
				>收起</span
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WyTextFold',
	props: {
		text: String,
		// 显示长度
		length: {
			type: Number,
			default: 40
		}
	},
	data() {
		return {
			show: false,
			newText: null,
			currentText: null,
			showFoldText: false
		}
	},
	watch: {
		text() {
			this.show = false
			this.init()
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			if (this.text && this.text.length > this.length) {
				this.newText = this.text.substring(0, this.length) + '...'
				this.showFoldText = true
				this.currentText = this.newText
			} else {
				this.currentText = this.text
			}
		},
		changeFold() {
			this.currentText = this.show ? this.newText : this.text
			this.show = !this.show
		}
	}
}
</script>
