<template>
	<div class="wy-steps" :class="{ flex: flex }">
		<div class="wy-steps-tab">
			<div
				v-for="(item, index) in list"
				:key="index"
				class="wy-steps_item"
				:class="{ active: index == step, finish: index < step }"
			>
				<span v-if="index >= step" class="wy-steps_index">{{
					index + 1
				}}</span>
				<span
					v-if="index < step"
					class="wy-steps_index iconfont icon-correct"
				></span>
				<h4 class="wy-steps_title">{{ item.title }}</h4>
				<div
					v-if="index < list.length - 1"
					class="wy-steps_border"
				></div>
			</div>
		</div>
		<div class="wy-steps_content">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WySteps',
	props: {
		step: Number,
		flex: Boolean
	},
	data() {
		return {
			list: []
		}
	},
	computed: {},
	watch: {},
	created() {},
	methods: {}
}
</script>
