<template>
	<div class="wy-treeAndTable" :class="{ multi: multi }">
		<wy-split mode="horizontal" value="20%" min="10%" max="60%">
			<div slot="left" class="wy-treeTable-tree">
				<el-tree
					ref="tree"
					class="normal"
					:highlight-current="true"
					:expand-on-click-node="false"
					:data="treeData"
					:props="treeProps"
					:node-key="treeKey"
					:default-expanded-keys="defaultExpandedKeys"
					:empty-text="treeEmptyText"
					:lazy="lazy"
					:load="loadNode"
					@current-change="changeTree"
				></el-tree>
			</div>
			<wy-split
				slot="right"
				mode="horizontal"
				value="75%"
				min="10%"
				max="90%"
			>
				<div slot="left" class="wy-treeTable_flex">
					<div class="wy-treeTable-table">
						<el-table
							ref="table"
							height="100%"
							class="normal"
							:row-key="tableKey"
							:empty-text="tableEmptyText"
							:data="table.list"
							@selection-change="changeSelection"
							@row-click="handleRowClick"
						>
							<el-table-column
								v-if="multi"
								:reserve-selection="reserveSelection"
								:selectable="selectable"
								type="selection"
								width="55"
							></el-table-column>
							<el-table-column v-if="!multi" width="55">
								<template slot-scope="scope">
									<i
										v-if="!isChecked(scope.row)"
										class="iconfont icon-radio-normal"
									></i>
									<i
										v-if="isChecked(scope.row)"
										class="iconfont icon-radio-checked"
									></i>
								</template>
							</el-table-column>
							<el-table-column
								type="index"
								label="序号"
								width="50"
							></el-table-column>
							<el-table-column
								v-for="item in tableList"
								:key="item.id"
								:width="item.width"
								:prop="item.id"
								:label="item.name"
							>
								<template slot-scope="scope">
									<div
										v-if="item.clean"
										v-html="item.clean(scope.row)"
									></div>
									<div v-if="!item.clean">
										{{ scope.row[item.id] }}
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="wy-treeTable-pages">
						<wy-pages
							:current="table.current"
							:page-size="table.rowCount"
							:page-sizes="[10, 20, 100]"
							:total="table.total"
							@changePageSize="changePageSize"
							@changeCurrentPage="changeCurrentPage"
						></wy-pages>
					</div>
				</div>
				<div
					v-if="multi || showClear"
					slot="right"
					class="wy-treeTable-result"
				>
					<div class="wy-treeTable_bar">
						<span class="wy-selection"
							>已选择{{ selection.length }}个</span
						>
						<el-button type="text" @click="clearAll"
							>全部清除</el-button
						>
					</div>
					<div class="wy-treeTable_list">
						<ul>
							<li
								v-for="(item, index) in selection"
								:key="item.id"
							>
								<span>{{ item[tableKeyName] }}</span>
								<i
									class="el-icon-close"
									@click="clear(item, index)"
								></i>
							</li>
						</ul>
					</div>
				</div>
			</wy-split>
		</wy-split>
	</div>
</template>
<script>
import mixin from './mixins'
export default {
	name: 'WyTreeAndTable',
	mixins: [mixin],
	props: {
		keyword: {
			type: String
		},
		showClear: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			treeProps: {
				label: 'name',
				children: 'child',
				isLeaf: 'isLeaf'
			},
			treeData: [],
			table: {
				tree: null,
				list: [],
				current: 1,
				rowCount: 20,
				total: 0,
				searchPhrase: null
			},
			defaultExpandedKeys: [],
			treeLoaded: false,
			tableLoaded: false,
			searchTimeout: null,
			selection: [],
			curSelection: []
		}
	},
	computed: {
		treeEmptyText() {
			if (this.treeLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		},
		tableEmptyText() {
			if (this.tableLoaded) {
				return '暂无数据'
			} else {
				return '数据加载中...'
			}
		}
	},
	watch: {
		keyword(newVal, oldVal) {
			if (newVal === oldVal) {
				return
			}
			this.$delay.register('wyTreeAndTableBox', this.changeKeyword, 500)
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			if (this.currentSelection && this.currentSelection.length > 0) {
				if (this.multi) {
					this.$nextTick(() => {
						this.currentSelection.forEach(item => {
							this.$refs.table.toggleRowSelection(item, true)
						})
					})
				} else {
					this.selection = [].concat(this.currentSelection)
				}
			}
			!this.lazy && this.getTreeData()
		},
		isArrayFn(value) {
			if (typeof Array.isArray === 'function') {
				return Array.isArray(value)
			} else {
				return (
					Object.prototype.toString.call(value) === '[object Array]'
				)
			}
		},
		getTreeData() {
			this.$axios({
				url: this.treeUrl,
				data: this.treeFilter
			}).then(res => {
				let data = [],
					tree = {}
				this.treeLoaded = true
				if (
					this.cleanTreeData &&
					typeof this.cleanTreeData == 'function'
				) {
					data = this.cleanTreeData(res.data)
				} else {
					data = [].concat(res.data)
				}
				this.treeData = data
				if (data.length > 0) {
					tree = data[0]
				}
				this.defaultExpandedKeys = [].concat(tree[this.treeKey])
				this.$nextTick(() => {
					this.$refs.tree.setCurrentKey(tree[this.treeKey])
				})
				this.table.tree = tree
				this.getTableData()
			})
		},
		getTableData() {
			const filterData = Object.assign(
				{},
				{
					current: this.table.current,
					rowCount: this.table.rowCount,
					searchPhrase: this.table.keyword
						? encodeURIComponent(this.table.keyword)
						: null
				},
				this.tableFilter
			)
			if (this.isArrayFn(this.defineID)) {
				this.defineID.forEach(el => {
					this.$set(filterData, el, this.table.tree[el] || '')
				})
			} else if (typeof this.defineID === 'object') {
				Object.keys(this.defineID).forEach(el => {
					this.$set(
						filterData,
						el,
						this.table.tree[this.defineID[el]] || ''
					)
				})
			} else {
				this.$set(
					filterData,
					this.defineID,
					this.table.tree[this.treeKey] || ''
				)
			}
			this.tableLoaded = false

			this.$axios({
				url: this.tableUrl,
				data: filterData
			}).then(res => {
				this.tableLoaded = true
				if (
					this.cleanTableData &&
					typeof this.cleanTableData == 'function'
				) {
					this.table.list = this.cleanTableData(res.data.rows)
				} else {
					this.table.list = res.data.rows
				}
				this.table.current = res.data.current
				this.table.rowCount = res.data.rowCount
				this.table.total = res.data.total
			})
		},
		changeKeyword() {
			this.table.current = 1
			this.table.keyword = this.keyword
			this.getTableData()
		},
		changeTree(data) {
			if (JSON.stringify(data) === JSON.stringify(this.table.tree)) {
				return
			}
			if (this.type === 'warehouse') {
				if (data.isCompany === '3') {
					// 项目为3 其他组织结构 0 1 2
					this.defineID = 'projectID'
				} else {
					this.defineID = 'orgID'
				}
			}
			this.table.tree = data
			this.table.current = 1
			this.getTableData()
		},
		handleRowClick(row) {
			if (this.tableRowClick && typeof this.tableRowClick == 'function') {
				this.tableRowClick(row)
			}
			if (this.selectable && typeof this.selectable == 'function') {
				if (!this.selectable(row)) return
			}
			if (this.multi) {
				this.$refs.table.toggleRowSelection(row)
			} else {
				this.selection = [].concat(row)
				this.$refs.table.toggleRowSelection(row, true)
			}
		},
		changePageSize(rowCount) {
			this.table.current = 1
			this.table.rowCount = rowCount
			this.getTableData()
		},
		changeCurrentPage(current) {
			this.table.current = current
			this.getTableData()
		},
		changeSelection(selection) {
			if (this.multi) {
				this.selection = selection
			}
		},
		isChecked(data) {
			if (
				this.selection.length > 0 &&
				data[this.tableKey] === this.selection[0][this.tableKey]
			) {
				return true
			}
			return false
		},
		getSelection() {
			return this.selection.concat(this.curSelection)
		},
		clear(row, index) {
			const target = this.table.list.find(item => item.id === row.id)
			this.selection.splice(index, 1)
			this.$refs.table.toggleRowSelection(target, false)
		},
		clearAll() {
			this.selection = []
			this.curSelection = []
			this.$refs.table.clearSelection()
		},
		// added by saqqdy on 20190911
		loadNode(node, resolve) {
			if (!this.lazy) return
			const props = {}
			let levels = ''
			if (node.level > 0) {
				if (this.isArrayFn(this.defineLazyID)) {
					this.defineLazyID.forEach(el => {
						props[el] = node.data[el] || ''
					})
				} else if (typeof this.defineLazyID === 'object') {
					Object.keys(this.defineLazyID).forEach(el => {
						props[el] = node.data[this.defineLazyID[el]] || ''
					})
				} else {
					props[this.defineLazyID] =
						node.data[this.defineLazyID] || ''
				}
				levels = this.showNodeAll ? node.level : node.level + 1
			} else {
				// 需要显示全部的时候，level为0，传“”；不需要显示全部的时候，node.level为0，传1；
				levels = this.showNodeAll ? '' : node.level + 1
			}
			const filter = {
				...this.treeFilter,
				...props,
				[this.levelName]: levels
			}
			this.$axios({
				url: this.treeUrl,
				data: filter
			}).then(res => {
				if (res.data && res.data.length > 0) {
					let data = [],
						tree = res.data[0]
					if (
						this.cleanTreeData &&
						typeof this.cleanTreeData == 'function'
					) {
						data = this.cleanTreeData(res.data)
					} else {
						data = [].concat(res.data)
					}
					data = data.map(el => {
						if (!el.hasOwnProperty('level')) {
							el.level = levels
						}
						return el
					})
					// 首次加载初始化
					if (node.level === 0) {
						// 显示全部节点时，默认选中“全部”节点
						if (this.showNodeAll) {
							tree = {
								[this.treeProps.label]: '全部',
								[this.treeProps.children]: [],
								[this.treeProps.isLeaf]: false,
								[this.treeKey]: '',
								[this.levelName]: ''
							}
							data = [
								{
									[this.treeProps.label]: '全部',
									[this.treeProps.children]: data,
									[this.treeProps.isLeaf]: false,
									[this.treeKey]: '',
									[this.levelName]: ''
								}
							]
							if (this.isArrayFn(this.defineLazyID)) {
								this.defineLazyID.forEach(el => {
									tree[el] = ''
									data[0][el] = ''
								})
							} else if (typeof this.defineLazyID === 'object') {
								Object.keys(this.defineLazyID).forEach(el => {
									tree[el] = ''
									data[0][el] = ''
								})
							} else {
								tree[this.defineLazyID] = ''
								data[0][this.defineLazyID] = ''
							}
						}
						this.table.tree = tree
						this.table.current = 1
						// this.defaultExpandedKeys = tree[this.treeKey] && true && node.level < 3 ? [].concat(tree[this.treeKey]) : [];
						// 显示全部节点时，默认选中“全部”节点
						this.defaultExpandedKeys = this.showNodeAll ? [''] : []
						this.getTableData()
					}
					this.limitLevels &&
						node.level ===
							(this.showNodeAll
								? this.limitLevels
								: this.limitLevels - 1) &&
						data.forEach(el => {
							el[this.treeProps.isLeaf] = true
						})
					setTimeout(() => {
						resolve(data)
						// 首次加载初始化当前选中
						if (node.level === 0) {
							this.$nextTick(() => {
								tree.hasOwnProperty(this.treeKey) &&
									this.$refs.tree.setCurrentKey(
										tree[this.treeKey]
									)
							})
						}
					}, 500)
				} else {
					return resolve([])
				}
			})
		}
	}
}
</script>
