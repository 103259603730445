import Vue from 'vue'
import { extend, nextIndex } from 'tool'
import tmp from './money-define-box.vue'

const defaultOpt = {
	width: '800px',
	height: '560px',
	url: '',
	title: '',
	filter: {},
	onCancel: Function,
	onOk: Function
}

let instance

/**
 * @param opt
 */
export default function uploadFile(opt) {
	const VueConstructor = Vue.extend(tmp)
	if (!instance) {
		instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(instance.$el)
	}

	const options = extend(true, {}, defaultOpt, opt)
	instance.zIndex = nextIndex(5000, 10000)
	instance.width = options.width
	instance.height = options.height
	instance.url = options.url
	instance.filter = options.filter
	instance.title = options.title
	instance.onCancel = options.onCancel
	instance.onOk = options.onOk

	Vue.nextTick(() => {
		instance.visible = true
	})

	Vue.$plugin.push({
		name: 'wyMoneyDefineListBox',
		id: instance._uid,
		instance
	})

	return instance
}
