<template>
	<div v-if="visible" class="wy-progress-v2 row align-center justify-center">
		<div v-if="showProgress" class="wy-progress-v2_cont">
			<div class="wy-progress-v2_title">{{ title }}</div>
			<div class="wy-loader-gif row justify-center">
				<img
					:src="require('../../src/img/loading-1.gif')"
					alt="加载中"
				/>
			</div>
			<p class="text-center wy-progress-v2_tip">{{ message }}</p>
			<div class="wy-progress-v2__progress">
				<el-progress
					:percentage="percentage"
					status="success"
				></el-progress>
				<p class="text-center">{{ percentage }}/100%</p>
			</div>
		</div>
	</div>
</template>
<script>
/**
 * 遗留问题：
 * 后台接口没有返回出错的情况
 */
import { fixNumber } from 'tool'
export default {
	props: {
		url: {
			type: String
		},
		filter: {
			type: Object,
			default: () => ({})
		},
		cleanData: {
			type: Function
		},
		limit: {
			type: Number,
			default: 50
		},
		showProgress: {
			type: Boolean,
			default: true
		},
		interval: {
			type: Number,
			default: 2000
		},
		title: {
			type: String,
			default: '处理进度'
		},
		message: {
			type: String,
			default: '处理中，请稍后...'
		},
		success: {
			type: Function
		},
		error: {
			type: Function
		}
	},
	data() {
		return {
			percent: 0,
			loadTimes: 0,
			timer: null,
			visible: false
		}
	},
	computed: {
		percentage() {
			return fixNumber(
				(this.loadTimes / this.limit) * (100 - this.percent) * 0.5 +
					this.percent,
				2
			)
		}
	},
	watch: {
		visible(nv) {
			if (nv) {
				this.init()
			}
		},
		percent(nv) {
			if (nv === 100) {
				this.exitProgress()
			}
		},
		loadTimes(nv) {
			if (nv === this.limit) {
				this.exitProgress(1)
			}
		}
	},
	created() {},
	methods: {
		init() {
			if (!this.showProgress && this.percent < 100) {
				this.$loader.show(this.message)
			}
			this.timer = setInterval(() => {
				this.getProgress()
			}, this.interval)
		},
		/**
		 * handleChange
		 *
		 * @description 处理显示状态
		 * @param {number} type 0=正常退出 1=超时 2=错误
		 */
		exitProgress(type = 0) {
			switch (type) {
				case 0:
					if (this.success) {
						this.success(0)
					} else {
						this.$message({
							type: 'success',
							message: '处理完成'
						})
					}
					break
				case 1:
					if (this.error) {
						this.error(1)
					} else {
						this.$message({
							type: 'warning',
							message: '处理超时，请重新操作'
						})
					}
					break
				case 2:
					if (this.error) {
						this.error(2)
					} else {
						this.$message({
							type: 'warning',
							message: '出错了，请联系管理员'
						})
					}
					break
				default:
					break
			}
			this.loadTimes = 0
			this.timer && clearTimeout(this.timer)
			if (!this.showProgress) {
				this.$loader.hide()
			}
			this.$nextTick(() => {
				setTimeout(() => {
					this.percent = 0
					this.visible = false
				}, 500)
			})
		},
		/**
		 * getProgress
		 *
		 * @description 获取进度
		 */
		getProgress() {
			this.$axios({
				url: this.url,
				data: this.filter
			}).then(res => {
				let data = parseInt(res.data) || 0
				if (this.cleanData instanceof Function) {
					data = this.cleanData(res.data)
				}
				this.loadTimes++
				this.percent = data
			})
		}
	}
}
</script>
