import TreeAndTableBox from './tree-and-table-box.vue'
import TreeAndTableBoxPlugin from './tree-and-table-box.js'

/* istanbul ignore next */
TreeAndTableBox.install = function (Vue) {
	Vue.component(TreeAndTableBox.name, TreeAndTableBox)
	Vue.$plugin ?? (Vue.prototype.$plugin = Vue.$plugin = [])
	Vue.prototype.$treeAndTableBox = Vue.$treeAndTableBox =
		TreeAndTableBoxPlugin
}

export default TreeAndTableBox
