<template>
	<div class="wy-layout-page-list">
		<header class="wy-layout-page-list_top">
			<div class="wy-layout-page-list_header">
				<h1
					class="wy-layout-page-list_title"
					data-guide="layout-page-list-title"
				>
					<el-tooltip content="点击查看功能指引">
						<i
							v-if="intro"
							class="iconfont icon-guide"
							@click="showIntro(true)"
						></i>
					</el-tooltip>
					{{ title }}
				</h1>
				<div
					v-if="showList && showChart"
					class="wy-layout-page-list_tab"
				>
					<el-button
						type="text"
						data-guide="layout-page-list-view-1"
						:class="{ grey: viewTab == 2 }"
						@click="changeView(1)"
					>
						<i class="iconfont icon-tab-list"></i>列表视图
					</el-button>
					<span class="span_line">|</span>
					<el-button
						type="text"
						data-guide="layout-page-list-view-2"
						:class="{ grey: viewTab == 1 }"
						@click="changeView(2)"
					>
						<i class="iconfont icon-tab-chart"></i>轻分析视图
					</el-button>
				</div>
				<div class="wy-layout-page-list_btn-panel">
					<slot name="btn-group"></slot>
				</div>
			</div>
			<div class="wy-layout-page-list_bar">
				<div class="wy-layout-page-list_project">
					<span v-if="showProject != 'false'">所属项目：</span>
					<el-input
						v-if="showProject != 'none' && showProject != 'false'"
						class="wy-border-bottom wy-pointer"
						placeholder="请选择项目"
						:title="curProject.name"
						:value="curProject.name"
						readonly
						@click.native="changeProject"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
						></i>
					</el-input>
					<span v-if="showProject == 'none'">{{
						curProject.name
					}}</span>
				</div>
				<div v-if="showFilter" class="wy-layout-page-list_filter">
					<template v-for="(item, index) in filterResult">
						<el-tag
							v-if="item.value"
							:key="item.id"
							:closable="item.closable"
							size="small"
							type="info"
							:disable-transitions="false"
							@click.native="showPanel = !showPanel"
							@close="removeFilter(item, index)"
						>
							<span v-if="item.name">{{ item.name }}:</span>
							<span>{{ item.value }}</span>
						</el-tag>
					</template>
				</div>
				<div class="row wy-layout-page-list_search">
					<el-select
						v-if="typeList.length > 0"
						v-model="searchType"
						class="col-1 wy-border-bottom"
						placeholder="请选择搜索类型"
						@change="changeSearchType"
					>
						<el-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
					<el-input
						v-if="showSearch"
						v-model="keyword"
						class="wy-border-bottom"
						:placeholder="placeholder"
						maxlength="32"
						:style="{ width: searchWidth }"
						@input="changeKeyword"
					>
						<i
							slot="prefix"
							class="el-input__icon el-icon-search"
							@click="search"
						></i>
					</el-input>
				</div>
				<el-button
					v-if="showFilter"
					class="wy-layout-page-list_more"
					type="text"
					@click="switchPanel"
				>
					<span v-if="!showPanel">更多高级查询</span>
					<span v-if="showPanel">关闭高级查询</span>
					<i
						class
						:class="{
							'el-icon-arrow-right': !showPanel,
							'el-icon-arrow-left': showPanel
						}"
					></i>
				</el-button>
			</div>
			<slot name="storage-group"></slot>
		</header>
		<div class="wy-layout-page-list_center">
			<section
				v-if="tabList.length > 0"
				class="row wy-layout-page-list_tabs-bar"
			>
				<div
					v-for="tab in tabList"
					v-show="!tab.hidden"
					:key="tab.id"
					class="col-1 wy-layout-page-list_tab-list"
					:class="{ active: curTab == tab.id }"
					@click="changeTab(tab.id)"
				>
					<div
						class="wy-layout-page-list_tab-item"
						:class="{
							'wy-layout-page-list_tab-item-total':
								tab.total || tab.total == 0
						}"
					>
						<div class="wy-layout-page-list_tab-name">
							{{ tab.name }}
						</div>
						<div
							:class="{
								'wy-layout-page-list_total-num':
									tab.total || tab.total == 0
							}"
						>
							{{ tab.total }}
						</div>
					</div>
				</div>
			</section>
			<section
				v-if="$slots.totalBar"
				class="wy-layout-page-list_total-bars"
			>
				<slot name="totalBar"></slot>
			</section>
			<section
				v-if="showList"
				v-show="viewTab == 1"
				class="wy-layout-page-list_main"
			>
				<slot name="content"></slot>
			</section>
			<section
				v-if="showChart"
				v-show="viewTab == 2"
				class="wy-layout-page-list_main"
			>
				<iframe class="wy-iframe" :src="chartUrl"></iframe>
			</section>
			<transition name="slide">
				<div
					class="wy-layout-page-list_filter-panel"
					data-guide="layout-page-list-filter"
					v-show="showPanel"
					v-if="showFilter"
				>
					<h3 class="wy-layout-page-list_title">高级查询</h3>
					<div class="wy-layout-page-list_filter-content">
						<el-form
							class="wy-layout-page-list_filter-form"
							:model="filterForm"
							label-position="top"
						>
							<el-form-item
								v-for="item in filter"
								v-show="
									!filterMap[item.key].hidden &&
									!filterMap[item.key].noVisible
								"
								:key="item.key"
								:label="item.name"
							>
								<template v-if="item.type == 'input'">
									<el-input
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
									></el-input>
								</template>
								<template v-if="item.type == 'number'">
									<el-input
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										@keyup.native="checkNumber(item.key, 0)"
									></el-input>
								</template>
								<template v-if="item.type == 'select'">
									<el-select
										v-model="filterForm[item.key]"
										value-key="id"
										class="wy-border-bottom"
										:multiple="item.multiple"
										:filterable="item.filterable"
										:clearable="item.clearable"
										@change="changeSelect(item, $event)"
										@visible-change="
											changeSelectVisible(item, $event)
										"
									>
										<el-option
											v-if="item.isSelectAll"
											key="all"
											label="全选"
											:value="selectAllOption"
											title="全选"
										></el-option>
										<el-option
											v-for="option in filterMap[item.key]
												.list"
											:key="option.id"
											:label="option.name"
											:value="option"
											:title="option.name"
										></el-option>
									</el-select>
								</template>
								<template v-if="item.type == 'date'">
									<el-date-picker
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										value-format="yyyy-MM-dd"
										align="right"
										type="date"
										placeholder="选择日期"
									></el-date-picker>
								</template>
								<template v-if="item.type == 'month'">
									<el-date-picker
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										value-format="yyyy-MM"
										align="right"
										type="month"
										placeholder="选择日期"
										:clearable="item.clearable"
										@change="changeMonth(item, $event)"
									></el-date-picker>
								</template>
								<!--时间段选择-->
								<template v-if="item.type == 'time'">
									<el-time-picker
										v-model="filterForm[item.key]"
										arrow-control
										class="wy-border-bottom"
										:picker-options="{
											selectableRange:
												'18:30:00 - 20:30:00'
										}"
										placeholder="选择时间"
									></el-time-picker>
								</template>
								<!--自定义日期选择-->
								<template v-if="item.type == 'dateRangeDefine'">
									<el-row :gutter="20" style="display: flex">
										<el-col>
											<el-date-picker
												v-model="
													filterForm[item.key][0]
												"
												:disabled="
													item.disabled &&
													item.disabled[0]
												"
												class="wy-border-bottom"
												value-format="yyyy-MM-dd"
												align="right"
												type="date"
												placeholder="选择日期"
												:picker-options="
													item.startPickerOptions
												"
												:clearable="item.clearable"
											></el-date-picker>
										</el-col>
										<el-col :span="2">
											<span>至</span>
										</el-col>
										<el-col>
											<el-date-picker
												v-model="
													filterForm[item.key][1]
												"
												:disabled="
													item.disabled &&
													item.disabled[1]
												"
												class="wy-border-bottom"
												value-format="yyyy-MM-dd"
												align="right"
												type="date"
												placeholder="选择日期"
												:picker-options="
													item.endPickerOptions
												"
												:clearable="item.clearable"
											></el-date-picker>
										</el-col>
									</el-row>
								</template>
								<!--日期区间选择-->
								<template v-if="item.type == 'dateRange'">
									<wy-date-picker
										v-model="filterForm[item.key]"
										:picker-options="
											(item.config &&
												item.config.pickerOptions) ||
											null
										"
										class="wy-border-bottom"
										type="daterange"
										range-separator="至"
										start-placeholder="开始日期"
										end-placeholder="结束日期"
										value-format="yyyy-MM-dd"
										format="yyyy-MM-dd"
									></wy-date-picker>
								</template>
								<!--日期时间区间选择-->
								<template v-if="item.type == 'dateTimeRange'">
									<el-date-picker
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										type="datetimerange"
										range-separator="至"
										start-placeholder="开始时间"
										end-placeholder="结束时间"
										value-format="yyyy-MM-dd HH:mm:ss"
										format="yyyy-MM-dd HH:mm:ss"
										:clearable="item.clearable"
										:picker-options="item.pickerOptions"
									></el-date-picker>
								</template>
								<!--日期区间选择（带快捷选项）-->
								<template v-if="item.type == 'dateRangeShort'">
									<el-date-picker
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										type="daterange"
										unlink-panels
										range-separator="至"
										start-placeholder="开始日期"
										end-placeholder="结束日期"
										value-format="yyyy-MM-dd"
										format="yyyy-MM-dd"
										:clearable="item.clearable"
										:picker-options="item.pickerOptions"
									></el-date-picker>
								</template>
								<!--月份区间选择-->
								<template v-if="item.type == 'monthRange'">
									<wy-date-picker
										v-model="filterForm[item.key]"
										class="wy-border-bottom"
										type="monthrange"
										range-separator="至"
										start-placeholder="开始时间"
										end-placeholder="结束时间"
										value-format="yyyy-MM"
										format="yyyy-MM"
									></wy-date-picker>
								</template>
								<!--数量区间选择-->
								<template v-if="item.type == 'numRange'">
									<el-row :gutter="20" style="display: flex">
										<el-col>
											<el-input
												v-model="
													filterForm[item.key][0]
												"
												class="wy-border-bottom"
												:placeholder="
													item.minPlaceholder || 0
												"
												@keyup.native="
													checkVal(
														item.key,
														0,
														item.rule
													)
												"
											></el-input>
										</el-col>
										<el-col :span="2">
											<span>-</span>
										</el-col>
										<el-col>
											<el-input
												v-model="
													filterForm[item.key][1]
												"
												class="wy-border-bottom"
												:placeholder="
													item.maxPlaceholder || 100
												"
												@keyup.native="
													checkVal(
														item.key,
														1,
														item.rule
													)
												"
											></el-input>
										</el-col>
									</el-row>
								</template>
								<!--数量单位选择-->
								<template v-if="item.type == 'numUnit'">
									<el-row :gutter="20" style="display: flex">
										<el-col :span="12">
											<el-input
												v-model="
													filterForm[item.key][0]
												"
												class="wy-border-bottom"
												@keyup.native="
													checkVal(
														item.key,
														0,
														item.rule
													)
												"
											></el-input>
										</el-col>
										<el-col :span="12">
											<el-select
												v-model="
													filterForm[item.key][1]
												"
												value-key="id"
												class="wy-border-bottom"
												:clearable="item.clearable"
											>
												<el-option
													v-for="option in filterMap[
														item.key
													].list"
													:key="option.id"
													:label="option.name"
													:value="option"
													:title="option.name"
												></el-option>
											</el-select>
										</el-col>
									</el-row>
								</template>
								<!--项目选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'project'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeProject(item.key)"
									></el-input>
								</template>
								<!--供应商选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'provider'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeProvider(item)"
									></el-input>
								</template>
								<!--第三方问题分类选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'question'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeQuestion(item.key)"
									></el-input>
								</template>
								<!--员工选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'employee'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeEmployee(item)"
									></el-input>
								</template>
								<!--仓库选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'warehouse'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeWarehouse(item)"
									></el-input>
								</template>
								<!--组织选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'orgunit'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeOrgunits(item)"
									></el-input>
								</template>
								<!--房客关系选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'cusRoom'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeRoomCus(item)"
									></el-input>
								</template>

								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'rooms'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeRoom(item)"
									></el-input>
								</template>
								<!--系统角色选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'orgRole'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeOrgRole(item)"
									></el-input>
								</template>
								<!--招商顾问选择-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'investConsultant'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="
											changeInvestConsultant(item)
										"
									></el-input>
								</template>
								<!--房间范围：组团-楼栋-单元-房间-->
								<template
									v-if="
										item.type == 'box' &&
										item.boxType == 'buildingRoom'
									"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="changeBuildingRoom(item)"
									></el-input>
								</template>
								<!--自定义弹出框-->
								<template
									v-if="item.type == 'box' && !item.boxType"
								>
									<el-input
										v-model="filterForm[item.key].name"
										class="wy-border-bottom wy-pointer"
										suffix-icon="el-icon-search"
										readonly="readonly"
										@click.native="handleClickBox(item)"
									></el-input>
								</template>
							</el-form-item>
						</el-form>
					</div>
					<div class="wy-layout-page-list_ctrl">
						<el-button type="primary" @click="submitForm"
							>查询</el-button
						>
						<el-button @click="cancelForm">取消</el-button>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import { hasOf } from 'tool'
import wyDatePicker from '../date-picker'

export default {
	name: 'WyLayoutPageList',
	components: {
		wyDatePicker
	},
	props: {
		title: String,
		showList: {
			type: Boolean,
			default: true
		},
		showChart: {
			type: Boolean,
			default: false
		},
		showProject: {
			type: String,
			default: 'none',
			validator: value =>
				['none', 'multi', 'single', 'false'].includes(value)
		},
		viewUrl: String,
		viewFilter: Object,
		showFilter: {
			type: Boolean,
			default: true
		},
		filter: {
			type: Array,
			default() {
				return []
			}
		},
		placeholder: {
			type: String,
			default: '请输入内容'
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		searchWidth: {
			type: String,
			default: '200px'
		},
		showView: {
			type: Boolean,
			default: false
		},
		showQuestion: {
			type: Boolean,
			default: false
		},
		isShowPanel: {
			type: Boolean,
			default: false
		},
		tabList: {
			type: Array,
			default() {
				return []
			}
		},
		typeList: {
			type: Array,
			default() {
				return []
			}
		},
		defaultSearchType: {
			type: String,
			default: ''
		},
		initFilter: {
			type: Boolean,
			default: false
		},
		initProject: {
			type: Boolean,
			default: true
		},
		intro: Object,
		name: String,
		defaultView: {
			type: Boolean,
			default: true
		},
		// 最大项目个数
		maxProject: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			viewTab: 1,
			form: {},
			keyword: null,
			searchType: null,
			showPanel: false,
			filterForm: {}, // 高级查询的表单
			filterResult: [], // 筛选条件显示的数组
			filterData: {}, // 筛选条件的查询结果键值
			filterMap: {},
			timeout: null,
			lastVal: '',
			chartUrl: '',
			viewFilterData: {},
			curProject: {
				name: null,
				id: null,
				selection: []
			},
			userInfo: {},
			selectVisible: null,
			curTab: null,
			selectAllOption: {
				id: 'all',
				name: '全选'
			}
		}
	},
	computed: {
		viewFilterCopy() {
			return Object.assign({}, this.viewFilter)
		}
	},
	watch: {
		viewFilterCopy(val, oldVal) {
			if (JSON.stringify(val) == JSON.stringify(oldVal)) {
				return
			}
			this.viewFilterData = Object.assign({}, val)
			if (this.viewTab == 2) {
				this.createChart()
			}
		}
	},
	created() {
		if (this.isShowPanel) {
			this.showPanel = true
		}
		if (this.showFilter && this.filter) {
			// 如果显示高级查询面板
			this.createFilter()
		}
		if (this.tabList.length > 0) {
			// 如果显示tab面板
			this.curTab = this.tabList[0].id
		}
		if (this.defaultSearchType) {
			this.searchType = this.defaultSearchType
		}
		// 用户信息
		this.$root.getUserInfo().then(data => {
			this.userInfo = data.curUser
			if (this.initProject) {
				this.filterData.projectID = data.curUser.projectID
				this.filterData.projectName = data.curUser.projectName
				this.curProject = {
					id: this.userInfo.projectID,
					name: this.userInfo.projectName,
					selection: [
						{
							id: this.userInfo.projectID,
							name: this.userInfo.projectName
						}
					]
				}
			}
		})
	},
	mounted() {
		this.showIntro()
	},
	methods: {
		showIntro(replay) {
			if (this.intro) {
				this.$intro({
					el: this.$el,
					options: this.intro,
					replay
				})
			}
		},
		search() {
			this.$emit('search')
		},
		createChart() {
			let param = {}
			if (this.defaultView) {
				param = {
					jsonParam: JSON.stringify(this.viewFilterData)
				}
			} else {
				param = this.viewFilterData
			}
			this.$axios({
				type: 'post',
				url: this.viewUrl,
				data: param,
				loading: true
			}).then(res => {
				if (res.data) {
					this.chartUrl = res.data.url
				}
			})
		},
		createFilter() {
			// 创建查询面板
			this.filter.forEach(item => {
				if (item.type == 'box' && !item.value) {
					item.value = {
						id: null,
						name: null,
						selection: []
					}
				}
				if (item.type == 'numRange' && !item.value) {
					item.value = []
				}
				if (item.type == 'dateRangeDefine' && !item.value) {
					item.value = []
				}
				if (item.type == 'numUnit' && !item.value) {
					item.value = []
				}
				this.$set(this.filterForm, item.key, item.value)
				this.$set(this.filterMap, item.key, item)
				if (this.initFilter) {
					this.initFilterView()
				}
			})
		},
		changeView(tab) {
			this.viewTab = tab
			if (tab == 2) {
				this.viewFilterData = Object.assign({}, this.viewFilter)
				this.createChart()
			}
			this.$emit('changeView', tab)
		},
		switchPanel() {
			// 切换高级查询面板开关
			this.showPanel = !this.showPanel
		},
		hidePanle() {
			this.showPanel = false
		},
		changeKeyword() {
			this.timeout && clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.$emit('changeKeyword', this.keyword)
			}, 500)
		},
		changeSearchType() {
			this.keyword = ''
			this.$emit('changeSearchType', this.searchType)
		},
		removeFilter(item, index) {
			let o = this.filter.find(el => el.key === item.id),
				p = this.filter.find(el => el.linkKey === item.id),
				filterData
			if (o && o.hasOwnProperty('linkKey')) {
				const i = this.filterResult.findIndex(el => el.id === o.linkKey)
				i > -1 && this.removeFilter(this.filterResult[i], i)
				return
			}
			this.filterResult.splice(index, 1)
			this.filterData[item.id] = null
			if (p) this.filterData[p.key] = null
			this.filter.forEach(list => {
				if (list.key == item.id) {
					if (
						typeof this.filterForm[item.id] == 'object' &&
						this.filterForm[item.id] != null
					) {
						if (this.filterForm[item.id] instanceof Array) {
							this.filterForm[item.id] = []
						} else {
							this.filterForm[item.id] = {
								id: null,
								name: null
							}
						}
					} else {
						this.filterForm[item.id] = null
					}
					// 将下拉选项设置显示条件的一起清除
					if (list.type == 'select') {
						this.changeSelect(list, { id: null, name: null })
					}
				}
			})
			// 清除值为null的项
			filterData = Object.assign({}, this.filterData)
			for (const z in filterData) {
				if (!filterData[z]) {
					delete filterData[z]
				}
			}
			this.filterData = filterData
			!this.showPanel && this.submitForm()
		},
		// 修改高级筛选表单值, key表示字段名
		changeFilterForm(key, val) {
			const index = hasOf(this.filterResult, { key }, key)
			this.filterForm[key] = val
			if (index == -1) {
				return
			}
			if (!val && this.filterForm[key]) {
				this.removeFilter(this.filterMap[key], index)
			}
		},
		// 修改高级筛选列表属性, key表示字段名，attr表示对于属性名
		changeFilterMap(key, attr, val) {
			if (!this.filterMap[key]) {
				return
			}
			if (attr == 'hidden' && val) {
				// 删除关联对象
				const objIndex = this.filterResult.findIndex(item => {
					return item.id === key // 筛选出匹配数据
				})
				if (objIndex && objIndex > -1) {
					this.filterResult.splice(objIndex, 1)
				}
			}
			this.filterMap[key][attr] = val
		},
		// 下拉选项设置回调
		changeSelect(item, data) {
			// 设置全选按钮
			if (item.isSelectAll) {
				const valList = [].concat(data)
				const vals = []
				if (!this.filterMap[item.key].selectAll) {
					this.$set(this.filterMap[item.key], 'selectAll', false)
				}
				valList.forEach(item => {
					vals.push(item.id)
				})
				if (this.filterMap[item.key].selectAll) {
					if (
						vals.length === item.list.length &&
						vals.includes('all')
					) {
						vals.shift()
						valList.shift()
						this.filterForm[item.key] = valList
						this.filterMap[item.key].selectAll = false
					}
					if (
						!vals.includes('all') &&
						item.list.length === vals.length
					) {
						this.filterForm[item.key] = []
						this.filterMap[item.key].selectAll = false
					}
				} else {
					if (vals.includes('all')) {
						this.filterForm[item.key] = [
							this.selectAllOption
						].concat(item.list)
						this.filterMap[item.key].selectAll = true
					}
					if (
						!vals.includes('all') &&
						item.list.length === vals.length
					) {
						vals.unshift('all')
						valList.unshift(this.selectAllOption)
						this.filterForm[item.key] = valList
						this.filterMap[item.key].selectAll = true
					}
				}
			}

			// 当select为多选时 需要在下拉框消失时再触发change事件  不然会频繁发送请求
			if (
				item.onChange &&
				typeof item.onChange == 'function' &&
				!this.selectVisible
			) {
				item.onChange(data)
			}
			if (item.clearChild) {
				item.clearChild.forEach(e => {
					this.filterForm[e] = null
				})
			}
			this.$emit('selectChange', {
				val1: item,
				val2: data
			})
		},
		changeSelectVisible(item, data) {
			this.selectVisible = data
			// 下拉框关闭时触发 change回调
			if (!data && item.onChange && typeof item.onChange == 'function') {
				item.onChange(this.filterForm[item.key])
			}
		},
		// 修改月份 联动修改
		changeMonth(item, data) {
			if (item.onChange && typeof item.onChange == 'function') {
				item.onChange(data)
			}
		},
		checkNumber(key) {
			const correctVal = this.filterForm[key].match(
				/^[0-9]+(\.[0-9]{0,num})?$/gi
			)
			if (correctVal) {
				this.lastVal = correctVal[0]
				this.$set(this.filterForm, key, correctVal[0])
			} else {
				this.$set(this.filterForm, key, this.lastVal)
			}
		},
		checkVal(key, type, rule) {
			if (this.filterForm[key][type]) {
				// 匹配正整数以及两位小数
				const regExp = /^([0-9]{1,}[.]{0,1}[0-9]{0,2})$/gi
				const correctVal = this.filterForm[key][type].match(
					rule || regExp
				)
				if (correctVal) {
					this.lastVal = correctVal[0]
					this.$set(this.filterForm[key], type, correctVal[0])
				} else {
					this.$set(this.filterForm[key], type, this.lastVal)
				}
			}
		},
		// 左树右表弹窗选择, 房客关系选择
		changeRoomCus(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: list.title,
				type: list.boxType,
				multi: list.multi,
				currentSelection: selection,
				tableFilter: list.filter || {},
				placeholder: '请输入房间/客户名称',
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.roomcusId)
						names.push(item.customerName)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 左树右表弹窗选择, 房间选择
		changeRoom(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: list.title,
				type: list.boxType,
				multi: list.multi,
				currentSelection: selection,
				tableFilter: list.filter || {},
				placeholder: '请输入房间名称',
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择项目范围
		changeProject() {
			this.$projectTreeBox({
				title: '选择项目',
				type: 'orgProject',
				multi: this.showProject == 'multi',
				currentSelection: this.curProject.selection,
				onOk: (data, cb) => {
					if (
						this.maxProject &&
						this.maxProject < data.selection.length
					) {
						// 判断选择项目个数是否超过最大值
						this.$message({
							type: 'warning',
							message: '最多可选择' + this.maxProject + '个项目'
						})
						return
					}
					this.curProject = data
					// 多项目时，不显示房间选择范围
					if (data.selection.length > 1) {
						this.changeFilterMap('buildingRoom', 'hidden', true)
					} else {
						this.changeFilterMap('buildingRoom', 'hidden', false)
					}
					// 需要通过选择项目触发高级筛选列表
					this.$emit('changeProject', this.curProject)
					this.filterData.projectID = data.id
					this.filterData.projectName = data.name
					this.filterData.projectCheckAll = data.checkAll
					this.filterData.buildingRoom = {}
					this.changeFilterForm('buildingRoom', {})
					this.submitForm()
					cb()
				}
			})
		},
		// 选择供应商
		changeProvider(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '供应商选择',
				type: 'provider',
				multi: list.multi,
				currentSelection: selection,
				tableFilter: {
					isEnabled: 1
				},
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择问题分类
		changeQuestion(key) {
			let id = []
			if (this.filterForm[key] && this.filterForm[key].id) {
				id = this.filterForm[key].id.split(',')
			}
			this.$changeQuestion({
				value: id,
				onOk: res => {
					let names = [],
						name
					if (res.id.length == 0) {
						this.$message({
							type: 'warning',
							message: '请至少选择一个问题类型'
						})
						return false
					}
					res.list.forEach(item => {
						names.push(item.name)
					})
					if (names.length > 3) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[key] = {
						id: res.id.join(','),
						name
					}
					return true
				},
				onClose: () => {}
			})
		},
		// 选择员工
		changeEmployee(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择员工',
				type: 'employee',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		changeWarehouse(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择仓库',
				type: 'warehouse',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择组织
		changeOrgunits(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择组织',
				type: 'orgUnitAndProject',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 选择系统角色
		changeOrgRole(list) {
			const selection = this.filterForm[list.key].selection
			this.$treeAndTableBox({
				title: '选择角色',
				type: 'orgRole',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 招商顾问
		changeInvestConsultant(list) {
			const projectID = []
			const selection = this.filterForm[list.key].selection
			this.curProject.selection.forEach(item => {
				projectID.push(item.id)
			})
			this.$treeAndTableBox({
				title: '选择招商顾问',
				type: 'investConsultant',
				placeholder: list.placeholder || '',
				multi: list.multi,
				reserveSelection: true,
				currentSelection: selection,
				treeFilter: {
					projectID: projectID.join(',')
				},
				onOk: (data, cb) => {
					let ids = [],
						names = [],
						name = null
					if (data.length == 0) {
						this.$message({
							type: 'warning',
							message: '请选择一条数据'
						})
						return
					}
					data.forEach(item => {
						ids.push(item.id)
						names.push(item.name)
					})
					if (names.length > 1) {
						name = names[0] + '等' + names.length + '个'
					} else {
						name = names.join(',')
					}
					this.filterForm[list.key] = {
						id: ids.join(','),
						name,
						selection: data
					}
					cb()
				},
				onCancel: () => {}
			})
		},
		// 房间范围
		changeBuildingRoom(opt) {
			const obj = this.filterForm[opt.key]
			this.$buildingRoom({
				options: {
					typeList: opt.typeList,
					type: obj.type,
					selection: obj.selection,
					projectID: this.curProject.id
				},
				onOk: instance => {
					return new Promise(resolve => {
						let result = instance.getSelection(),
							ids = [],
							names = [],
							name = null
						result.selection.forEach(item => {
							ids.push(item.id)
							names.push(item.name)
						})
						if (names.length > 1) {
							name = names[0] + '等' + names.length + '个'
						} else {
							name = names.join(',')
						}
						if (result.type == 1) {
							// 组团
							name = name + '组团'
						} else if (result.type == 2) {
							// 楼栋
							name = name + '楼栋'
						} else if (result.type == 3) {
							// 单元
							name = name + '单元'
						}
						this.filterForm.buildingRoomType = result.type
						this.filterForm[opt.key] = {
							type: result.type,
							typeList: opt.typeList,
							id: ids.join(','),
							name,
							selection: result.selection
						}
						resolve()
					})
				}
			})
		},
		handleClickBox(opt) {
			// 自定义弹出框事件
			opt.event(this.filterForm[opt.key]).then(res => {
				this.filterForm[opt.key] = res
			})
		},
		initFilterView() {
			const obj = {}
			const name = []
			for (const key in this.filterForm) {
				let value = this.filterForm[key]
				if (!this.filterMap[key] || this.filterMap[key].hidden) {
					continue
				}
				if (value instanceof Array) {
					if (this.filterMap[key].type == 'select') {
						const names = []
						const ids = []
						value.forEach(item => {
							names.push(item.name)
							ids.push(item.id)
						})
						value = {
							id: ids.join(','),
							name: names.join(',')
						}
					} else if (
						(this.filterMap[key].type == 'dateRange' ||
							this.filterMap[key].type == 'dateRangeShort' ||
							this.filterMap[key].type == 'dateTimeRange' ||
							this.filterMap[key].type == 'monthRange' ||
							this.filterMap[key].type == 'numRange' ||
							this.filterMap[key].type == 'dateRangeDefine' ||
							this.filterMap[key].type == 'numUnit') &&
						value.length > 0
					) {
						const valArr = [].concat(value)
						if (
							(valArr[0] || valArr[0] === 0) &&
							(valArr[1] || valArr[1] === 0)
						) {
							value = valArr[0] + '~' + valArr[1]
						}
					}
				}
				if (value && typeof value == 'object') {
					obj[key] = value.id
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable
					})
				} else if (this.filterMap[key].type == 'box') {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable
					})
				} else {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value,
						closable: !this.filterMap[key].closable
					})
				}
			}
			this.filterResult = name
		},
		submitForm() {
			let obj = {},
				name = [],
				filterData
			for (const key in this.filterForm) {
				let value = this.filterForm[key]
				if (!this.filterMap[key] || this.filterMap[key].hidden) {
					continue
				}
				if (value instanceof Array) {
					if (this.filterMap[key].type == 'select') {
						const names = []
						const ids = []
						value.forEach(item => {
							names.push(item.name)
							ids.push(item.id)
						})
						value = {
							id: ids.join(','),
							name: names.join(',')
						}
					} else if (
						(this.filterMap[key].type == 'dateRange' ||
							this.filterMap[key].type == 'dateRangeShort' ||
							this.filterMap[key].type == 'dateTimeRange' ||
							this.filterMap[key].type == 'monthRange' ||
							this.filterMap[key].type == 'numRange' ||
							this.filterMap[key].type == 'dateRangeDefine' ||
							this.filterMap[key].type == 'numUnit') &&
						value.length > 0
					) {
						const valArr = [].concat(value)
						if (
							(valArr[0] && !valArr[1]) ||
							(!valArr[0] && valArr[1])
						) {
							this.$message({
								type: 'warning',
								message: '请补全区间'
							})
							return
						}
						if (
							(this.filterMap[key].type == 'numRange' &&
								parseFloat(valArr[0]) >
									parseFloat(valArr[1])) ||
							(this.filterMap[key].type != 'numRange' &&
								valArr[0] > valArr[1])
						) {
							// 字符串数字大小判断 unicode码
							this.$message({
								type: 'warning',
								message: `${this.filterMap[key].name}结束区间不能小于开始区间`
							})
							return
						}
						if (
							this.filterMap[key].type == 'numUnit' &&
							valArr[0] &&
							valArr[1]
						) {
							value = {
								id: valArr[0] + '~' + valArr[1].id,
								name: valArr[0] + valArr[1].name + ''
							}
						} else {
							if (
								(valArr[0] || valArr[0] === 0) &&
								(valArr[1] || valArr[1] === 0)
							) {
								value = valArr[0] + '~' + valArr[1]
							}
						}
					}
				}
				if (value && typeof value == 'object') {
					obj[key] = value.id
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable
					})
				} else if (this.filterMap[key].type == 'box') {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value: value.name,
						closable: !this.filterMap[key].closable
					})
				} else {
					obj[key] = value
					name.push({
						id: key,
						name: this.filterMap[key].name,
						value,
						closable: !this.filterMap[key].closable
					})
				}
			}
			this.hidePanle()
			this.filterResult = name
			filterData = Object.assign({}, this.filterData, obj)
			// 清除值为null的项
			for (const z in filterData) {
				if (filterData[z] === null) {
					delete filterData[z]
				}
			}
			if (this.filterMap.buildingRoom) {
				// 高级查询包含 房间范围
				delete filterData.groupID
				delete filterData.buildingID
				delete filterData.buildUnitID
				delete filterData.roomID
				delete filterData.floorID
			}
			if (filterData.buildingRoom) {
				// 有房间范围
				if (this.filterForm.buildingRoomType == 1) {
					filterData.groupID = filterData.buildingRoom
				} else if (this.filterForm.buildingRoomType == 2) {
					filterData.buildingID = filterData.buildingRoom
				} else if (this.filterForm.buildingRoomType == 3) {
					filterData.buildUnitID = filterData.buildingRoom
				} else if (this.filterForm.buildingRoomType == 4) {
					filterData.roomID = filterData.buildingRoom
				} else if (this.filterForm.buildingRoomType == 5) {
					filterData.floorID = filterData.buildingRoom
				}
				delete filterData.buildingRoom
				delete filterData.buildingRoomType
			}
			this.filterData = filterData
			this.$emit('query', this.filterData)
		},
		cancelForm() {
			this.hidePanle()
		},
		// 切换tab
		changeTab(id) {
			this.curTab = id
			this.$emit('changeTab', id)
		}
	}
}
</script>
