import Vue from 'vue'
import { nextIndex } from 'tool'
import tmp from './mask.vue'

const VueConstructor = Vue.extend(tmp)

class Mask {
	constructor() {
		this.instance = null
		this.instance = new VueConstructor({
			el: document.createElement('div'),
			methods: {}
		})
		document.body.appendChild(this.instance.$el)
	}

	show(opt = {}) {
		this.instance.zIndex = nextIndex(5000, 10000)
		this.instance.onClick = opt.onClick || null
		this.instance.opacity = opt.opacity || 0.3
		this.instance.color = opt.color || '#000000'
		this.instance.visible = true
	}

	hide() {
		this.instance.visible = false
	}
}

export default Mask
