<template>
	<div v-show="isShow" class="wy-step">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'WyStep',
	props: {
		title: String
	},
	data() {
		return {}
	},
	computed: {
		isShow() {
			const step = this.$parent.step
			const list = this.$parent.list
			const curStep = list[step]
			return curStep == this
		}
	},
	watch: {},
	beforeCreate() {
		this.$parent.list.push(this)
	},
	created() {},
	beforeDestroy() {
		const list = this.$parent.list
		const index = list.indexOf(this)
		if (index >= 0) {
			list.splice(index, 1)
		}
	},
	methods: {}
}
</script>
