var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "wy-checkbox",
      class: { disabled: _vm.disabled, checked: _vm.isChecked },
      on: { click: _vm.handleClick },
    },
    [
      _c("i", {
        staticClass: "iconfont",
        class: {
          "icon-checkbox-normal": !_vm.isChecked,
          "icon-checkbox-checked": _vm.isChecked,
        },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }