var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "wy-button",
      class: [
        _vm.theme ? "wy-button_theme" : "wy-button_default",
        _vm.curSize ? "wy-button_" + _vm.curSize : "",
      ],
      attrs: { type: _vm.type },
      on: { click: _vm.handleClick },
    },
    [_c("span", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }