<template>
	<div
		v-loading="loading"
		class="wy-design-table"
		:class="{
			'wy-design-table_has-bar':
				showSetting || showPages || showSelection || $slots.total,
			'wy-design-table_1': layoutType === 1,
			'wy-design-table_2': layoutType === 2
		}"
	>
		<!-- start layoutType === 1 时显示在上面; layoutType === 2 时显示在上面-->
		<div class="wy-design-table_bar">
			<i
				v-if="showSetting"
				class="wy-design-table_set el-icon-setting"
				data-guide="design-table-set"
				@click="setting"
			></i>
			<span v-if="showSelection" class="wy-design-table_total">
				已选择
				<em>{{ selection.length }}</em>
				个
			</span>
			<span class="wy-design-table_total">
				<slot name="total"></slot>
			</span>
			<wy-pages
				v-if="showPages"
				background
				small
				:current="table.current"
				:page-sizes="pageSizes"
				:page-size="table.rowCount"
				:total="table.total"
				@changePageSize="changePageSize"
				@changeCurrentPage="changeCurrentPage"
			>
			</wy-pages>
		</div>
		<!-- end -->

		<div class="wy-design-table_list">
			<el-table
				ref="table"
				class="wy-design-table"
				:border="true"
				height="100%"
				:data="table.list"
				:span-method="getSpanMethod"
				:show-summary="showSummary"
				:summary-method="summaryMethod"
				:default-sort="defaultSort"
				:row-class-name="getTableRowClassName"
				:expand-row-keys="expandRowKeys"
				:row-key="tableKey"
				:lazy="tableLazy"
				:load="tableLoad"
				@sort-change="sortChange"
				:tree-props="tableTreeProps"
				@filter-change="filterChange"
				v-if="tableField.length > 0"
				@selection-change="changeSelection"
				@row-click="rowClick"
				@expand-change="expandChange"
				@header-dragend="changeHeaderWidth"
			>
				<el-table-column
					v-if="showSelection"
					type="selection"
					width="55"
					align="center"
				></el-table-column>
				<el-table-column
					v-if="showIndex"
					type="index"
					label="序号"
					width="55"
					:fixed="hasFixedLeft"
					align="center"
				></el-table-column>
				<el-table-column v-if="expandFiled.length > 0" type="expand">
					<template slot-scope="prop">
						<el-table
							:data="prop.row.tableData"
							border
							:style="{ width: expandTableWidth }"
							:row-class-name="getTableRowClassName"
						>
							<wy-table-column
								v-for="(item, index) in expandFiled"
								:key="item.field"
								:data="item"
								:index="index"
							></wy-table-column>
						</el-table>
					</template>
				</el-table-column>
				<wy-table-column
					v-for="(item, index) in list"
					:key="item.field"
					:data="item"
					:index="index"
				></wy-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
/**
 * @author su 2019-11-08
 * @description table组件
 * @params
 * @returns
 * @example
 */
import { cleanData, extend, getType } from 'tool'
import wyTableColumn from '../design-table-column'
import wyPages from '../pages'
import settingBox from './set.vue'
export default {
	name: 'DesignTable',
	components: {
		wyTableColumn,
		wyPages
	},
	props: {
		// 布局方式，1：bar在上，2：bar在下
		layoutType: {
			type: Number,
			default: 1
		},
		// 业务组件名称
		name: {
			type: String
		},
		// 初始化自动加载数据
		auto: {
			type: Boolean
		},
		// 是否显示设置按钮
		showSetting: {
			type: Boolean,
			default: true
		},
		// 是否显示多选框
		showSelection: {
			type: Boolean,
			default: true
		},
		// 是否显示合计
		showSummary: {
			type: Boolean
		},
		// 合计栏名称
		summaryName: {
			type: String,
			default: '全部合计：'
		},
		// 是否显示序号
		showIndex: {
			type: Boolean,
			default: true
		},
		// 是否显示分页
		showPages: {
			type: Boolean,
			default: true
		},
		// 是否启用动态表头
		enableNetField: {
			type: Boolean
		},
		// 列表页请求地址
		url: {
			type: String,
			default: ''
		},
		// table的数据，可以是对象直接传入，也可以是函数方法返回
		tableData: {
			type: [Object, Function]
		},
		// 列表页字段列表
		field: {
			type: Array,
			default() {
				return []
			}
		},
		// 展开行的数据，可以是对象直接传入，也可以是函数方法返回
		expandTableData: {
			type: [Object, Function]
		},
		// 展开行列表页字段列表
		expandFiled: {
			type: Array,
			default() {
				return []
			}
		},
		// 列表页字段列表唯一标识
		tableKey: {
			type: String,
			default: 'id'
		},
		// 树形数据懒加载方法
		tableLoad: {
			type: Function
		},
		// 是否懒加载
		tableLazy: {
			type: Boolean,
			default: false
		},
		tableTreeProps: {
			type: Object,
			default: () => ({
				children: 'children',
				hasChildren: 'hasChildren'
			}),
			description: '树形数据'
		},
		// 关键词
		keyword: {
			type: String
		},
		// 关键词ID
		keywordID: {
			type: String
		},
		// 关键词查询类型
		keywordType: {
			type: String
		},
		// 分页设置
		pageSizes: {
			type: Array,
			default() {
				return [20, 50, 100]
			}
		},
		// 表格展开项宽度
		expandTableWidth: {
			type: String
		},
		// 表格高亮行
		tableRowClassName: {
			type: [String, Array, Function]
		},
		// 合并行与合并列
		spanMethod: {
			type: [Array, Object, Function]
		}
	},
	data() {
		return {
			// 默认排序
			defaultSort: {
				prop: '', // 对应列
				order: '' // 排序方式  降序descending  升序ascending
			},
			// 选择值
			selection: [],
			// table对象
			table: {
				// 请求状态
				status: false,
				// 数据集合
				list: [],
				// 当前页数
				current: 1,
				// 分页条数
				rowCount: 50,
				// 总条数
				total: 0,
				// sort 排序
				sort: {},
				// filters 过滤条件
				filters: {},
				// 查询条件
				query: {},
				// 合计信息
				summary: {},
				// 自定义附加条件
				defineParams: {}
			},
			// 列表表头字段
			tableField: [],
			// filter的map对象
			filterMap: {},
			// 个人配置信息
			userConfig: null,
			// 加载状态
			loading: false,
			// 字段map对象
			fieldMap: {},
			expandRowKeys: []
		}
	},
	computed: {
		list() {
			// 列表表头数据，过滤不显示
			return this.tableField.filter(p => p.show)
		},
		// 是否有冻结在左侧的列
		hasFixedLeft() {
			let fixed = false
			this.field.forEach(item => {
				if (item.fixed) {
					fixed = true
				}
			})
			return fixed
		}
	},
	watch: {},
	created() {},
	mounted() {
		// 初始化
		this.init()
	},
	methods: {
		/**
		 * @function init
		 * @description 初始化
		 */
		async init() {
			// 获取用户配置
			await this.getUserConfig()
			// 如果未开启动态表头，则直接初始化表头信息
			if (!this.enableNetField) {
				// 将表头对象转成map对象集合
				await this.setFieldMap(this.field)
				await this.setTableField(this.field)
			} else {
			}
			// 如果自动加载数据，则调用reload加载
			if (this.auto) {
				this.reload()
			}
		},
		/**
		 * @function init
		 * @description 获取用户配置
		 */
		getUserConfig() {
			return new Promise(resolve => {
				// 如果没有名称或者启用的动态表头，则不开启用户自定义保存
				if (!this.name) {
					resolve()
					return
				}
				this.$userConfig({
					type: 'get',
					configType: this.name,
					configKey: 'tableField'
				}).then(data => {
					if (data && data.length > 0) {
						this.userConfig = data
						resolve(data)
					} else {
						resolve()
					}
				})
			})
		},
		/**
		 * @param newWidth
		 * @param oldWidth
		 * @param column
		 * @function changeHeaderWidth
		 * @description 拖动表头改变大小时触发
		 */
		changeHeaderWidth(newWidth, oldWidth, column) {
			const userConfig =
				this.userConfig || extend(true, [], this.tableField)
			const userConfigIdx = userConfig.findIndex(
				el => el.field === column.property
			)
			const tableFieldIdx = this.tableField.findIndex(
				el => el.field === column.property
			)
			userConfig[userConfigIdx].width = newWidth
			this.tableField[tableFieldIdx].width = newWidth
			// 保存配置
			this.changeUserConfig(userConfig)
		},
		/**
		 * @param value
		 * @function changeUserConfig
		 * @description 修改用户配置
		 */
		changeUserConfig(value) {
			if (!this.name) {
				return
			}
			this.$userConfig({
				type: 'post',
				configType: this.name,
				configKey: 'tableField',
				configContent: JSON.stringify(value)
			})
		},
		/**
		 * @function setFieldMap
		 * @description 将字段配置信息储存为map
		 * @param fieldList
		 * @params {Array} fieldList 字段数组
		 */
		async setFieldMap(fieldList) {
			for (const item of fieldList) {
				this.fieldMap[item.field] = item
				if (item.children) {
					this.setFieldMap(item.children)
				}
				if (item.showFilter && item.filterConfig.type === 1) {
					this.filterMap[item.field] = await this.getFilters(item)
				}
			}
		},
		/**
		 * @function setTableField
		 * @description 将表头信息与个人配置信息结合，赋值给我tableField
		 * @param fieldList
		 * @params {Array} fieldList 字段数组
		 */
		async setTableField(fieldList) {
			// 如果有用户定制列表
			if (this.userConfig && this.userConfig.length > 0) {
				try {
					for (const item of fieldList) {
						const i = this.userConfig.findIndex(
							p => p.field === item.field
						)
						let willSave = false
						if (typeof item.canShow === 'undefined') {
							// 如果没有传值
							willSave = true
						} else if (
							item instanceof Promise &&
							(await item.canShow())
						) {
							// 如果是一个promise对象
							willSave = true
						} else if (
							typeof item.canShow === 'function' &&
							item.canShow()
						) {
							// 如果是一个普通函数
							willSave = true
						} else if (
							typeof item.canShow === 'boolean' &&
							item.canShow
						) {
							// 如果是一个表达式
							willSave = true
						}
						if (
							(willSave && i === -1) ||
							(i > -1 && this.userConfig[i].name !== item.name)
						) {
							throw item
						}
					}
					this.userConfig.forEach(item => {
						const i = fieldList.findIndex(
							p => p.field === item.field
						)
						if (i === -1 || fieldList[i].name !== item.name) {
							throw item
						}
					})
					fieldList = this.userConfig
				} catch (e) {
					console.info('字段有更新')
				}
			}
			// 因为el-table的表头存在child的时候，指定key渲染有问题，
			// 只能先清空，再等渲染后赋值，再渲染后重新doLayout校准table
			this.tableField = []
			await this.$nextTick()
			this.tableField = await this.extendField(fieldList)
			this.$nextTick(() => {
				if (this.$refs.table) {
					this.$refs.table.bodyWrapper.scrollTop = 0
					this.$refs.table.doLayout()
				}
				this.loading = false
			})
		},
		/**
		 * @function extendField
		 * @description 将表头信息与个人配置信息结合
		 * @param fieldList
		 * @params {Array} fieldList 字段数组
		 */
		extendField(fieldList) {
			const resetList = async list => {
				const arr = []
				for (const item of list) {
					const config = this.fieldMap[item.field]
					if (item.children) {
						item.children = await resetList(item.children)
					}
					if (config) {
						// 从config读取配置
						for (const i in config) {
							if (config.hasOwnProperty(i)) {
								if (
									![
										'field',
										'children',
										'show',
										'width'
									].includes(i)
								) {
									item[i] = config[i]
								}
							}
						}
					}
					if (typeof item.canShow === 'undefined') {
						// 如果没有传值
						arr.push(item)
					} else if (
						item instanceof Promise &&
						(await item.canShow())
					) {
						// 如果是一个promise对象
						arr.push(item)
					} else if (
						typeof item.canShow === 'function' &&
						item.canShow()
					) {
						// 如果是一个普通函数
						arr.push(item)
					} else if (
						typeof item.canShow === 'boolean' &&
						item.canShow
					) {
						// 如果是一个表达式
						arr.push(item)
					}
				}
				return arr
			}
			return resetList(fieldList)
		},
		async getTable(arg) {
			let data,
				params = {
					current: this.table.current,
					rowCount: this.showPages ? this.table.rowCount : -1,
					sort: this.table.sort,
					filters: this.table.filters,
					project: this.table.project,
					query: this.table.query,
					keyword: this.keyword
						? encodeURIComponent(this.keyword)
						: this.keyword,
					keywordID: this.keywordID || null,
					searchType: this.keywordType,
					defineParams: this.table.defineParams
				}
			this.loading = true
			if (this.url) {
				this.$emit('loadingChange', true)
				// 如果有url传入，则直接发请求
				data = await this.getTableData(params)
				setTimeout(() => {
					this.$emit('loadingChange', false)
				}, 500)
			} else if (typeof this.tableData === 'object') {
				// 直接传入的table数据
				data = this.tableData
			} else if (typeof this.tableData === 'function') {
				this.$emit('loadingChange', true)
				// 传入的获取 tableData 的方法
				data = await this.tableData(params, arg)
				setTimeout(() => {
					this.$emit('loadingChange', false)
				}, 500)
			}
			if (this.enableNetField) {
				if (!data.field) data.field = []
				// 把前端设置的render复制给接口返回的field
				this.defaultSort = {}
				const fields = data.field.map(el => {
					if (el.sortStatus == 1) {
						this.defaultSort.order = 'ascending'
						this.defaultSort.prop = el.field
					} else if (el.sortStatus == -1) {
						this.defaultSort.order = 'descending'
						this.defaultSort.prop = el.field
					}
					const o = this.field.find(item => item.field === el.field)
					const map = ['render', 'rowspan', 'config', 'canShow']
					if (o) el = Object.assign({}, el, cleanData(o, map))
					return el
				})
				// 将表头对象转成map对象集合
				await this.setFieldMap(fields)
				await this.setTableField(fields)
			}
			// 不管是否显示都将列表数据筛入rows，
			this.table.list = this.getRowspan(data.rows, this.tableField)
			// 合计
			if (data.summary) {
				this.table.summary = data.summary
			}
			// 数据请求完后可以通知父组件更新后的数据
			this.$emit('updateTable', data)
			if (this.showPages) {
				// 列表显示分页
				this.table.total = data.total
			}
			// 如果是动态表头
			if (!this.enableNetField) {
				// 防止el-table的合计行没有计算高度的bug，重新渲染校准
				this.$nextTick(() => {
					if (this.$refs.table) {
						this.$refs.table.bodyWrapper.scrollTop = 0
						this.$refs.table.doLayout()
					}
					this.loading = false
				})
			}
		},
		async expandChange(row, expandedRows) {
			this.expandRowKeys = []
			if (expandedRows.length > 0) {
				expandedRows.forEach(item => {
					this.expandRowKeys.push(item.id)
				})
			}
			if (
				this.expandRowKeys.includes(row.id) &&
				typeof this.expandTableData === 'function'
			) {
				const arr = await this.expandTableData(row, expandedRows)
				this.$set(row, 'tableData', arr)
			}
		},
		getTableData(query) {
			return new Promise(resolve => {
				this.$axios({
					url: this.url,
					data: query,
					error: true
				})
					.then(res => {
						resolve(res.data)
					})
					.catch(res => {
						this.$message({
							type: 'error',
							message: res.msg
						})
						resolve({
							current: 0,
							total: 0,
							rows: [],
							rowCount: query.rowCount,
							field: []
						})
					})
			})
		},
		getRowspan(data, tableField) {
			// 初始化
			data.forEach(item => {
				// 如果不存在则赋值
				if (typeof item.rowspan === 'undefined') {
					item.rowspan = {}
				}
			})
			if (!tableField) {
				return data
			}

			// 统计合并行
			for (let i = 0; i < tableField.length; i++) {
				const field = tableField[i].field
				const rowspan = tableField[i].rowspan
				const children = tableField[i].children
				if (children && children.length > 0) {
					this.getRowspan(data, children)
				}
				if (!rowspan) {
					continue
				}
				for (let row = 0; row < data.length; row++) {
					if (typeof data[row].rowspan[field] === 'undefined') {
						// 默认为1
						data[row].rowspan[field] = 1
					}
					for (
						let rowNext = row + 1;
						rowNext < data.length;
						rowNext++
					) {
						if (typeof data[rowNext].rowspan === 'undefined') {
							// 如果不存在则赋值
							data[rowNext].rowspan = {}
						}
						if (
							typeof data[row][field] !== 'undefined' &&
							data[row][field] !== null &&
							((typeof rowspan === 'string' &&
								data[row][rowspan] ===
									data[rowNext][rowspan]) ||
								(typeof rowspan === 'function' &&
									rowspan(data[row], data[rowNext])))
						) {
							// 当前行的值跟下一行的值相等
							data[row].rowspan[field] =
								data[row].rowspan[field] + 1
							data[rowNext].rowspan[field] = 0
							if (rowNext === data.length - 1) {
								row = rowNext
								break
							}
						} else {
							// 当前行与循环行不相等，则跳出，从循环行开始重新循环
							row = rowNext - 1
							data[rowNext].rowspan[field] = 1
							break
						}
					}
				}
			}
			return data
		},
		summaryMethod(param) {
			if (!this.showSummary) {
				return
			}
			const { columns } = param
			let sums = [],
				start = 0
			columns.forEach((column, index) => {
				const field = column.property
				let colSpan = 1
				if (index === 0) {
					sums[index] = this.summaryName
					if (this.showSelection) {
						colSpan++
						start++
					}
					if (this.showIndex) {
						colSpan++
						start++
					}
					column.colSpan = colSpan
				} else if (this.table.summary && index > start) {
					sums[index - start] = this.table.summary[field]
				}
			})
			return sums
		},
		changePageSize(value) {
			this.table.rowCount = value
			this.table.current = 1
			this.getTable({ type: 'changePageSize' })
			const { current, rowCount } = this.table
			this.$emit('pageChange', { current, rowCount })
		},
		changeCurrentPage(value) {
			this.table.current = value
			this.getTable({ type: 'changeCurrentPage' })
			const { current, rowCount } = this.table
			this.$emit('pageChange', { current, rowCount })
		},
		sortChange({ prop, order }) {
			if (order === 'ascending') {
				order = 1
			} else if (order === 'descending') {
				order = -1
			} else {
				order = null
			}
			this.table.sort = {
				key: prop,
				order
			}
			this.table.current = 1
			this.$emit('updateTableProps', {
				prop: 'sort',
				data: this.table.sort
			})
			this.getTable({ type: 'sortChange' })
		},
		getFilters(data) {
			return new Promise(resolve => {
				this.$axios({
					url: data.filterConfig.url
				}).then(res => {
					resolve(res.data)
				})
			})
		},
		filterChange(filters) {
			const obj = {}
			for (const i in filters) {
				if (filters[i].length > 0) {
					obj[i] = filters[i].join(',')
				}
			}
			this.table.filters = obj
			this.table.current = 1
			this.getTable({ type: 'filterChange' })
		},
		setting() {
			this.$box({
				title: '表头设置',
				width: '600px',
				template: settingBox,
				templateOpt: {
					tableField: this.tableField
				},
				onOk: instance => {
					return new Promise(resolve => {
						const list = instance.submit()
						this.userConfig = list
						this.changeUserConfig(list)
						this.tableField = []
						this.$nextTick(() => {
							this.tableField = list
							this.table.list = this.getRowspan(this.table.list)
							this.$nextTick(() => {
								if (this.$refs.table) {
									this.$refs.table.bodyWrapper.scrollTop = 0
									this.$refs.table.doLayout()
								}
							})
							resolve()
						})
					})
				}
			})
		},
		getSpanMethod(param) {
			const { row, column, rowIndex, columnIndex } = param
			const key = column.columnKey
			if (this.spanMethod) {
				const type = getType(this.spanMethod)
				if (['array', 'object'].includes(type)) return this.spanMethod
				else if (type === 'function') return this.spanMethod(param)
			} else if (
				key &&
				row.rowspan &&
				typeof row.rowspan[key] !== 'undefined'
			) {
				return {
					rowspan: row.rowspan[key],
					colspan: 1
				}
			} else {
				return {
					rowspan: 1,
					colspan: 1
				}
			}
		},
		getTableRowClassName({ row, rowIndex }) {
			if (
				this.selection.find(
					p => p[this.tableKey] === row[this.tableKey]
				)
			) {
				return 'current-row'
			} else if (this.tableRowClassName) {
				const type = getType(this.tableRowClassName)
				if (['string', 'array'].includes(type))
					return [].concat(this.tableRowClassName)
				else if (type === 'function')
					return this.tableRowClassName(row, rowIndex)
			}
			return ''
		},
		changeSelection(selection) {
			this.selection = selection
		},
		rowClick(row) {
			this.$refs.table.toggleRowSelection(row)
		},
		resetTreeTable(id) {
			const store = (this.$refs.table && this.$refs.table.store) || null
			if (store && this.tableLoad) {
				if (id) {
					store.states.treeData[id] = {
						display: true,
						loading: false,
						loaded: false,
						expanded: false,
						children: [],
						lazy: true,
						level: 0
					}
				} else {
					for (const k in store.states.treeData) {
						store.states.treeData[k] = {
							display: true,
							loading: false,
							loaded: false,
							expanded: false,
							children: [],
							lazy: true,
							level: 0
						}
					}
				}
			}
		},
		reload(current = 1, id) {
			if (this.expandRowKeys.length > 0) {
				this.expandRowKeys = []
			}
			this.resetTreeTable(id)
			// 重新加载数据
			this.table.current = current
			this.getTable({ type: 'reload' })
		},
		reloadCurrentPage(id) {
			this.resetTreeTable(id)
			// 重新加载当前页面数据
			this.getTable({ type: 'reloadCurrentPage' })
		},
		reloadQuery(form) {
			// 重新加载query
			const params = [
				'project',
				'keyword',
				'keywordID',
				'searchType',
				'query',
				'filter',
				'current',
				'rowCount',
				'sort',
				'defineParams'
			]
			for (const i in form) {
				if (params.includes(i)) {
					this.table[i] = form[i]
				}
			}
			this.reload()
		},
		reloadKeyword(keyword, searchType) {
			// 重新加载关键词
			his.table.keyword = keyword
			this.table.searchType = searchType
			this.reload()
		},
		reloadSearchType(type) {
			if (this.table.keyword) {
				this.table.searchType = type
				this.reload()
			}
		},
		reloadProject(project) {
			this.table.project = project
			this.reload()
		},
		getSelection() {
			return this.selection
		}
	}
}
</script>
