var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-cropper" }, [
    _c("div", { staticClass: "wy-cropper-box" }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.croppable,
            expression: "croppable",
          },
        ],
        ref: "cropper",
        attrs: { id: "image", src: _vm.url, alt: "Picture" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }