var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-cloud-guide" }, [
    _c(
      "div",
      { staticClass: "wy-cloud-guide_step" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "wy-cloud-guide_step_item",
            class: { active: _vm.step >= index },
          },
          [
            index >= _vm.step
              ? _c("i", { staticClass: "wy-cloud-guide_step_num" }, [
                  _vm._v(_vm._s(index + 1)),
                ])
              : _vm._e(),
            index < _vm.step
              ? _c("i", {
                  staticClass: "wy-cloud-guide_step_num el-icon-check",
                })
              : _vm._e(),
            _c("h2", { staticClass: "wy-cloud-guide_step_title" }, [
              _vm._v(_vm._s(item.title)),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "wy-cloud-guide_content" },
      [
        _c(
          _vm.curComponent,
          _vm._b(
            { ref: _vm.curComponent.name, tag: "component" },
            "component",
            _vm.$attrs,
            false
          )
        ),
      ],
      1
    ),
    _c("div", { staticClass: "wy-cloud-guide_footer" }, [
      _c("div", { staticClass: "wy-cloud-guide_footer_tips" }, [
        _c("span", [_vm._v(_vm._s(_vm.list[_vm.step].description))]),
        _vm.list[_vm.step].cloud
          ? _c(
              "span",
              {
                staticClass: "link",
                on: {
                  click: function ($event) {
                    return _vm.openCloud(_vm.list[_vm.step].cloud)
                  },
                },
              },
              [_vm._v("查看指引")]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "wy-cloud-guide_footer_btn" },
        [
          _vm.step > 0
            ? _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handlePrev } },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleNext },
            },
            [
              _vm._v(
                _vm._s(_vm.step == _vm.list.length - 1 ? "完成" : "下一步")
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }