(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("Vuex"), require("Quill"));
	else if(typeof define === 'function' && define.amd)
		define("CloudUI", ["Vue", "Vuex", "Quill"], factory);
	else if(typeof exports === 'object')
		exports["CloudUI"] = factory(require("Vue"), require("Vuex"), require("Quill"));
	else
		root["CloudUI"] = factory(root["Vue"], root["Vuex"], root["Quill"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__81__, __WEBPACK_EXTERNAL_MODULE__302__) {
return 