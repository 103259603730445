/**
 * Created by Su on 2018/5/10.
 */
/**
 * @module v-drag
 * @description 拖拽区域
 */
import { off, on } from 'tool'

let start = {},
	end = {},
	move = {},
	isAppend = false,
	element,
	options
/**
 * @param e
 */
function dragstart(e) {
	start = {
		x: e.clientX,
		y: e.clientY
	}
	element = document.createElement('div')
	element.style.position = 'fixed'
	element.style.zIndex = 1000
	element.style.left = start.x + 'px'
	element.style.top = start.y + 'px'
	element.style.background = 'rgba(0,0,0,0.1)'
	element.style.border = '1px dashed #999'
	on(document.body, 'mousemove', drag)
	on(document.body, 'mouseup', dragend)
}
/**
 * @param e
 */
function drag(e) {
	if (!start || !element) {
		return
	}
	if (!isAppend) {
		document.body.appendChild(element)
		isAppend = true
	}
	end = {
		x: e.clientX,
		y: e.clientY
	}
	if (e.clientX - start.x > 0) {
		element.style.width = end.x - start.x + 'px'
		move.x1 = start.x
		move.x2 = end.x
	} else {
		element.style.width = start.x - end.x + 'px'
		element.style.left = end.x + 'px'
		move.x1 = end.x
		move.x2 = start.x
	}
	if (e.clientY - start.y > 0) {
		element.style.height = end.y - start.y + 'px'
		move.y1 = start.y
		move.y2 = end.y
	} else {
		element.style.height = start.y - end.y + 'px'
		element.style.top = end.y + 'px'
		move.y1 = end.y
		move.y2 = start.y
	}
	if (typeof options.move == 'function') {
		options.move(move)
	}
	window.getSelection
		? window.getSelection().removeAllRanges()
		: document.selection.empty()
}
/**
 *
 */
function dragend() {
	if (typeof options.end == 'function') {
		options.end({
			start,
			end
		})
	}
	if (element && isAppend) {
		document.body.removeChild(element)
	}
	element = null
	isAppend = false
	off(document.body, 'mousemove', this.drag)
	off(document.body, 'mouseup', this.dragend)
}

export default {
	name: 'drag-area',
	inserted: (el, binding) => {
		options = binding.value
		on(el, 'mousedown', dragstart)
	}
}
