<template>
	<div class="wy-pages">
		<div class="wy-pages_total">
			共<em>{{ total }}</em
			>条记录/<em>{{ totalPages }}</em
			>页
		</div>
		<div class="wy-pages_current">
			第<input
				class="wy-pages_input"
				:value="current"
				@input="changeCurrent($event)"
			/>页
		</div>
		<div
			title="上一页"
			class="wy-pages_prev el-icon-arrow-left"
			:class="{ disabled: current <= 1 }"
			@click="prev"
		></div>
		<div
			title="下一页"
			class="wy-pages_next el-icon-arrow-right"
			:class="{ disabled: current >= totalPages }"
			@click="next"
		></div>
		<el-popover ref="popover" trigger="click" width="120px">
			<ul class="wy-page-sizes-list">
				<li
					v-for="item in pageSizes"
					:key="item"
					@click="changePageSize(item)"
				>
					{{ item }}条/页
				</li>
			</ul>
			<div slot="reference" class="wy-page-sizes">
				<span>{{ pageSize }}条/页</span>
				<i class="el-icon-arrow-down"></i>
			</div>
		</el-popover>
	</div>
</template>
<script>
export default {
	name: 'WyPages',
	props: {
		total: {
			type: Number,
			default: 0
		},
		pageSizes: {
			type: Array,
			default() {
				return [20, 50, 100]
			}
		},
		pageSize: {
			type: Number,
			default: 20
		},
		current: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			timeout: null
		}
	},
	computed: {
		totalPages() {
			return Math.ceil(this.total / this.pageSize) || 0
		}
	},
	methods: {
		prev() {
			if (this.current <= 1) {
				return
			}
			this.$emit('changeCurrentPage', this.current - 1)
		},
		next() {
			if (this.current >= this.totalPages) {
				return
			}
			this.$emit('changeCurrentPage', this.current + 1)
		},
		changeCurrent(e) {
			this.timeout && clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				let num = e.target.value.replace(/[^\d]/g, '')
				if (num) {
					num = parseInt(num)
				} else {
					num = 1
				}
				if (num > this.totalPages) {
					num = this.totalPages
				}
				if (num < 1) {
					num = 1
				}
				e.target.value = num
				if (num != this.current) {
					this.$emit('changeCurrentPage', num)
				}
			}, 300)
		},
		changePageSize(num) {
			this.$refs.popover.doClose()
			this.$emit('changePageSize', num)
		}
	}
}
</script>
