import tmp from './index.vue'

const defaultOpt = {
	width: '800px',
	height: '500px',
	options: {
		projectID: '',
		selection: [],
		typeList: [1, 2, 3, 4],
		type: 4
	},
	onOk: null,
	onClose: null,
	onCancel: null
}

/**
 * @param opt
 */
export default function (opt = {}) {
	const config = Object.assign({}, defaultOpt, opt)
	this.$box({
		template: tmp,
		showHeader: false,
		width: config.width,
		height: config.height,
		templateOpt: config.options,
		onOk: config.onOk,
		onClose: config.onClose,
		onCancel: config.onCancel
	})
}
