<template>
	<wy-box
		:visible="visible"
		:title="title"
		width="800px"
		height="400px"
		@onOk="save"
		@onCancel="cancel"
		@onClose="cancel"
	>
		<div class="wy-money-define-box">
			<li class="wy-money-define-box_all" @click="selectAll">
				<i
					class="iconfont"
					:class="{
						'icon-checkbox-normal': !isSelectAll,
						'icon-checkbox-checked': isSelectAll
					}"
				></i>
				<span class="wy-money-define-box_name">全选</span>
			</li>
			<ul>
				<li
					v-for="item in list"
					:key="item.id"
					@click="checkItem(item)"
				>
					<i
						class="iconfont"
						:class="{
							'icon-checkbox-normal': !item.selected,
							'icon-checkbox-checked': item.selected
						}"
					></i>
					<span class="wy-money-define-box_name">{{
						item.name
					}}</span>
				</li>
			</ul>
		</div>
	</wy-box>
</template>
<script>
export default {
	name: 'WyMoneyDefineBox',
	components: {},
	props: {
		url: {
			type: String
		},
		title: {
			type: String
		},
		filter: {
			type: Object
		}
	},
	data() {
		return {
			visible: false,
			list: [],
			isSelectAll: false
		}
	},
	computed: {},
	created() {
		this.$nextTick(() => {
			this.getData()
		})
	},
	mounted() {},
	methods: {
		getData() {
			const params = Object.assign({}, this.filter)
			this.$axios({
				url: this.url,
				data: params
			}).then(res => {
				this.list = res.data
			})
		},
		checkItem(item) {
			this.$set(item, 'selected', !item.selected)
		},
		selectAll() {
			if (this.isSelectAll) {
				this.list.forEach(item => {
					this.$set(item, 'selected', false)
				})
			} else {
				this.list.forEach(item => {
					this.$set(item, 'selected', true)
				})
			}
			this.isSelectAll = !this.isSelectAll
		},
		save() {
			const selection = []
			this.list.forEach(item => {
				if (item.selected) {
					selection.push({
						id: item.id,
						name: item.name
					})
				}
			})
			this.visible = false
			this.onOk(selection)
		},
		cancel() {
			this.visible = false
			if (this.onCancel && typeof this.onCancel == 'function') {
				this.onCancel()
			}
		}
	}
}
</script>
