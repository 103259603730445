var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.curVisible
      ? _c(
          "div",
          {
            staticClass: "wy-box-wrap",
            style: { "z-index": _vm.zIndex },
            attrs: { tabindex: "-1" },
          },
          [
            _c(
              "div",
              {
                ref: "wyBox",
                staticClass: "wy-box",
                class: {
                  "wy-box__one-btn": _vm.btns === 1,
                  "wy-box__two-btn": _vm.btns === 2,
                  "wy-box__no-btn": _vm.btns === 0,
                },
                style: {
                  width: _vm.mWidth || _vm.width,
                  maxWidth: !_vm.max ? _vm.maxW : "",
                },
              },
              [
                _vm.showHeader
                  ? _c(
                      "div",
                      {
                        staticClass: "wy-box-header",
                        class: { "wy-box__slot-title": _vm.$slots.title },
                      },
                      [
                        !_vm.$slots.title
                          ? [_vm._v(_vm._s(_vm.title))]
                          : _vm._e(),
                        _vm.$slots.title ? _vm._t("title") : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.$slots.default
                  ? _c(
                      "div",
                      {
                        staticClass: "wy-box-content",
                        class: { "wy-no-header": !_vm.showHeader },
                        style: {
                          height: _vm.mHeight || _vm.height,
                          maxHeight: !_vm.max ? _vm.maxH : "",
                        },
                      },
                      [_vm._t("default")],
                      2
                    )
                  : _vm._e(),
                _vm.showFooter
                  ? _c(
                      "div",
                      { staticClass: "wy-box-footer" },
                      [
                        !_vm.$slots.footer
                          ? [
                              _vm.showOkBtn
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "wy-box__btn-theme",
                                      class: _vm.loading
                                        ? "wy-box__btn__disabled"
                                        : "wy-box__btn",
                                      attrs: {
                                        autofocus: "",
                                        theme: "",
                                        disabled: _vm.loading,
                                      },
                                      on: { click: _vm.handleOk },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(_vm.okBtnName) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showCancelBtn
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "wy-box__btn",
                                      on: { click: _vm.handleCancel },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(_vm.cancelBtnName) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.$slots.footer ? _vm._t("footer") : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _c("div", { staticClass: "wy-box-ico" }, [
                  _c(
                    "div",
                    { staticClass: "wy-box-filter" },
                    [_vm._t("filter")],
                    2
                  ),
                  _vm.showMax && _vm.max
                    ? _c("i", {
                        staticClass: "wy-box-max iconfont icon-tuichuzhuanhuan",
                        attrs: { title: "恢复默认" },
                        on: { click: _vm.handleMax },
                      })
                    : _vm._e(),
                  _vm.showMax && !_vm.max
                    ? _c("i", {
                        staticClass: "wy-box-max iconfont icon-quanping",
                        attrs: { title: "最大化" },
                        on: { click: _vm.handleMax },
                      })
                    : _vm._e(),
                  _vm.showClose
                    ? _c("i", {
                        staticClass: "wy-box-close iconfont icon-del",
                        attrs: { title: "关闭" },
                        on: { click: _vm.handleClose },
                      })
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }