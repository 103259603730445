var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-design-set" }, [
    _c("header", { staticClass: "wy-design-set_header" }, [
      _c(
        "h1",
        {
          staticClass: "wy-design-set_title",
          attrs: { "data-guide": "design-set-title" },
        },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
      ),
      _c(
        "div",
        { staticClass: "wy-design-set_btn-group wy-fr" },
        [_vm._t("wy-btn-group")],
        2
      ),
    ]),
    _c("section", { staticClass: "wy-design-set_main" }, [
      !_vm.isGroup
        ? _c("div", [
            _c(
              "table",
              { staticClass: "wy-design-set_list" },
              [
                _vm._l(_vm.list, function (item, index) {
                  return [
                    _vm.isVisible(item.permission, item.schema)
                      ? _c("tr", { key: index }, [
                          _c(
                            "th",
                            {
                              staticClass: "wy-design-set_name",
                              attrs: { width: _vm.nameWidth },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(item.name) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "wy-design-set_description" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(item.description) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "wy-design-set_ctrl" },
                            [
                              item.url
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        "data-guide":
                                          "btn-1-" + (item.permission || index),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$openUrl(
                                            item.name,
                                            item.url
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.buttonName))]
                                  )
                                : _vm._e(),
                              item.http
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: {
                                        href: "javascript: void(0);",
                                        "data-guide":
                                          "link-1-" +
                                          (item.permission || index),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRedirect(item.http)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.buttonName))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.isGroup
        ? _c(
            "div",
            _vm._l(_vm.list, function (group, index) {
              return _c(
                "wy-panel-fold",
                { key: index, attrs: { title: group.name } },
                [
                  _vm.list instanceof Array
                    ? _c(
                        "table",
                        { staticClass: "wy-design-set_list" },
                        [
                          _vm._l(group.list, function (item, index) {
                            return [
                              _vm.isVisible(item.permission, item.schema)
                                ? _c("tr", { key: index }, [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "wy-design-set_name",
                                        attrs: { width: _vm.nameWidth },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(item.name) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "wy-design-set_description",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(item.description) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "wy-design-set_ctrl" },
                                      [
                                        item.url
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  "data-guide":
                                                    "btn-1-" + index,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$openUrl(
                                                      item.name,
                                                      item.url
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.buttonName))]
                                            )
                                          : _vm._e(),
                                        item.http
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "link",
                                                attrs: {
                                                  href: "javascript: void(0);",
                                                  "data-guide":
                                                    "link-1-" + index,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRedirect(
                                                      item.http
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.buttonName))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }