var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "picContent",
      staticClass: "wy-show-pic-content",
      class: { "wy-show-pic_header": _vm.title },
    },
    [
      _c(
        "el-carousel",
        {
          attrs: {
            height: _vm.height,
            autoplay: _vm.auto,
            "initial-index": _vm.index,
            arrow: _vm.list && _vm.list.length > 1 ? "hover" : "never",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c("el-carousel-item", { key: item }, [
            _c(
              "div",
              {
                staticClass: "wy-show-pic_box",
                on: {
                  wheel: function ($event) {
                    $event.preventDefault()
                    return _vm.mousewheel($event, index)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-refresh",
                  on: {
                    click: function ($event) {
                      return _vm.refresh(index)
                    },
                  },
                }),
                _c("img", {
                  staticClass: "wy-show-pic_img",
                  style: _vm.imgStyle[index],
                  attrs: { src: item },
                  on: {
                    mousedown: function ($event) {
                      return _vm.mousedown($event, index)
                    },
                  },
                }),
              ]
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }