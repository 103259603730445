var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wy-project", style: { width: _vm.width } }, [
    _c("div", { staticClass: "wy-project-box" }, [
      _c(
        "div",
        { staticClass: "wy-project-header" },
        [
          _c("h2", { staticClass: "wy-project_title" }, [
            _vm._v("选择项目范围"),
          ]),
          _c("el-input", {
            staticClass: "wy-border-bottom",
            attrs: {
              noborder: "",
              placeholder: "请输入项目名称...",
              "suffix-icon": "el-icon-search",
            },
            on: { change: _vm.keywordInput },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wy-project-content", style: { height: _vm.height } },
        [
          _c(
            "div",
            { staticClass: "wy-project-content-tree" },
            [
              _c("el-tree", {
                staticClass: "wy-project_normal",
                attrs: {
                  "default-expanded-keys": [-1],
                  "node-key": "id",
                  data: _vm.orgTree,
                  props: _vm.treeProps,
                },
                on: { "node-click": _vm.handleClickTree },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wy-project-content-list" },
            [
              _c(
                "wy-table",
                {
                  ref: "table",
                  attrs: {
                    url: "/projects/getEmployeeProjectV2",
                    "enabled-multi-page": _vm.enabledMultiPage,
                    auto: _vm.auto,
                    multi: _vm.multi,
                    filter: _vm.filter,
                    "selected-rows": _vm.projectList,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "项目名称" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "wy-project-footer" }, [
        _c(
          "button",
          {
            staticClass: "wy-project_btn wy-project_btn-theme",
            attrs: { theme: "" },
            on: { click: _vm.submit },
          },
          [_vm._v("\n\t\t\t\t确定\n\t\t\t")]
        ),
        _c(
          "button",
          { staticClass: "wy-project_btn", on: { click: _vm.cancel } },
          [_vm._v("取消")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }